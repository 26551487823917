import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import TextEditor from '~/components/shared/TextEditor';
import UploadImage from '~/components/shared/UploadImage';
import { Button, Dropdown, ErrorMessage, Icon, Input, Textarea } from '~/ui';
import { isTextEditorEmpty } from '~/utils';

import { BigContainer, ButtonsContainer, DeleteButton, Form, TextEditorTitle } from './styles';

const NewsArticleForm = ({ isEdit, articleError, competitionsList, formSubmit, deleteArticle, deleteArticleImage }) => {
  const history = useHistory();
  const { id: urlCommunityId } = useParams();

  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const selectedNews = useSelector(state => state.news.selectedNews);
  const [title, setTitle] = useState(selectedNews.title);
  const [subtitle, setSubtitle] = useState(selectedNews.title);
  const [relatedCompetition, setRelatedCompetition] = useState(null);
  const [image, setImage] = useState(selectedNews.image);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  if (communityId !== parseInt(urlCommunityId)) {
    history.push(`/admin/community/${communityId}/news`);
  }

  useEffect(() => {
    setTitle(selectedNews.title);
    setSubtitle(selectedNews.subtitle);
    setImage(selectedNews.imageUrl);

    const blocksFromHTML = convertFromHTML(selectedNews.message);
    const stateFromHTML = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(stateFromHTML));
  }, [selectedNews]);

  const handleImageUri = imageUri => setImage(imageUri);
  const editorHasText = isTextEditorEmpty(editorState);

  const handleSubmitButton = async e => {
    e.preventDefault();
    let newImage;

    const editorStateHTML = stateToHTML(editorState.getCurrentContent());

    if (image && image.startsWith('https')) {
      // if the image has not changed, send `null` which will not change the current news image
      newImage = null;
    } else if (image === '') {
      newImage = null;
    } else {
      newImage = image;
    }

    const data = {
      title: title.trim(),
      subtitle: subtitle.trim(),
      message: editorStateHTML,
      imageUri: newImage,
      competitionId: relatedCompetition,
      communityId,
      visible: true,
    };

    formSubmit(data);
  };

  return (
    <Form>
      <Input label='Title' type='text' value={title} onChange={e => setTitle(e.target.value.trimStart())} />
      <Textarea
        label='Excerpt'
        placeholder='A short summary of the article or a subtitle.'
        value={subtitle}
        onChange={e => setSubtitle(e.target.value.trimStart())}
      />
      {!isEdit && competitionsList && (
        <Dropdown
          getOptionLabel={competition => competition.name}
          getOptionValue={competition => competition.name}
          label='In Competition'
          optional
          options={competitionsList}
          placeholder='Search competitions'
          showOptionIcon
          onChange={value => setRelatedCompetition(value.id)}
        />
      )}
      <BigContainer>
        <UploadImage deleteImage={deleteArticleImage} handleImageUri={handleImageUri} initialImage={image} />
        <TextEditorTitle>Article</TextEditorTitle>
        <TextEditor editorState={editorState} setEditorState={setEditorState} />
        {articleError && <ErrorMessage>{articleError}</ErrorMessage>}
        <ButtonsContainer>
          <Button disabled={!title || !subtitle || !editorHasText} onClick={handleSubmitButton}>
            <Icon color='tmGreen' cursor='pointer' icon={isEdit ? 'edit' : 'newsPlus'} />
            {isEdit ? 'Update' : 'Publish'}
          </Button>
          {isEdit && (
            <DeleteButton type='button' onClick={deleteArticle}>
              <Icon color='danger' cursor='pointer' icon='trash' />
              Delete
            </DeleteButton>
          )}
        </ButtonsContainer>
      </BigContainer>
    </Form>
  );
};

export default NewsArticleForm;
