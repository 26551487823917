import React from 'react';

import { Icon, Table } from '~/ui';

import { DeselectButton } from './styles';
import { getPlayersColumns, getPlayersData } from './utils';

const CompetitionPlayersTable = ({
  competitionType,
  groupsAndMembers,
  data,
  error,
  isLoading,
  selectedItems = [],
  tableType,
  onAdd,
  onClear,
}) => {
  return (
    <>
      <Table
        columns={getPlayersColumns({ competitionType, groupsAndMembers, selectedItems, tableType, onAdd })}
        data={getPlayersData({ data })}
        error={error}
        isLoading={isLoading}
        itemsName='players'
        selectedItems={selectedItems.length && selectedItems.map(player => player.accountId)}
      />
      {tableType === 'addPlayers' && (
        <DeselectButton noBorder small onClick={onClear}>
          <Icon cursor='pointer' icon='userX' />
          Deselect all
        </DeselectButton>
      )}
    </>
  );
};

export default CompetitionPlayersTable;
