import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Title = styled.h3`
  margin: 0 0 16px 0;
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;

export const NoticeContainer = styled.div`
  margin: 24px 0 32px;
`;

export const Notice = styled.p`
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

export const OptionNoticeContainer = styled.div`
  display: flex;
  margin: 16px 0 32px;
  padding: 12px 16px;
  background: ${colors.highlight1};

  > p {
    margin: 0;
    margin-left: 12px;
  }
`;
