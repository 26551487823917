import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const InputsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  position: relative;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const StatusTitle = styled.h1`
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  margin: 0;
  color: ${colors.white};
  text-align: center;
`;

export const StatusMessage = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${colors.ash2};
  text-align: center;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isReversed }) => (isReversed ? 'row-reverse' : 'row')};
  gap: 8px;
`;

export const UserName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
`;

export const ScoresContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Score = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${({ isWin }) => (isWin ? colors.tmGreen : colors.ash2)};
`;

export const ScoreTableTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.whiteOpacity._5};
  margin-bottom: -24px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.invert};
`;
