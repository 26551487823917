import 'react-phone-number-input/style.css';

import React, { useRef, useState } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { uploadUserAvatarRequest } from '~/api';
import AsyncLocationDropdown from '~/components/shared/AsyncLocationDropdown';
import { ANALYTICS_EVENTS } from '~/enums';
import { logout } from '~/store/auth/utils';
import { setupUserProfile } from '~/store/user/utils';
import { Button, DatePicker, Dropdown, ErrorMessage, Icon, Input, Textarea } from '~/ui';
import { Label } from '~/ui/components/Input/styles';
import { subDays, trackAnalyticsEvent } from '~/utils';

import { genders, playingHands, playingStyles, tennisLevels } from './placeholder';
import { ChangeImgButton, FormContainer, Img, Logout, Notice, PhoneInputWrapper } from './styles';

const CompleteProfileForm = ({ isEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const hiddenImageFileInput = useRef(null);
  const user = useSelector(state => state.user);
  const { accountId } = useSelector(state => state.auth);
  const userError = useSelector(state => state.user.error);

  const [aboutMe, setAboutMe] = useState(!user.aboutMe ? '' : user.aboutMe);
  const [birthday, setBirthday] = useState(user.birthday);
  const [firstName, setFirstName] = useState(user.firstName);
  const [gender, setGender] = useState(user.gender);
  const [hometown, setHometown] = useState(user.hometown);
  const [lastName, setLastName] = useState(user.lastName);
  const [racket, setRacket] = useState(user.favoriteRacket);
  const [apparel, setApparel] = useState(user.favoriteApparel);
  const [image, setImage] = useState(user.avatarUrl);
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(user.phone.phoneNumber);
  const [mobileCountryCode, setMobileCountryCode] = useState(user.phone.countryCode);
  const [playingHand, setPlayingHand] = useState(user.playingHand);
  const [playingStyle, setPlayingStyle] = useState(user.playingStyle);
  const [playingStyleDescription, setPlayingStyleDescription] = useState('');
  const [tennisLevel, setTennisLevel] = useState(user.tennisLevel);
  const [tennisLevelDescription, setTennisLevelDescription] = useState('');

  const handleFileUpload = async e => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleCompleteProfileSubmit = async e => {
    e.preventDefault();
    const { preferredMatchTime, favoritePlayer, favoriteATPTournament, favoriteBalls } = user;

    const data = {
      aboutMe,
      birthday,
      favoriteApparel: apparel,
      favoriteATPTournament,
      favoriteBalls,
      favoritePlayer,
      favoriteRacket: racket,
      firstName: firstName.trim(),
      gender: gender.gender,
      hometown,
      lastName: lastName.trim(),
      phone: {
        phoneNumber: mobilePhoneNumber,
        countryCode: mobileCountryCode,
      },
      playingHand: playingHand.playingHand,
      playingStyle: playingStyle.playingStyle,
      preferredMatchTime,
      tennisLevel: tennisLevel.skillLevel,
    };

    if (isEdit) {
      if (image.includes('data:image')) {
        await uploadUserAvatarRequest(user.accountId, {
          avatarDataUri: image,
        });
      }
    }

    dispatch(setupUserProfile(accountId, data));
    history.push(isEdit ? '/home/my-profile' : '/home');
  };

  return (
    <FormContainer onSubmit={handleCompleteProfileSubmit}>
      {isEdit && (
        <>
          <Img alt='User avatar' src={image} />
          <ChangeImgButton noBorder type='button' onClick={() => hiddenImageFileInput.current.click()}>
            <Icon cursor='pointer' icon='camera' />
            Change picture
          </ChangeImgButton>

          <input
            accept='image/*'
            ref={hiddenImageFileInput}
            style={{ display: 'none' }}
            type='file'
            value=''
            onChange={handleFileUpload}
          />
        </>
      )}
      <Input label='First name' required value={firstName} onChange={e => setFirstName(e.target.value.trimStart())} />
      <Input label='Last name' required value={lastName} onChange={e => setLastName(e.target.value.trimStart())} />
      <DatePicker
        icon
        isClearable
        label='Birthday'
        maxDate={subDays(new Date(), 1)}
        value={birthday}
        onChange={date => setBirthday(date)}
      />
      <Dropdown
        getOptionLabel={gender => gender.prettyName}
        getOptionValue={gender => gender.gender}
        label='Gender'
        options={genders}
        placeholder='Please select your gender'
        value={gender}
        onChange={value => setGender(value)}
      />
      <AsyncLocationDropdown
        label='Hometown'
        placeholder='Search towns...'
        type='towns'
        value={hometown}
        onChange={hometown => setHometown(hometown)}
      />
      <PhoneInputWrapper>
        <Label>Mobile phone</Label>
        <PhoneInput
          countrySelectProps={{
            arrowComponent: () => <Icon icon='caretDown' size='s' />,
          }}
          defaultCountry='DK'
          focusInputOnCountrySelection
          initialValueFormat='national'
          inputComponent={Input}
          onChange={v => setMobilePhoneNumber(v?.replace(mobileCountryCode, ''))}
          onCountryChange={c => setMobileCountryCode(`+${getCountryCallingCode(c)}`)}
        />
      </PhoneInputWrapper>
      <Notice>
        We will never share or display your phone number publicly. It is shared only with users you schedule a match
        with to provide better means of contact.
      </Notice>
      <Dropdown
        getOptionLabel={tennisLevel => tennisLevel.prettyName}
        getOptionValue={tennisLevel => tennisLevel.skillLevel}
        label='Tennis Level'
        options={tennisLevels}
        placeholder='Please select your tennis level'
        value={tennisLevel}
        onChange={value => {
          setTennisLevel(value);
          setTennisLevelDescription(value.description);
        }}
      />
      {tennisLevelDescription && <Notice>{tennisLevelDescription}</Notice>}
      <Dropdown
        getOptionLabel={playingStyle => playingStyle.prettyName}
        getOptionValue={playingStyle => playingStyle.playingStyle}
        label='Playing Style'
        options={playingStyles}
        placeholder='Please select your playing style'
        value={playingStyle}
        onChange={value => {
          setPlayingStyle(value);
          setPlayingStyleDescription(value.description);
        }}
      />
      {playingStyleDescription && <Notice>{playingStyleDescription}</Notice>}
      <Dropdown
        getOptionLabel={playingHand => playingHand.prettyName}
        getOptionValue={playingHand => playingHand.playingHand}
        label='Playing Hand'
        options={playingHands}
        placeholder='Please select your playing hand'
        value={playingHand}
        onChange={value => setPlayingHand(value)}
      />
      <Input label='Favorite Racket' required value={racket} onChange={e => setRacket(e.target.value.trimStart())} />
      <Input label='Favorite Apparel' required value={apparel} onChange={e => setApparel(e.target.value.trimStart())} />
      {isEdit && <Textarea label='About me' value={aboutMe} onChange={e => setAboutMe(e.target.value)} />}

      {userError && <ErrorMessage>{userError.message}</ErrorMessage>}

      <Button
        disabled={
          !firstName ||
          !lastName ||
          !birthday ||
          !gender ||
          !hometown ||
          !mobilePhoneNumber ||
          !mobileCountryCode ||
          !tennisLevel ||
          !playingStyle ||
          !playingHand
        }
        fullWidth
        type='submit'
      >
        {isEdit ? 'Save changes' : 'Continue'}
      </Button>
      {!isEdit && (
        <Logout
          onClick={() => {
            trackAnalyticsEvent(ANALYTICS_EVENTS.USER_LOGGED_OUT);
            dispatch(logout());
          }}
        >
          Log Out
        </Logout>
      )}
    </FormContainer>
  );
};

export default CompleteProfileForm;
