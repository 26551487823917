import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  data: {
    myCommunities: [],
    communityInvites: [],
    myCompetitions: [],
    recentMatches: [],
    upcomingMatches: [],
    myChallenges: [],
  },
  errorMessage: '',
};

export const homeScreen = createSlice({
  name: 'homeScreen',
  initialState,
  reducers: {
    setHomeData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setIsLoadingHome: state => {
      state.isLoading = true;
    },
    setHomeErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.data = {
        myCommunities: [],
        communityInvites: [],
        myCompetitions: [],
        recentMatches: [],
        upcomingMatches: [],
        myChallenges: [],
      };
      state.isLoading = false;
    },
  },
});

export default homeScreen.reducer;

export const { setHomeData, setIsLoadingHome, setHomeErrorMessage } = homeScreen.actions;
