import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { WidgetItem, WidgetWrapper } from '~/components/shared/index';
import { getCommunityTournamentsScreen } from '~/store/tournaments/utils';
import { Icon } from '~/ui/index';

import { useTabs } from '../context/TabContext';
import { ShowAllText } from '../styles';
import TournamentsList from './TournamentsList';

const Tournaments = () => {
  const { selectedTab } = useTabs();
  const history = useHistory();

  const dispatch = useDispatch();
  const {
    auth,
    communities: { communityScreen },
    tournaments: { communityTournamentsScreen, communityTournamentsScreenLoading, communityTournamentsScreenError },
  } = useSelector(state => state);

  const { accountId } = auth;
  const communityId = get(communityScreen, ['community', 'id'], null);
  const communityName = get(communityScreen, ['community', 'communityName'], '');
  const myTournaments = get(communityTournamentsScreen, ['myTournaments'], []);
  const activeTournaments = get(communityTournamentsScreen, ['activeTournaments'], []);
  const eligibleForRegistration = get(communityTournamentsScreen, ['eligibleForRegistration'], []);

  useEffect(() => {
    if (communityId && accountId) {
      dispatch(getCommunityTournamentsScreen(communityId, accountId));
    }
  }, [communityId, accountId, dispatch]);

  return (
    <>
      <TournamentsList
        emptyListMessage={`You are not participating in any tournaments at ${communityName}.`}
        errorMessage={communityTournamentsScreenError}
        isLoading={communityTournamentsScreenLoading}
        tournaments={myTournaments}
        widgetIcon={selectedTab === 'overview' ? 'filledBgTrophy' : ''}
        widgetIconColor={selectedTab === 'overview' ? 'tmGreen' : ''}
        widgetTitle='My Tournaments'
      />
      {selectedTab === 'tournaments' && (
        <>
          <TournamentsList
            emptyListMessage={`There are no open tournaments at ${communityName}.`}
            errorMessage={communityTournamentsScreenError}
            isLoading={communityTournamentsScreenLoading}
            tournaments={eligibleForRegistration}
            widgetTitle='Open for Registration'
          />
          <TournamentsList
            emptyListMessage={`There are no active tournaments at ${communityName}.`}
            errorMessage={communityTournamentsScreenError}
            isLoading={communityTournamentsScreenLoading}
            tournaments={activeTournaments}
            widgetTitle='Active Tournaments'
          />
          <WidgetWrapper onClick={() => history.push(`/home/community/${communityId}/finished-tournaments`)}>
            <WidgetItem>
              <ShowAllText> See finished tournaments</ShowAllText>
              <Icon color='ash3' icon='arrowLeft' />
            </WidgetItem>
          </WidgetWrapper>
        </>
      )}
    </>
  );
};

export default Tournaments;
