import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';

import NewsList from '../../shared/NewsList';
import Leagues from '../Leagues/index';
import Tournaments from '../Tournaments/index';

const Overview = () => {
  const { communityScreen } = useSelector(state => state.communities);

  const { memberData } = communityScreen;

  const news = get(memberData, ['news', 'records'], []);
  const communityName = get(communityScreen, ['community', 'communityName'], '');

  return (
    <>
      <Leagues />
      <Tournaments />
      <NewsList emptyListMessage={`There are no news at ${communityName}.`} news={news} />
    </>
  );
};

export default Overview;
