import styled from 'styled-components';

import checkIcon from '../../assets/icons/check.svg';
import checkIcon2 from '../../assets/icons/check-white.svg';
import theme from '../../theme';

const { colors } = theme;

export const CheckboxContainer = styled.input`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.ash3};
  border-radius: 2px;
  background: ${colors.highlight1};
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:checked {
    background-image: url(${checkIcon});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const NewStyleCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const NewStyleCheckbox = styled.input`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  border: 1px solid ${colors.whiteOpacity._5};
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:checked {
    background-image: url(${checkIcon2});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-color: ${colors.tmGreen};
    border: 1px solid transparent;
  }
`;

export const NewStyleLabel = styled.label`
  ont-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  cursor: pointer;
`;
