import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 12px;
    object-fit: cover;
  }

  span {
    color: ${colors.whiteOpacity._75};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
`;
