import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 7px;
  margin-top: 16px;
  padding: 8px;
  padding-right: 24px;
  background: ${colors.highlight3};
  color: ${colors.white};
  font-size: 16px;
  line-height: 24px;
`;
