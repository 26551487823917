import styled from 'styled-components';

import { theme } from '~/ui';

const { mediaQueries } = theme;

export const Container = styled.span`
  &:first-of-type {
    span {
      margin-left: 0;
    }
  }

  @media screen and (max-width: ${mediaQueries.desktop}px) {
    margin-right: 6px;

    span {
      margin-left: 0 !important;
    }
  }
`;
