import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { removeMemberFromCommunityRequest, updateMemberRoleRequest } from '~/api';
import BackButton from '~/components/shared/BackButton';
import { MATCH_LOST, MEMBER_ERROR_MESSAGE } from '~/enums';
import { ErrorMessage, Icon } from '~/ui';
import { getPcrBalls, getUserMatchesStatistics } from '~/utils';

import MemberProfileModal from '../MemberProfileModal';
import DetailsInfo from './DetailsInfo';
import { Name, Value } from './DetailsInfo/styles';
import MatchesInfo from './MatchesInfo';
import {
  AboutMeText,
  BlackBlock,
  Container,
  EditProfile,
  FullName,
  Img,
  Notice,
  OptionsButton,
  Section,
  UserDetails,
  WinLoseCircleSymbol,
} from './styles';

const MemberProfile = ({ user, userError, isMyProfilePage }) => {
  const history = useHistory();
  const location = useLocation();

  const { accountId: currentUserId } = useSelector(state => state.user);
  const { id: communityId, communityName } = useSelector(state => state.communities.selectedCommunity.communityInfo);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [optionsModalError, setOptionsModalError] = useState('');
  const [removeMember, setRemoveMember] = useState(false);

  const { totalMatches, wonMatches, lostMatches, winRatio } = getUserMatchesStatistics(user);

  const noMatchesPlayed = !user.statsApi.winLose.length;
  const recentResults = user.statsApi.winLose.slice(0, 5);

  const handlePromoteMember = async selectedRole => {
    if (!newRole) {
      setNewRole(selectedRole);
    } else {
      try {
        await updateMemberRoleRequest(communityId, user.accountId, newRole);
        setShowOptionsModal(false);
        setNewRole('');
      } catch (error) {
        setOptionsModalError('Something went wrong with updating the member role. Try again.');
      }
    }
  };

  const handleRemoveMember = async () => {
    if (!removeMember) {
      setRemoveMember(true);
    } else {
      try {
        await removeMemberFromCommunityRequest(communityId, user.accountId);
        setShowOptionsModal(false);
        history.push(`/admin/community/${communityId}/members`);
      } catch (error) {
        if (error.data) {
          error.data.errors.map(({ code }) => {
            if (code === MEMBER_ERROR_MESSAGE.UNABLE_TO_REMOVE_ADMIN) {
              setOptionsModalError('Unable to remove admin.');
            }

            return null;
          });
        } else {
          setOptionsModalError('Something went wrong with removing the member. Try again.');
        }
      }
    }
  };

  const handleCancelButton = () => {
    setShowOptionsModal(false);
    setNewRole('');
    setRemoveMember(false);
    setOptionsModalError('');
  };

  return (
    <>
      <BackButton onClick={history.goBack} />
      {userError ? (
        <ErrorMessage>{userError}</ErrorMessage>
      ) : (
        <Container maxWidth={location.pathname.includes('home') ? 'unset' : '495px'}>
          <FullName>
            {user.firstName} {user.lastName}
          </FullName>
          {currentUserId !== user.accountId && (
            <OptionsButton noBorder onClick={() => setShowOptionsModal(true)}>
              <Icon cursor='pointer' icon='threeDots' /> Options
            </OptionsButton>
          )}
          <Img alt='Member image' src={user.avatarUrl} />
          {currentUserId === user.accountId && isMyProfilePage && (
            <EditProfile to={`${location.pathname}/edit`}>
              <Icon color='tmGreen' cursor='pointer' icon='edit' />
              Edit Profile
            </EditProfile>
          )}
          <BlackBlock>
            <span>{getPcrBalls(user.statsApi.playerCredRating)}</span>
            <Name>PCR</Name>
            <Value>
              {user.statsApi.noOfPcrReviews} {user.statsApi.noOfPcrReviews === 1 ? 'review' : 'reviews'}
            </Value>
          </BlackBlock>
          <div>
            <MatchesInfo number={totalMatches} text='Played' />
            <MatchesInfo number={wonMatches} text='Wins' />
            <MatchesInfo number={lostMatches} text='Loses' />
            <MatchesInfo number={`${winRatio}%`} text='Win Ratio' />
          </div>
          <UserDetails>
            {/* TODO: replace the email value with the real user email after backend improvements  */}
            {/* <DetailsInfo name='Email' value='example@email.com' /> */}
            <DetailsInfo name='Skill Level' value={user.tennisLevel.prettyName.replace('Level ', '')} />
            <DetailsInfo name='Playing style' value={user.playingStyle.prettyName} />
            <DetailsInfo name='Playing hand' value={user.playingHand.prettyName} />
            <DetailsInfo name='Racket' value={user.favoriteRacket} />
            <DetailsInfo name='Tennis apparel' value={user.favoriteApparel} />
          </UserDetails>
          <Section>
            <p>Recent form</p>
            {noMatchesPlayed ? (
              <Notice>No matches played</Notice>
            ) : (
              recentResults.map(({ symbol }, i) => (
                <WinLoseCircleSymbol key={i} symbol={symbol} title={`Match ${symbol === MATCH_LOST ? 'lost' : 'won'}.`}>
                  {symbol}
                </WinLoseCircleSymbol>
              ))
            )}
          </Section>
          {user.aboutMe && (
            <Section>
              <p>About me</p>
              <BlackBlock style={{ marginTop: '0' }}>
                <AboutMeText>{user.aboutMe}</AboutMeText>
              </BlackBlock>
            </Section>
          )}
          {/* TODO: hiding this for now, since API does not support it  */}
          {/* <Button disabled={noMatchesPlayed}>
            <Icon color='tmGreen' cursor='pointer' icon='match' />
            View match history
          </Button> */}
        </Container>
      )}
      {showOptionsModal && (
        <MemberProfileModal
          communityName={communityName}
          handleCancelButton={handleCancelButton}
          handlePromoteMember={handlePromoteMember}
          handleRemoveMember={handleRemoveMember}
          image={user.avatarUrl}
          name={`${user.firstName} ${user.lastName}`}
          newRole={newRole}
          optionsModalError={optionsModalError}
          removeMember={removeMember}
        />
      )}
    </>
  );
};

export default MemberProfile;
