import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCommunityLeaguesScreen } from '~/store/leagues/utils';

import { useTabs } from '../context/TabContext';
import FinishedLeagues from './FinishedLeagues';
import LeaguesList from './LeaguesList';

const Leagues = () => {
  const { selectedTab } = useTabs();

  const dispatch = useDispatch();
  const {
    auth,
    communities: { communityScreen },
    leagues: { communityLeaguesScreen, communityLeaguesScreenLoading, communityLeaguesScreenError },
  } = useSelector(state => state);

  const { accountId } = auth;
  const communityId = get(communityScreen, ['community', 'id'], null);
  const communityName = get(communityScreen, ['community', 'communityName'], '');
  const myLeagues = get(communityLeaguesScreen, ['myLeagues'], []);
  const activeLeagues = get(communityLeaguesScreen, ['activeLeagues'], []);
  const eligibleForRegistration = get(communityLeaguesScreen, ['eligibleForRegistration'], []);

  useEffect(() => {
    if (communityId && accountId) {
      dispatch(getCommunityLeaguesScreen(communityId, accountId));
    }
  }, [communityId, accountId, dispatch]);

  return (
    <>
      <LeaguesList
        emptyListMessage={`You are not participating in any leagues at ${communityName}.`}
        errorMessage={communityLeaguesScreenError}
        isLoading={communityLeaguesScreenLoading}
        leagues={myLeagues}
        widgetIcon={selectedTab === 'overview' ? 'filledBgTrophy' : ''}
        widgetIconColor={selectedTab === 'overview' ? 'tmGreen' : ''}
        widgetTitle='My Leagues'
      />
      {selectedTab === 'leagues' && (
        <>
          <LeaguesList
            emptyListMessage={`There are no open leagues at ${communityName}.`}
            errorMessage={communityLeaguesScreenError}
            isLoading={communityLeaguesScreenLoading}
            leagues={eligibleForRegistration}
            widgetTitle='Open for Registration'
          />
          <LeaguesList
            emptyListMessage={`There are no active leagues at ${communityName}.`}
            errorMessage={communityLeaguesScreenError}
            isLoading={communityLeaguesScreenLoading}
            leagues={activeLeagues}
            widgetTitle='Active Leagues'
          />
          <FinishedLeagues />
        </>
      )}
    </>
  );
};

export default Leagues;
