import React from 'react';

import { UserWithAvatar, WidgetItem, WidgetWrapper } from '~/components/shared/index';

import { Badge, Header, Text } from './styles';

const TournamentWinners = ({ winners }) => {
  if (winners.length === 0) {
    return null;
  }

  const winner = winners.find(el => el.place === 1);
  const runnerUp = winners.find(el => el.place === 2);

  return (
    <>
      <div>
        <Header>
          <Badge color='tmGreen' />
          <Text>Winner</Text>
        </Header>
        <WidgetWrapper>
          <WidgetItem>
            <UserWithAvatar
              avatarUrl={winner.userProfileCardView.avatarUrl}
              userName={`${winner.userProfileCardView.firstName} ${winner.userProfileCardView.lastName}`}
            />
          </WidgetItem>
        </WidgetWrapper>
      </div>
      <div>
        <Header>
          <Badge color='white' />
          <Text>Runner up</Text>
        </Header>
        <WidgetWrapper>
          <WidgetItem>
            <UserWithAvatar
              avatarUrl={runnerUp.userProfileCardView.avatarUrl}
              userName={`${runnerUp.userProfileCardView.firstName} ${runnerUp.userProfileCardView.lastName}`}
            />
          </WidgetItem>
        </WidgetWrapper>
      </div>
    </>
  );
};

export default TournamentWinners;
