import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCommunityFinishedLeaguesRequest } from '~/api';

import LeaguesList from './LeaguesList';

const FinishedLeagues = () => {
  const [finishedLeagues, setFinishedLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { communityId } = useParams();
  const { communityScreen } = useSelector(state => state.communities);

  const communityName = get(communityScreen, ['community', 'communityName'], '');

  useEffect(() => {
    try {
      const getFinishedLeagues = async () => {
        setIsLoading(true);
        const response = await getCommunityFinishedLeaguesRequest(communityId);
        const data = response.data.body;

        setFinishedLeagues(data.records);
      };
      getFinishedLeagues();
    } catch (error) {
      setErrorMessage('Something went wrong with getting finished leagues. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [communityId]);

  return (
    <LeaguesList
      emptyListMessage={`There are no finished leagues at ${communityName}.`}
      errorMessage={errorMessage}
      isLoading={isLoading}
      leagues={finishedLeagues}
      widgetTitle='Finished Leagues'
    />
  );
};

export default FinishedLeagues;
