import get from 'lodash/get';
import React from 'react';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';

import ListItemsStatusFeedback from '../ListItemStatusFeedback';
import MatchInviteItem from '../MatchInviteItem';

const MyChallenges = ({
  myChallenges = [],
  isLoading,
  errorMessage,
  emptyListMessage = 'There are no challenges',
  children,
}) => {
  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgFlag' iconColor='danger' title='Challenges' />
      {myChallenges.length > 0 ? (
        myChallenges.map(({ match, opponent, community }) => {
          const communityName = get(community, ['communityName'], '');
          return <MatchInviteItem communityName={communityName} key={match.id} match={match} opponent={opponent} />;
        })
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage={emptyListMessage}
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isLoading}
        />
      )}
      {children}
    </WidgetWrapper>
  );
};

export default MyChallenges;
