import React from 'react';

import { PCR_DESCRIPTION } from '~/enums';
import { Checkbox } from '~/ui';

import { Checkboxes, ReasonsTitle } from './styles';

const PCRDescriptionCheckboxes = ({ onCheckboxSelect }) => {
  return (
    <Checkboxes>
      <ReasonsTitle>Reasons for low rating?</ReasonsTitle>
      <Checkbox
        id={PCR_DESCRIPTION.NO_SHOW}
        isNewStyle
        label='Did not show up for the agreed match'
        onChange={e => onCheckboxSelect(e.target.checked, PCR_DESCRIPTION.NO_SHOW)}
      />
      <Checkbox
        id={PCR_DESCRIPTION.LATE}
        isNewStyle
        label='Was late for the agreed match time or left before the match was completed'
        onChange={e => onCheckboxSelect(e.target.checked, PCR_DESCRIPTION.LATE)}
      />
      <Checkbox
        id={PCR_DESCRIPTION.INCORRECT_SKILL}
        isNewStyle
        label='Skill level in the opponent’s profile was misleading (much higher or lower)'
        onChange={e => onCheckboxSelect(e.target.checked, PCR_DESCRIPTION.INCORRECT_SKILL)}
      />
      <Checkbox
        id={PCR_DESCRIPTION.UNFRIENDLY}
        isNewStyle
        label='Exhibited unfriendly and/or disrespectful behavior (e.g. swearing, shouting, …)'
        onChange={e => onCheckboxSelect(e.target.checked, PCR_DESCRIPTION.UNFRIENDLY)}
      />
      <Checkbox
        id={PCR_DESCRIPTION.CHEATER}
        isNewStyle
        label='Innacurate in trying to keep score (e.g. trying to steal points)'
        onChange={e => onCheckboxSelect(e.target.checked, PCR_DESCRIPTION.CHEATER)}
      />
      <Checkbox
        id={PCR_DESCRIPTION.RUSHING_OR_SLOWING}
        isNewStyle
        label='Deliberately slowing or rushing the game (e.g. long breaks between points, serving when receiver not ready, …)'
        onChange={e => onCheckboxSelect(e.target.checked, PCR_DESCRIPTION.RUSHING_OR_SLOWING)}
      />
    </Checkboxes>
  );
};

export default PCRDescriptionCheckboxes;
