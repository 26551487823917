export const timePeriods = ['am', 'pm'];

export const updateDateTime = (date, time) => {
  const [hours, minutes] = time.split(':');

  return new Date(date).setHours(hours, minutes);
};

export const getTimeString = date => {
  return `${date.getHours()}:${date.getMinutes()}`;
};
