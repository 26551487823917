import React, { useState } from 'react';

import FilterModal from '~/components/shared/FilterModal';
import { DatePicker, Grid } from '~/ui';

import { FilterRowTitle, StyledDropdown } from './styles';

const NewsFilterModal = ({
  setShowModal,
  filters: initialFilters,
  competitionsList,
  handleApplyFilters,
  handleClearFilters,
}) => {
  const [dateFrom, setDateFrom] = useState(initialFilters.dateFrom);
  const [dateTo, setDateTo] = useState(initialFilters.dateTo);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState(initialFilters.selectedCompetitionId);

  return (
    <FilterModal
      apply={() => handleApplyFilters({ dateFrom, dateTo, selectedCompetitionId })}
      back={() => setShowModal(false)}
      cancel={() => setShowModal(false)}
      clear={() => handleClearFilters()}
      showCancelButton={!dateFrom && !dateTo && !selectedCompetitionId}
      title='Filter News'
    >
      <Grid.Container>
        <FilterRowTitle>Dates</FilterRowTitle>
        <Grid.Row>
          <Grid.Column columns={2}>
            <DatePicker value={dateFrom} onChange={date => setDateFrom(date)} />
          </Grid.Column>
          <Grid.Column columns={2}>
            <DatePicker value={dateTo} onChange={date => setDateTo(date)} />
          </Grid.Column>
        </Grid.Row>
        <FilterRowTitle>In Competition</FilterRowTitle>
        <StyledDropdown
          getOptionLabel={competition => competition.name}
          getOptionValue={competition => competition.name}
          options={competitionsList}
          placeholder='Search competitions'
          showOptionIcon
          value={competitionsList.find(competition => competition.id === selectedCompetitionId)}
          onChange={value => setSelectedCompetitionId(value.id)}
        />
      </Grid.Container>
    </FilterModal>
  );
};

export default NewsFilterModal;
