import React from 'react';
import { components } from 'react-select';

import { COMPETITION_TYPE } from '~/enums';

import Icon from '../Icon';
import {
  CustomDropdown,
  Label,
  NewStyleContainer,
  NewStyleCustomDropdown,
  NewStylePrefixIcon,
  Optional,
} from './styles';

const Dropdown = ({
  label,
  options,
  onChange,
  placeholder,
  icon,
  iconColor,
  leftIconSize = 's',
  isSearchable = false,
  isMulti = false,
  showOptionIcon,
  optional,
  isNewStyle = false,
  ...props
}) => {
  const DropdownIndicator = props => {
    if (isNewStyle) {
      return (
        <components.DropdownIndicator {...props}>
          <Icon
            color={iconColor}
            cursor='pointer'
            icon={props.selectProps.menuIsOpen ? 'chevron2Up' : 'chevron2Down'}
            size='s'
          />
        </components.DropdownIndicator>
      );
    }

    return (
      <components.DropdownIndicator {...props}>
        <Icon cursor='pointer' icon={props.selectProps.menuIsOpen ? 'caretUp' : 'caretDown'} />
      </components.DropdownIndicator>
    );
  };

  const OptionIcon = ({ data, label, ...props }) => {
    let icon;
    let option;
    const competitionType = data.competitionType;
    const avatarUrl = data.avatarUrl;

    if (showOptionIcon && competitionType) {
      icon = competitionType.toLowerCase() === COMPETITION_TYPE.LEAGUE ? 'flag' : 'trophy';
      option = (
        <>
          <Icon cursor='pointer' icon={icon} />
          {label}
        </>
      );
    } else if (showOptionIcon && avatarUrl) {
      option = (
        <>
          <img alt='avatar' src={avatarUrl} />
          {label}
        </>
      );
    } else {
      option = label;
    }

    return <components.Option {...props}>{option}</components.Option>;
  };

  if (isNewStyle) {
    return (
      <NewStyleContainer>
        <NewStylePrefixIcon leftIconSize={leftIconSize}>
          <Icon color={iconColor} icon={icon} size={leftIconSize} />
        </NewStylePrefixIcon>
        <NewStyleCustomDropdown
          classNamePrefix={'NewStyleCustomDropdown'}
          components={{ DropdownIndicator }}
          isMulti={isMulti}
          isSearchable={isSearchable}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        />
      </NewStyleContainer>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {label && (
        <Label>
          {label} {optional && <Optional>optional</Optional>}
        </Label>
      )}
      <CustomDropdown
        classNamePrefix={'CustomDropdown'}
        components={{ DropdownIndicator, Option: OptionIcon }}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default Dropdown;
