import capitalize from 'lodash/capitalize';
import React from 'react';

import NotificationBadge from '~/components/Admin/Notification/Badge';
import TableCompetitionNameCell from '~/components/Admin/TableCompetitionNameCell';
import TableCompetitionPlayersCell from '~/components/Admin/TableCompetitionPlayersCell';
import TableCompetitionTimelineCell from '~/components/Admin/TableCompetitionTimelineCell';
import { Cell, Time } from '~/components/Admin/TableCompetitionTimelineCell/styles';
import { COMPETITION_TYPE } from '~/enums';
import { getCompetitionStatus } from '~/utils';

import { StyledLink } from './styles';

export const getCompetitionsData = ({ data }) => {
  return data.map(competition => {
    const {
      ageFrom,
      ageTo,
      competitionType,
      endTime,
      gender,
      id,
      name,
      registerEndTime,
      registerStartTime,
      skillLevelFrom,
      skillLevelTo,
      startTime,
    } = competition;

    return {
      ageFrom,
      ageTo,
      competitionType,
      endTime,
      gender: !gender || gender.prettyName === 'Both' ? 'Both' : gender,
      id,
      name,
      registerEndTime,
      registerStartTime,
      skillLevelFrom,
      skillLevelTo,
      startTime,
    };
  });
};

export const getCompetitionsColumns = ({ communityId, competitionsType, tableType }) => {
  if (tableType === 'registration') {
    return [
      {
        label: `${capitalize(competitionsType)} Name`,
        value: 'name',
        sortBy: 'name',
        iconLeft: COMPETITION_TYPE.LEAGUE === competitionsType ? 'flag' : 'trophy',
        renderCustomCell: competition => {
          const { id } = competition;
          const statusText = getCompetitionStatus(competition);

          return (
            <StyledLink to={`/admin/community/${communityId}/${competitionsType}/${id}`}>
              <TableCompetitionNameCell competition={competition} status={statusText.status} />
            </StyledLink>
          );
        },
      },
      {
        label: 'Timeline',
        renderCustomCell: competition => {
          const statusText = getCompetitionStatus(competition);
          const notificationBadgeText =
            competitionsType === COMPETITION_TYPE.LEAGUE ? 'Seed players in groups' : 'Populate brackets';

          return (
            <Cell>
              {statusText.timeline[1] && <Time>{statusText.timeline[1]}</Time>}
              <Time>{statusText.timeline[0]}</Time>
              {statusText.status === 'Registration ended' && (
                <NotificationBadge dotColor='pending' text={notificationBadgeText} />
              )}
            </Cell>
          );
        },
      },
      {
        label: 'Players',
        hideOnMobile: true,
        renderCustomCell: competition => {
          const { ageFrom, ageTo, gender, skillLevelFrom, skillLevelTo } = competition;

          return (
            <TableCompetitionPlayersCell
              ageFrom={ageFrom}
              ageTo={ageTo}
              gender={gender}
              hideOnMobile
              skillLevelFrom={skillLevelFrom}
              skillLevelTo={skillLevelTo}
              text='Max players: 0'
            />
          );
        },
      },
    ];
  } else {
    return [
      {
        label: `${capitalize(competitionsType)} Name`,
        value: 'name',
        sortBy: 'name',
        iconLeft: COMPETITION_TYPE.LEAGUE === competitionsType ? 'flag' : 'trophy',
        renderCustomCell: competition => {
          const { id } = competition;

          return (
            <StyledLink to={`/admin/community/${communityId}/${competitionsType}/${id}`}>
              <TableCompetitionNameCell
                competition={competition}
                status={`${competitionsType} ${tableType === 'active' ? 'started' : 'ended'}`}
              />
            </StyledLink>
          );
        },
      },
      {
        label: 'Timeline',
        renderCustomCell: competition => {
          const { endTime, startTime } = competition;

          return <TableCompetitionTimelineCell time={[startTime, endTime]} />;
        },
      },
      {
        label: 'Players',
        hideOnMobile: true,
        renderCustomCell: competition => {
          const { ageFrom, ageTo, gender, skillLevelFrom, skillLevelTo } = competition;

          return (
            <TableCompetitionPlayersCell
              ageFrom={ageFrom}
              ageTo={ageTo}
              gender={gender}
              hideOnMobile
              skillLevelFrom={skillLevelFrom}
              skillLevelTo={skillLevelTo}
              text='Max players: 0'
            />
          );
        },
      },
    ];
  }
};
