import React from 'react';

import { Icon } from '~/ui';

import { StyledBackButton } from './styles';

const BackButton = ({ onClick, styleType = 'clean', text = 'Back' }) => {
  return (
    <StyledBackButton styleType={styleType} onClick={onClick}>
      <Icon color='tmGreen' cursor='pointer' icon='arrowLeft' />
      {text}
    </StyledBackButton>
  );
};

export default BackButton;
