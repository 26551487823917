import React from 'react';

import { TabsProvider } from './context/TabContext';
import PageContent from './PageContent';

const CommunityDetails = () => {
  return (
    <TabsProvider>
      <PageContent />
    </TabsProvider>
  );
};

export default CommunityDetails;
