import React, { useState } from 'react';

import BackButton from '~/components/shared/BackButton';
import { Button, Dropdown, ErrorMessage, Icon, Modal } from '~/ui';

import { Notice, NoticeContainer, OptionNoticeContainer, Title } from './styles';

const seedOptions = [{ type: 'Randomly' }, { type: 'By skill level' }];

const SeedPlayersModal = ({ error, onSubmit, setShowModal }) => {
  const [selectedOption, setSelectedOption] = useState({ type: 'Randomly' });

  return (
    <Modal>
      <BackButton onClick={() => setShowModal(false)} />
      <Title>Seed players into groups</Title>
      <NoticeContainer>
        <Notice>TennisMate can seed the registered players into groups that have been created.</Notice>
        <Notice>This action can be random or within a set of parameters chosen below.</Notice>
      </NoticeContainer>
      <Dropdown
        defaultValue={selectedOption}
        getOptionLabel={option => option.type}
        getOptionValue={option => option.type}
        label='Seed Players'
        options={seedOptions}
        placeholder='Select option'
        value={selectedOption}
        onChange={value => setSelectedOption(value)}
      />
      <OptionNoticeContainer>
        <Icon color='ash2' icon='info' />
        <Notice>
          {selectedOption.type === 'Randomly' ? (
            <>
              Seed players randomly into groups. <br />
              Each group will receive an equal number of players.
            </>
          ) : (
            <>
              Seed players into groups by their skill level. eg - The first group will have the advanced players, the
              second group will have intermediate players and the third group will have beginners.
            </>
          )}
        </Notice>
      </OptionNoticeContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Button onClick={() => onSubmit(selectedOption)}>
        <Icon color='tmGreen' cursor='pointer' icon='shuffle' />
        Seed players
      </Button>
    </Modal>
  );
};

export default SeedPlayersModal;
