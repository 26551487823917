import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Icon } from '~/ui';

import CommunityUser from './CommunityUser';
import { Container, Nav, StyledNavLink } from './styles';

const SideNavigation = ({ showMobileMenu }) => {
  const { myStatus, communityInfo } = useSelector(state => state.communities.selectedCommunity);
  const { pathname } = useLocation();

  useEffect(() => {
    if (showMobileMenu) document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, [showMobileMenu]);

  const setOverviewIconActive = () => {
    if (pathname === '/admin' || pathname === '/admin/new-community') {
      return true;
    }
    return false;
  };

  return (
    <Nav showMobileMenu={showMobileMenu}>
      <Container>
        <CommunityUser />
        <StyledNavLink exact isActive={setOverviewIconActive} to='/admin'>
          <Icon cursor='pointer' icon='home' />
          Overview
        </StyledNavLink>
        <StyledNavLink
          isActive={(_, { pathname }) => pathname.includes('member') || pathname.includes('profile')}
          to={`/admin/community/${communityInfo.id}/members`}
        >
          <Icon cursor='pointer' icon='multipleUsers' />
          Members
        </StyledNavLink>
        <StyledNavLink
          isActive={(_, { pathname }) => pathname.includes('league')}
          to={`/admin/community/${communityInfo.id}/leagues`}
        >
          <Icon cursor='pointer' icon='flag' />
          Leagues
        </StyledNavLink>
        <StyledNavLink
          isActive={(_, { pathname }) => pathname.includes('tournament')}
          to={`/admin/community/${communityInfo.id}/tournaments`}
        >
          <Icon cursor='pointer' icon='trophy' />
          Tournaments
        </StyledNavLink>
        {/* <StyledNavLink to={`/admin/community/${communityInfo.id}/matches`}>
          <Icon cursor='pointer' icon='match' />
          Matches
        </StyledNavLink> */}
        <StyledNavLink to={`/admin/community/${communityInfo.id}/news`}>
          <Icon cursor='pointer' icon='news' />
          News
        </StyledNavLink>
        {myStatus.role === 'ADMIN' && (
          <StyledNavLink to={`/admin/community/${communityInfo.id}/administration`}>
            <Icon cursor='pointer' icon='settings' />
            Administration
          </StyledNavLink>
        )}
      </Container>
    </Nav>
  );
};

export default SideNavigation;
