import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Badge = styled.div`
  background-color: ${colors.highlight3};
  color: ${colors.white};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 12px 24px;
  border-radius: 24px;
  margin: 0 auto;
  width: fit-content;
`;
