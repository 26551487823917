import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const InfoTitle = styled.h4`
  font-weight: 500;
  line-height: 24px;
  font-size: 21px;
  color: ${colors.white};
  margin: 12px 0 0;
`;

export const InfoText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${colors.ash2};
  word-break: break-word;
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;

  & div {
    gap: 6px;
  }
`;

export const AboutTournamentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  gap: 16px;
`;

export const StatusTitle = styled.h1`
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  margin: 0;
  color: ${colors.white};
  text-align: center;
`;

export const StatusMessage = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${colors.ash2};
  text-align: center;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;
