import React from 'react';

import { ButtonContainer, NewStyleButtonContainer } from './styles';

const Button = ({ children, isNewStyle = false, fullWidth = false, color = 'tmGreen', ...props }) => {
  if (isNewStyle) {
    return (
      <NewStyleButtonContainer color={color} fullWidth={fullWidth} {...props}>
        {children}
      </NewStyleButtonContainer>
    );
  }

  return <ButtonContainer {...props}>{children}</ButtonContainer>;
};

export default Button;
