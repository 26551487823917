import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aboutMe: '',
  accountId: null,
  avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/user-default-image.png',
  birthday: null,
  favoriteApparel: '',
  favoriteATPTournament: '',
  favoriteBalls: '',
  favoritePlayer: '',
  favoriteRacket: '',
  firstName: '',
  gender: {
    gender: '',
    prettyName: '',
  },
  hometown: {
    description: '',
  },
  lastName: '',
  phone: {
    countryCode: '',
    phoneNumber: '',
  },
  playingHand: {
    playingHand: '',
    prettyName: '',
  },
  playingStyle: {
    playingStyle: '',
    prettyName: '',
    description: '',
  },
  preferredMatchTime: null,
  tennisLevel: {
    skillLevel: '',
    prettyName: '',
    description: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userProfile: (state, action) => {
      return {
        ...action.payload,
        error: '',
      };
    },
    userProfileError: (state, action) => {
      state.error = action.payload;
    },
    userInitialState: () => {
      return initialState;
    },
  },
});

export const { userProfile, userProfileError, userInitialState } = userSlice.actions;

export default userSlice.reducer;
