import React from 'react';
import { useSelector } from 'react-redux';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';
import { COMMUNITY_USER_STATUS } from '~/enums';
import { Icon } from '~/ui';

import CommunityUserStatus from './CommunityUserStatus';
import { AboutItem, AboutItemDescription, AboutItemTitle } from './styles';

const About = () => {
  const { communityScreen } = useSelector(state => state.communities);

  const { community, memberCount, communityStatus } = communityScreen;

  const { url, info, email, court, communityName, id } = community;

  return (
    <WidgetWrapper>
      {communityStatus !== COMMUNITY_USER_STATUS.JOINED && (
        <CommunityUserStatus
          communityId={id}
          communityMembers={memberCount}
          communityName={communityName}
          communityStatus={communityStatus}
        />
      )}
      <WidgetHeader title='About the Community' />
      <AboutItem>
        <Icon color='tmGreen' icon='description' />
        <div>
          <AboutItemTitle>Description</AboutItemTitle>
          <AboutItemDescription>{info}</AboutItemDescription>
        </div>
      </AboutItem>
      <AboutItem>
        <Icon color='tmGreen' icon='location2' />
        <div>
          <AboutItemTitle>Address</AboutItemTitle>
          <AboutItemDescription>{court?.formattedAddress}</AboutItemDescription>
        </div>
      </AboutItem>
      <AboutItem>
        <Icon color='tmGreen' icon='web' />
        <div>
          <AboutItemTitle>Website</AboutItemTitle>
          <a href={url}>
            <AboutItemDescription>{url}</AboutItemDescription>
          </a>
        </div>
      </AboutItem>
      <AboutItem>
        <Icon color='tmGreen' icon='envelope' />
        <div>
          <AboutItemTitle>Email Address</AboutItemTitle>
          <a href={`mailto:${email}`}>
            <AboutItemDescription>{email}</AboutItemDescription>
          </a>
        </div>
      </AboutItem>
    </WidgetWrapper>
  );
};

export default About;
