import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FlexColumnWrapper } from '~/components/shared/index';
import { getMatchesCentralData } from '~/store/matches/matchesCentral.utils';

import Header from '../shared/Header';
import MyChallenges from '../shared/MyChallenges';
import MyRecentMatches from '../shared/MyRecentMatches/index';
import MyUpcomingMatches from '../shared/MyUpcomingMatches/index';
import SeeAll from './SeeAll/index';

const MatchesCentral = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, isLoading, errorMessage } = useSelector(state => state.matchesCentral);

  useEffect(() => {
    dispatch(getMatchesCentralData());
  }, [dispatch]);

  const { challenges, recentMatches, upcomingMatches } = data;

  return (
    <FlexColumnWrapper>
      <Header icon='racket' title='Matches Central' />
      <MyChallenges errorMessage={errorMessage} isLoading={isLoading} myChallenges={challenges.slice(0, 5)}>
        {challenges.length > 5 && (
          <SeeAll text='See all Challenges' onClick={() => history.push('/home/matches-central/challenges')} />
        )}
      </MyChallenges>
      <MyUpcomingMatches
        errorMessage={errorMessage}
        isLoading={isLoading}
        upcomingMatches={upcomingMatches.slice(0, 5)}
      >
        {upcomingMatches.length > 5 && (
          <SeeAll text='See all Upcoming matches' onClick={() => history.push('/home/matches-central/upcoming')} />
        )}
      </MyUpcomingMatches>
      <MyRecentMatches errorMessage={errorMessage} isLoading={isLoading} recentMatches={recentMatches.slice(0, 5)}>
        {recentMatches.length > 1 && (
          <SeeAll text='See all Recent matches' onClick={() => history.push('/home/matches-central/recent')} />
        )}
      </MyRecentMatches>
    </FlexColumnWrapper>
  );
};

export default MatchesCentral;
