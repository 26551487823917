import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  display: flex;
  height: 310px;
  margin: 24px 0;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
`;

export const AddImageText = styled.span`
  display: flex;
  color: ${colors.whiteOpacity._75};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  align-items: center;
  line-height: 32px;
  cursor: pointer;

  &:hover {
    color: ${colors.tmGreen};

    .icon {
      color: ${colors.tmGreen};
    }
  }

  .icon {
    margin-right: 12px;
  }
`;

export const StyledButton = styled.button`
  display: flex;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  align-items: center;
  cursor: pointer;
  outline: none;

  &:hover {
    color: ${colors.tmGreen};

    .icon {
      color: ${colors.tmGreen};
    }
  }

  &:first-of-type {
    margin-right: 16px;
  }

  .icon {
    margin-right: 12px;
  }
`;
