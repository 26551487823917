import React, { useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import { Icon } from '~/ui';

import { NavigationButton, RoundsMenuContainer } from './styles';

const CustomScrollMenu = ({ extraIcon, selected, values, onChange }) => {
  const [translate, setTranslate] = useState(0);

  const Arrow = ({ type }) => (
    <NavigationButton extraIcon={extraIcon}>
      {type === 'left' && extraIcon && <Icon color='tmGreen' cursor='pointer' icon='chevronLeft' />}
      <Icon
        color='tmGreen'
        cursor='pointer'
        icon={extraIcon ? extraIcon : type === 'left' ? 'chevronLeft' : 'chevronRight'}
      />
      {type === 'right' && extraIcon && <Icon color='tmGreen' cursor='pointer' icon='chevronRight' />}
    </NavigationButton>
  );

  return (
    <RoundsMenuContainer>
      <ScrollMenu
        alignCenter={false}
        arrowDisabledClass='disabledArrow'
        arrowLeft={<Arrow type='left' />}
        arrowRight={<Arrow type='right' />}
        data={values()}
        dragging
        hideSingleArrow
        scrollToSelected
        selected={selected}
        transition={0.3}
        translate={translate}
        onSelect={onChange}
        onUpdate={({ translate }) => setTranslate(translate)}
      />
    </RoundsMenuContainer>
  );
};

export default CustomScrollMenu;
