import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';

import FilterModal from '~/components/shared/FilterModal';
import { DatePicker, Dropdown, Grid, InputRange } from '~/ui';
import { findArrayDataByPropertyAndValue } from '~/utils';

import { Container, FilterRowTitle } from './styles';

const genders = [
  { gender: 'BOTH', prettyName: 'Both' },
  { gender: 'MALE', prettyName: 'Male' },
  { gender: 'FEMALE', prettyName: 'Female' },
];

const CompetitionsFilterModal = ({
  defaultFilterValues,
  filters: initialFilters,
  handleApplyFilters,
  handleClearFilters,
  onHide,
  title,
}) => {
  const [gender, setGender] = useState(initialFilters.gender);
  const [dateFrom, setDateFrom] = useState(initialFilters.dateFrom);
  const [dateTo, setDateTo] = useState(initialFilters.dateTo);
  const [ageRange, setAgeRange] = useState(initialFilters.ageRange);
  const [skillLevelRange, setSkillLevelRange] = useState(initialFilters.skillLevelRange);

  const selectedGender = findArrayDataByPropertyAndValue(genders, 'prettyName', gender);

  return (
    <FilterModal
      apply={() =>
        handleApplyFilters({
          gender,
          dateFrom,
          dateTo,
          ageRange,
          skillLevelRange,
        })
      }
      back={onHide}
      cancel={onHide}
      clear={() => handleClearFilters()}
      showCancelButton={
        gender === defaultFilterValues.gender &&
        !dateFrom &&
        !dateTo &&
        isEqual(ageRange, defaultFilterValues.ageRange) &&
        isEqual(skillLevelRange, defaultFilterValues.skillLevelRange)
      }
      title={title}
    >
      <Grid.Container>
        <FilterRowTitle>Playing Dates</FilterRowTitle>
        <Grid.Row>
          <Grid.Column columns={2}>
            <DatePicker value={dateFrom} onChange={date => setDateFrom(date)} />
          </Grid.Column>
          <Grid.Column columns={2}>
            <DatePicker value={dateTo} onChange={date => setDateTo(date)} />
          </Grid.Column>
        </Grid.Row>
        <Container>
          <Dropdown
            defaultValue={selectedGender}
            getOptionLabel={gender => gender.prettyName}
            getOptionValue={gender => gender.gender}
            label='Participant gender'
            options={genders}
            placeholder='Select gender'
            value={selectedGender}
            onChange={value => setGender(value.gender)}
          />
          <InputRange
            label='Participant age'
            maxValue={72}
            minValue={16}
            value={ageRange}
            valueLabel={value => value}
            onChange={value => setAgeRange(value)}
          />
          <InputRange
            label='Participant skill level'
            maxValue={7.0}
            minValue={1.0}
            step={0.5}
            value={skillLevelRange}
            valueLabel={value => parseFloat(value).toFixed(1)}
            onChange={value => setSkillLevelRange(value)}
          />
        </Container>
      </Grid.Container>
    </FilterModal>
  );
};

export default CompetitionsFilterModal;
