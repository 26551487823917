import { fromUnixTime } from 'date-fns';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { WidgetItem } from '~/components/shared/styles';
import { DATE_FORMATS } from '~/enums';
import { Icon } from '~/ui';
import { formatDateFromUnixTime, timeSince } from '~/utils';

import { MatchInfo, MatchInfoContainer, MatchOpponentAvatar, MatchOpponentName, MatchTimeAndPlace } from '../styles';
import { ChallengeInviteInfo, OpenMatchAvatar } from './styles';

const MatchInviteItem = ({ match, opponent, communityName }) => {
  const { accountId } = useSelector(state => state.auth);
  const history = useHistory();

  const opponentFirstName = get(opponent, ['firstName'], '');
  const opponentLastName = get(opponent, ['lastName'], '');

  return (
    <WidgetItem onClick={() => history.push(`/match/${match.id}`)}>
      <MatchInfoContainer>
        {opponent ? (
          <MatchOpponentAvatar alt={opponentFirstName} src={get(opponent, ['avatarUrl'], '')} />
        ) : (
          <OpenMatchAvatar>
            <Icon color='white' icon='web' />
          </OpenMatchAvatar>
        )}
        <MatchInfo>
          <MatchOpponentName>
            {opponent ? `${opponentFirstName} ${opponentLastName}` : 'Open Challenge'}
          </MatchOpponentName>
          {opponent ? (
            <ChallengeInviteInfo isUserChallenged={match.challengerId !== accountId}>
              {`${match.challengerId === accountId ? 'You' : opponentFirstName} challenged ${
                match.opponentId === accountId ? 'you' : opponentFirstName
              } ${timeSince(fromUnixTime(match.inviteTime))} ago.
            `}
            </ChallengeInviteInfo>
          ) : (
            <ChallengeInviteInfo isUserChallenged>
              {`You posted this ${communityName ? `to ${communityName}` : ''} ${timeSince(
                fromUnixTime(match.inviteTime)
              )} ago.`}
            </ChallengeInviteInfo>
          )}
          <MatchTimeAndPlace>
            {`${formatDateFromUnixTime(match.playTime, DATE_FORMATS.LONG_WITH_TIME)} • ${get(
              match,
              ['court', 'name'],
              ''
            )}`}
          </MatchTimeAndPlace>
        </MatchInfo>
      </MatchInfoContainer>
      <Icon
        color='ash3'
        icon='rightArrow2'
        size='s'
        style={{
          minWidth: '16px',
          minHeight: '16px',
        }}
      />
    </WidgetItem>
  );
};

export default MatchInviteItem;
