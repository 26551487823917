// eslint-disable-line max-len
import queryString from 'query-string';

import { TennisMateApi } from '~/services';

export async function getMatches() {
  return await TennisMateApi.get('/matches');
}

export async function getMatch({ matchId }) {
  return await TennisMateApi.get(`/matches/${matchId}`);
}

export async function getMatchResults(matchId) {
  return await TennisMateApi.get(`/matches/${matchId}/results`);
}

export async function getMatchPlayers(communityId) {
  const response = await TennisMateApi.get(`/communities/${communityId}/members`);
  const communityMembers = response.data.body;

  const communityMembersProfiles = Promise.all(
    communityMembers.map(user =>
      getMatchPlayer(user.accountId).then(res => {
        return {
          ...res.data.body.userProfileContract,
          statsApi: res.data.body.userStatsApi,
        };
      })
    )
  );

  return communityMembersProfiles;
}

export async function getMatchPlayer(accountId) {
  return await TennisMateApi.get(`/account/${accountId}/profile`);
}

export const getUserMatchInvites = (accountId, params) => {
  const query = queryString.stringify(params);

  return TennisMateApi.get(`/v1/match-invite/users/${accountId}?${query}`);
};

export const getUserAgreedMatches = (accountId, params) => {
  const query = queryString.stringify(params);

  return TennisMateApi.get(`/matches/user/${accountId}/agreed?${query}`);
};

export const getUserRecentMatches = (accountId, params) => {
  const query = queryString.stringify(params);

  return TennisMateApi.get(`/matches/user/${accountId}/recent?${query}`);
};

export const submitMatchInvite = data => {
  return TennisMateApi.post('/match-invite', data);
};

export const getMatchDetailsScreenRequest = matchId => {
  return TennisMateApi.get(`/mobile/v1/match-screen/${matchId}`);
};

export const cancelMatch = (matchId, message) => {
  return TennisMateApi.put(`/matches/${matchId}/cancel`, { message: message || null });
};

export const proposeNewMatchTime = (matchId, data) => {
  return TennisMateApi.put(`/matches/${matchId}/propose-new-time`, data);
};

export const acceptNewMatchTime = (matchId, message) => {
  return TennisMateApi.put(`/matches/${matchId}/accept-new-time`, { message: message || null });
};

export const rejectNewMatchTime = (matchId, message) => {
  return TennisMateApi.put(`/matches/${matchId}/reject-new-time`, { message: message || null });
};

export const acceptMatchInvite = inviteId => {
  return TennisMateApi.post(`/match-invite/${inviteId}/accept`);
};

export const rejectMatchInvite = inviteId => {
  return TennisMateApi.post(`/match-invite/${inviteId}/reject`);
};

export const registerMatchResult = (matchId, data) => {
  return TennisMateApi.post(`/matches/${matchId}/register-result`, data);
};

export const acceptMatchResult = (matchId, data) => {
  return TennisMateApi.post(`/matches/${matchId}/accept-result`, data);
};

export const challengeMatchResult = (matchId, data) => {
  return TennisMateApi.post(`/matches/${matchId}/challenge-result`, data);
};

export const rejectMatchResult = (matchId, data) => {
  return TennisMateApi.post(`/matches/${matchId}/reject-result`, data);
};

export const cancelMatchInvite = matchInviteId => {
  return TennisMateApi.post(`/match-invite/${matchInviteId}/cancel`);
};

export const acceptMatchCancelation = matchId => {
  return TennisMateApi.put(`/matches/${matchId}/accept-cancellation`);
};

export const rejectMatchCancelation = matchId => {
  return TennisMateApi.put(`/matches/${matchId}/reject-cancellation`);
};

export const getMatchesCentralScreenRequest = accountId => {
  return TennisMateApi.get(`/match-central-screen/user/${accountId}`);
};

export const getRecentMatches = (accountId, params = {}) => {
  const query = queryString.stringify(params);

  return TennisMateApi.get(`/recent-matches/user/${accountId}?${query}`);
};

export const searchOpenMatches = filters => {
  return TennisMateApi.post('/match-invites/search?pageNumber=1&pageSize=1000', filters);
};
