import { capitalize, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CompetitionsFilterModal from '~/components/Admin/CompetitionsFilterModal';
import CompetitionsTable from '~/components/Admin/CompetitionsTable';
import Filters from '~/components/Admin/Filters';
import SearchAndFilter from '~/components/shared/SearchAndFilter';
import { COMPETITION_TYPE, GENDER } from '~/enums';
import { selectedLeagueReset } from '~/store/leagues/slice';
import { getCommunityLeagues } from '~/store/leagues/utils';
import { selectedTournamentReset } from '~/store/tournaments/slice';
import { getCommunityTournaments } from '~/store/tournaments/utils';
import { Button, Grid, Icon, PageHeader, PageTableName } from '~/ui';
import {
  filterCompetitionByAgeRange,
  filterCompetitionByDate,
  filterCompetitionByGender,
  filterCompetitionBySearchValue,
  filterCompetitionBySkillLevelRange,
  isCurrentDateTimeBetweenDates,
  isDateAfterCurrentDateTime,
  isDateBeforeCurrentDateTime,
  isDateInThePast,
} from '~/utils';

import { StyledBackButton } from './styles';

const defaultFilterValues = {
  gender: GENDER.BOTH,
  dateFrom: '',
  dateTo: '',
  ageRange: { min: 16, max: 72 },
  skillLevelRange: { min: 1.0, max: 7.0 },
};

const CompetitionsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const competitionsType = location.pathname.split('/').pop();
  const competitionsTypeSingular = competitionsType.slice(0, -1);

  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { leaguesList, leaguesError, selectedLeague } = useSelector(state => state.leagues);
  const { tournamentsList, tournamentsError, selectedTournament } = useSelector(state => state.tournaments);

  const competitionsList = competitionsType === 'leagues' ? leaguesList : tournamentsList;
  const competitionsListError = competitionsType === 'leagues' ? leaguesError : tournamentsError;

  const { id: urlCommunityId } = useParams();
  const [loadingCompetitions, setLoadingCompetitions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(defaultFilterValues);
  const [showOnlyPastCompetitions, setShowOnlyPastCompetitions] = useState(false);

  if (communityId !== parseInt(urlCommunityId)) {
    history.push(`/admin/community/${communityId}/${competitionsType}`);
  }

  if (selectedLeague.id !== 0) dispatch(selectedLeagueReset());
  if (selectedTournament.id !== 0) dispatch(selectedTournamentReset());

  useEffect(() => {
    const loadCompetitions = async () => {
      setLoadingCompetitions(true);
      if (communityId) {
        if (competitionsType === 'leagues') {
          await dispatch(getCommunityLeagues(communityId));
        } else {
          await dispatch(getCommunityTournaments(communityId));
        }
      }
      setLoadingCompetitions(false);
    };

    loadCompetitions();
    handleClearFilters();
    setSearchValue('');
    setShowOnlyPastCompetitions(false);
  }, [dispatch, communityId, competitionsType]);

  const handleApplyFilters = ({ gender, dateFrom, dateTo, ageRange, skillLevelRange }) => {
    setFilters({
      gender,
      dateFrom,
      dateTo,
      ageRange,
      skillLevelRange,
    });
    setShowModal(false);
  };

  const handleClearFilters = () => {
    setFilters(defaultFilterValues);
    setShowModal(false);
  };

  const filterData = data => {
    let filteredData = data;
    const { gender, dateFrom, dateTo, ageRange, skillLevelRange } = filters;

    filteredData = filterCompetitionByDate({
      from: dateFrom,
      to: dateTo,
      data: filteredData,
    });

    filteredData = filterCompetitionBySearchValue({
      searchValue,
      data: filteredData,
    });

    filteredData = filterCompetitionByGender({
      gender,
      data: filteredData,
    });

    filteredData = filterCompetitionByAgeRange({
      ageRange,
      data: filteredData,
    });

    filteredData = filterCompetitionBySkillLevelRange({
      skillLevelRange,
      data: filteredData,
    });

    return filteredData;
  };

  const filteredCompetitions = filterData(competitionsList);

  const isFilterActive = !isEqual(defaultFilterValues, filters);

  const registrationPhaseCompetitions = filteredCompetitions.filter(competition => {
    const isTodayBetweenRegistrationStartAndEnd = isCurrentDateTimeBetweenDates({
      start: competition.registerStartTime,
      end: competition.registerEndTime,
    });
    const isRegistrationStartAfterToday = isDateAfterCurrentDateTime(competition.registerStartTime);
    const isCompetitionStarted = isDateBeforeCurrentDateTime(competition.startTime);

    if (isTodayBetweenRegistrationStartAndEnd || isRegistrationStartAfterToday || !isCompetitionStarted) {
      return competition;
    } else {
      return null;
    }
  });

  const pastCompetitions = filteredCompetitions.filter(competition => isDateInThePast(competition.endTime));

  const activeCompetitions = filteredCompetitions.filter(
    competition =>
      !pastCompetitions.find(pastCompetition => competition.id === pastCompetition.id) &&
      !registrationPhaseCompetitions.find(regCompetition => competition.id === regCompetition.id)
  );

  return (
    <>
      <PageHeader
        buttonIcon={competitionsTypeSingular === COMPETITION_TYPE.LEAGUE ? 'flag' : 'trophy'}
        buttonText={`Create a ${competitionsTypeSingular}`}
        link={`/admin/community/${communityId}/${competitionsType}/new-${competitionsTypeSingular}`}
        title={capitalize(competitionsType)}
      />
      {showOnlyPastCompetitions && <StyledBackButton onClick={() => setShowOnlyPastCompetitions(false)} />}
      <Grid.Container>
        <Grid.Row>
          <Grid.Column columns={2}>
            <div>
              <Filters default={defaultFilterValues} filters={filters} onClick={value => setFilters(value)} />
            </div>
            {showOnlyPastCompetitions ? (
              <PageTableName
                name={`Past ${competitionsType}`}
                number={loadingCompetitions ? '' : pastCompetitions.length}
              />
            ) : (
              <PageTableName
                name='Registration phase'
                number={loadingCompetitions ? '' : registrationPhaseCompetitions.length}
              />
            )}
          </Grid.Column>
          <Grid.Column columns={2}>
            <SearchAndFilter
              filterActive={isFilterActive}
              filterDisabled={!competitionsList.length}
              filterOnClick={() => setShowModal(true)}
              searchOnChange={e => setSearchValue(e.target.value)}
              searchValue={searchValue}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
      {!showOnlyPastCompetitions && (
        <>
          <CompetitionsTable
            competitionsType={competitionsTypeSingular}
            data={registrationPhaseCompetitions}
            error={competitionsListError}
            isLoading={loadingCompetitions}
            tableType='registration'
          />
          <PageTableName
            name={`Active ${competitionsType}`}
            number={loadingCompetitions ? '' : activeCompetitions.length}
          />
          <CompetitionsTable
            competitionsType={competitionsTypeSingular}
            data={activeCompetitions}
            error={competitionsListError}
            isLoading={loadingCompetitions}
            tableType='active'
          />
          <PageTableName
            name={`Past ${competitionsType}`}
            number={loadingCompetitions ? '' : pastCompetitions.length}
          />
        </>
      )}
      <CompetitionsTable
        competitionsType={competitionsTypeSingular}
        data={showOnlyPastCompetitions ? pastCompetitions : pastCompetitions.slice(-3)}
        error={competitionsListError}
        isLoading={loadingCompetitions}
        tableType='past'
      />
      {!filteredCompetitions.length && isFilterActive && (
        <Button onClick={handleClearFilters}>
          <Icon color='tmGreen' cursor='pointer' icon='trash' />
          Clear Filters
        </Button>
      )}
      {!showOnlyPastCompetitions && (
        <div>
          <Button disabled={!pastCompetitions.length} small onClick={() => setShowOnlyPastCompetitions(true)}>
            View all past {competitionsType}
          </Button>
        </div>
      )}
      {showModal && (
        <CompetitionsFilterModal
          defaultFilterValues={defaultFilterValues}
          filters={filters}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          title={`Filter ${capitalize(competitionsType)}`}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default CompetitionsPage;
