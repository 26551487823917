import React from 'react';
import { useSelector } from 'react-redux';

import { MATCH_RESULT_STATUS } from '~/enums/match';
import { Icon } from '~/ui';

import {
  Container,
  LeftArrow,
  PlayerContainer,
  PlayerIndentificatorBadge,
  PlayerName,
  PlayerNameContainer,
  RightArrow,
  Score,
  ScoresContainer,
} from './styles';

const PENDING_RESULTS_SCORE_LIST = [
  {
    id: 1,
    gamesWonByGuest: '-',
    gamesWonByHost: '-',
  },
  {
    id: 2,
    gamesWonByGuest: '-',
    gamesWonByHost: '-',
  },
  {
    id: 3,
    gamesWonByGuest: '-',
    gamesWonByHost: '-',
  },
];

const getBracketPlayerName = (player, roundNumber) => {
  if (player) {
    return `${player.firstName} ${player.lastName}`;
  }

  if (roundNumber === 1) {
    return 'BYE';
  }

  return 'TBD';
};

const Bracket = ({ host, guest, roundNumber, totalRounds, result = {} }) => {
  const { accountId } = useSelector(state => state.auth);

  const { matchResultContract = {}, matchResultSetScoreList = PENDING_RESULTS_SCORE_LIST } = result;

  const { status, setsWonByGuest, setsWonByHost } = matchResultContract;

  return (
    <Container>
      {roundNumber !== 1 && (
        <LeftArrow>
          <Icon color='white' icon='rightArrow2' size='xs' />
        </LeftArrow>
      )}
      {roundNumber !== 1 && (
        <LeftArrow>
          <Icon color='white' icon='rightArrow2' size='xs' />
        </LeftArrow>
      )}
      <PlayerContainer>
        <PlayerNameContainer>
          <PlayerName isWinner={status === MATCH_RESULT_STATUS.REGISTERED_RESULT && setsWonByHost > setsWonByGuest}>
            {getBracketPlayerName(host, roundNumber)}
          </PlayerName>
          {accountId === host?.accountId && <PlayerIndentificatorBadge>Me</PlayerIndentificatorBadge>}
        </PlayerNameContainer>
        <ScoresContainer>
          {matchResultSetScoreList.map(score => {
            const { gamesWonByHost, id } = score;

            return <Score key={id}>{gamesWonByHost}</Score>;
          })}
        </ScoresContainer>
      </PlayerContainer>
      <PlayerContainer>
        <PlayerNameContainer>
          <PlayerName isWinner={status === MATCH_RESULT_STATUS.REGISTERED_RESULT && setsWonByHost < setsWonByGuest}>
            {getBracketPlayerName(guest, roundNumber)}
          </PlayerName>
          {accountId === guest?.accountId && <PlayerIndentificatorBadge>Me</PlayerIndentificatorBadge>}
        </PlayerNameContainer>
        <ScoresContainer>
          {matchResultSetScoreList.map(score => {
            const { gamesWonByGuest, id } = score;

            return <Score key={id}>{gamesWonByGuest}</Score>;
          })}
        </ScoresContainer>
      </PlayerContainer>
      {roundNumber !== totalRounds && (
        <RightArrow>
          <Icon color='white' icon='rightArrow2' size='s' />
        </RightArrow>
      )}
    </Container>
  );
};

export default Bracket;
