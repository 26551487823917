import React from 'react';

import { ButtonsContainer } from '~/pages/shared/styles';
import { Icon } from '~/ui/index';

import { HeaderContainer, TitleContainer } from './styles';

const Header = ({ title, icon, children }) => {
  return (
    <HeaderContainer>
      <TitleContainer>
        <Icon color='tmGreen' icon={icon} size='l' />
        <h1>{title}</h1>
      </TitleContainer>
      <ButtonsContainer>{children}</ButtonsContainer>
    </HeaderContainer>
  );
};

export default Header;
