import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors, zIndex } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 12px 0;
  position: relative;

  div.react-datepicker-wrapper {
    width: 100%;
  }

  button.react-datepicker__close-icon {
    position: absolute;
    top: -24px;

    ::after {
      background: none;
      color: ${colors.whiteOpacity._75};
      font-size: 20px;
    }
  }

  .icon {
    position: absolute;
    left: 10px;
    top: ${({ label }) => (label ? '30px' : '6px')};
    z-index: ${zIndex.level1};
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid ${({ error }) => (error ? colors.danger : colors.invert)};
  padding-left: ${({ icon }) => (icon ? '45px' : '16px')};
  background-color: ${colors.highlight2};
  color: ${colors.whiteOpacity._75};
  font-family: system-ui;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 24px;
  outline: none;

  ::placeholder {
    color: ${colors.whiteOpacity._75};
  }
`;

const smallIconCss = css`
  left: 11px;
  top: 12px;
`;

const mediumIconCss = css`
  left: 10px;
  top: 8px;
`;

export const NewStyleContainer = styled(Container)`
  margin: 0;
  box-shadow: 0px 2px 1px rgba(18, 22, 39, 0.02);

  & .icon {
    left: unset;
  }

  & .icon:first-child {
    position absolute;
    ${({ leftIconSize }) => (leftIconSize === 's' ? smallIconCss : mediumIconCss)};
  }

  & .icon:last-child {
    position absolute;
    right: 14px;
    top: 12px;
  }
`;

export const NewStyleDatePicker = styled(StyledDatePicker)`
  padding: 0 40px;
  border: 1px solid ${({ error }) => (error ? colors.danger : colors.ash3)};
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  box-sizing: border-box;
  color: ${colors.white};
  background-color: ${colors.highlight3};

  ::placeholder {
    color: ${colors.whiteOpacity._25};
  }

  :focus {
    border-color: ${colors.tmGreen};
  }
`;
