import React, { useState } from 'react';

import AdditionalRowInfo from '~/components/Admin/AdditionalRowInfo';
import TableCellWithIcon from '~/components/Admin/TableCellWithIcon';
import { Icon } from '~/ui';

import { CellBlock, CellFirstInfo, CellSecondInfo } from './styles';

const TableCompetitionNameCell = ({ competition, status }) => {
  const [selectedCompetition, setSelectedCompetition] = useState({ id: null });
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const handleInfoClick = competition => {
    setSelectedCompetition(competition);
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  return (
    <TableCellWithIcon style={{ position: 'relative' }}>
      <CellBlock>
        <CellFirstInfo bold>{competition.name}</CellFirstInfo>
        <CellSecondInfo>{status}</CellSecondInfo>
      </CellBlock>
      <Icon color='invert' cursor='pointer' icon='info' onClick={() => handleInfoClick(competition)} />
      {competition.id === selectedCompetition.id && showAdditionalInfo && (
        <AdditionalRowInfo competition={selectedCompetition} setShowAdditionalInfo={setShowAdditionalInfo} />
      )}
    </TableCellWithIcon>
  );
};

export default TableCompetitionNameCell;
