import React from 'react';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';

import ListItemsStatusFeedback from '../ListItemStatusFeedback';
import MatchItem from '../MatchItem/MatchItem';

const MyUpcomingMatches = ({ upcomingMatches = [], isLoading, errorMessage, children }) => {
  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgClock' iconColor='blue' title='Upcoming Matches' />
      {upcomingMatches.length > 0 ? (
        upcomingMatches.map(({ match, opponent }) => <MatchItem key={match.id} match={match} opponent={opponent} />)
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='You do not have any upcoming matches.'
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isLoading}
        />
      )}
      {children}
    </WidgetWrapper>
  );
};

export default MyUpcomingMatches;
