import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

const sharedStyle = css`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const Label = styled.label`
  ${sharedStyle};
  display: block;
  margin-bottom: 32px;
  color: ${colors.whiteOpacity._75};
`;

export const Container = styled.div`
  > div.input-range {
    margin: 25px 0 30px;

    .input-range__label-container {
      position: unset;
      left: 0;
    }

    .input-range__track {
      height: 4px;
      background: ${colors.ash3};

      .input-range__track--active {
        background: ${colors.ash2};
      }

      .input-range__slider-container {
        .input-range__label-container {
          ${sharedStyle};
          color: ${colors.whiteOpacity._75};
        }

        span.input-range__label--value {
          top: -32px;
        }

        &:nth-of-type(1) {
          div.input-range__slider {
            margin-left: 0;
          }
        }

        &:nth-of-type(2) {
          span.input-range__label-container,
          div.input-range__slider {
            display: block;
            margin-left: -20px;
            text-align: right;
          }

          div.input-range__slider {
            margin-left: -16px;
          }
        }
      }
    }

    .input-range__label {
      &.input-range__label--min,
      &.input-range__label--max {
        ${sharedStyle};
        color: ${colors.ash2};
      }
    }

    .input-range__slider {
      width: 16px;
      height: 16px;
      border-color: ${colors.ash};
      background: ${colors.ash};
      box-sizing: border-box;
    }
  }
`;

export const NewStyleLabel = styled.label`
  display: block;
  margin-bottom: 20px;
  color: ${colors.whiteOpacity._25};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
`;
