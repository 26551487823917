import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const InfoMessage = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
  color: ${colors.ash2};
  text-align: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.ash3};
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 112px;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    padding-top: 0;
  }
`;

export const TournamentMatchInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.invert};
  padding: 12px;
  gap: 12px;

  & p {
    text-align: center;
    color: ${colors.white};
    font-weight: bold;
    margin: 0;
  }
`;
