import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { acceptMatchInvite, rejectMatchInvite } from '~/api/matches';
import { ErrorMessage } from '~/components/shared/styles';
import { ANALYTICS_EVENTS, MATCH_INVITE_STATUS, MATCH_TYPE } from '~/enums';
import { ButtonsContainer } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button } from '~/ui/index';
import { trackAnalyticsEvent } from '~/utils/analytics';

import { StatusContainer, StatusTitle } from '../styles';

const MatchInviteActions = () => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [errorMessage, setErrorMesssage] = useState('');

  const dispatch = useDispatch();
  const { data } = useSelector(state => state.matchScreen);
  const { accountId } = useSelector(state => state.auth);

  const { matchId } = useParams();

  const { match, matchInvite, host } = data;

  if (
    !isEmpty(match) ||
    ![MATCH_INVITE_STATUS.PENDING_CHALLENGER, MATCH_INVITE_STATUS.PENDING_OPPONENT].includes(
      matchInvite.matchInviteStatus
    ) ||
    host.accountId === accountId
  ) {
    return null;
  }

  const type = matchInvite.type;

  const title =
    type === MATCH_TYPE.OPEN
      ? `${host.firstName} created open challenge. Do you want to play?`
      : `${host.firstName} challenged you to a direct match. Do you want to play?`;

  const onAccept = () => {
    setIsAccepting(true);
    acceptMatchInvite(matchId)
      .then(() => {
        setIsAccepting(false);

        if (type === MATCH_TYPE.OPEN) {
          trackAnalyticsEvent(ANALYTICS_EVENTS.OPEN_MATCH_SCHEDULED);
        } else {
          trackAnalyticsEvent(
            matchInvite?.competitionId
              ? ANALYTICS_EVENTS.TOURNAMENT_MATCH_SCHEDULED
              : ANALYTICS_EVENTS.NEW_DIRECT_MATCH_SCHEDULED
          );
        }

        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMesssage('Something went wrong. Please try again.');
        setIsAccepting(false);
      });
  };

  const onReject = () => {
    setIsRejecting(true);
    rejectMatchInvite(matchId)
      .then(() => {
        setIsRejecting(false);

        trackAnalyticsEvent(ANALYTICS_EVENTS.NEW_DIRECT_MATCH_INVITE_REJECTED);

        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMesssage('Something went wrong. Please try again.');
        setIsRejecting(false);
      });
  };

  return (
    <StatusContainer>
      <StatusTitle>{title}</StatusTitle>
      <ButtonsContainer>
        <Button disabled={isAccepting || isRejecting} isNewStyle onClick={onAccept}>
          {isAccepting ? 'Accepting...' : 'Accept'}
        </Button>
        {type !== MATCH_TYPE.OPEN && (
          <Button color='danger' disabled={isAccepting || isRejecting} isNewStyle onClick={onReject}>
            {isRejecting ? 'Rejecting...' : 'Reject'}
          </Button>
        )}
      </ButtonsContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StatusContainer>
  );
};

export default MatchInviteActions;
