function tokensExists() {
  return JSON.parse(localStorage.getItem('tokens')) !== null;
}

function setTokens(tokens = null) {
  localStorage.setItem('tokens', JSON.stringify(tokens));
}

function getTokens() {
  return JSON.parse(localStorage.getItem('tokens'));
}

function getAccessToken() {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  return tokens.accessToken;
}

function getRefreshToken() {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  return tokens.refreshToken;
}

function clearTokens() {
  localStorage.removeItem('tokens');
}

function setTokensExpirationTime(time) {
  localStorage.setItem('tokensExpirationTime', JSON.stringify(time));
}

function getTokensExpirationTime() {
  const time = JSON.parse(localStorage.getItem('tokensExpirationTime'));

  return time;
}

function clearTokensExpirationTime() {
  localStorage.removeItem('tokensExpirationTime');
}

const TokensService = {
  setTokens,
  getTokens,
  getAccessToken,
  getRefreshToken,
  clearTokens,
  tokensExists,
  setTokensExpirationTime,
  getTokensExpirationTime,
  clearTokensExpirationTime,
};

export default TokensService;
