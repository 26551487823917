import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { Spinner, theme } from '~/ui';

import { Container, FeedbackMessage } from './styles';

const { colors } = theme;

const VerifyEmail = () => {
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      toast.success('Account verified successfully.', {
        style: {
          borderRadius: '10px',
          background: colors.background,
          color: colors.white,
        },
      });

      history.push('/home');

      clearTimeout(timeout);
    }, 1500);
  }, []);

  return (
    <Container>
      <Spinner />
      <FeedbackMessage>Verifying your email, please wait.</FeedbackMessage>
    </Container>
  );
};

export default VerifyEmail;
