import get from 'lodash/get';
import React from 'react';
import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';

import { ErrorMessage, Icon, Pagination, Spinner } from '~/ui';

import {
  PaginationContainer,
  SpinnerContainer,
  TableCell,
  TableContainer,
  TableEmptyState,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from './styles';

const DEFAULT_CELL_WIDTH = 150;

function TableComponent({
  columns,
  data,
  error,
  isLoading,
  itemsName,
  pageSize = 10,
  rowBorderBottom,
  selectedItems,
  onItemClick,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // pagination props
    pageCount,
    gotoPage,
    state: { pageIndex },
  } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize } }, useFlexLayout, useSortBy, usePagination);

  return (
    <TableContainer>
      <div {...getTableProps()}>
        {headerGroups.map(headerGroup => (
          <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                <TableHeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.iconLeft && <Icon color='ash' icon={column.iconLeft} />}
                  <span>{column.render('Header')}</span>
                  {column.canSort ? (
                    column.isSorted ? (
                      <Icon color='ash' cursor='pointer' icon={column.isSortedDesc ? 'sortDown' : 'sortUp'} />
                    ) : (
                      <Icon color='ash' cursor='pointer' icon={'sortDefault'} />
                    )
                  ) : null}
                </TableHeaderCell>
              );
            })}
          </TableHeaderRow>
        ))}
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <div {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  isSelected={selectedItems.length && selectedItems.includes(row.original.id || row.original.accountId)}
                  rowBorderBottom={rowBorderBottom}
                  onClick={onItemClick ? () => onItemClick(row.original) : null}
                >
                  {row.cells.map(cell => {
                    const { hideOnMobile } = cell.column;

                    return (
                      <TableCell {...cell.getCellProps()} hideOnMobile={hideOnMobile}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {data.length === 0 && <TableEmptyState>{`No ${itemsName} available.`}</TableEmptyState>}
          </div>
        )}
      </div>
      {pageCount > 1 && (
        <PaginationContainer>
          <Pagination
            forcePage={pageIndex}
            pageCount={pageCount}
            onPageChange={pageClicked => gotoPage(pageClicked.selected)}
          />
        </PaginationContainer>
      )}
    </TableContainer>
  );
}

function Table(props) {
  const {
    columns = [],
    data = [],
    error,
    isLoading = false,
    itemsName = 'items',
    pageSize,
    rowBorderBottom = false,
    selectedItems = [],
    onItemClick,
  } = props;

  const tableColumns = columns.map(column => {
    const { iconLeft, label, sortBy, value, width, renderCustomCell, hideOnMobile = false } = column;

    return {
      accessor: value,
      Cell: ({ row, cell }) => (renderCustomCell ? renderCustomCell(row.original) : cell.value),
      iconLeft,
      hideOnMobile,
      disableSortBy: !sortBy,
      Header: label,
      sortType: (rowA, rowB, _id, desc) => {
        const valueA = get(rowA.original, sortBy);
        const valueB = get(rowB.original, sortBy);

        if (desc) {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? -1 : 1;
        }
      },
      width: width || DEFAULT_CELL_WIDTH,
    };
  });

  const mappedColumns = React.useMemo(
    () => [...tableColumns],
    [tableColumns, props] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <TableComponent
      columns={mappedColumns}
      data={data}
      error={error}
      isLoading={isLoading}
      itemsName={itemsName}
      pageSize={pageSize}
      rowBorderBottom={rowBorderBottom}
      selectedItems={selectedItems}
      onItemClick={onItemClick}
    />
  );
}

export default Table;
