import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon, useOutsideClick } from '~/ui';

import CommunityMenu from '../CommunityMenu';
import { Name, UserContainer } from './styles';

const CommunityUser = () => {
  const { communityName } = useSelector(state => state.communities.selectedCommunity.communityInfo);
  const [showCommunityMenu, setShowCommunityMenu] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => setShowCommunityMenu(false));

  return (
    <UserContainer ref={ref} onClick={() => setShowCommunityMenu(!showCommunityMenu)}>
      <Name>{communityName}</Name>
      <Icon cursor='pointer' icon={showCommunityMenu ? 'caretUp' : 'caretDown'} />
      {showCommunityMenu && <CommunityMenu setShowCommunityMenu={setShowCommunityMenu} />}
    </UserContainer>
  );
};

export default CommunityUser;
