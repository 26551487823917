import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import AddCompetitionPlayersPage from '~/pages/Admin/AddCompetitionPlayers';
import AdministrationPage from '~/pages/Admin/Administration';
// TODO: commenting out this since API does not support inviting members
// import CommunityMembersInvitePage from '~/pages/Admin/CommunityMembersInvite';
import CompetitionsPage from '~/pages/Admin/Competitions';
import EditProfilePage from '~/pages/Admin/EditProfile';
import EditSingleCompetitionPage from '~/pages/Admin/EditSingleCompetition';
// import NewMatchPage from '~/pages/Admin/NewMatch';
import MemberProfilePage from '~/pages/Admin/MemberProfile';
// TODO: commenting out this since API does not support admin matches overview yet
// import AddMatchPlayer from '~/pages/Admin/AddMatchPlayer';
// import MatchesPage from '~/pages/Admin/Matches';
// import MatchOverviewPage from '~/pages/Admin/MatchOverview';
import MembersPage from '~/pages/Admin/Members';
import NewCommunityPage from '~/pages/Admin/NewCommunity';
import NewCompetitionPage from '~/pages/Admin/NewCompetition';
import NewsPage from '~/pages/Admin/News';
import NewsArticlePage from '~/pages/Admin/NewsArticle';
import NewsEditArticlePage from '~/pages/Admin/NewsEditArticle';
import NewsNewArticlePage from '~/pages/Admin/NewsNewArticle';
import OverviewPage from '~/pages/Admin/Overview';
import SingleLeaguePage from '~/pages/Admin/SingleLeague';
import SingleTournamentPage from '~/pages/Admin/SingleTournament';
import WelcomePage from '~/pages/Admin/Welcome';
import NotFoundPage from '~/pages/NotFound';
import { ProtectedRoute, withSpinner } from '~/ui';

const AdminRoutes = ({ parentRoute }) => {
  const { adminCommunities } = useSelector(state => state.communities);

  return (
    <>
      {adminCommunities.length === 0 ? (
        <Switch>
          <ProtectedRoute component={NewCommunityPage} exact path={`${parentRoute}/new-community`} />
          <ProtectedRoute component={EditProfilePage} exact path={`${parentRoute}/my-profile/edit`} />
          <ProtectedRoute component={MemberProfilePage} exact path={`${parentRoute}/my-profile`} />
          <ProtectedRoute component={WelcomePage} exact path={`${parentRoute}`} />
          <ProtectedRoute component={NotFoundPage} exact path={`${parentRoute}/*`} />
        </Switch>
      ) : (
        <Switch>
          <ProtectedRoute component={AdministrationPage} exact path={`${parentRoute}/community/:id/administration`} />
          <ProtectedRoute
            component={AddCompetitionPlayersPage}
            exact
            path={`${parentRoute}/community/:id/league/:leagueId/add-players`}
          />
          <ProtectedRoute
            component={EditSingleCompetitionPage}
            exact
            path={`${parentRoute}/community/:id/league/:leagueId/edit-league`}
          />
          <ProtectedRoute component={SingleLeaguePage} exact path={`${parentRoute}/community/:id/league/:leagueId`} />
          <ProtectedRoute
            component={NewCompetitionPage}
            exact
            path={`${parentRoute}/community/:id/leagues/new-league`}
          />
          <ProtectedRoute component={CompetitionsPage} exact path={`${parentRoute}/community/:id/leagues`} />
          <ProtectedRoute
            component={AddCompetitionPlayersPage}
            exact
            path={`${parentRoute}/community/:id/tournament/:tournamentId/add-players`}
          />
          <ProtectedRoute
            component={EditSingleCompetitionPage}
            exact
            path={`${parentRoute}/community/:id/tournament/:tournamentId/edit-tournament`}
          />
          <ProtectedRoute
            component={SingleTournamentPage}
            exact
            path={`${parentRoute}/community/:id/tournament/:tournamentId`}
          />
          <ProtectedRoute
            component={NewCompetitionPage}
            exact
            path={`${parentRoute}/community/:id/tournaments/new-tournament`}
          />
          <ProtectedRoute component={CompetitionsPage} exact path={`${parentRoute}/community/:id/tournaments`} />
          <ProtectedRoute component={MemberProfilePage} exact path={`${parentRoute}/profile/:accountId`} />
          {/* TODO: commenting out this since API does not support inviting members */}
          {/* <ProtectedRoute
            component={CommunityMembersInvitePage}
            exact
            path={`${parentRoute}/community/:id/members/invite-members`}
          /> */}
          <ProtectedRoute component={MembersPage} exact path={`${parentRoute}/community/:id/members`} />
          {/* TODO: commenting out this since API does not support admin matches overview yet */}
          {/*<ProtectedRoute component={MatchesPage} exact path={`${parentRoute}/community/:id/matches`} />
          <ProtectedRoute component={NewMatchPage} exact path={`${parentRoute}/community/:id/matches/new`} />
          <ProtectedRoute component={AddMatchPlayer} exact path={`${parentRoute}/community/:id/matches/add-player`} />
          <ProtectedRoute component={MatchOverviewPage} 
          exact path={`${parentRoute}/community/:id/matches/:matchId`} /> */}
          <ProtectedRoute component={NewCommunityPage} exact path={`${parentRoute}/new-community`} />
          <ProtectedRoute component={NewsNewArticlePage} exact path={`${parentRoute}/community/:id/news/new-article`} />
          <ProtectedRoute
            component={NewsEditArticlePage}
            exact
            path={`${parentRoute}/community/:id/news/:newsId/edit-article`}
          />
          <ProtectedRoute component={NewsArticlePage} exact path={`${parentRoute}/community/:id/news/:newsId`} />
          <ProtectedRoute component={NewsPage} exact path={`${parentRoute}/community/:id/news`} />
          <ProtectedRoute component={EditProfilePage} exact path={`${parentRoute}/my-profile/edit`} />
          <ProtectedRoute component={MemberProfilePage} exact path={`${parentRoute}/my-profile`} />
          <ProtectedRoute component={OverviewPage} exact path={`${parentRoute}`} />
          <ProtectedRoute component={NotFoundPage} exact path={`${parentRoute}/*`} />
        </Switch>
      )}
    </>
  );
};

export default withSpinner(AdminRoutes);
