import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import newsDefaultImg from '~/assets/images/news-default-image.png';
import BackButton from '~/components/shared/BackButton';
import { DATE_FORMATS } from '~/enums';
import { selectedNewsReset } from '~/store/news/slice';
import { ErrorMessage, Icon } from '~/ui';
import { formatDateFromUnixTime } from '~/utils';

import { Container, Content, EditArticle, Img, InfoContainer, InfoParagraph, Title } from './styles';

const NewsArticleContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: urlCommunityId } = useParams();

  const selectedCommunityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { selectedNews, selectedNewsError } = useSelector(state => state.news);
  const { title, imageUrl, message, competitionId, timestamp } = selectedNews;

  if (selectedCommunityId !== parseInt(urlCommunityId)) {
    dispatch(selectedNewsReset());
    history.push(`/admin/community/${selectedCommunityId}/news`);
  }

  const handleBackButton = () => {
    history.goBack();
    dispatch(selectedNewsReset());
  };

  return (
    <>
      <BackButton onClick={handleBackButton} />
      {selectedNewsError ? (
        <ErrorMessage>{selectedNewsError}</ErrorMessage>
      ) : (
        <Container>
          <div>
            <Title>{title}</Title>
            <Link to={`/admin/community/${selectedCommunityId}/news/${selectedNews.id}/edit-article`}>
              <EditArticle>
                <Icon color='tmGreen' cursor='pointer' icon='edit' />
                Edit
              </EditArticle>
            </Link>
          </div>
          <Img alt='News image' src={imageUrl ? imageUrl : newsDefaultImg} />
          <Content dangerouslySetInnerHTML={{ __html: message }} />
          <InfoContainer withIcon={competitionId}>
            <InfoParagraph>
              Posted in{' '}
              <span>
                {competitionId ? (
                  <>
                    <Icon icon='trophy' />
                    Competition News
                  </>
                ) : (
                  'Community News'
                )}
              </span>
            </InfoParagraph>
            {timestamp && (
              <InfoParagraph>
                Time <span>{formatDateFromUnixTime(timestamp, DATE_FORMATS.WITH_DOT_TIME)}</span>
              </InfoParagraph>
            )}
          </InfoContainer>
        </Container>
      )}
    </>
  );
};

export default NewsArticleContent;
