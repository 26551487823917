import { createSlice } from '@reduxjs/toolkit';

import { renameUserProperties } from '~/utils';

const initialLeagueState = {
  additionalDescription: '',
  adminEmail: '',
  ageFrom: 16,
  ageTo: 72,
  communityId: 0,
  competitionType: '',
  court: {
    placeId: '',
    name: '',
    imageUrl: '',
    location: {
      lat: 0,
      lon: 0,
    },
    formattedAddress: '',
  },
  endTime: 0,
  gender: { gender: 'BOTH', prettyName: 'Both' },
  id: 0,
  name: '',
  numberOfRegisteredPlayers: 0,
  registerEndTime: 0,
  registerStartTime: 0,
  skillLevelFrom: {
    skillLevel: 'LEVEL_1_0',
    prettyName: 'Level 1.0',
    description: '',
  },
  skillLevelTo: {
    skillLevel: 'LEVEL_7_0',
    prettyName: 'Level 7.0',
    description: '',
  },
  startTime: 0,
  groupsAndMembers: [
    {
      leagueGroup: {
        id: 0,
        competitionId: 0,
        name: '',
        status: '',
      },
      members: [],
    },
  ],
  remainingMatches: null,
  leagueEligibilityStatus: '',
  news: [],
  members: [],
  membersError: '',
};

const initialState = {
  leaguesList: [initialLeagueState],
  leaguesError: '',
  selectedLeague: initialLeagueState,
  selectedLeagueError: '',
  communityLeaguesScreen: {},
  communityLeaguesScreenLoading: false,
  communityLeaguesScreenError: '',
};

export const leaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    leaguesList: (state, action) => {
      state.leaguesList = action.payload;
      state.leaguesError = '';
    },
    leaguesError: (state, action) => {
      state.leaguesList = [];
      state.leaguesError = action.payload;
    },
    selectedLeague: (state, action) => {
      const {
        leagueResponse,
        leagueGroupsAndMembers,
        news,
        remainingMatches,
        hasLeagueEnded,
        leagueEligibilityStatus,
      } = action.payload;

      state.selectedLeague = {
        ...leagueResponse,
        groupsAndMembers: leagueGroupsAndMembers,
        news,
        remainingMatches,
        hasLeagueEnded,
        leagueEligibilityStatus,
        members: [],
      };
      state.selectedLeagueError = '';
    },
    selectedLeagueError: (state, action) => {
      state.selectedLeague = initialLeagueState;
      state.selectedLeagueError = action.payload;
    },
    selectedLeagueMembers: (state, action) => {
      const leagueMembers = action.payload.map(user => {
        const { leagueGroupId, leagueStatus } = user;
        const renamedProperties = renameUserProperties(user);

        return {
          leagueGroupId,
          leagueStatus,
          ...renamedProperties,
        };
      });

      state.selectedLeague.members = leagueMembers;
      state.selectedLeague.membersError = '';
    },
    selectedLeagueMembersError: (state, action) => {
      state.selectedLeague.members = [];
      state.selectedLeague.membersError = action.payload;
    },
    selectedLeagueReset: state => {
      state.selectedLeague = initialLeagueState;
      state.selectedLeagueError = '';
    },
    leaguesInitialState: () => {
      return initialState;
    },
    communityLeaguesScreen: (state, action) => {
      state.communityLeaguesScreen = action.payload;
      state.communityLeaguesScreenLoading = false;
      state.communityLeaguesScreenError = '';
    },
    communityLeaguesScreenLoading: state => {
      state.communityLeaguesScreenLoading = true;
    },
    communityLeaguesScreenError: (state, action) => {
      state.communityLeaguesScreen = {};
      state.communityLeaguesScreenLoading = false;
      state.communityLeaguesScreenError = action.payload;
    },
  },
});

export const {
  leaguesList,
  leaguesError,
  selectedLeague,
  selectedLeagueError,
  selectedLeagueMembers,
  selectedLeagueMembersError,
  selectedLeagueReset,
  leaguesInitialState,
  communityLeaguesScreen,
  communityLeaguesScreenLoading,
  communityLeaguesScreenError,
} = leaguesSlice.actions;

export default leaguesSlice.reducer;
