import React from 'react';

import { Icon } from '~/ui';

import { Container, Content, Header, Title } from './styles';

const ModalContent = ({ children, title = 'Modal', onClose = () => {} }) => {
  return (
    <Container>
      <Header>
        <div></div>
        <Title>{title}</Title>
        <Icon color='tmGreen' cursor='pointer' icon='close2' size='s' onClick={onClose} />
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default ModalContent;
