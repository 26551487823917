import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  display: flex;
  margin: 32px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin: 16px;
  }
`;

export const Content = styled.div`
  flex: 1;
  flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : '75%')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '75%')};
  height: 100%;
  margin-left: ${({ fullWidth }) => (fullWidth ? '0' : '32px')};
  padding: 32px;
  background: ${colors.highlight1};
  box-sizing: border-box;

  > *.noDefaultPadding {
    margin: -32px;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    flex-basis: 100%;
    margin-left: 0;
    padding: 0;
    background: ${colors.background};
    max-width: 100%;

    > *.noDefaultPadding {
      margin: -16px;
    }
  }
`;
