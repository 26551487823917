import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from '~/components/shared';
import { getTournamentTreeData } from '~/store/tournaments/tournamentTree.utils';
import { Spinner } from '~/ui';

import Bracket from '../Bracket';
import PageHeader from '../Header';
import RoundsHeader from '../RoundsHeader';
import { BracketsColumn, ContentContainer, TreeContainer } from '../styles';

const TournamentTree = () => {
  const dispatch = useDispatch();
  const { tournamentId } = useParams();

  const { data, isLoading, errorMessage } = useSelector(state => state.tournamentTree);

  useEffect(() => {
    if (tournamentId) {
      dispatch(getTournamentTreeData(tournamentId));
    }
  }, [tournamentId, dispatch]);

  if (isLoading || isEmpty(data)) {
    return <Spinner />;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const { tournamentName, rounds } = data;

  return (
    <>
      <PageHeader title={tournamentName} />
      <ContentContainer>
        <RoundsHeader numberOfRounds={rounds.length} />
        <TreeContainer>
          {rounds.map(({ roundNumber, roundMatches }) => (
            <BracketsColumn key={roundNumber}>
              {roundMatches.map(match => {
                const { host, guest, result, id } = match;

                return (
                  <Bracket
                    guest={guest}
                    host={host}
                    key={id}
                    result={result || {}}
                    roundNumber={roundNumber}
                    totalRounds={rounds.length}
                  />
                );
              })}
            </BracketsColumn>
          ))}
        </TreeContainer>
      </ContentContainer>
    </>
  );
};

export default TournamentTree;
