import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdminPage from '~/pages/Admin';
import CompleteProfilePage from '~/pages/CompleteProfile';
import HomePage from '~/pages/Home';
import LoginAndSignupPage from '~/pages/LoginAndSignup';
import MatchPage from '~/pages/Match';
import NotFoundPage from '~/pages/NotFound';
import TournamentTreePage from '~/pages/Tournament/index';
import UserPage from '~/pages/User';
import VerifyEmail from '~/pages/VerifyEmail/index';

const InitialRoutes = () => {
  const { isAuthenticated, isProfileCompleted } = useSelector(state => state.auth);

  const completeProfileRules = () => {
    if (isAuthenticated && isProfileCompleted) {
      return <Redirect to='/home' />;
    } else if (isAuthenticated && !isProfileCompleted) {
      return <CompleteProfilePage />;
    } else {
      return <Redirect to='/login' />;
    }
  };

  return (
    <Switch>
      <Route exact path='/login' render={() => (isAuthenticated ? <Redirect to='/home' /> : <LoginAndSignupPage />)} />
      <Route component={AdminPage} path='/admin' />
      <Route exact path='/complete-profile' render={() => completeProfileRules()} />
      <Route component={VerifyEmail} exact path='/api/users/activate/:token' />
      <Route component={UserPage} path='/home' />
      <Route component={MatchPage} path='/match' />
      <Route component={TournamentTreePage} path='/tournament-tree' />
      <Route component={HomePage} exact path='/' />
      <Route component={NotFoundPage} path='*' />
    </Switch>
  );
};

export default InitialRoutes;
