import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FlexColumnWrapper, WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';
import { Button, Icon, Search } from '~/ui';

import Header from '../shared/Header';
import CommunityList from './CommunityList';

const JoinCommunity = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const { searchedCommunities } = useSelector(state => state.communities);

  return (
    <FlexColumnWrapper>
      <Header icon='search2' title='Join a Community'>
        <Button onClick={() => history.push('/admin/new-community')}>
          <Icon color='tmGreen' icon='plus2' size='s' />
          New Community
        </Button>
      </Header>
      <Search placeholder='Search for a community...' onSearch={term => setSearchTerm(term)} />
      <WidgetWrapper>
        <WidgetHeader
          icon='filledBgUsers'
          iconColor='tmGreen'
          title={!!searchTerm ? `${searchedCommunities.length} communities found` : 'Recommended communities'}
        />
        <CommunityList searchTerm={searchTerm} />
      </WidgetWrapper>
    </FlexColumnWrapper>
  );
};

export default JoinCommunity;
