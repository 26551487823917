import React from 'react';
import ReactPaginate from 'react-paginate';

import { PaginationWrapper } from './styles';

function Pagination({ forcePage, pageCount, onPageChange }) {
  return (
    <PaginationWrapper>
      <ReactPaginate
        // classes
        activeClassName='pageActive'
        activeLinkClassName='pageActiveLink'
        breakClassName='break'
        breakLabel='_'
        breakLinkClassName='breakLink'
        containerClassName='container'
        forcePage={forcePage}
        // other
        marginPagesDisplayed={3}
        nextLabel={null}
        pageClassName='page'
        pageCount={pageCount}
        pageLinkClassName='pageLink'
        pageRangeDisplayed={4}
        previousLabel={null}
        onPageChange={onPageChange}
      />
    </PaginationWrapper>
  );
}

export default Pagination;
