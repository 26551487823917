import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    flex-direction: column;
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  margin: 0;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${colors.whiteOpacity._5};
  margin: 0;
`;
