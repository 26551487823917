import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Role = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${colors.ash};
`;

export const UserInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UserName = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
`;
