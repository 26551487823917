import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { forgotPassword } from '~/store/auth/utils';
import { Button, Icon, Input } from '~/ui';

import { BackButton, ForgotPasswordButton, FormFooter, Notice } from '../styles';

const ForgotPassword = ({ setShowForgotPasswordForm }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const handleForgotPasswordSubmit = async e => {
    e.preventDefault();

    if (email === '') return;

    dispatch(forgotPassword({ email }));
    setShowForgotPasswordForm(false);
  };

  return (
    <>
      <BackButton onClick={() => setShowForgotPasswordForm(false)}>
        <Icon color='tmGreen' cursor='pointer' icon='arrowLeft' />
        Back
      </BackButton>
      <Notice>
        Forgot your password? Just input your email address below and we’ll email you to reset your password.
      </Notice>
      <form onSubmit={handleForgotPasswordSubmit}>
        <Input
          icon='mail'
          iconColor='tmGreen'
          label='Email address'
          type='email'
          onChange={e => setEmail(e.target.value)}
        />
        <FormFooter>
          <Button type='submit'>Send</Button>
          <ForgotPasswordButton onClick={() => setShowForgotPasswordForm(false)}>Cancel</ForgotPasswordButton>
        </FormFooter>
      </form>
    </>
  );
};

export default ForgotPassword;
