import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const TermsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;

  span {
    margin-left: 16px;
    color: ${colors.whiteOpacity._75};
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Notice = styled.p`
  color: ${({ error }) => (error ? colors.danger : colors.whiteOpacity._5)};
  font-size: 16px;
  line-height: 24px;
`;
