import styled, { css } from 'styled-components';

import { Button } from '~/ui';

const getCleanButtonStyle = ({ styleType }) => {
  return styleType === 'default' ? false : cleanStyle; // if `false` button will use default Button component style
};

const cleanStyle = css`
  border: none;
  margin: 0 -5px;
  padding: 0;
  background: none;
  width: fit-content;
  display: flex;
  align-items: center;

  &:hover,
  &:active {
    background: none;
  }

  .icon {
    display: inline-block;
    margin-right: 12px;
    vertical-align: bottom;
  }
`;

export const StyledBackButton = styled(Button)`
  ${getCleanButtonStyle}
`;
