import styled from 'styled-components';

import { Button, Dropdown, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const IconContainer = styled.span`
  .icon {
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  .CustomDropdown__control,
  .CustomDropdown__control:hover,
  .CustomDropdown__control:focus {
    padding-left: 0;
    background: transparent;
    cursor: pointer;
  }

  .CustomDropdown__menu-notice {
    display: flex;
    text-align: left;

    .icon {
      margin-left: 0;
      margin-right: 12px;
    }
  }
`;

export const DeselectButton = styled(Button)`
  height: auto;
  margin: 0;
  padding: 10px 16px;
  color: ${colors.ash};

  .icon {
    margin-left: 0;
  }
`;

export const MobileLabel = styled.span`
  margin-right: 10px;
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;

  @media screen and (min-width: ${mediaQueries.mobile}px) {
    display: none;
  }
`;
