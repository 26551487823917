import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { cancelMatch } from '~/api/matches';
import ModalContent from '~/components/shared/ModalContent/';
import { ErrorMessage } from '~/components/shared/styles';
import { ANALYTICS_EVENTS } from '~/enums/analytics';
import { useMatchOpponent } from '~/hooks';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button, Textarea } from '~/ui';
import { trackAnalyticsEvent } from '~/utils';

const CancelMatchModal = ({ onClose }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const { matchId } = useParams();

  const opponent = useMatchOpponent();

  const onMatchCancel = () => {
    setIsLoading(true);
    setErrorMessage('');

    cancelMatch(matchId, message.trim())
      .then(() => {
        setIsLoading(false);
        trackAnalyticsEvent(ANALYTICS_EVENTS.MATCH_CANCELED);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsLoading(false);
      });
  };

  return (
    <ModalContent title='Cancel Match' onClose={onClose}>
      <InfoMessage>
        Are you sure you want to cancel your match with {opponent.firstName}? If you want, you can write a short message
        to {opponent.firstName} on why you are canceling.
      </InfoMessage>
      <Textarea
        isNewStyle
        placeholder='Write a message...'
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button color='danger' disabled={isLoading} isNewStyle onClick={onMatchCancel}>
          {isLoading ? 'Canceling...' : 'Cancel'}
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default CancelMatchModal;
