import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries, zIndex } = theme;

const getMobileSideNavigation = ({ showMobileMenu }) => {
  return showMobileMenu ? mobileNavigationShow : mobileNavigationHide;
};

const mobileNavigationShow = css`
  flex-basis: 100%;
  width: 100%;
  max-width: calc(100% - 64px);
  height: 100%;
  padding: 32px;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: ${zIndex.level3};
`;

const mobileNavigationHide = css`
  display: none;
`;

export const Nav = styled.nav`
  display: flex;
  flex-basis: 25%;
  max-width: 274px;
  background: ${colors.background};

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    ${getMobileSideNavigation}
  }
`;

export const Container = styled.div`
  position: relative;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: block;
  margin-bottom: 20px;
  color: ${colors.whiteOpacity._75};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;

  &.active,
  &:active {
    > .icon {
      color: ${colors.tmGreen};
    }
  }

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: ${colors.white};
  }

  &:first-of-type {
    margin-top: 45px;
  }

  > .icon {
    display: inline-block;
    margin-right: 12px;
    vertical-align: middle;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    &:first-of-type {
      margin-top: 15px;
    }
  }
`;
