import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ModalContent from '~/components/shared/ModalContent';
import { Button, DatePicker, Dropdown, InputRange, Modal } from '~/ui';
import { genderOptions } from '~/utils/members';

import { Divider } from '../NewMatch/styles';
import { ModalWrapper } from './styles';

const FiltersModal = ({ isVisible, onClose, setFilters, filters }) => {
  const { allUserCommunities } = useSelector(state => state.communities);
  const communities = useMemo(() => {
    return [
      { value: null, label: 'All communities' },
      ...allUserCommunities.map(community => ({
        value: community.communityInfo.id,
        label: `Only in ${community.communityInfo.communityName}`,
      })),
    ];
  }, [allUserCommunities]);

  const [communityId, setCommunityId] = useState(filters.communityId);
  const [gender, setGender] = useState(filters.gender);
  const [skillLevelRange, setSkillLevelRange] = useState(filters.skillLevelRange);
  const [opponentAgeRange, setOpponentAgeRange] = useState(filters.opponentAgeRange);
  const [timeFrom, setTimeFrom] = useState(filters.timeFrom);
  const [timeTo, setTimeTo] = useState(filters.timeTo);

  if (isVisible) {
    return (
      <Modal ContentWrapper={ModalWrapper}>
        <ModalContent title='Open Match Filetrs' onClose={onClose}>
          <>
            <Dropdown
              defaultValue={communities[0]}
              icon='flag2'
              iconColor='ash2'
              isNewStyle
              options={communities}
              onChange={community => setCommunityId(community.value)}
            />
            <Dropdown
              defaultValue={genderOptions[2]}
              icon='unisex2'
              iconColor='ash2'
              isNewStyle
              options={genderOptions}
              placeholder='Pick opponent gender'
              onChange={gender => setGender(gender.value)}
            />
            <InputRange
              isNewStyle
              label='Pick opponent level'
              maxValue={7.0}
              minValue={1.0}
              step={0.5}
              value={skillLevelRange}
              onChange={skillLevelRange => setSkillLevelRange(skillLevelRange)}
            />
            <InputRange
              isNewStyle
              label='Pick opponent age'
              maxValue={72}
              minValue={16}
              step={1}
              value={opponentAgeRange}
              onChange={opponentAgeRange => setOpponentAgeRange(opponentAgeRange)}
            />
            <DatePicker
              icon='calendar'
              isNewStyle
              label='Date from'
              minDate={new Date()}
              value={timeFrom}
              onChange={date => setTimeFrom(date)}
            />
            <DatePicker
              icon='calendar'
              isNewStyle
              label='Date to'
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              value={timeTo}
              onChange={date => setTimeTo(date)}
            />
            <Divider />
            <Button
              fullWidth
              isNewStyle
              onClick={() => {
                setFilters({
                  communityId,
                  gender,
                  skillLevelRange,
                  opponentAgeRange,
                  timeFrom,
                  timeTo,
                });
                onClose();
              }}
            >
              Apply filters
            </Button>
          </>
        </ModalContent>
      </Modal>
    );
  }

  return null;
};

export default FiltersModal;
