import React from 'react';

import { ErrorMessageContainer } from './styles';

const ErrorMessage = ({ bold, children, red, style = {} }) => {
  return (
    <ErrorMessageContainer bold={bold} red={red} style={style}>
      {children}
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
