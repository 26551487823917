import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import CommunityForm from '~/components/Admin/CommunityForm';
import CommunityHeaderImage from '~/components/Admin/CommunityHeaderImage';
import MembersTable from '~/components/Admin/MembersTable';
import {
  Container as InfoContainer,
  Key,
  Value,
  ValueContainer,
} from '~/components/Admin/SingleCompetitionInfo/styles';
import { ROLE } from '~/enums';
import { getCommunityMembers } from '~/store/communities/utils';
import { Button, Icon } from '~/ui';

import { Container, HeaderLabel, Name, TableName } from './styles';

const AdministrationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: urlCommunityId } = useParams();

  const { communityInfo, members, membersError, myStatus } = useSelector(state => state.communities.selectedCommunity);
  const { id: communityId, email, communityName, court, imageUrl, info } = communityInfo;

  const [loadingMembers, setLoadingMembers] = useState(false);
  const [isEditCommunity, setIsEditCommunity] = useState(false);

  useEffect(() => {
    const loadMembers = async () => {
      setLoadingMembers(true);
      await dispatch(getCommunityMembers(communityId));
      setLoadingMembers(false);
    };

    loadMembers();
    setIsEditCommunity(false);
  }, [dispatch, communityId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (myStatus.role !== ROLE.ADMIN) history.push('/admin');

  if (communityId !== Number(urlCommunityId)) {
    history.push(`/admin/community/${communityId}/administration`);
  }

  const administrators = members.filter(member => member.role === ROLE.ADMIN || member.role === ROLE.OPERATOR);

  return (
    <>
      {isEditCommunity ? (
        <CommunityForm isEdit onBack={() => setIsEditCommunity(false)} />
      ) : (
        <>
          <CommunityHeaderImage image={imageUrl} />
          <Container>
            <HeaderLabel>Community</HeaderLabel>
            <Name>{communityName}</Name>
            <InfoContainer>
              <ValueContainer>
                <Key>Community email</Key>
                <Value>{email}</Value>
              </ValueContainer>
              <ValueContainer>
                <Key>Community address</Key>
                <Value>{court.formattedAddress}</Value>
              </ValueContainer>
            </InfoContainer>
            {info && (
              <InfoContainer>
                <ValueContainer>
                  <Key>Description</Key>
                  <Value newLine style={{ fontSize: '16px', letterSpacing: '0', lineHeight: '24px' }}>
                    {info}
                  </Value>
                </ValueContainer>
              </InfoContainer>
            )}
            <Button onClick={() => setIsEditCommunity(true)}>
              <Icon color='tmGreen' cursor='pointer' icon='edit' />
              Edit community info
            </Button>
            <TableName>Admins & Operators {!loadingMembers && `(${administrators.length})`}</TableName>
            <MembersTable data={administrators} error={membersError} isLoading={loadingMembers} />
          </Container>
        </>
      )}
    </>
  );
};

export default AdministrationPage;
