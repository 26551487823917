import React from 'react';
import { useSelector } from 'react-redux';

import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import TextWithIcon from '~/components/shared/TextWithIcon';
import { MATCH_TYPE } from '~/enums/match';
import { GENDER } from '~/enums/member';

const MatchInviteInfo = () => {
  const { data } = useSelector(state => state.matchScreen);
  const { matchInvite } = data;

  const { opponentAgeFrom, opponentAgeTo, skillLevelFrom, skillLevelTo, opponentGender, type } = matchInvite;

  if (type !== MATCH_TYPE.OPEN) return null;

  let genderIcon = 'unisex2';

  if (opponentGender) {
    genderIcon = opponentGender.gender === GENDER.MALE ? 'male' : 'female';
  }

  return (
    <WidgetWrapper>
      <WidgetItem>
        <TextWithIcon
          icon={genderIcon}
          iconColor='tmGreen'
          text={opponentGender ? opponentGender.prettyName : 'Both'}
        />
      </WidgetItem>
      <WidgetItem>
        <TextWithIcon icon='balloon' iconColor='tmGreen' text={`${opponentAgeFrom} to ${opponentAgeTo} years`} />
      </WidgetItem>
      <WidgetItem>
        <TextWithIcon
          icon='badge'
          iconColor='tmGreen'
          text={`${skillLevelFrom?.prettyName} - ${skillLevelTo?.prettyName}`}
        />
      </WidgetItem>
    </WidgetWrapper>
  );
};

export default MatchInviteInfo;
