import React from 'react';
import { useParams } from 'react-router-dom';

import { Table } from '~/ui';

import { getCompetitionsColumns, getCompetitionsData } from './utils';

const CompetitionsTable = ({ competitionsType, data, error, isLoading, tableType }) => {
  const { id: communityId } = useParams();

  return (
    <Table
      columns={getCompetitionsColumns({ communityId, competitionsType, tableType })}
      data={getCompetitionsData({ data })}
      error={error}
      isLoading={isLoading}
      itemsName={`${tableType} ${competitionsType}s`}
    />
  );
};

export default CompetitionsTable;
