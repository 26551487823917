import capitalize from 'lodash/capitalize';
import React from 'react';
import { Link } from 'react-router-dom';

import NotificationBadge from '~/components/Admin/Notification/Badge';
import NotificationMatches from '~/components/Admin/Notification/Matches';
import TableCompetitionNameCell from '~/components/Admin/TableCompetitionNameCell';
import { COMPETITION_TYPE } from '~/enums';
import { getCompetitionStatus } from '~/utils';

import { CellBlock, CellFirstInfo, CellSecondInfo, ManageButton } from './styles';

export const getCompetitionsData = ({ data = [] }) => {
  return data.map(competition => {
    const { status, timeline } = getCompetitionStatus(competition);
    const {
      ageFrom,
      ageTo,
      communityId,
      competitionType,
      court,
      endTime,
      gender,
      id,
      name,
      numberOfRegisteredPlayers,
      startTime,
      skillLevelFrom,
      skillLevelTo,
    } = competition;

    return {
      ageFrom,
      ageTo,
      communityId,
      competitionType,
      court,
      endTime,
      gender,
      id,
      name,
      numberOfRegisteredPlayers,
      status,
      startTime,
      skillLevelFrom,
      skillLevelTo,
      timeline,
    };
  });
};

export const getCompetitionsColumns = ({ communityId, type }) => {
  const competitionTypeSingular = type.slice(0, -1);

  return [
    {
      label: `${capitalize(type)}`,
      value: 'name',
      sortBy: 'name',
      iconLeft: `${competitionTypeSingular === COMPETITION_TYPE.LEAGUE ? 'flag' : 'trophy'}`,
      renderCustomCell: competition => {
        const { status } = competition;

        return (
          <div style={{ width: '100%' }}>
            <TableCompetitionNameCell competition={competition} status={status} />
            {status === 'Registration ended' && (
              <NotificationBadge
                dotColor='pending'
                text={
                  competitionTypeSingular === COMPETITION_TYPE.LEAGUE ? 'Seed players in groups' : 'Populate Brackets'
                }
              />
            )}
          </div>
        );
      },
    },
    {
      label: 'Status',
      width: 300,
      renderCustomCell: competition => {
        const { competitionType, numberOfRegisteredPlayers, status, timeline } = competition;

        return (
          <>
            <CellBlock>
              {status === 'Registration not started' || status === 'Registration started' ? (
                <CellFirstInfo>{timeline[1]}</CellFirstInfo>
              ) : (
                <>
                  <CellFirstInfo>
                    {timeline[0]}. {timeline[1]}
                  </CellFirstInfo>
                </>
              )}
              <CellSecondInfo>
                {status === `${capitalize(competitionType)} started` ? (
                  <>
                    <NotificationMatches dotColor='tmGreen' text='0 Matches played' />
                    <NotificationMatches dotColor='pending' text='0 Matches left' />
                  </>
                ) : status === `${capitalize(competitionType)} ended` ? (
                  <NotificationMatches dotColor='tmGreen' text='0 Matches played' />
                ) : (
                  <>Registered players: {numberOfRegisteredPlayers} / Max players: 0</>
                )}
              </CellSecondInfo>
            </CellBlock>
            <Link to={`/admin/community/${communityId}/${competitionType.toLowerCase()}/${competition.id}`}>
              <ManageButton small>Manage</ManageButton>
            </Link>
          </>
        );
      },
    },
  ];
};
