import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const WidgetLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: ${colors.highlight3};
  position: relative;
  min-height: 72px;
  text-align: center;
`;

export const InfoMessage = styled.p`
  color: ${colors.ash2};
  font-weight: 400;
  font-size: 13px;
  line-height: 2;
`;

export const MatchInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const MatchOpponentName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.white};
`;

export const MatchTimeAndPlace = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 2;
  color: ${colors.ash2};
`;

export const MatchStatus = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 2;
  color: ${({ color }) => colors[color]};
`;

export const MatchInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MatchOpponentAvatarContainer = styled.div`
  position: relative;
`;

export const MatchOpponentAvatar = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const MatchWinLossBadge = styled.span`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
  padding: 4px;
  border-radius: 4px;
  color: ${colors.white};
  font-size: 10px;
  background-color: ${({ isWin }) => (isWin ? colors.tmGreen : colors.danger)};
`;
