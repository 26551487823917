import React, { useRef, useState } from 'react';

import { Icon, useOutsideClick } from '~/ui';
import { range, timePeriods } from '~/utils';

import {
  ActionsContainer,
  ClearTimeButton,
  ConfirmTimeButton,
  Container,
  DropdownContainer,
  InputContainer,
  TimeOption,
  TimeOptions,
  TimeOptionsContainer,
} from './styles';

const IntervalTimePicker = ({
  value,
  icon,
  iconColor = 'ash2',
  leftIconSize = 's',
  isAmPm = false,
  minuteInterval = 15,
  maxHours = null,
  maxMinutes = null,
  name = 'time',
  placeholder,
  onChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [period, setPeriod] = useState('am');

  const containerRef = useRef(null);

  const [hourOptions] = useState(() => {
    const hoursRange = range(0, isAmPm ? 11 : 23, 1);
    if (isAmPm) {
      hoursRange[0] = '12';
    }
    return hoursRange;
  });
  const [minutesOptions] = useState(range(0, 45, minuteInterval));

  const onHourSelect = hours => {
    setHours(hours);

    if (!minutes) {
      setMinutes('00');
    }
  };

  const onMinuteSelect = minutes => {
    setMinutes(minutes);

    if (!hours) {
      setHours('00');
    }
  };

  const onOk = e => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (hours && minutes) {
      onChange(`${hours}:${minutes} ${isAmPm ? period.toUpperCase() : ''}`.trim());
    }
    setIsDropdownOpen(() => false);
  };

  const onClear = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setHours('');
    setMinutes('');
    onChange('');
    setIsDropdownOpen(() => false);
  };

  const onOutsideClick = () => {
    setHours(value.substr(0, 2));
    setMinutes(value.substr(3, 2));
    setIsDropdownOpen(false);
  };

  useOutsideClick(containerRef, onOutsideClick);

  return (
    <Container leftIconSize={leftIconSize} ref={containerRef} onClick={() => setIsDropdownOpen(true)}>
      {icon && <Icon color={iconColor} icon={icon} size={leftIconSize} />}
      <InputContainer
        hasLeftIcon={!!icon}
        isDropdownOpen={isDropdownOpen}
        name={name}
        placeholder={placeholder}
        readOnly
        type='text'
        value={value}
      />
      {isDropdownOpen && (
        <DropdownContainer>
          <TimeOptionsContainer>
            <TimeOptions>
              {hourOptions.map(option => (
                <TimeOption
                  isDisabled={maxHours ? Number(option) > maxHours - 1 : false}
                  isSelected={hours === option}
                  key={option}
                  onClick={e => {
                    if (maxHours && Number(option) > maxHours - 1) {
                      return;
                    }

                    e.nativeEvent.stopImmediatePropagation();
                    onHourSelect(option);
                  }}
                >
                  {option}
                </TimeOption>
              ))}
            </TimeOptions>
            <TimeOptions>
              {minutesOptions.map(option => (
                <TimeOption
                  isDisabled={maxMinutes ? Number(option) > maxMinutes : false}
                  isSelected={minutes === option}
                  key={option}
                  onClick={e => {
                    if (maxMinutes && Number(option) > maxMinutes) {
                      return;
                    }

                    e.nativeEvent.stopImmediatePropagation();
                    onMinuteSelect(option);
                  }}
                >
                  {option}
                </TimeOption>
              ))}
            </TimeOptions>
            {isAmPm && (
              <TimeOptions>
                {timePeriods.map(per => (
                  <TimeOption
                    isSelected={period === per}
                    key={per}
                    onClick={e => {
                      e.nativeEvent.stopImmediatePropagation();
                      setPeriod(per);
                    }}
                  >
                    {per.toUpperCase()}
                  </TimeOption>
                ))}
              </TimeOptions>
            )}
          </TimeOptionsContainer>
          <ActionsContainer>
            <ClearTimeButton onClick={onClear}>Clear</ClearTimeButton>
            <ConfirmTimeButton onClick={onOk}>OK</ConfirmTimeButton>
          </ActionsContainer>
        </DropdownContainer>
      )}
      <Icon color={iconColor} icon='chevron2Down' size='s' />
    </Container>
  );
};

export default IntervalTimePicker;
