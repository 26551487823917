import styled from 'styled-components';

import womanServe from '~/assets/images/woman-serve.png';
import { theme } from '~/ui';

const { mediaQueries } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

export const FormContainer = styled.div`
  width: 458px;

  form {
    margin: 0 32px;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;

    form {
      margin: 0 16px;
    }
  }
`;

export const FormHeader = styled.div`
  display: flex;
  height: 312px;
  padding: 0 32px;
  background-image: url(${womanServe});
  align-items: flex-end;
  justify-content: center;

  img {
    width: auto;
    margin-bottom: 15px;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 450px) {
    img {
      width: 100%;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 512px;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 1500px) {
    background-image: linear-gradient(180deg, rgba(40, 40, 40, 0.0001) 80%, #282828 100%), url(${props => props.src});
  }
`;
