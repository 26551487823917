import update from 'immutability-helper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFlexLayout, useTable } from 'react-table';

import UserWithAvatar from '~/components/shared/UserWithAvatar';
import { ErrorMessage, Icon, Spinner } from '~/ui';
import {
  SpinnerContainer,
  TableCell,
  TableContainer,
  TableEmptyState,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from '~/ui/components/Table/styles';

const DND_ITEM_TYPE = 'row';

const Row = ({ row, index, moveRow, colorLineUnderRowNumber, onDelete }) => {
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <TableRow
      colorLineUnderRowNumber={colorLineUnderRowNumber}
      ref={dropRef}
      style={{ opacity: isDragging ? 0 : 1, borderBottom: 'none' }}
    >
      {row.cells.map((cell, i) => (
        <TableCell {...cell.getCellProps()}>
          {i === 0 ? (
            <>
              <span ref={dragRef} style={{ display: 'flex', width: '12px' }}>
                <Icon color='ash2' cursor='move' icon='drag' />
              </span>
              <span style={{ width: '15px', padding: '0 16px' }}>{index + 1}.</span>
              <UserWithAvatar avatarUrl={cell.row.original.avatarUrl} userName={cell.row.original.name} />
            </>
          ) : i + 1 === row.cells.length ? (
            <>
              {cell.render('Cell')}
              <span style={{ marginLeft: '12px' }}>
                <Icon color='danger' cursor='pointer' icon='trash' onClick={() => onDelete(row)} />
              </span>
            </>
          ) : (
            cell.render('Cell')
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

const DragAndDropPlayersTable = ({
  colorLineUnderRowNumber,
  columns,
  data: initialData,
  error,
  isLoading,
  onChange,
  onDelete,
}) => {
  const [data, setData] = useState(initialData);

  useEffect(() => onChange(data), [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useCallback(row => row.accountId, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { data: data, columns, getRowId },
    useFlexLayout
  );

  const moveRow = (dragIndex, hoverIndex) => {
    const dragData = data[dragIndex];

    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragData],
        ],
      })
    );
  };

  return (
    <TableContainer>
      <DndProvider backend={HTML5Backend}>
        <div {...getTableProps()}>
          {headerGroups.map(headerGroup => (
            <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHeaderCell {...column.getHeaderProps()}>
                  {column.iconLeft && <Icon icon={column.iconLeft} />}
                  <span>{column.render('Header')}</span>
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          ))}
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : (
            <div {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Row
                    colorLineUnderRowNumber={colorLineUnderRowNumber}
                    index={index}
                    moveRow={moveRow}
                    row={row}
                    onDelete={onDelete}
                    {...row.getRowProps()}
                  />
                );
              })}
              {data.length === 0 && <TableEmptyState>No players available.</TableEmptyState>}
            </div>
          )}
        </div>
      </DndProvider>
    </TableContainer>
  );
};

export default DragAndDropPlayersTable;
