import styled from 'styled-components';

import theme from '../../theme';

const { mediaQueries } = theme;

export const PageHeaderContainer = styled.div`
  display: flex;

  a,
  button {
    margin-left: auto;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: block;

    h1 {
      margin: 0;
    }
  }
`;
