import React from 'react';

import { Container, Name, Value } from './styles';

const DetailsInfo = ({ name, value }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Value>{value}</Value>
    </Container>
  );
};

export default DetailsInfo;
