import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

import theme from '../../theme';
import { CustomDropdownStyle } from '../Dropdown/styles';

const { colors } = theme;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: ${colors.whiteOpacity._75};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const CutomAsyncDropdown = styled(AsyncSelect)`
  ${CustomDropdownStyle}

  .CustomDropdown__menu-notice--no-options,
  .CustomDropdown__menu-notice--loading {
    padding-left: 20px;
    color: ${colors.whiteOpacity._5};
    text-align: left;
  }
`;
