import React from 'react';

import defaultHeaderImage from '~/assets/images/man-serve.png';

import { HeaderImage } from './styles';

const CommunityHeaderImage = ({ image, children }) => {
  return <HeaderImage image={image ? image : defaultHeaderImage}>{children}</HeaderImage>;
};

export default CommunityHeaderImage;
