export const genders = [
  { gender: 'MALE', prettyName: 'Male' },
  { gender: 'FEMALE', prettyName: 'Female' },
];

export const playingHands = [
  {
    playingHand: 'RIGHT',
    prettyName: 'Right',
  },
  {
    playingHand: 'LEFT',
    prettyName: 'Left',
  },
  {
    playingHand: 'BOTH',
    prettyName: 'Both',
  },
];

export const playingStyles = [
  {
    playingStyle: 'BASELINER',
    prettyName: 'Baseliner',
    description: 'Playing near the baseline and using varied ground strokes. Preferring long exchanges.',
  },
  {
    playingStyle: 'RETRIEVER',
    prettyName: 'Retriever',
    description: "Retrieving any shot as a “human backboard”. Hitting consistently and relying on opponent's misses.",
  },
  {
    playingStyle: 'JUNK_BALLER',
    prettyName: 'Junk Baller',
    description: 'Varying height, depth, angles and spin constantly. Rarely making unforced errors.',
  },
  {
    playingStyle: 'CHOP_SLICER',
    prettyName: 'Chop Slicer',
    description:
      'Mastered the chop slice shot so well, rarely bother hitting a flat or topspin shot. Can put the chopped slice deep, short, angled and lobbed.',
  },
  {
    playingStyle: 'SPIN_DOCTOR',
    prettyName: 'Spin Doctor',
    description:
      'Striking the ball with pace and  crazy slices, side spins, top spins and some spins we don’t yet have names for.',
  },
  {
    playingStyle: 'BLASTER',
    prettyName: 'Blaster',
    description: 'Always looking for the opportunity to hit the ball hard and go for a winner... or an unforced error.',
  },
  {
    playingStyle: 'NET_RUSHER',
    prettyName: 'Net Rusher',
    description: 'Rushing forward whenever possible. Finishing majority of points with volleys.',
  },
  {
    playingStyle: 'UNIVERSAL',
    prettyName: 'Universal',
    description:
      'Being able to use any of the playing styles. Adapting own style according to what is needed to beat the opponent.',
  },
];

export const tennisLevels = [
  {
    skillLevel: 'LEVEL_1_0',
    prettyName: 'Level 1.0',
    description: 'This player is just starting to play tennis.',
  },
  {
    skillLevel: 'LEVEL_1_5',
    prettyName: 'Level 1.5',
    description:
      'This player has limited playing experience and is still working primarily on getting the ball into play.',
  },
  {
    skillLevel: 'LEVEL_2_0',
    prettyName: 'Level 2.0',
    description:
      'This player needs on-court experience. This player has obvious stroke weaknesses but is familiar with basic positions for singles and doubles play.',
  },
  {
    skillLevel: 'LEVEL_2_5',
    prettyName: 'Level 2.5',
    description:
      'This player is learning to judge where the ball is going although court coverage is weak. This player can sustain a slow rally with other players of same ability.',
  },
  {
    skillLevel: 'LEVEL_3_0',
    prettyName: 'Level 3.0',
    description:
      'This player is consistent when hitting medium pace shots, but is not comfortable with all strokes and lacks control when trying for directional intent, depth, or power.',
  },
  {
    skillLevel: 'LEVEL_3_5',
    prettyName: 'Level 3.5',
    description:
      'This player has achieved improved stroke dependability and direction on moderate pace shots, but still lacks depth and variety. This player exhibits more aggressive net play, has improved court coverage and is developing teamwork in doubles.',
  },
  {
    skillLevel: 'LEVEL_4_0',
    prettyName: 'Level 4.0',
    description:
      'This player has dependable strokes, including directional intent, on both forehand and backhand sides on moderate shots, plus the ability to use lobs, overheads, approach shots and volleys with some success. This player occasionally forces errors when serving and teamwork in doubles is evident.',
  },
  {
    skillLevel: 'LEVEL_4_5',
    prettyName: 'Level 4.5',
    description:
      'This player has begun to master the use of power and spins and is beginning to handle pace, has sound footwork, can control depth of shots, and is beginning to vary tactics according to opponents. This player can hit first serves with power and accuracy and place the second serve and is able to rush the net successfully.',
  },
  {
    skillLevel: 'LEVEL_5_0',
    prettyName: 'Level 5.0',
    description:
      'This player has good shot anticipation and frequently has an outstanding shot or attribute around which a game may be structured. This player can regularly hit winners or force errors off of short balls, can put away volleys, can successfully execute lobs, drop shots, half volleys and overhead smashes, and has good depth and spin on most second serves.',
  },
  {
    skillLevel: 'LEVEL_5_5',
    prettyName: 'Level 5.5',
    description:
      'This player has developed power and/or consistency as a major weapon. This player can vary strategies and styles of play in a competitive situation and hits dependable shots in a stress situation.',
  },
  {
    skillLevel: 'LEVEL_6_0',
    prettyName: 'Level 6.0',
    description:
      'These players will generally not need NRTP rankings. Rankings or past rankings will speak for themselves. The 6.0 player typically has had intensive training for national tournament competition at the junior level and collegiate levels and has obtained a sectional or national ranking.',
  },
  {
    skillLevel: 'LEVEL_6_5',
    prettyName: 'Level 6.5',
    description:
      'This player has a reasonable chance of succeeding at the 7.0 level and has extensive satellite tournament experience.',
  },
  {
    skillLevel: 'LEVEL_7_0',
    prettyName: 'Level 7.0',
    description:
      'This is a world class player who is committed to tournament competition on the international level and whose major source of income is tournament prize winnings.',
  },
];
