import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Text = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.tmGreen};

  & + .icon {
    transform: rotate(180deg);
  }
`;
