import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import CompleteProfileForm from '~/components/CompleteProfileForm';
import BackButton from '~/components/shared/BackButton';
import { PageTitle } from '~/ui';

const EditProfilePage = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div style={{ maxWidth: location.pathname.includes('home') ? 'unset' : '395px' }}>
      <BackButton onClick={() => history.goBack()} />
      <PageTitle title='Edit Profile' />
      <CompleteProfileForm isEdit />
    </div>
  );
};

export default EditProfilePage;
