import Select from 'react-select';
import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors, zIndex } = theme;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: ${colors.whiteOpacity._75};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const CustomDropdownStyle = css`
  .CustomDropdown__control {
    width: 100%;
    height: auto;
    border: none;
    border-bottom: 1px solid ${colors.invert};
    border-radius: 0;
    padding-left: 16px;
    background-color: ${colors.highlight2};
    color: ${colors.whiteOpacity._75};
    font-size: 16px;
    line-height: 24px;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;

    &:active {
      background-color: ${colors.highlight3};
    }

    &:focus,
    &:hover {
      border: none;
      border-bottom: 1px solid ${colors.invert};
      box-shadow: none;
    }

    &--is-focused {
      border: none;
      border-bottom: 1px solid ${colors.invert};
      box-shadow: none;
      background-color: ${colors.highlight3};
    }

    .CustomDropdown__value-container {
      padding: 0;
      color: ${colors.whiteOpacity._75};

      .CustomDropdown__placeholder,
      .CustomDropdown__single-value,
      .CustomDropdown__input {
        color: ${colors.whiteOpacity._75};
      }

      .CustomDropdown__clear-indicator:hover {
        color: ${colors.white};
      }

      .CustomDropdown__multi-value {
        background: transparent;

        .CustomDropdown__multi-value__label {
          color: ${colors.whiteOpacity._75};
          font-size: 16px;
        }

        .CustomDropdown__multi-value__remove:hover {
          background: transparent;
          color: ${colors.white};
        }
      }
    }

    .CustomDropdown__indicators {
      .CustomDropdown__indicator-separator {
        display: none;
      }

      .CustomDropdown__clear-indicator {
        color: ${colors.whiteOpacity._75};

        &:hover {
          color: ${colors.white};
        }
      }
    }
  }

  .CustomDropdown__menu {
    margin: 0;
    margin-bottom: 20px;
    border-radius: 0;
    background-color: ${colors.highlight3};
    color: ${colors.whiteOpacity._75};
    font-size: 16px;
    line-height: 24px;

    .CustomDropdown__option {
      width: auto;
      margin: 0 16px;
      padding: 8px 0;
      border-bottom: 1px solid ${colors.invert};

      &:last-child {
        border-bottom: none;
      }

      &--is-focused {
        background: none;
        color: ${colors.white};
        cursor: pointer;
      }

      &--is-selected {
        background: none;
        color: ${colors.white};
        cursor: pointer;
      }

      span.icon,
      img {
        display: inline-block;
        margin-right: 16px;
        vertical-align: middle;
      }

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
      }
    }
  }
`;

export const CustomDropdown = styled(Select)`
  ${CustomDropdownStyle}
`;

export const Optional = styled.span`
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-weight: normal;
  text-transform: lowercase;
  float: right;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const NewStyleContainer = styled.div`
  width: 100%;
  margin: 0;
  position: relative;
  box-shadow: 0px 2px 1px rgba(18, 22, 39, 0.02);
`;

const smallIconCss = css`
  left: 11px;
  top: 12px;
`;

const mediumIconCss = css`
  left: 10px;
  top: 8px;
`;

export const NewStylePrefixIcon = styled.span`
  position absolute;
  ${({ leftIconSize }) => (leftIconSize === 's' ? smallIconCss : mediumIconCss)};
  z-index: ${zIndex.level2};
`;

const NewStyleCustomDropdownCss = css`
  .NewStyleCustomDropdown__control {
    width: 100%;
    height: auto;
    border: 1px solid ${colors.ash3};
    border-radius: 4px;
    padding-left: 40px;
    height: 40px;
    background-color: ${colors.highlight3};
    color: ${colors.whiteOpacity._75};
    font-size: 14px;
    line-height: 24px;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;

    &:active {
      background-color: ${colors.highlight3};
    }

    &:focus,
    &:hover {
      border: 1px solid ${colors.tmGreen};
      box-shadow: none;
    }

    &--is-focused {
      border: 1px solid ${colors.tmGreen};
      box-shadow: none;
      background-color: ${colors.highlight3};
    }
  }

  .NewStyleCustomDropdown__indicator-separator {
    display: none;
  }

  .NewStyleCustomDropdown__indicator {
    position: absolute;
    right: 13px;
    top: 10px;
    padding: 0;
  }

  .NewStyleCustomDropdown__option {
    width: auto;
    margin: 0 16px;
    padding: 8px 0;
    border-bottom: 1px solid ${colors.ash3};

    &:last-child {
      border-bottom: none;
    }

    &--is-focused {
      background: none;
      color: ${colors.white};
      cursor: pointer;
    }

    &--is-selected {
      background: none;
      color: ${colors.white};
      cursor: pointer;
    }

    &:active {
      background: none;
    }
  }

  .NewStyleCustomDropdown__value-container {
    color: ${colors.white};
    padding: 0;
  }

  .NewStyleCustomDropdown__single-value {
    color: ${colors.white};
  }

  .NewStyleCustomDropdown__multi-value {
    background: transparent;

    .NewStyleCustomDropdown__multi-value__label {
      color: ${colors.whiteOpacity._75};
      font-size: 16px;
    }

    .NewStyleCustomDropdown__multi-value__remove:hover {
      background: transparent;
      color: ${colors.white};
    }
  }

  .NewStyleCustomDropdown__menu {
    margin: 0;
    margin-bottom: 20px;
    border-radius: 0;
    background-color: ${colors.highlight3};
    color: ${colors.whiteOpacity._75};
    font-size: 16px;
    line-height: 24px;
    z-index: ${zIndex.level4};
  }
`;

export const NewStyleCustomDropdown = styled(Select)`
  ${NewStyleCustomDropdownCss}
`;
