import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { registerMatchResult } from '~/api/matches';
import ResultInputs from '~/components/Match/ResultInputs/index';
import ModalContent from '~/components/shared/ModalContent/index';
import { ErrorMessage } from '~/components/shared/styles';
import { useMatchOpponent } from '~/hooks/match';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button, showToast } from '~/ui';

import PCRDescriptionCheckboxes from '../PCRDescriptionCheckboxes/index';
import RateOpponent from '../RateOpponent/index';
import { Divider } from '../styles';

const RegisterResultModal = ({ onClose }) => {
  const [scores, setScores] = useState([]);
  const [isScoresError, setIsScoresError] = useState(false);
  const [rating, setRating] = useState(0);
  const [pcrDescriptionList, setPcrDescriptionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { matchId } = useParams();
  const dispatch = useDispatch();

  const opponent = useMatchOpponent();
  const { data } = useSelector(state => state.matchScreen);

  const { host, guest } = data;

  const onCheckboxSelect = (checked, value) => {
    if (checked) {
      setPcrDescriptionList([...pcrDescriptionList, value]);
    } else {
      setPcrDescriptionList(pcrDescriptionList.filter(el => el !== value));
    }
  };

  const onRegisterResult = () => {
    setIsLoading(true);
    setErrorMessage('');

    const setResults = scores.map((val, i) => ({ setNo: i + 1, ...val }));

    const data = {
      playerCredibilityRating: +rating,
      pcrDescriptionList,
      setResults,
    };

    registerMatchResult(matchId, data)
      .then(() => {
        showToast({
          title: 'Results submitted!',
          message:
            'Your match scores and feedback have been submitted. We’ll notify you once your opponent submits their scores.',
        });

        setIsLoading(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsLoading(false);
      });
  };

  return (
    <ModalContent title='Register Result' onClose={onClose}>
      <InfoMessage style={{ textAlign: 'left' }}>
        Register your result in the match against {opponent.firstName} {opponent.lastName}.
      </InfoMessage>
      <ResultInputs
        guest={guest}
        host={host}
        scores={scores}
        setIsInputError={setIsScoresError}
        setScores={setScores}
      />
      <Divider />
      <RateOpponent value={rating} onChange={setRating} />
      {rating <= 2 && rating > 0 && <PCRDescriptionCheckboxes onCheckboxSelect={onCheckboxSelect} />}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button
          disabled={isLoading || isScoresError || rating <= 0 || scores.length <= 0}
          isNewStyle
          onClick={onRegisterResult}
        >
          {isLoading ? 'Submitting...' : 'Submit Result'}
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default RegisterResultModal;
