import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getCommunityFinishedTournamentsRequest } from '~/api';
import { BackButton, FlexColumnWrapper } from '~/components/shared';
import { getCommunityScreen } from '~/store/communities/utils';

import TournamentsList from './TournamentsList';

const FinishedTournaments = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [finishedTournaments, setFinishedTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { communityId } = useParams();
  const { communityScreen } = useSelector(state => state.communities);

  const communityName = get(communityScreen, ['community', 'communityName'], '');

  useEffect(() => {
    setIsLoading(true);
    dispatch(getCommunityScreen(communityId));
    getCommunityFinishedTournamentsRequest(communityId, { pageSize: 1000 })
      .then(response => {
        const data = response.data.body;

        setFinishedTournaments(data.records);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage('Something went wrong with getting finished tournaments. Please try again.');
      });
  }, [communityId]);

  return (
    <FlexColumnWrapper>
      <BackButton text={`Back to ${communityName}`} onClick={history.goBack} />
      <TournamentsList
        emptyListMessage={`There are no finished tournaments at ${communityName}.`}
        errorMessage={errorMessage}
        isLoading={isLoading}
        tournaments={finishedTournaments}
        widgetTitle='Finished Tournaments'
      />
    </FlexColumnWrapper>
  );
};

export default FinishedTournaments;
