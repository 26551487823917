import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Cell = styled.div`
  align-self: flex-start;
`;

export const Time = styled.span`
  display: block;
  color: ${colors.white};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;
