import React from 'react';

import { Icon, Modal } from '~/ui';

import { CancelButton, ConfirmButton, HeadCancelButton, ModalContainer, Text, Title } from './styles';

const ConfirmModal = ({
  cancel,
  confirm,
  confirmButtonIcon,
  confirmButtonColor,
  confirmButtonText,
  cancelButtonText,
  text,
  title,
}) => {
  return (
    <ModalContainer>
      <Modal>
        <HeadCancelButton onClick={cancel}>
          <Icon color='tmGreen' cursor='pointer' icon='close' /> Cancel
        </HeadCancelButton>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <ConfirmButton confirmButtonColor={confirmButtonColor} onClick={confirm}>
          <Icon color={confirmButtonColor} cursor='pointer' icon={confirmButtonIcon} />
          {confirmButtonText}
        </ConfirmButton>
        <CancelButton onClick={cancel}>{cancelButtonText}</CancelButton>
      </Modal>
    </ModalContainer>
  );
};

export default ConfirmModal;
