import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPastMatches } from '~/api/competition';
import { WidgetItem, WidgetWrapper } from '~/components/shared/index';
import { Icon } from '~/ui/index';

import ListItemsStatusFeedback from '../../shared/ListItemStatusFeedback/index';
import MatchItem from '../../shared/MatchItem/MatchItem';
import { PreviousMatchesContainer, ShowPreviousText } from './styles';

const PreviousMatches = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { tournamentId } = useParams();

  useEffect(() => {
    if (matches.length === 0 && isExpanded && !!tournamentId) {
      setIsLoading(true);
      setErrorMessage('');
      getPastMatches(tournamentId)
        .then(response => {
          setIsLoading(false);
          setMatches(response.data.body.pastMatchStats);
        })
        .catch(() => {
          setIsLoading(false);
          setMatches([]);
          setErrorMessage('Something went wrong. Please try again.');
        });
    }
  }, [isExpanded, matches.length, tournamentId]);

  return (
    <WidgetWrapper>
      <WidgetItem onClick={() => setIsExpanded(!isExpanded)}>
        <ShowPreviousText isExpanded={isExpanded}>{isExpanded ? 'Hide ' : 'Show '} Previous Matches</ShowPreviousText>
        <Icon color='ash3' cursor='pointer' icon='rightArrow2' size='s' />
      </WidgetItem>
      <PreviousMatchesContainer isExpanded={isExpanded}>
        {matches.length > 0 ? (
          matches.map(contract => {
            const { matchContract, opponent } = contract;

            return <MatchItem key={matchContract.id} match={matchContract} opponent={opponent} />;
          })
        ) : (
          <ListItemsStatusFeedback
            emptyListMessage='There are no previous matches.'
            errorMessage={errorMessage}
            isError={!!errorMessage}
            isLoading={isLoading}
          />
        )}
      </PreviousMatchesContainer>
    </WidgetWrapper>
  );
};

export default PreviousMatches;
