import React, { useEffect, useRef, useState } from 'react';

import newsDefaultImg from '~/assets/images/news-default-image.png';
import { Icon } from '~/ui';

import { AddImageText, Container, StyledButton } from './styles';

const UploadImage = ({ initialImage, handleImageUri, deleteImage }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (initialImage) setImage(initialImage);
  }, [initialImage]);

  const hiddenImageFileInput = useRef(null);

  const handleSelectFileWindow = e => {
    e.preventDefault();
    hiddenImageFileInput.current.click();
  };

  const handleFileUpload = async e => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      // get image and create image URI
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        handleImageUri(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResetImage = () => {
    setImage(null);
    handleImageUri(null);
    if (deleteImage) deleteImage();
  };

  return (
    <Container image={image ? image : newsDefaultImg}>
      {image ? (
        <>
          <StyledButton id='communityImage' onClick={handleSelectFileWindow}>
            <Icon cursor='pointer' icon='camera' />
            Edit
          </StyledButton>
          <StyledButton type='reset' onClick={handleResetImage}>
            <Icon cursor='pointer' icon='close' />
            Remove
          </StyledButton>
        </>
      ) : (
        <AddImageText onClick={handleSelectFileWindow}>
          <Icon cursor='pointer' icon='camera' /> Add a featured image
        </AddImageText>
      )}

      <input
        accept='image/*'
        ref={hiddenImageFileInput}
        style={{ display: 'none' }}
        type='file'
        value=''
        onChange={handleFileUpload}
      />
    </Container>
  );
};

export default UploadImage;
