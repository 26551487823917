import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  > div {
    margin-bottom: 40px;

    &:first-child {
      margin-bottom: 24px;
    }
  }
`;

export const NoticeBox = styled.div`
  display: flex;
  padding-top: 16px;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

export const NoticeText = styled.span`
  margin-left: 20px;
`;
