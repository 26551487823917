import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  errorMessage: '',
};

export const tournamentScreenSlice = createSlice({
  name: 'tournamentScreen',
  initialState,
  reducers: {
    setTournamentScreenData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setIsLoadingTournamentScreen: state => {
      state.isLoading = true;
    },
    setTournamentScreenErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.data = {};
      state.isLoading = false;
    },
  },
});

export const { setTournamentScreenData, setIsLoadingTournamentScreen, setTournamentScreenErrorMessage } =
  tournamentScreenSlice.actions;

export default tournamentScreenSlice.reducer;
