import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 100%;
  border: 1px solid ${colors.ash3};
  background-color: ${colors.highlight3};
  box-sizing: border-box;
`;

export const Tab = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const TabLabel = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${({ isSelected }) => (isSelected ? colors.tmGreen : colors.ash3)};
`;

export const Separator = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${colors.ash3};

  &:last-child {
    display: none;
  }
`;
