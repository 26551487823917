import React from 'react';
import { useSelector } from 'react-redux';

import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader/index';
import { DATE_FORMATS } from '~/enums/dates';
import ListItemsStatusFeedback from '~/pages/User/shared/ListItemStatusFeedback/index';
import { formatDateFromUnixTime } from '~/utils/dates';

import { Info, Time } from './styles';

const History = () => {
  const { data } = useSelector(state => state.matchScreen);

  const { fullMatchHistory = [] } = data;

  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgClock' iconColor='blue' title='History' />
      {fullMatchHistory.length > 0 ? (
        fullMatchHistory.map(item => (
          <WidgetItem key={item.timestamp + item.description}>
            <Info>{item.description}</Info>
            <Time>{formatDateFromUnixTime(item.timestamp, DATE_FORMATS.MONTH_DAY_WITH_TIME)}</Time>
          </WidgetItem>
        ))
      ) : (
        <ListItemsStatusFeedback emptyListMessage='There are no history items for this match.' />
      )}
    </WidgetWrapper>
  );
};

export default History;
