import React from 'react';

import { WidgetItem } from '~/components/shared/index';

import { InfoFlexContianer, WaitingForOpponentMessage, WaitingForOpponentStatusIcon } from './styles';

const NoOpponentItem = ({ nextOpponent }) => {
  if (!nextOpponent || !!nextOpponent.opponent) return null;

  const { bracketRoundContract } = nextOpponent;

  const status = bracketRoundContract?.number === 1 ? 'BYE' : 'TBD';

  return (
    <WidgetItem>
      <InfoFlexContianer>
        <WaitingForOpponentStatusIcon>{status}</WaitingForOpponentStatusIcon>
        <WaitingForOpponentMessage>
          {status === 'BYE'
            ? 'You get a pass into the next round! Sit tight until your next opponent is decided.'
            : 'Sit tight! Once your opponent is decided, you will be able to schedule a match.'}
        </WaitingForOpponentMessage>
      </InfoFlexContianer>
    </WidgetItem>
  );
};

export default NoOpponentItem;
