import React from 'react';

import { CommunityDropdownMenuItem } from './styles';

const CommunityMenuItem = ({ onClick, communityName, numberOfMembers }) => {
  return (
    <CommunityDropdownMenuItem onClick={onClick}>
      <span>{communityName}</span>
      <span>{numberOfMembers} members</span>
    </CommunityDropdownMenuItem>
  );
};

export default CommunityMenuItem;
