import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-height: 68px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;

  & h1 {
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    margin: 0;
    color: ${colors.white};
  }
`;
