import React from 'react';
import { useSelector } from 'react-redux';

import UserWithAvatar from '~/components/shared/UserWithAvatar';
import { ErrorMessage, Icon, Spinner } from '~/ui';

import { MembersContainer, MembersContent, MembersHead, MembersLink, MembersNumber } from './styles';

const Members = ({ data = [], error, isLoading }) => {
  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);

  return (
    <MembersContainer error={error}>
      <MembersHead>
        <Icon icon='multipleUsers' />
        MEMBERS
      </MembersHead>
      <MembersContent>
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <MembersNumber>
                  {data.length} {data.length === 1 ? 'Member' : 'Members'}
                </MembersNumber>
                {data.slice(0, 10).map(member => {
                  const { accountId, avatarUrl, firstName, lastName } = member;

                  return <UserWithAvatar avatarUrl={avatarUrl} key={accountId} userName={`${firstName} ${lastName}`} />;
                })}
                <MembersLink to={`/admin/community/${communityId}/members`}>View all members</MembersLink>
              </>
            )}
          </>
        )}
      </MembersContent>
    </MembersContainer>
  );
};

export default Members;
