import capitalize from 'lodash/capitalize';
import React, { useRef } from 'react';

import { Grid, Icon, useOutsideClick } from '~/ui';
import { getDateWithYearFromTimestamp } from '~/utils';

import { CompetitionInfo, StyledColumn, StyledUl } from './styles';

const AdditionalRowInfo = ({ competition, setShowAdditionalInfo }) => {
  const startTime = getDateWithYearFromTimestamp({
    timestamp: competition.startTime,
  });

  const endTime = getDateWithYearFromTimestamp({
    timestamp: competition.endTime,
  });

  const { id, skillLevelFrom, skillLevelTo, ageFrom, ageTo, gender, court } = competition;

  const ref = useRef();
  useOutsideClick(ref, () => setShowAdditionalInfo(false));

  return (
    <CompetitionInfo key={id} ref={ref}>
      <Grid.Container>
        <Grid.Row>
          <StyledColumn colums='2'>
            <Icon color='ash' cursor='pointer' icon='close' onClick={() => setShowAdditionalInfo(false)} />
          </StyledColumn>
          <StyledColumn colums='2'>
            <StyledUl>
              <li>
                <span>Start date</span>
                {startTime}
              </li>
              <li>
                <span>End date</span>
                {endTime}
              </li>
              <li>
                <span>Skill level</span>
                {skillLevelFrom.prettyName.replace('Level ', '')} - {skillLevelTo.prettyName.replace('Level ', '')}
              </li>
              <li>
                <span>Age</span>
                {ageFrom} - {ageTo}
              </li>
              <li>
                <span>Gender</span>
                {gender ? capitalize(gender) : 'Both'}
              </li>
              <li>
                <span>Location</span>
                <a
                  href={`https://maps.google.com/?q=${court.location.lat},${court.location.lon}`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {court.name}
                </a>
              </li>
            </StyledUl>
          </StyledColumn>
        </Grid.Row>
      </Grid.Container>
    </CompetitionInfo>
  );
};

export default AdditionalRowInfo;
