import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MATCH_INVITE_STATUS } from '~/enums/match';
import { ModalWrapper } from '~/pages/shared/styles';
import { Button, Modal } from '~/ui/index';

import CancelOpenMatchModal from '../CancelOpenMatchModal/index';

const PendingOpenMatchButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState();

  const { data } = useSelector(state => state.matchScreen);

  const { match, matchInvite } = data;

  if (!matchInvite || !!match) return null;

  const { matchInviteStatus } = matchInvite;

  if (matchInviteStatus !== MATCH_INVITE_STATUS.PENDING_OPPONENT) return null;

  return (
    <>
      <Button color='danger' isNewStyle onClick={() => setIsModalOpen(true)}>
        Cancel
      </Button>
      {isModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <CancelOpenMatchModal onClose={() => setIsModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default PendingOpenMatchButtons;
