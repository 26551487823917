import React from 'react';

import { Table } from '~/ui';

import { getJoinRequestsColumns, getJoinRequestsData } from './utils';

const JoinRequestsTable = ({ data, error, isLoading, onAccept, onDecline }) => {
  return (
    <Table
      columns={getJoinRequestsColumns({ onAccept, onDecline })}
      data={getJoinRequestsData({ data })}
      error={error}
      isLoading={isLoading}
      itemsName='join requests'
    />
  );
};

export default JoinRequestsTable;
