import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  > .icon rect {
    fill: none;
  }

  > span:not(.icon) {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: ${colors.white};

    @media screen and (max-width: ${mediaQueries.mobile}px) {
      display: none;
    }
  }
`;
