import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MATCH_TYPE } from '~/enums/match';
import { getMatchInviteStatusColor, getMatchStatusColor } from '~/utils/matches';

export const useMatchOpponent = () => {
  const { accountId } = useSelector(state => state.auth);
  const { data } = useSelector(state => state.matchScreen);

  const { host, guest } = data;

  return useMemo(() => (accountId === host.accountId ? guest : host), [accountId, host, guest]);
};

/**
 * @param  {string} status - status of match
 */
export const useMatchStatusColor = status => {
  return useMemo(() => {
    return getMatchStatusColor(status);
  }, [status]);
};

/**
 * @param  {string} status - status of match
 */
export const useMatchInviteStatusColor = status => {
  return useMemo(() => {
    return getMatchInviteStatusColor(status);
  }, [status]);
};

export const useIsMatchParticipant = () => {
  const { accountId } = useSelector(state => state.auth);
  const { data } = useSelector(state => state.matchScreen);
  const { host, guest, matchInvite } = data;

  const hostId = host?.accountId;
  const guestId = guest?.accountId;
  const inviteType = matchInvite?.type;

  if (inviteType === MATCH_TYPE.OPEN && hostId !== accountId) return true;

  return hostId === accountId || guestId === accountId;
};
