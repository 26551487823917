import React from 'react';
import { useLocation } from 'react-router-dom';

import { Icon } from '~/ui';

import CommunityMenu from '../CommunityMenu/index';
import { Nav, NavLinkContainer } from './styles';

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <Nav>
      <NavLinkContainer className={pathname === '/home' ? 'active' : ''} to='/home'>
        <Icon cursor='pointer' icon='home2' />
        <span>Home</span>
      </NavLinkContainer>
      <NavLinkContainer className={pathname.includes('matches-central') ? 'active' : ''} to='/home/matches-central'>
        <Icon cursor='pointer' icon='match' />
        <span>Matches</span>
      </NavLinkContainer>
      <CommunityMenu />
      {/* TODO: Commented out since this is not implemented yet */}
      {/* <NavLinkContainer className={pathname === '/mates' ? 'active' : ''} to='/mates'>
        <Icon cursor='pointer' icon='user2' />
        <span>Mates</span>
      </NavLinkContainer> */}
    </Nav>
  );
};

export default Navigation;
