import styled from 'styled-components';

import theme from '../../theme';
import Button from '../Button';

const { colors, mediaQueries } = theme;

export const RoundsMenuContainer = styled.div`
  width: 700px;
  margin-bottom: 24px;

  .menu-item-wrapper {
    border-right: 5px solid ${colors.highlight1}; // if we set 'margin-right: 5px;' then the last or last two items are not shown in the menu
    outline: 0;

    &:first-child {
      border-left: 5px solid ${colors.highlight1};
    }
  }

  .scroll-menu-arrow.disabledArrow {
    filter: opacity(45%);

    * {
      cursor: not-allowed;

      &:active,
      &:focus,
      &:hover {
        background: ${colors.highlight2};
      }
    }
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 343px;
  }
`;

export const NavigationButton = styled(Button)`
  display: flex;
  align-items: center;
  width: ${({ extraIcon }) => (extraIcon ? '96px' : '50px')};
  height: 40px;
  padding: 12px;
  justify-content: center;

  .icon {
    width: 18px;
    height: 18px;
    margin-left: ${({ extraIcon }) => (extraIcon ? '0' : '5px')};

    &:nth-child(2) {
      margin-right: 0;
      margin-left: ${({ extraIcon }) => (extraIcon ? '12px' : '0')};
    }
  }
`;
