import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const TextEditorContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 256px;
`;

export const TextEditorBox = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.invert};
  padding: 16px;
  background: ${colors.highlight2};
  color: ${colors.whiteOpacity._75};
  line-height: 22px;

  /* this is editor input */
  > div {
    height: 80%;
    margin-top: 16px;

    div.DraftEditor-editorContainer,
    div.public-DraftEditor-content {
      height: 100%;
    }

    h2 {
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    > div,
    p {
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
    }
  }
`;

export const EditorOption = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 16px;
  padding: 2px;
  background: ${colors.highlight3};
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;

  &:last-of-type {
    margin-right: 0px;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;
