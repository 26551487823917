import React from 'react';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';
import { Button } from '~/ui';

import CompetitionItem from '../../shared/CompetitionItem/index';
import ListItemsStatusFeedback from '../../shared/ListItemStatusFeedback/index';
import { useTabs } from '../context/TabContext';

const LeaguesList = ({
  leagues = [],
  widgetTitle = '',
  widgetIcon = '',
  widgetIconColor = '',
  emptyListMessage = '',
  errorMessage = '',
  isLoading = false,
}) => {
  const { selectedTab, setSelectedTab } = useTabs();

  return (
    <WidgetWrapper>
      <WidgetHeader icon={widgetIcon} iconColor={widgetIconColor} title={widgetTitle}></WidgetHeader>
      {leagues.length > 0 ? (
        leagues.map(league => (
          <CompetitionItem
            id={league.id || league.leagueId}
            info={`${league.numberOfRegisteredPlayers} participants`}
            key={league.id || league.leagueId}
            name={league.name}
            type={league.competitionType || 'LEAGUE'}
          />
        ))
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage={emptyListMessage}
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isLoading}
        >
          {selectedTab === 'overview' && widgetTitle === 'My Leagues' && (
            <Button isNewStyle onClick={() => setSelectedTab('leagues')}>
              Discover Leagues
            </Button>
          )}
        </ListItemsStatusFeedback>
      )}
    </WidgetWrapper>
  );
};

export default LeaguesList;
