import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { acceptUserJoinCommunity, getCommunityJoinRequests, rejectUserJoinCommunity } from '~/api';
import Filters from '~/components/Admin/Filters';
import JoinRequestsTable from '~/components/Admin/JoinRequestsTable';
import MembersFilterModal from '~/components/Admin/MembersFilterModal';
import MembersTable from '~/components/Admin/MembersTable';
import SearchAndFilter from '~/components/shared/SearchAndFilter';
import { GENDER } from '~/enums';
import { getCommunityMembers } from '~/store/communities/utils';
import { Button, Grid, Icon, PageHeader, PageTableName } from '~/ui';
import {
  filterMembersByAgeRange,
  filterMembersByGender,
  filterMembersByName,
  filterMembersByPcrRange,
  filterMembersBySkillLevelRange,
} from '~/utils';

const defaultFilterValues = {
  gender: GENDER.BOTH,
  pcrRange: { min: 1, max: 5 },
  ageRange: { min: 16, max: 72 },
  skillLevelRange: { min: 1.0, max: 7.0 },
};

const MembersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { members, membersError } = useSelector(state => state.communities.selectedCommunity);
  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);

  const [loadingMembers, setLoadingMembers] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(defaultFilterValues);
  const [joinRequests, setJoinRequests] = useState([]);
  const [joinRequestsError, setJoinRequestsError] = useState('');

  useEffect(() => {
    loadMembers();
    handleClearFilters();
    setSearchValue('');
    history.push(`/admin/community/${communityId}/members`);
  }, [dispatch, communityId, joinRequestsError]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMembers = async () => {
    if (communityId && !joinRequestsError) {
      setLoadingMembers(true);
      await dispatch(getCommunityMembers(communityId));

      getCommunityJoinRequests(communityId)
        .then(res => setJoinRequests(res))
        .catch(() => setJoinRequestsError("Can't load join requests."))
        .finally(() => setLoadingMembers(false));
    }
  };

  const handleApplyFilters = ({ gender, pcrRange, ageRange, skillLevelRange }) => {
    setFilters({
      gender,
      pcrRange,
      ageRange,
      skillLevelRange,
    });
    setShowModal(false);
  };

  const handleClearFilters = () => {
    setFilters(defaultFilterValues);
    setShowModal(false);
  };

  const filterMembers = data => {
    let filteredData = data;
    const { gender, pcrRange, ageRange, skillLevelRange } = filters;

    filteredData = filterMembersByName({
      name: searchValue,
      data: filteredData,
    });

    filteredData = filterMembersByGender({
      gender,
      data: filteredData,
    });

    filteredData = filterMembersByPcrRange({
      pcrRange,
      data: filteredData,
    });

    filteredData = filterMembersByAgeRange({
      ageRange,
      data: filteredData,
    });

    filteredData = filterMembersBySkillLevelRange({
      skillLevelRange,
      data: filteredData,
    });

    return filteredData;
  };

  const filteredMembers = filterMembers(members);
  const isFilterActive = !isEqual(defaultFilterValues, filters);

  return (
    <>
      {/* TODO: commenting out this since API does not support inviting members */}
      <PageHeader
        // buttonIcon='userPlus'
        // buttonText='Invite Members'
        // link={`/admin/community/${communityId}/members/invite-members`}
        title='Members'
      />
      <PageTableName name='Join requests' number={loadingMembers ? '' : joinRequests && joinRequests.length} />
      <JoinRequestsTable
        data={joinRequests}
        error={joinRequestsError}
        isLoading={loadingMembers}
        onAccept={member => {
          setLoadingMembers(true);
          acceptUserJoinCommunity(member.accountId, communityId)
            .then(() => loadMembers())
            .catch(() => setJoinRequestsError('Something went wrong. Refresh the page and try again.'))
            .finally(() => setLoadingMembers(false));
        }}
        onDecline={member => {
          setLoadingMembers(true);
          rejectUserJoinCommunity(member.accountId, communityId)
            .then(() => loadMembers())
            .catch(() => setJoinRequestsError('Something went wrong. Refresh the page and try again.'))
            .finally(() => setLoadingMembers(false));
        }}
      />
      <Grid.Container>
        <Grid.Row>
          <Grid.Column columns={2}>
            <PageTableName name='Community members' number={loadingMembers ? '' : members && members.length} />
          </Grid.Column>
          <Grid.Column columns={2}>
            <SearchAndFilter
              filterActive={isFilterActive}
              filterDisabled={membersError}
              filterOnClick={() => setShowModal(true)}
              searchOnChange={e => setSearchValue(e.target.value)}
              searchValue={searchValue}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
      <Filters default={defaultFilterValues} filters={filters} onClick={value => setFilters(value)} />
      <MembersTable data={filteredMembers} error={membersError} isLoading={loadingMembers} />
      {!filteredMembers.length && isFilterActive && (
        <Button onClick={handleClearFilters}>
          <Icon color='tmGreen' cursor='pointer' icon='trash' />
          Clear Filters
        </Button>
      )}
      {showModal && (
        <MembersFilterModal
          defaultFilterValues={defaultFilterValues}
          filters={filters}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          title='Filter Members'
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default MembersPage;
