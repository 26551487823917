import React from 'react';

import { Icon } from '~/ui';

import { StyledFilterOption } from './styles';

const FilterOption = ({ value, onClick }) => {
  return (
    <StyledFilterOption>
      {value}
      <Icon cursor='pointer' icon='close' onClick={onClick} />
    </StyledFilterOption>
  );
};

export default FilterOption;
