import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  ${({ isAdmin }) => isAdmin} {
    > div {
      width: 100%;
      margin-left: 0;

      > * {
        padding: 0 32px;
      }
    }
  }
`;

export const InfoContainer = styled.div`
  position: relative;
  top: -50px;
`;

export const Title = styled.h1`
  color: ${colors.white};
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
`;

export const Notice = styled.p`
  max-width: 530px;
  color: ${colors.white};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`;
