import { getMatchesCentralScreenRequest } from '~/api';

import {
  setIsLoadingMatchesCentralData,
  setMatchesCentralData,
  setMatchesCentralErrorMessage,
} from './matchesCentral.slice';

export const getMatchesCentralData = () => async (dispatch, getState) => {
  try {
    const { accountId } = getState().auth;

    dispatch(setIsLoadingMatchesCentralData());

    const response = await getMatchesCentralScreenRequest(accountId);
    const matchScreenData = response.data.body;

    dispatch(setMatchesCentralData(matchScreenData));
  } catch (error) {
    dispatch(setMatchesCentralErrorMessage('Something went wrong with getting matches screen data. Please try again.'));
  }
};
