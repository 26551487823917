import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, zIndex } = theme;

export const ButtonWithTooltipContainer = styled.div`
  position: relative;

  &:hover {
    & > div {
      display: block;
      transition: opacity 0.25s ease;
      opacity: 1;
    }
  }
`;

export const TooltipContainer = styled.div`
  width: max-content;
  max-width: 250px;
  border-radius: 12px;
  background-color: ${colors.invert};
  padding: 10px;
  position: absolute;
  left: 25%;
  top: 150%;
  z-index: ${zIndex.level4};
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease;
`;

export const TooltipText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: ${colors.white};
`;
