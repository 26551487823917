import styled from 'styled-components';

import theme from '../../theme';

const { colors, mediaQueries } = theme;

export const StyledFilterOption = styled.span`
  display: inline-flex;
  width: fit-content;
  margin-bottom: 12px;
  padding: 8px 16px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;

  &:not(:last-of-type) {
    margin-right: 24px;
  }

  .icon {
    margin-left: 20px;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin: 16px 8px 0 0 !important;
  }
`;
