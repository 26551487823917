import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { editLeagueRequest, editTournamentRequest } from '~/api';
import CompetitionForm from '~/components/Admin/CompetitionForm';
import BackButton from '~/components/shared/BackButton';
import { COMPETITION_TYPE, TIME } from '~/enums';
import { getLeagueDetails } from '~/store/leagues/utils';
import { getTournamentDetails } from '~/store/tournaments/utils';
import { PageTitle, Spinner } from '~/ui';
import { isDateInThePast } from '~/utils';

const EditSingleCompetitionPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { leagueId, tournamentId } = useParams();

  const competitionType = location.pathname.split('/').pop().replace('edit-', '');

  const league = useSelector(state => state.leagues.selectedLeague);
  const tournament = useSelector(state => state.tournaments.selectedTournament);
  const competition = competitionType === COMPETITION_TYPE.LEAGUE ? league : tournament;

  const [loadingCompetition, setLoadingCompetition] = useState(false);
  const [competitionError, setCompetitionError] = useState('');

  useEffect(() => {
    const loadCompetition = async () => {
      setLoadingCompetition(true);

      if (competitionType === COMPETITION_TYPE.LEAGUE) {
        await dispatch(getLeagueDetails(leagueId));
      } else {
        await dispatch(getTournamentDetails(tournamentId));
      }

      setLoadingCompetition(false);
    };

    if (competition.id === 0) loadCompetition();
  }, [dispatch, leagueId, tournamentId, competition.id, competitionType]);

  const handleEditCompetitionSubmit = async data => {
    try {
      if (competitionType === COMPETITION_TYPE.LEAGUE) {
        await editLeagueRequest(data);
      } else {
        await editTournamentRequest(data);
      }

      history.push(`/admin/community/${competition.communityId}/${competitionType}/${competition.id}`);
    } catch (error) {
      if (error) {
        if (error.data && error.data.errors) {
          error.data.errors.map(({ code }) => {
            if (code === TIME.TIME_HAS_PASSED) {
              setCompetitionError('Registrations started. Editing is not allowed.');
            }

            return null;
          });
        } else {
          setCompetitionError('Something went wrong. Try again.');
        }
      } else {
        setCompetitionError('Something went wrong. Try again.');
      }
    }
  };

  const registrationStarted = isDateInThePast(competition.registerStartTime);

  return (
    <div style={{ maxWidth: '366px' }}>
      {loadingCompetition ? (
        <Spinner />
      ) : (
        <>
          <BackButton onClick={() => history.goBack()} />
          <PageTitle title={`Edit ${competitionType}`} />
          <CompetitionForm
            competitionError={competitionError}
            competitionType={competitionType}
            formSubmit={handleEditCompetitionSubmit}
            partialEdit={registrationStarted}
          />
        </>
      )}
    </div>
  );
};

export default EditSingleCompetitionPage;
