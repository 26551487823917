import React from 'react';

import NotificationDot from '../Dot';
import { Badge } from './styles';

const NotificationBadge = ({ dotColor, text }) => {
  return (
    <Badge>
      <NotificationDot color={dotColor} />
      {text}
    </Badge>
  );
};

export default NotificationBadge;
