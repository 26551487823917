import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSelector } from 'react-redux';

import Avatar from '~/components/shared/Avatar';
import { MATCH_STATUS } from '~/enums/match';

import { Container, Info, Name, WinnerOrLoser } from './styles';

const Participants = () => {
  const { data } = useSelector(state => state.matchScreen);

  const { host, guest, match, result } = data;

  if (isEmpty(host) || isEmpty(guest)) {
    return null;
  }

  const matchStatus = match?.matchStatus;

  const setsWonByGuest = get(result, ['matchResultContract', 'setsWonByGuest'], 0);
  const setsWonByHost = get(result, ['matchResultContract', 'setsWonByHost'], 0);

  return (
    <Container>
      <Info>
        <Avatar alt={host.firstName} size='xl' src={host.avatarUrl} />
        <div>
          <Name>
            {host.firstName} {host.lastName}
          </Name>
          {matchStatus === MATCH_STATUS.CONFIRMED && (
            <WinnerOrLoser color='tmGreen'>{setsWonByHost > setsWonByGuest && 'Winner'}</WinnerOrLoser>
          )}
        </div>
      </Info>
      <span>VS</span>
      <Info>
        <div>
          <Name style={{ textAlign: 'right' }}>
            {guest.firstName} {guest.lastName}
          </Name>
          {matchStatus === MATCH_STATUS.CONFIRMED && (
            <WinnerOrLoser color='tmGreen' style={{ textAlign: 'right' }}>
              {setsWonByGuest > setsWonByHost && 'Winner'}
            </WinnerOrLoser>
          )}
        </div>
        <Avatar alt={guest.firstName} size='xl' src={guest.avatarUrl} />
      </Info>
    </Container>
  );
};

export default Participants;
