import React from 'react';

import backgroundImage from '~/assets/images/login-signup-background.png';
import logo from '~/assets/images/tennismate-logo.png';

import { Container, FormContainer, FormHeader, ImageContainer } from './styles';

const InitialAppFormContainer = ({ children }) => {
  return (
    <Container>
      <FormContainer>
        <FormHeader>
          <img alt='TennisMate logo' src={logo} />
        </FormHeader>
        {children}
      </FormContainer>
      <ImageContainer alt='Background Image' src={backgroundImage} />
    </Container>
  );
};

export default InitialAppFormContainer;
