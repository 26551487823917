import {
  getCommunityLeaguesForUserRequest,
  getCommunityLeaguesRequest,
  getLeagueDetailsRequest,
  getLeagueMembersRequest,
  getUserProfileRequest,
} from '~/api';
import { renameCommunityLeaguesScreenProperties } from '~/utils';

import {
  communityLeaguesScreen,
  communityLeaguesScreenError,
  communityLeaguesScreenLoading,
  leaguesError,
  leaguesList,
  selectedLeague,
  selectedLeagueError,
  selectedLeagueMembers,
  selectedLeagueMembersError,
} from './slice';

export const getCommunityLeagues = communityId => async dispatch => {
  try {
    const response = await getCommunityLeaguesRequest(communityId);
    const leaguesListData = response.data.body;

    dispatch(leaguesList(leaguesListData));
  } catch (error) {
    dispatch(leaguesError("Can't load community leagues."));
  }
};

export const getLeagueDetails = leagueId => async dispatch => {
  try {
    const response = await getLeagueDetailsRequest(leagueId);
    const leagueData = response.data.body;

    dispatch(selectedLeague(leagueData));
  } catch (error) {
    dispatch(selectedLeagueError("Can't load league data."));
  }
};

export const getSelectedLeagueMembers = leagueId => async dispatch => {
  try {
    const response = await getLeagueMembersRequest(leagueId);
    const leagueMembersData = response.data.body;

    const leagueMembersProfiles = await Promise.all(
      leagueMembersData.map(async user => {
        const response = await getUserProfileRequest(user.accountId);
        const userProfileData = response.data.body;

        return {
          leagueStatus: user.status,
          leagueGroupId: user.groupId,
          ...userProfileData,
        };
      })
    );

    dispatch(selectedLeagueMembers(leagueMembersProfiles));
  } catch (error) {
    dispatch(selectedLeagueMembersError("Can't load league members."));
  }
};

export const getCommunityLeaguesScreen = (communityId, accountId) => async dispatch => {
  try {
    dispatch(communityLeaguesScreenLoading());

    const response = await getCommunityLeaguesForUserRequest(communityId, accountId);
    const data = renameCommunityLeaguesScreenProperties(response.data.body);

    dispatch(communityLeaguesScreen(data));
  } catch (error) {
    dispatch(communityLeaguesScreenError('Something went wrong with loading community leagues. Please try again.'));
  }
};
