import queryString from 'query-string';

import { TennisMateApi } from '~/services';

export const getCommunityTournamentsRequest = communityId => {
  return TennisMateApi.get(`/tournaments/community/${communityId}`);
};

export const createCommunityTournamentRequest = data => {
  return TennisMateApi.post('/tournaments', data);
};

export const getTournamentDetailsRequest = tournamentId => {
  return TennisMateApi.get(`/tournament-screen/${tournamentId}`);
};

export const editTournamentRequest = data => {
  return TennisMateApi.put('/tournaments', data);
};

export const getTournamentMembersRequest = tournamentId => {
  return TennisMateApi.get(`/tournaments/${tournamentId}/members`);
};

export const getTournamentTree = async tournamentId => {
  return await TennisMateApi.get(`/tournament/${tournamentId}/tournament-tree`);
};

export const createTournamentBracket = async tournamentId => {
  return await TennisMateApi.post(`/tournaments/${tournamentId}/bracket`, { bracketType: 'WINNERS' });
};

export const deleteTournamentBracket = async competitionId => {
  return await TennisMateApi.delete(`/tournaments/${competitionId}/bracket`);
};

export const getMyTournamentsScreen = async (communityId, accountId) => {
  return await TennisMateApi.get(`/mobile/v1/community/${communityId}/user/${accountId}/tournaments`);
};

export const getCommunityTournamentsForUserRequest = async (communityId, accountId) => {
  return await TennisMateApi.get(`/mobile/v1/community/${communityId}/user/${accountId}/tournaments`);
};

export const getCommunityFinishedTournamentsRequest = async (communityId, params) => {
  const query = queryString.stringify(params);

  return await TennisMateApi.get(`/tournaments/communities/${communityId}/finished-tournaments-screen?${query}`);
};

export const getTournamentScreenRequest = async tournamentId => {
  return TennisMateApi.get(`/tournament-screen/${tournamentId}`);
};
