import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { FilterOption } from '~/ui';
import { formatDate } from '~/utils';

const Filters = ({ default: defaultValues, filters, onClick }) => {
  return (
    <>
      {filters.gender !== defaultValues.gender && (
        <FilterOption
          value={capitalize(filters.gender)}
          onClick={() => onClick({ ...filters, gender: defaultValues.gender })}
        />
      )}
      {!isEqual(filters.pcrRange, defaultValues.pcrRange) && (
        <FilterOption
          value={`PCR: ${filters.pcrRange.min} - ${filters.pcrRange.max}`}
          onClick={() => onClick({ ...filters, pcrRange: defaultValues.pcrRange })}
        />
      )}
      {!isEqual(filters.ageRange, defaultValues.ageRange) && (
        <FilterOption
          value={`Age: ${filters.ageRange.min} - ${filters.ageRange.max}`}
          onClick={() => onClick({ ...filters, ageRange: defaultValues.ageRange })}
        />
      )}
      {!isEqual(filters.skillLevelRange, defaultValues.skillLevelRange) && (
        <FilterOption
          value={`Skill level: ${filters.skillLevelRange.min} - ${filters.skillLevelRange.max}`}
          onClick={() => onClick({ ...filters, skillLevelRange: defaultValues.skillLevelRange })}
        />
      )}
      {filters.players &&
        filters.players.map(player => (
          <FilterOption
            key={player.accountId}
            value={`${player.firstName} ${player.lastName}`}
            onClick={() =>
              onClick({
                ...filters,
                players: filters.players.filter(currentPlayer => currentPlayer.accountId !== player.accountId),
              })
            }
          />
        ))}
      {filters.dateFrom && (
        <FilterOption
          value={`From: ${formatDate(filters.dateFrom)}`}
          onClick={() => onClick({ ...filters, dateFrom: defaultValues.dateFrom })}
        />
      )}
      {filters.dateTo && (
        <FilterOption
          value={`To: ${formatDate(filters.dateTo)}`}
          onClick={() => onClick({ ...filters, dateTo: defaultValues.dateTo })}
        />
      )}
      {filters.matchStatus && filters.matchStatus.value !== defaultValues.matchStatus.value && (
        <FilterOption
          value={`Status: ${capitalize(filters.matchStatus.value)}`}
          onClick={() =>
            onClick({
              ...filters,
              matchStatus: defaultValues.matchStatus,
            })
          }
        />
      )}
      {filters.competition && filters.competition.id !== defaultValues.competition.id && (
        <FilterOption
          value={`${filters.competition.name}`}
          onClick={() => onClick({ ...filters, competition: defaultValues.competition })}
        />
      )}
      {filters.selectedCompetition !== 0 && filters.selectedCompetitionId && filters.selectedCompetitionId !== 0 && (
        <FilterOption
          value={filters.selectedCompetition.name}
          onClick={() => onClick({ ...filters, selectedCompetitionId: defaultValues.selectedCompetitionId })}
        />
      )}
    </>
  );
};

export default Filters;
