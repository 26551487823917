import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon, useOutsideClick } from '~/ui';

import Menu from '../Menu';
import { Avatar, Name, UserContainer } from './styles';

const User = () => {
  const { firstName, lastName, avatarUrl } = useSelector(state => state.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userName = firstName && lastName ? `${firstName} ${lastName}` : '';

  const ref = useRef();
  useOutsideClick(ref, () => setIsMenuOpen(false));

  return (
    <UserContainer ref={ref} onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <Avatar alt={`${firstName}'s avatar`} src={`${avatarUrl}`} />
      <Name>{userName}</Name>
      <Icon cursor='pointer' icon={isMenuOpen ? 'caretUp' : 'caretDown'} />
      {isMenuOpen && <Menu />}
    </UserContainer>
  );
};

export default User;
