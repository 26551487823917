import React from 'react';

import CompleteProfileForm from '~/components/CompleteProfileForm';
import InitialAppFormContainer from '~/components/InitialAppFormContainer';

import { Container, Notice, Title } from './styles';

const CompleteProfilePage = () => {
  return (
    <InitialAppFormContainer>
      <Container>
        <Title>Complete Your Profile</Title>
        <Notice>
          Thank you for becoming a member of TennisMate. In order to use the platform, we ask you to fill out the
          following information.
        </Notice>
      </Container>
      <CompleteProfileForm />
    </InitialAppFormContainer>
  );
};

export default CompleteProfilePage;
