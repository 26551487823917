import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const TableName = styled.h3`
  color: ${colors.white};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;
