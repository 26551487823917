import get from 'lodash/get';
import set from 'lodash/set';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Avatar from '~/components/shared/Avatar/index';
import { checkMatchSetScores } from '~/utils/matches';

import { Container, Input, InputsContainer, Label, LabelsContainer, PlayerInfo, PlayerName } from './styles';

const ResultInputs = ({
  host,
  guest,
  disabled = false,
  setScores = () => {},
  label = '',
  setIsInputError = () => {},
  scores = [],
  numberOfSets = 5,
}) => {
  const hostInputRefs = useRef([]);
  const guestInputRefs = useRef([]);

  const { accountId } = useSelector(state => state.auth);

  const handleHostScoreChange = (e, i) => {
    if (disabled) return;

    const {
      target: { value },
    } = e;

    const newScores = scores;

    if (value.length >= 1) {
      if (guestInputRefs.current) {
        for (let i = 0; i < numberOfSets; i++) {
          const nextInput = guestInputRefs.current[i];
          if (!nextInput.value) {
            nextInput.focus();
            break;
          }
        }
      }

      set(newScores, [i, 'hostScore'], +value.charAt(value.length - 1));
      setScores([...newScores]);
    } else {
      const scoresCopy = [...newScores];
      delete scoresCopy[i].hostScore;

      if (!Number.isInteger(scoresCopy[i].guestScore)) {
        scoresCopy.splice(i, 1);
      }

      setScores([...scoresCopy]);
    }
  };

  const handleGuestScoreChange = (e, i) => {
    if (disabled) return;

    const {
      target: { value },
    } = e;

    const newScores = scores;

    if (value.length >= 1) {
      if (hostInputRefs.current) {
        for (let i = 0; i < numberOfSets; i++) {
          const nextInput = hostInputRefs.current[i];
          if (!nextInput.value) {
            nextInput.focus();
            break;
          }
        }
      }

      set(newScores, [i, 'guestScore'], +value.charAt(value.length - 1));
      setScores([...newScores]);
    } else {
      const scoresCopy = [...newScores];
      delete scoresCopy[i].guestScore;

      if (!Number.isInteger(scoresCopy[i].hostScore)) {
        scoresCopy.splice(i, 1);
      }

      setScores([...scoresCopy]);
    }
  };

  const onFocus = e => {
    if (disabled) return;

    e.target.select();
  };

  const checkIsError = useCallback(
    i => {
      if (disabled) return false;

      return checkMatchSetScores(scores, i);
    },
    [scores, disabled]
  );

  useEffect(() => {
    if (disabled) return;

    setIsInputError(
      !scores.every(
        (sc, i) => Number.isInteger(sc.hostScore) && Number.isInteger(sc.guestScore) && !checkMatchSetScores(scores, i)
      )
    );
  }, [scores, setIsInputError, numberOfSets, disabled]);

  return (
    <Container>
      <PlayerInfo style={{ gridArea: 'host' }}>
        <Avatar alt={host.firstName} size='s' src={host.avatarUrl} />
        <PlayerName>
          {host.firstName} {host.lastName} {host.accountId === accountId && '(You)'}
        </PlayerName>
      </PlayerInfo>
      <Label style={{ gridArea: 'info', width: 'auto', justifySelf: 'start' }}>{label}</Label>
      <LabelsContainer>
        {Array.from({ length: numberOfSets }).map((_, i) => (
          <Label key={`set-label-${i}`}>Set {i + 1}</Label>
        ))}
      </LabelsContainer>
      <InputsContainer style={{ gridArea: 'hostScores' }}>
        {Array.from({ length: numberOfSets }).map((_, i) => {
          const hostScore = +get(scores, [i, 'hostScore']);
          const guestScore = +get(scores, [i, 'guestScore']);

          return (
            <Input
              disabled={disabled}
              isError={checkIsError(i)}
              isWin={guestScore < hostScore}
              key={`host-input-${i}`}
              max={7}
              maxLength={1}
              min={0}
              name={`hostScore${i + 1}`}
              ref={r => (hostInputRefs.current[i] = r)}
              type='number'
              value={hostScore >= 0 ? hostScore : ''}
              onChange={e => handleHostScoreChange(e, i)}
              onFocus={onFocus}
            />
          );
        })}
      </InputsContainer>
      <PlayerInfo style={{ gridArea: 'guest' }}>
        <Avatar alt={guest.firstName} size='s' src={guest.avatarUrl} />
        <PlayerName>
          {guest.firstName} {guest.lastName} {guest.accountId === accountId && '(You)'}
        </PlayerName>
      </PlayerInfo>
      <InputsContainer numberOfColumns={numberOfSets}>
        {Array.from({ length: numberOfSets }).map((_, i) => {
          const hostScore = +get(scores, [i, 'hostScore']);
          const guestScore = +get(scores, [i, 'guestScore']);

          return (
            <Input
              disabled={disabled}
              isError={checkIsError(i)}
              isWin={guestScore > hostScore}
              key={`guest-input-${i}`}
              max={7}
              maxLength={1}
              min={0}
              name={`guestScore${i + 1}`}
              ref={r => (guestInputRefs.current[i] = r)}
              type='number'
              value={guestScore >= 0 ? guestScore : ''}
              onChange={e => handleGuestScoreChange(e, i)}
              onFocus={onFocus}
            />
          );
        })}
      </InputsContainer>
    </Container>
  );
};

export default ResultInputs;
