import jwt from 'jsonwebtoken';

import { forgotPasswordRequest, getUserStatusRequest, loginRequest, signupRequest } from '~/api';
import { ANALYTICS_EVENTS, MEMBER_ERROR_MESSAGE } from '~/enums';
import { history } from '~/index';
import { TokensService } from '~/services';
import { trackAnalyticsEvent } from '~/utils';

import { communitiesInitialState } from '../communities/slice';
import { isLoading, isLoadingInitialState } from '../general/slice';
import { leaguesInitialState } from '../leagues/slice';
import { newsInitialState } from '../news/slice';
import { tournamentsInitialState } from '../tournaments/slice';
import { userInitialState } from '../user/slice';
import { getUserProfile } from '../user/utils';
import {
  userForgotPassword,
  userForgotPasswordError,
  userIsProfileCompleted,
  userLogin,
  userLoginError,
  userLogout,
  userSignupError,
} from './slice';

export const login = data => async dispatch => {
  try {
    dispatch(isLoading(true));
    const response = await loginRequest(data);
    const tokens = response.data.body;

    const user = jwt.decode(tokens.accessToken);
    const { accountId } = user.authenticatedUser;

    const userStatusResponse = await getUserStatusRequest(accountId);
    const userStatus = userStatusResponse.data.body;

    trackAnalyticsEvent(ANALYTICS_EVENTS.USER_LOGGED_IN);

    if (userStatus.profileCreated) {
      dispatch(userLogin(tokens));
      TokensService.setTokens(tokens);

      localStorage.setItem('profileCompleted', true);
      dispatch(userIsProfileCompleted(true));

      dispatch(getUserProfile(accountId));

      history.push('/home');
    } else {
      dispatch(userLogin(tokens));
      TokensService.setTokens(tokens);

      dispatch(userIsProfileCompleted(false));

      history.push('/complete-profile');
    }

    isLoading(isLoading(false));
  } catch (error) {
    if (error) {
      if (error.status === 401) {
        const payload = {
          message: 'Your username and password do not match.',
        };

        dispatch(userLoginError(payload));
      } else {
        dispatch(userLoginError(error));
      }
    } else {
      const payload = {
        message: 'Something went wrong. Please try again.',
      };

      dispatch(userLoginError(payload));
    }

    history.push('/login');
  }
};

const setStoreToInitialValues = () => async dispatch => {
  dispatch(userInitialState());
  dispatch(communitiesInitialState());
  dispatch(isLoadingInitialState());
  dispatch(leaguesInitialState());
  dispatch(tournamentsInitialState());
  dispatch(newsInitialState());
};

export const logout = () => async dispatch => {
  TokensService.clearTokens();
  TokensService.clearTokensExpirationTime();
  localStorage.removeItem('profileCompleted');
  dispatch(userLogout());
  dispatch(setStoreToInitialValues());
  history.push('/');
};

export const signup = data => async dispatch => {
  try {
    dispatch(isLoading(true));
    const response = await signupRequest(data);
    const tokens = response.data.body;

    dispatch(userLogin(tokens));
    dispatch(userIsProfileCompleted(false));

    TokensService.setTokens(tokens);

    trackAnalyticsEvent(ANALYTICS_EVENTS.NEW_ACCOUNT_CREATED);

    history.push('/complete-profile');
    dispatch(isLoading(false));
  } catch (error) {
    if (error) {
      if (error.status === 500) {
        dispatch(
          userSignupError({
            message: error.data.error,
          })
        );
      } else if (error.status === 502) {
        dispatch(
          userSignupError({
            message: 'Something went wrong. Please try again.',
          })
        );
      } else {
        const errorMessage = error.data.errors[0].code;

        if (errorMessage === MEMBER_ERROR_MESSAGE.USER_ALREADY_EXISTS) {
          dispatch(
            userSignupError({
              message: 'User with this email address already exists. Please use another email address.',
            })
          );
        }
      }
    } else {
      const payload = {
        message: 'Something went wrong. Please try again.',
      };

      dispatch(userSignupError(payload));
    }
  }
};

export const forgotPassword = email => async dispatch => {
  try {
    const response = await forgotPasswordRequest(email);

    const payload = {
      status: response.status,
      message: {
        success: 'Password reset successfully sent!',
        notice: 'We have sent you a link with instructions on how to reset your password.',
      },
    };

    dispatch(userForgotPassword(payload));
  } catch (error) {
    const payload = {
      message: {
        error: 'Something went wrong. Try again.',
      },
    };

    dispatch(userForgotPasswordError(payload));
  }
};
