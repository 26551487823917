import { fromUnixTime } from 'date-fns';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { submitMatchInvite } from '~/api/matches';
import { ErrorMessage } from '~/components/shared/styles';
import { ANALYTICS_EVENTS, DATE_FORMATS, MATCH_TYPE } from '~/enums';
import { resetForm, updateFormField } from '~/store/matches/newMatchForm.slice';
import { Button, DatePicker, Icon, Input, IntervalTimePicker, Modal, Textarea } from '~/ui';
import { checkIfDateDaysAreEqual, trackAnalyticsEvent } from '~/utils';

import { FormContainer } from '../../shared/styles';
import PickCourt from '../PickCourt/index';
import PickOpponent from '../PickOpponent/index';
import { ModalWrapper, TournamentMatchInfo } from '../styles';

const DirectMatchForm = () => {
  const [isPickOpponentModalOpen, setIsPickOpponentModalOpen] = useState(false);
  const [isPickCourtModalOpen, setIsPickCourtModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const { opponent, date, time, court, message, communityId, competitionId, groupId, competitionName } = useSelector(
    state => state.newMatchForm
  );
  const { accountId } = useSelector(state => state.auth);

  const { data } = useSelector(state => state.tournamentScreen);

  const tournamentEnd = get(data, ['tournamentResponse', 'endTime'], null);
  const tournamentEndDate = tournamentEnd && competitionId ? new Date(fromUnixTime(tournamentEnd)) : null;
  const tournamentEndHours = tournamentEndDate && competitionId ? tournamentEndDate.getHours() : null;
  const tournamentEndMinutes = tournamentEndDate && competitionId ? tournamentEndDate.getMinutes() : null;

  useEffect(() => {
    return () => dispatch(resetForm());
  }, [dispatch]);

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      challengerId: accountId,
      type: MATCH_TYPE.DIRECT,
      opponentId: opponent.id,
      court: court,
      message: message,
      time: new Date(`${date} ${time}`).getTime() / 1000,
      communityId,
      skillLevelFrom: null,
      skillLevelTo: null,
      opponentAgeFrom: null,
      opponentAgeTo: null,
      competitionId,
      groupId,
      opponentGender: null,
    };

    submitMatchInvite(formData)
      .then(() => {
        trackAnalyticsEvent(ANALYTICS_EVENTS.NEW_DIRECT_MATCH_INVITE);
        history.push('/home');
      })
      .catch(() => {
        setIsSubmitting(false);
        setErrorMessage('Something went wrong, please try again.');
      });
  };

  const onOpponentPick = opponent => {
    const {
      userProfileCardView: { accountId, firstName, lastName },
    } = opponent;

    dispatch(
      updateFormField({
        opponent: {
          id: accountId,
          fullName: `${firstName} ${lastName}`,
        },
      })
    );
    setIsPickOpponentModalOpen(false);
  };

  const onCourtPick = court => {
    dispatch(
      updateFormField({
        court,
      })
    );
    setIsPickCourtModalOpen(false);
  };

  const checkMaxHoursLimit = () => {
    if (competitionId && tournamentEndDate) {
      if (checkIfDateDaysAreEqual(new Date(date), tournamentEndDate)) {
        return tournamentEndHours;
      }
    }

    return null;
  };

  const checkMaxMinutesLimit = () => {
    if (competitionId && tournamentEndDate) {
      if (checkIfDateDaysAreEqual(new Date(date), tournamentEndDate)) {
        return tournamentEndMinutes;
      }
    }

    return null;
  };

  return (
    <FormContainer onFocus={() => setErrorMessage('')} onSubmit={onSubmit}>
      {competitionName && (
        <TournamentMatchInfo>
          <Icon color='tmGreen' icon='trophy2' />
          <p>{competitionName}</p>
        </TournamentMatchInfo>
      )}
      <Input
        icon='user2'
        iconColor='ash2'
        isNewStyle
        placeholder='Pick your opponent'
        value={opponent.fullName || ''}
        onChange={() => undefined}
        onFocus={() => {
          if (competitionId) {
            return;
          }
          setIsPickOpponentModalOpen(true);
        }}
      />
      <DatePicker
        dateFormat={DATE_FORMATS.WITH_MONTH_NAME}
        icon='calendar'
        iconColor='ash2'
        isNewStyle
        maxDate={tournamentEndDate}
        minDate={new Date()}
        placeholder='Pick a date'
        returnValueDateFormat='yyyy/MM/dd'
        value={date || ''}
        onChange={date => dispatch(updateFormField({ date }))}
      />
      <IntervalTimePicker
        icon='clock'
        iconColor='ash2'
        maxHours={checkMaxHoursLimit()}
        maxMinutes={checkMaxMinutesLimit()}
        placeholder='Pick the time'
        value={time || ''}
        onChange={time => dispatch(updateFormField({ time }))}
      />
      <Input
        icon='location2'
        iconColor='ash2'
        isNewStyle
        placeholder='Where do you want to play?'
        value={court.name || ''}
        onChange={() => undefined}
        onFocus={() => {
          if (competitionId) {
            return;
          }
          setIsPickCourtModalOpen(true);
        }}
      />

      <Textarea
        icon='envelope'
        isNewStyle
        placeholder='If you want, you can write a short message to the opponent.'
        rows={6}
        value={message || ''}
        onChange={e => dispatch(updateFormField({ message: e.target.value }))}
      />
      {isPickOpponentModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <PickOpponent onClose={() => setIsPickOpponentModalOpen(false)} onSelect={onOpponentPick} />
        </Modal>
      )}
      {isPickCourtModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <PickCourt onClose={() => setIsPickCourtModalOpen(false)} onSelect={onCourtPick} />
        </Modal>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Button disabled={!opponent || !date || !time || !court.name || isSubmitting} fullWidth isNewStyle type='submit'>
        {isSubmitting ? 'Submitting...' : 'Submit Direct Challenge'}
      </Button>
    </FormContainer>
  );
};

export default DirectMatchForm;
