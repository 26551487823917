import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  display: inline-block;
  width: 25%;
  text-align: center;
`;

export const Number = styled.span`
  display: block;
  color: ${colors.white};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

export const Text = styled.span`
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;
