import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, zIndex } = theme;

export const CommunityMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;

  > span:not(.icon) {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: ${colors.white};
  }
`;

export const MenuList = styled.ul`
  width: 280px;
  padding: 0 32px;
  position: absolute;
  right: 0;
  top: calc(100% + 16px);
  background-color: ${colors.highlight3};
  list-style: none;
  z-index: ${zIndex.level4};
  margin: 0;
  padding: 0;
`;

export const JoinCommunity = styled.li`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 16px 12px;

  & span:last-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.tmGreen};
  }
`;
