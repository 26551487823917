import React from 'react';

import TableCellWithIcon from '~/components/Admin/TableCellWithIcon';
import { Icon } from '~/ui';
import { getSkillLevelNumber } from '~/utils';

import { Container, Text } from './styles';

const TableCompetitionPlayersCell = ({ hideOnMobile, ageFrom, ageTo, gender, skillLevelFrom, skillLevelTo, text }) => {
  const minSkillLevel = getSkillLevelNumber(skillLevelFrom.prettyName);
  const maxSkillLevel = getSkillLevelNumber(skillLevelTo.prettyName);

  return (
    <TableCellWithIcon hideOnMobile={hideOnMobile} style={{ width: '100%', padding: '0' }}>
      <Container>
        <Text>{text}</Text>
        <Text>
          Skill level: {minSkillLevel} - {maxSkillLevel}
        </Text>
        <Text>
          Age: {ageFrom} - {ageTo}
        </Text>
      </Container>
      <Icon color='ash2' icon={gender.toLowerCase() === 'both' ? 'unisex' : gender.toLowerCase()} />
    </TableCellWithIcon>
  );
};

export default TableCompetitionPlayersCell;
