import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

const iconSizeMap = {
  xs: '12px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '48px',
};

export const IconContainer = styled.span`
  width: ${({ size }) => iconSizeMap[size] || '24px'};
  height: ${({ size }) => iconSizeMap[size] || '24px'};
  color: ${({ color }) => colors[color]};
  cursor: ${({ cursor }) => (cursor ? cursor : 'auto')};
  display: inline-block;

  & svg {
    width: 100%;
    height: 100%;
  }
`;
