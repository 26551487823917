import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { submitMatchInvite } from '~/api/matches';
import { ErrorMessage } from '~/components/shared/styles';
import { ANALYTICS_EVENTS, DATE_FORMATS, MATCH_TYPE } from '~/enums';
import { resetForm, updateFormField } from '~/store/matches/newMatchForm.slice';
import { Button, DatePicker, Dropdown, Input, InputRange, IntervalTimePicker, Modal, Textarea } from '~/ui';
import { trackAnalyticsEvent } from '~/utils/analytics';
import { genderOptions } from '~/utils/members';
import { getUnderscoredSkillLevel } from '~/utils/skills';

import { FormContainer } from '../../shared/styles';
import PickCourt from '../PickCourt/index';
import { Divider, ModalWrapper } from '../styles';

const OpenMatchForm = () => {
  const [isPickCourtModalOpen, setIsPickCourtModalOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const { opponentGender, communityId, court, message, date, time, skillLevelRange, opponentAgeRange } = useSelector(
    state => state.newMatchForm
  );
  const { allUserCommunities } = useSelector(state => state.communities);
  const { accountId } = useSelector(state => state.auth);

  const communities = useMemo(() => {
    return [
      { value: null, label: 'Visible To Everyone' },
      ...allUserCommunities.map(community => ({
        value: community.communityInfo.id,
        label: `Visible to ${community.communityInfo.communityName} members`,
      })),
    ];
  }, [allUserCommunities]);

  const onCourtPick = court => {
    dispatch(
      updateFormField({
        court,
      })
    );
    setIsPickCourtModalOpen(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      challengerId: accountId,
      communityId,
      type: MATCH_TYPE.OPEN,
      court,
      message,
      time: new Date(`${date} ${time}`).getTime() / 1000,
      skillLevelFrom: getUnderscoredSkillLevel(parseFloat(skillLevelRange.min)),
      skillLevelTo: getUnderscoredSkillLevel(parseFloat(skillLevelRange.max)),
      opponentAgeFrom: opponentAgeRange.min,
      opponentAgeTo: opponentAgeRange.max,
      opponentGender,
    };

    submitMatchInvite(formData)
      .then(() => {
        trackAnalyticsEvent(ANALYTICS_EVENTS.OPEN_MATCH_CHALLENGE_CREATED);
        history.push('/home');
      })
      .catch(() => {
        setIsSubmitting(false);
        setErrorMessage('Something went wrong, please try again.');
      });
  };

  useEffect(() => {
    return () => dispatch(resetForm());
  }, [dispatch]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <Dropdown
        defaultValue={communities[0]}
        icon='flag2'
        iconColor='ash2'
        isNewStyle
        options={communities}
        onChange={community => dispatch(updateFormField({ communityId: community.value }))}
      />
      <Dropdown
        defaultValue={genderOptions[2]}
        icon='unisex2'
        iconColor='ash2'
        isNewStyle
        options={genderOptions}
        placeholder='Pick opponent gender'
        onChange={gender => dispatch(updateFormField({ opponentGender: gender.value }))}
      />
      <InputRange
        isNewStyle
        label='Pick opponent level'
        maxValue={7.0}
        minValue={1.0}
        step={0.5}
        value={skillLevelRange}
        onChange={skillLevelRange => dispatch(updateFormField({ skillLevelRange }))}
      />
      <InputRange
        isNewStyle
        label='Pick opponent age'
        maxValue={72}
        minValue={16}
        step={1}
        value={opponentAgeRange}
        onChange={opponentAgeRange => dispatch(updateFormField({ opponentAgeRange }))}
      />
      <Divider />
      <DatePicker
        dateFormat={DATE_FORMATS.WITH_MONTH_NAME}
        icon='calendar'
        iconColor='ash2'
        isNewStyle
        minDate={new Date()}
        placeholder='Pick a date'
        returnValueDateFormat='yyyy/MM/dd'
        value={date || ''}
        onChange={date => dispatch(updateFormField({ date }))}
      />
      <IntervalTimePicker
        icon='clock'
        iconColor='ash2'
        placeholder='Pick the time'
        value={time || ''}
        onChange={time => dispatch(updateFormField({ time }))}
      />
      <Input
        icon='location2'
        iconColor='ash2'
        isNewStyle
        placeholder='Where do you want to play?'
        value={court.name || ''}
        onChange={() => undefined}
        onFocus={() => setIsPickCourtModalOpen(true)}
      />
      {isPickCourtModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <PickCourt onClose={() => setIsPickCourtModalOpen(false)} onSelect={onCourtPick} />
        </Modal>
      )}
      <Textarea
        icon='envelope'
        isNewStyle
        placeholder='If you want, you can write a short message to the opponent.'
        rows={6}
        value={message || ''}
        onChange={e => dispatch(updateFormField({ message: e.target.value }))}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Button disabled={!time || !date || !court.name || isSubmitting} fullWidth isNewStyle type='submit'>
        {isSubmitting ? 'Submitting...' : 'Submit Open Challenge'}
      </Button>
    </FormContainer>
  );
};

export default OpenMatchForm;
