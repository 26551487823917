import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminCommunities: [],
  memberCommunities: [],
  allUserCommunities: [],
  allUserCommunitiesLoading: false,
  selectedCommunity: {
    communityInfo: {
      communityName: '',
      imageUrl: '',
    },
    myStatus: {
      role: '',
    },
    members: [],
    membersError: '',
  },
  searchedCommunities: [],
  searchedCommunitiesError: '',
  searchedCommunitiesLoading: true,
  communityScreen: {},
  communityScreenLoading: {},
  communityScreenError: '',
  communityScreenMembers: [],
  communityScreenMembersLoading: false,
  communityScreenMembersErrorMessage: '',
};

export const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {
    adminCommunities: (state, action) => {
      state.adminCommunities = action.payload;
      state.communitiesError = '';
      state.selectedCommunityError = '';
    },
    communitiesError: (state, action) => {
      state.communitiesError = action.payload;
    },
    selectedCommunity: (state, action) => {
      if (!action.payload) {
        state.selectedCommunity = initialState.selectedCommunity;
      } else {
        state.selectedCommunity = {
          ...state.selectedCommunity,
          ...action.payload,
        };
      }
    },
    selectedCommunityError: (state, action) => {
      state.selectedCommunityError = action.payload;
    },
    selectedCommunityMembers: (state, action) => {
      state.selectedCommunity.members = action.payload;
      state.selectedCommunity.membersError = '';
    },
    selectedCommunityMembersError: (state, action) => {
      state.selectedCommunity.members = [];
      state.selectedCommunity.membersError = action.payload;
    },
    communitiesInitialState: () => {
      return initialState;
    },
    memberCommunities: (state, action) => {
      state.memberCommunities = action.payload;
      state.communitiesError = '';
    },
    allUserCommunities: (state, action) => {
      state.allUserCommunities = action.payload;
      state.allUserCommunitiesLoading = false;
    },
    allUserCommunitiesLoading: state => {
      state.allUserCommunitiesLoading = true;
    },
    searchedCommunitiesLoading: state => {
      state.searchedCommunitiesLoading = true;
    },
    searchedCommunities: (state, action) => {
      state.searchedCommunities = action.payload;
      state.searchedCommunitiesError = '';
      state.searchedCommunitiesLoading = false;
    },
    searchedCommunitiesError: (state, action) => {
      state.searchedCommunities = [];
      state.searchedCommunitiesError = action.payload;
      state.searchedCommunitiesLoading = false;
    },
    communityScreen: (state, action) => {
      state.communityScreen = action.payload;
      state.communityScreenLoading = false;
      state.communityScreenError = '';
    },
    communityScreenLoading: state => {
      state.communityScreenLoading = true;
      state.communityScreen = {};
    },
    communityScreenError: (state, action) => {
      state.communityScreenError = action.payload;
      state.communityScreenLoading = false;
      state.communityScreen = {};
    },
    communityScreenMembers: (state, action) => {
      state.communityScreenMembers = action.payload;
      state.communityScreenMembersLoading = false;
      state.communityScreenMembersErrorMessage = '';
    },
    communityScreenMembersLoading: state => {
      state.communityScreenMembersLoading = true;
    },
    communityScreenMembersErrorMessage: (state, action) => {
      state.communityScreenMembersErrorMessage = action.payload;
      state.communityScreenMembers = [];
      state.communityScreenMembersLoading = false;
    },
  },
});

export const {
  adminCommunities,
  communitiesError,
  selectedCommunity,
  selectedCommunityError,
  selectedCommunityMembers,
  selectedCommunityMembersError,
  communitiesInitialState,
  memberCommunities,
  allUserCommunities,
  allUserCommunitiesLoading,
  searchedCommunities,
  searchedCommunitiesError,
  searchedCommunitiesLoading,
  communityScreen,
  communityScreenLoading,
  communityScreenError,
  communityScreenMembers,
  communityScreenMembersLoading,
  communityScreenMembersErrorMessage,
} = communitiesSlice.actions;

export default communitiesSlice.reducer;
