import React from 'react';

import Avatar from '~/components/shared/Avatar/index';
import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';

import { Score, ScoresContainer, ScoreTableTitle, UserInfo, UserName } from '../styles';

const Results = ({ host, guest, results, title }) => {
  if (!host || !guest || !results) return null;

  const { matchResultSetScoreList } = results;

  if (!matchResultSetScoreList) return null;

  return (
    <>
      <ScoreTableTitle>{title}</ScoreTableTitle>
      <WidgetWrapper>
        <WidgetItem>
          <UserInfo>
            <Avatar alt={host.firstName} size='s' src={host.avatarUrl} />
            <UserName>
              {host.firstName} {host.lastName}
            </UserName>
          </UserInfo>
          <ScoresContainer>
            {matchResultSetScoreList.map(result => (
              <Score isWin={result.gamesWonByHost > result.gamesWonByGuest} key={result.id}>
                {result.gamesWonByHost}
              </Score>
            ))}
          </ScoresContainer>
        </WidgetItem>
        <WidgetItem>
          <UserInfo>
            <Avatar alt={guest.firstName} size='s' src={guest.avatarUrl} />
            <UserName>
              {guest.firstName} {guest.lastName}
            </UserName>
          </UserInfo>
          <ScoresContainer>
            {matchResultSetScoreList.map(result => (
              <Score isWin={result.gamesWonByGuest > result.gamesWonByHost} key={result.id}>
                {result.gamesWonByGuest}
              </Score>
            ))}
          </ScoresContainer>
        </WidgetItem>
      </WidgetWrapper>
    </>
  );
};

export default Results;
