import styled from 'styled-components';

import BackButton from '~/components/shared/BackButton';
import { theme } from '~/ui';

const { mediaQueries } = theme;

export const StyledBackButton = styled(BackButton)`
  width: fit-content;
  margin-top: -16px;
  text-align: left;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;
    margin-top: 0;
  }
`;
