import { getCommunityNewsRequest, getNewsByIdRequest } from '~/api';

import { newsError, newsList, selectedNews, selectedNewsError } from './slice';

export const getCommunityNews =
  (communityId, pageSize = 100) =>
  async dispatch => {
    try {
      const response = await getCommunityNewsRequest(communityId, pageSize);
      const newsListData = response.data.body;

      dispatch(newsList(newsListData));
    } catch (error) {
      dispatch(newsError("Can't load community news."));
    }
  };

export const getNewsById = newsId => async dispatch => {
  try {
    const response = await getNewsByIdRequest(newsId);
    const newsData = response.data.body;
    if (!newsData) throw new Error();

    dispatch(selectedNews(newsData));
  } catch (error) {
    dispatch(selectedNewsError(error.data));
  }
};
