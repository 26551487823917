import React from 'react';

import UserWithAvatar from '~/components/shared/UserWithAvatar';

import { AvatarPlaceholder, Match, Player } from './styles';

const RoundMatch = ({ match, placeholderName }) => {
  const { guest, host } = match;

  return (
    <Match>
      <Player>
        {host ? (
          <UserWithAvatar avatarUrl={host.avatarUrl} userName={`${host.firstName} ${host.lastName}`} />
        ) : (
          <>
            <AvatarPlaceholder />
            {placeholderName}
          </>
        )}
      </Player>
      <Player>
        {guest ? (
          <UserWithAvatar avatarUrl={guest.avatarUrl} userName={`${guest.firstName} ${guest.lastName}`} />
        ) : (
          <>
            <AvatarPlaceholder />
            {placeholderName}
          </>
        )}
      </Player>
    </Match>
  );
};

export default RoundMatch;
