import React from 'react';

import { Spinner } from '~/ui';

import { InfoMessage, WidgetLoadingContainer } from '../styles';

const ListItemsStatusFeedback = ({
  isLoading,
  isError,
  errorMessage = 'There was an error, please try again later.',
  emptyListMessage = 'No items available.',
  style = {},
  children,
}) => {
  if (isLoading) {
    return (
      <WidgetLoadingContainer style={style}>
        <Spinner />
      </WidgetLoadingContainer>
    );
  }

  if (isError) {
    return (
      <WidgetLoadingContainer style={style}>
        <InfoMessage>{errorMessage}</InfoMessage>
      </WidgetLoadingContainer>
    );
  }

  return (
    <WidgetLoadingContainer style={style}>
      <InfoMessage>{emptyListMessage}</InfoMessage>
      {children && children}
    </WidgetLoadingContainer>
  );
};

export default ListItemsStatusFeedback;
