import React from 'react';

import { Badge } from './styles';

const TournamentEndedStatus = ({ tournamentConcluded }) => {
  if (tournamentConcluded) {
    return <Badge>This tournament has ended.</Badge>;
  }

  return null;
};

export default TournamentEndedStatus;
