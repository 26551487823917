import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acceptUserJoinCommunity, getCommunityJoinRequests, rejectUserJoinCommunity } from '~/api';
import CommunityHeaderImage from '~/components/Admin/CommunityHeaderImage';
import JoinRequestsTable from '~/components/Admin/JoinRequestsTable';
import NewsTable from '~/components/Admin/NewsTable';
import { getCommunityMembers } from '~/store/communities/utils';
import { getCommunityLeagues } from '~/store/leagues/utils';
import { getCommunityNews } from '~/store/news/utils';
import { getCommunityTournaments } from '~/store/tournaments/utils';
import { Grid, PageTitle } from '~/ui';

import CompetitionsTable from './CompetitionsTable';
import Members from './Members';
import { Container } from './styles';

const OverviewPage = () => {
  const dispatch = useDispatch();
  const { id: communityId, imageUrl } = useSelector(state => state.communities.selectedCommunity.communityInfo);
  const { members, membersError } = useSelector(state => state.communities.selectedCommunity);
  const { newsList, newsError } = useSelector(state => state.news);
  const { leaguesList, leaguesError } = useSelector(state => state.leagues);
  const { tournamentsList, tournamentsError } = useSelector(state => state.tournaments);
  const [loadingMembers, setLoadingMembers] = useState();
  const [loadingLeagues, setLoadingLeagues] = useState();
  const [loadingTournaments, setLoadingTournaments] = useState();
  const [loadingNews, setLoadingNews] = useState();
  const [joinRequests, setJoinRequests] = useState([]);
  const [joinRequestsError, setJoinRequestsError] = useState('');

  useEffect(() => {
    loadMembers();
  }, [dispatch, communityId, joinRequestsError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadLeagues = async () => {
      setLoadingLeagues(true);
      if (communityId) await dispatch(getCommunityLeagues(communityId));
      setLoadingLeagues(false);
    };

    const loadTournaments = async () => {
      setLoadingTournaments(true);
      if (communityId) await dispatch(getCommunityTournaments(communityId));
      setLoadingTournaments(false);
    };

    const loadNews = async () => {
      setLoadingNews(true);
      if (communityId) await dispatch(getCommunityNews(communityId));
      setLoadingNews(false);
    };

    loadLeagues();
    loadTournaments();
    loadNews();
  }, [dispatch, communityId]);

  const loadMembers = async () => {
    if (communityId && !joinRequestsError) {
      setLoadingMembers(true);
      await dispatch(getCommunityMembers(communityId));

      getCommunityJoinRequests(communityId)
        .then(res => setJoinRequests(res))
        .catch(() => setJoinRequestsError("Can't load join requests."))
        .finally(() => setLoadingMembers(false));
    }
  };

  return (
    <>
      <CommunityHeaderImage image={imageUrl} />
      <Container>
        <PageTitle title='Overview' />
        <JoinRequestsTable
          data={joinRequests}
          error={joinRequestsError}
          isLoading={loadingMembers}
          onAccept={member => {
            setLoadingMembers(true);
            acceptUserJoinCommunity(member.accountId, communityId)
              .then(() => loadMembers())
              .catch(() => setJoinRequestsError('Something went wrong. Refresh the page and try again.'))
              .finally(() => setLoadingMembers(false));
          }}
          onDecline={member => {
            setLoadingMembers(true);
            rejectUserJoinCommunity(member.accountId, communityId)
              .then(() => loadMembers())
              .catch(() => setJoinRequestsError('Something went wrong. Refresh the page and try again.'))
              .finally(() => setLoadingMembers(false));
          }}
        />
        <CompetitionsTable data={leaguesList} error={leaguesError} isLoading={loadingLeagues} type='leagues' />
        <CompetitionsTable
          data={tournamentsList}
          error={tournamentsError}
          isLoading={loadingTournaments}
          type='tournaments'
        />
        <Grid.Container>
          <Grid.Row>
            <Grid.Column columns={2}>
              <Members data={members} error={membersError} isLoading={loadingMembers} />
            </Grid.Column>
            <Grid.Column columns={2}>{/* Matches table */}</Grid.Column>
          </Grid.Row>
        </Grid.Container>
        <NewsTable data={newsList.records} error={newsError} isLoading={loadingNews} />
      </Container>
    </>
  );
};

export default OverviewPage;
