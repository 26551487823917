import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const FilterRowTitle = styled.p`
  margin: 32px 0 0;
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const Container = styled.div`
  > div {
    margin-bottom: 40px;

    &:first-child {
      margin: 12px 0 24px;
    }

    label {
      color: ${colors.white};
    }
  }
`;
