import React from 'react';
import { useSelector } from 'react-redux';

import { WidgetItem } from '~/components/shared/index';

import { InfoFlexContianer, WaitingForOpponentMessage, WaitingForOpponentStatusIcon } from './styles';

const UserOutOfTournamentItem = ({ nextOpponent, winners }) => {
  const { accountId } = useSelector(state => state.auth);

  if (nextOpponent) {
    return null;
  }

  const isOneOfWinners = !!winners.find(el => el.userProfileCardView.accountId === accountId);

  if (isOneOfWinners) {
    return null;
  }

  return (
    <WidgetItem>
      <InfoFlexContianer>
        <WaitingForOpponentStatusIcon>GG</WaitingForOpponentStatusIcon>
        <WaitingForOpponentMessage>Thank you for participating. Better luck next time.</WaitingForOpponentMessage>
      </InfoFlexContianer>
    </WidgetItem>
  );
};

export default UserOutOfTournamentItem;
