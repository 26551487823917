import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  box-sizing: border-box;
`;

export const TreeContainer = styled.div`
  display: flex;
  gap: 64px;
  padding: 32px 40px;
  box-sizing: border-box;
`;

export const BracketsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 320px;
  max-width: 320px;
  min-width: 320px;
`;
