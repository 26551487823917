import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    position: static;
    width: 64px;
    height: 64px;
    background: none;
  }
`;

export const FeedbackMessage = styled.p`
  color: ${colors.white};
  font-size: 14px;
  font-weight: bold;
`;
