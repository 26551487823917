import styled from 'styled-components';

import theme from '../../theme';

const { zIndex } = theme;

export const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(21, 21, 21, 0.4);
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.level2};
`;
