import { convertToRaw } from 'draft-js';

export const isPasswordValid = password => {
  // Regex explanation
  // (?=.*[a-z]) - at least one lowercase letter
  // (?=.*[A-Z]) - at least one uppercase letter
  // (?=.*[\d\W_]) - at least one digit or special character (inclucing underscore)
  // {6,16} - between 6 and 16 characters
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W_]).{6,16}$/;

  return passwordRegex.test(password);
};

export const isEmailValid = email => {
  const emailRegex =
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  return emailRegex.test(email);
};

export const isUrlValidWithHttpPrefix = url => {
  // Regex explanation
  // (https?:\/\/) - at least `http://` prefix
  // [(www\.)?a-zA-Z0-9@:%._\+~#=] - set of characters between 2 and 256 characters
  // \. - matches `.` (dot) character before the url domain
  // [a-z]{2,6} - set of characters for domain - between 2 and 6 characters
  // \b([-a-zA-Z0-9@:%_\+.~#?&//=]*) - matches URL parameters

  // eslint-disable-next-line no-useless-escape
  const urlRegex = /(https?:\/\/)[(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

  return urlRegex.test(url);
};

export const isTextEditorEmpty = editorState => {
  const editorStateBlocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const editorHasText = editorStateBlocks.some(block => block.text.trim() !== '');

  return editorHasText;
};
