import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { leaveCompetition } from '~/api/competition';
import { ErrorMessage, ModalContent } from '~/components/shared/index';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getTournamentScreen } from '~/store/tournaments/tournamentScreen.utils';
import { Button } from '~/ui/index';

const LeaveTournamentModal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const { accountId } = useSelector(state => state.auth);

  const { tournamentId } = useParams();

  const onLeaveTournament = () => {
    leaveCompetition(tournamentId, accountId)
      .then(() => {
        setIsLoading(false);
        dispatch(getTournamentScreen(tournamentId));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage('Something went wrong. Please try again.');
      });
  };

  return (
    <ModalContent title='Leave Tournament' onClose={onClose}>
      <InfoMessage>Are you sure you want to leave this tournament?</InfoMessage>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button color='danger' disabled={isLoading} isNewStyle onClick={onLeaveTournament}>
          {isLoading ? 'Leaving...' : 'Leave'}
        </Button>
        <Button color='white' disabled={isLoading} isNewStyle onClick={onClose}>
          Cancel
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default LeaveTournamentModal;
