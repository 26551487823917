import React from 'react';

import UserWithAvatar from '~/components/shared/UserWithAvatar';
import { COMPETITION_TYPE } from '~/enums';
import { Icon, theme } from '~/ui';
import { getDateFromString, getDifferenceInYears, getPcrBalls, getSkillLevelNumber } from '~/utils';

import { IconContainer, MobileLabel, StyledDropdown } from './styles';

const { colors } = theme;

export const getPlayersData = ({ data = [] }) => {
  return data.map(member => {
    const { accountId, avatarUrl, birthday, gender, firstName, lastName, leagueGroupId, statsApi, tennisLevel } =
      member;

    return {
      accountId,
      avatarUrl,
      birthday,
      gender,
      firstName,
      lastName,
      leagueGroupId,
      statsApi,
      tennisLevel,
    };
  });
};

export const getPlayersColumns = ({ competitionType, groupsAndMembers, selectedItems, tableType, onAdd }) => {
  return [
    {
      label: 'Name',
      value: 'firstName',
      width: 250,
      sortBy: 'firstName',
      iconLeft: 'multipleUsers',
      renderCustomCell: user => {
        const { avatarUrl, firstName, lastName } = user;
        const isSelected = selectedItems && selectedItems.find(arrayPlayer => arrayPlayer.accountId === user.accountId);

        return (
          <>
            {tableType === 'addPlayers' && (
              <IconContainer>
                <Icon
                  color={isSelected ? 'tmGreen' : 'ash2'}
                  cursor='pointer'
                  icon='userPlus'
                  onClick={() => onAdd(user)}
                />
              </IconContainer>
            )}
            <UserWithAvatar
              avatarUrl={avatarUrl}
              style={{ color: isSelected ? colors.white : colors.whiteOpacity._75 }}
              userName={`${firstName} ${lastName}`}
            />
          </>
        );
      },
    },
    {
      label: 'Pcr',
      value: 'pcr',
      renderCustomCell: user => {
        const { statsApi } = user;

        return getPcrBalls(statsApi.playerCredRating);
      },
    },
    {
      label: 'Skill Level',
      renderCustomCell: user => {
        const { tennisLevel } = user;

        return (
          <>
            <MobileLabel>Skill level</MobileLabel>
            {getSkillLevelNumber(tennisLevel.prettyName).toFixed(1)}
          </>
        );
      },
    },
    {
      label: 'Age',
      hideOnMobile: true,
      renderCustomCell: user => {
        const { birthday } = user;

        return getDifferenceInYears(getDateFromString(birthday, 'yyyy-MM-dd'), new Date());
      },
    },
    {
      label: competitionType === COMPETITION_TYPE.LEAGUE ? 'Seed' : 'Gender',
      renderCustomCell: user => {
        if (competitionType === COMPETITION_TYPE.LEAGUE) {
          const userGroup =
            groupsAndMembers && groupsAndMembers.find(({ leagueGroup }) => leagueGroup.id === user.leagueGroupId);

          return (
            <StyledDropdown
              getOptionLabel={groupsAndMembers => groupsAndMembers.leagueGroup.name}
              getOptionValue={groupsAndMembers => groupsAndMembers.leagueGroup.name}
              noOptionsMessage={() => (
                <>
                  <Icon color='ash2' icon='info' />
                  Create groups to sort players into.
                </>
              )}
              options={groupsAndMembers}
              placeholder='Unsorted'
              value={userGroup ? userGroup : 'Unsorted'}
              onChange={value => onAdd({ user, group: value })}
            />
          );
        } else {
          return user.gender.prettyName;
        }
      },
    },
  ];
};
