import React, { useState } from 'react';

import { ROLE } from '~/enums/member';
import { Button, Modal } from '~/ui/index';

import { ModalWrapper } from '../../../shared/styles';
import LeaveCommunityModal from '../LeaveCommunityModal/index';
import { ButtonWithTooltipContainer, TooltipContainer, TooltipText } from './styles';

const LeaveCommunityButton = ({ communityName, userRole }) => {
  const [isLeaveCommunityModalOpen, setIsLeaveCommunityModalOpen] = useState(false);

  const isButtonDisabled = userRole === ROLE.ADMIN;

  return (
    <>
      <ButtonWithTooltipContainer>
        <Button
          color='danger'
          disabled={isButtonDisabled}
          isNewStyle
          onClick={() => setIsLeaveCommunityModalOpen(true)}
        >
          Leave community
        </Button>
        {isButtonDisabled && (
          <TooltipContainer>
            <TooltipText>As the administrator, you are unable to leave this community.</TooltipText>
          </TooltipContainer>
        )}
      </ButtonWithTooltipContainer>
      {isLeaveCommunityModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <LeaveCommunityModal communityName={communityName} onClose={() => setIsLeaveCommunityModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default LeaveCommunityButton;
