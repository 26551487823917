import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  color: ${colors.white};
  display: block;
`;

export const UserPCR = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 1.8;
  color: ${colors.tmGreen};
  display: block;
`;

export const UserAge = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
  color: ${colors.ash2};
  display: block;
`;
