import shuffle from 'lodash/shuffle';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  addUsersToLeagueGroup,
  addUserToLeagueGroup,
  changeUserLeagueGroup,
  deleteGroupById,
  deleteUserFromLeagueGroup,
  deleteUsersFromLeagueGroup,
} from '~/api';
import CompetitionPlayersTable from '~/components/Admin/CompetitionPlayersTable';
import LeagueCreateGroupsModal from '~/components/Admin/LeagueCreateGroupsModal';
import NotificationBadge from '~/components/Admin/Notification/Badge';
import SeedPlayersModal from '~/components/Admin/SeedPlayersModal';
import SingleCompetitionInfo from '~/components/Admin/SingleCompetitionInfo';
import ConfirmModal from '~/components/shared/ConfirmModal';
import { selectedLeagueReset } from '~/store/leagues/slice';
import { getLeagueDetails, getSelectedLeagueMembers } from '~/store/leagues/utils';
import { Button, ErrorMessage, Icon, PageTableName, Spinner, useOutsideClick } from '~/ui';
import { getCompetitionStatus, getSkillLevelNumber } from '~/utils';

import {
  GroupList,
  GroupListItem,
  GroupsContainer,
  GroupTableContainer,
  Header,
  Name,
  Option,
  OptionsBox,
  OptionsButton,
  SeedPlayersNoticeContainer,
  Status,
  TableHeaderContainer,
} from './styles';

const SingleLeaguePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: urlCommunityId, leagueId } = useParams();
  const leagueOptionsRef = useRef();
  const groupOptionsRef = useRef();
  const selectedCommunityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { selectedLeague, selectedLeagueError } = useSelector(state => state.leagues);

  const [loadingLeague, setLoadingLeague] = useState(false);
  const [showLeagueOptions, setShowLeagueOptions] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [groupError, setGroupError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(selectedLeague.groupsAndMembers[0]);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showSeedPlayersModal, setShowSeedPlayersModal] = useState(false);
  const [seedPlayersError, setSeedPlayersError] = useState('');
  const [seedPlayersNotice, setSeedPlayersNotice] = useState('');

  const optionsContainerRef = useRef();

  useOutsideClick(optionsContainerRef, () => setShowLeagueOptions(false));

  if (selectedCommunityId !== Number(urlCommunityId)) {
    dispatch(selectedLeagueReset());
    history.push(`/admin/community/${selectedCommunityId}/leagues`);
  }

  const loadLeague = async () => {
    setLoadingLeague(true);
    await dispatch(getLeagueDetails(leagueId));
    await dispatch(getSelectedLeagueMembers(leagueId));
    setLoadingLeague(false);
  };

  useEffect(() => {
    loadLeague();
  }, [dispatch, leagueId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedLeague.groupsAndMembers.length) {
      setSelectedGroup(selectedLeague.groupsAndMembers[0]);
    }
  }, [selectedLeague.groupsAndMembers]);

  useOutsideClick(groupOptionsRef, () => setShowGroupOptions(false));

  const leagueStatus = getCompetitionStatus(selectedLeague);
  const unsortedPlayers = selectedLeague.members.filter(member => !member.leagueGroupId);

  const handlePlayerLeagueGroup = async ({ user, group }) => {
    const { accountId, firstName, lastName, leagueGroupId: currentUserGroupId } = user;
    const { id: newGroupId, name: newGroupName } = group.leagueGroup;

    setSeedPlayersNotice('');

    if (currentUserGroupId === newGroupId) {
      setGroupError(`${firstName} ${lastName} is already in ${newGroupName}.`);
    } else {
      try {
        if (newGroupName.toLowerCase() === 'unsorted') {
          await deleteUserFromLeagueGroup(accountId, currentUserGroupId);
        } else if (!currentUserGroupId) {
          await addUserToLeagueGroup(accountId, newGroupId);
        } else {
          await changeUserLeagueGroup(accountId, currentUserGroupId, newGroupId);
        }

        setGroupError('');
        loadLeague();
      } catch (error) {
        setGroupError(`Something went wrong with adding ${firstName} ${lastName} to ${newGroupName}. Try again.`);
      }
    }
  };

  const handleDeleteSelectedGroup = async () => {
    const { id, name } = selectedGroup.leagueGroup;
    const groupPlayersIdList = selectedGroup.members.map(player => player.profile.accountId);

    deleteUsersFromLeagueGroup(groupPlayersIdList, id)
      .then(() => deleteGroupById(id))
      .then(async () => await loadLeague())
      .then(() => {
        setGroupError('');
      })
      .catch(() => {
        setGroupError(`Something went wrong with deleting ${name}.`);
      })
      .finally(() => {
        setShowDeleteGroupModal(false);
        setSeedPlayersNotice('');
      });
  };

  const handleDeleteSelectedGroupPlayers = async () => {
    const { id, name } = selectedGroup.leagueGroup;
    const groupPlayersIdList = selectedGroup.members.map(player => player.profile.accountId);

    setSeedPlayersNotice('');

    if (!groupPlayersIdList.length) {
      setShowGroupOptions(false);
    } else {
      deleteUsersFromLeagueGroup(groupPlayersIdList, id)
        .then(async () => await loadLeague())
        .catch(() => setGroupError(`Something went wrong with deleting players from the ${name}.`))
        .finally(() => setShowGroupOptions(false));
    }
  };

  const handleSeedPlayers = async ({ type }) => {
    let unsortedPlayersIdList = unsortedPlayers.map(player => player.accountId);
    const groupsIdList = selectedLeague.groupsAndMembers.map(({ leagueGroup }) => leagueGroup.id);
    const playersPerGroup = Math.ceil(unsortedPlayersIdList.length / groupsIdList.length);

    if (type === 'By skill level') {
      const sortPlayersBySkillLevel = unsortedPlayers.sort((a, b) => {
        return getSkillLevelNumber(b.tennisLevel.prettyName) - getSkillLevelNumber(a.tennisLevel.prettyName);
      });

      unsortedPlayersIdList = sortPlayersBySkillLevel.map(player => player.accountId);
    } else {
      unsortedPlayersIdList = shuffle(unsortedPlayersIdList);
    }

    const dividedPlayersArray = [];
    unsortedPlayersIdList.forEach(player => {
      if (
        !dividedPlayersArray.length ||
        dividedPlayersArray[dividedPlayersArray.length - 1].length === playersPerGroup
      ) {
        dividedPlayersArray.push([]);
      }

      dividedPlayersArray[dividedPlayersArray.length - 1].push(player);
    });

    try {
      for (let i = 0; i < dividedPlayersArray.length; i++) {
        await addUsersToLeagueGroup(dividedPlayersArray[i], groupsIdList[i]);
      }
      await loadLeague();
      setSeedPlayersNotice(
        `TennisMate has seeded the players ${type.toLowerCase()} into groups so that ${selectedLeague.name} can begin.`
      );
      setShowSeedPlayersModal(false);
      setSeedPlayersError('');
    } catch (error) {
      setSeedPlayersNotice('');
      setSeedPlayersError('Something went wrong with seeding players into groups.');
    }
  };

  return (
    <>
      {loadingLeague ? (
        <Spinner />
      ) : selectedLeagueError ? (
        <ErrorMessage>{selectedLeagueError}</ErrorMessage>
      ) : (
        <>
          <Header>
            <div>
              <Name>{selectedLeague.name}</Name>
              <Status>{leagueStatus.status}</Status>
              {leagueStatus.status === 'Registration ended' && (
                <NotificationBadge dotColor='pending' text='Seed players in groups' />
              )}
              {seedPlayersNotice && (
                <SeedPlayersNoticeContainer>
                  <NotificationBadge dotColor='pending' text={seedPlayersNotice} />
                </SeedPlayersNoticeContainer>
              )}
            </div>
            <Link to={`/admin/community/${selectedCommunityId}/league/${selectedLeague.id}/add-players`}>
              <Button>
                <Icon color='tmGreen' cursor='pointer' icon='userPlus' />
                Add players to League
              </Button>
            </Link>
            <div ref={optionsContainerRef} style={{ flex: 0 }}>
              <OptionsButton noBorder onClick={() => setShowLeagueOptions(true)}>
                <Icon color={showLeagueOptions ? 'white' : 'ash'} cursor='pointer' icon='threeDots' />
              </OptionsButton>
              {showLeagueOptions && (
                <OptionsBox ref={leagueOptionsRef}>
                  <Link to={`/admin/community/${selectedCommunityId}/league/${selectedLeague.id}/edit-league`}>
                    <Option>Edit League</Option>
                  </Link>
                  <Option onClick={() => setShowLeagueOptions(false)}>Cancel League</Option>
                </OptionsBox>
              )}
            </div>
          </Header>
          <SingleCompetitionInfo competition={selectedLeague} />
          <TableHeaderContainer>
            {unsortedPlayers && unsortedPlayers.length > 0 && (
              <PageTableName name='Unsorted players' number={loadingLeague ? '' : unsortedPlayers.length} />
            )}
            <Button small onClick={() => setShowGroupsModal(true)}>
              Create Groups
            </Button>
          </TableHeaderContainer>
          {unsortedPlayers && unsortedPlayers.length > 0 && (
            <CompetitionPlayersTable
              competitionType='league'
              data={unsortedPlayers}
              error={selectedLeague.membersError}
              groupsAndMembers={selectedLeague.groupsAndMembers}
              isLoading={loadingLeague}
              onAdd={handlePlayerLeagueGroup}
            />
          )}
          {groupError && <ErrorMessage>{groupError}</ErrorMessage>}
          {selectedLeague.groupsAndMembers.length > 0 && (
            <>
              <PageTableName name='Groups' />
              <GroupsContainer>
                <GroupList ref={groupOptionsRef}>
                  {selectedLeague.groupsAndMembers.map(group => {
                    const { leagueGroup, members } = group;

                    return (
                      <GroupListItem
                        active={leagueGroup.id === selectedGroup.leagueGroup.id}
                        key={leagueGroup.id}
                        onClick={() => setSelectedGroup(group)}
                      >
                        {leagueGroup.name} ({members.length})
                        {leagueGroup.id === selectedGroup.leagueGroup.id && (
                          <Icon
                            color='white'
                            cursor='pointer'
                            icon='threeDots'
                            onClick={() => setShowGroupOptions(!showGroupOptions)}
                          />
                        )}
                        {leagueGroup.id === selectedGroup.leagueGroup.id && showGroupOptions && (
                          <OptionsBox small>
                            <Option
                              onClick={() => {
                                setShowGroupOptions(false);
                                setShowDeleteGroupModal(true);
                              }}
                            >
                              Delete Group
                            </Option>
                            <Option onClick={handleDeleteSelectedGroupPlayers}>Move players to Unsorted</Option>
                          </OptionsBox>
                        )}
                      </GroupListItem>
                    );
                  })}
                </GroupList>
                <GroupTableContainer>
                  {selectedGroup.leagueGroup.id !== 0 && (
                    <CompetitionPlayersTable
                      competitionType='league'
                      data={selectedLeague.members.filter(
                        member => member.leagueGroupId === selectedGroup.leagueGroup.id
                      )}
                      error={selectedLeague.membersError}
                      groupsAndMembers={[{ leagueGroup: { name: 'Unsorted' } }, ...selectedLeague.groupsAndMembers]}
                      isLoading={loadingLeague}
                      onAdd={handlePlayerLeagueGroup}
                    />
                  )}
                </GroupTableContainer>
              </GroupsContainer>
            </>
          )}
          {unsortedPlayers && unsortedPlayers.length > 0 && selectedLeague.groupsAndMembers.length > 0 && (
            <Button onClick={() => setShowSeedPlayersModal(true)}>
              <Icon color='tmGreen' cursor='pointer' icon='shuffle' />
              Seed players
            </Button>
          )}
          {showGroupsModal && (
            <LeagueCreateGroupsModal league={selectedLeague} onHide={() => setShowGroupsModal(false)} />
          )}
          {selectedLeague.groupsAndMembers.length > 0 && showDeleteGroupModal && (
            <ConfirmModal
              cancel={() => setShowDeleteGroupModal(false)}
              cancelButtonText='Cancel'
              confirm={handleDeleteSelectedGroup}
              confirmButtonColor='danger'
              confirmButtonIcon='trash'
              confirmButtonText='Delete Group'
              text={
                <>
                  <p>
                    Are you sure you want to delete <strong>{selectedGroup.leagueGroup.name}</strong>?
                  </p>
                  <p>All players seeded to this group will end up Unsorted.</p>
                </>
              }
              title='Delete Group'
            />
          )}
          {showSeedPlayersModal && (
            <SeedPlayersModal
              error={seedPlayersError}
              setShowModal={setShowSeedPlayersModal}
              onSubmit={handleSeedPlayers}
            />
          )}
        </>
      )}
    </>
  );
};

export default SingleLeaguePage;
