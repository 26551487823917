import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const UserContainer = styled.div`
  display: flex;
  color: ${colors.whiteOpacity._75};
  align-items: center;
  cursor: pointer;

  > .icon {
    width: auto;
  }

  > ul {
    cursor: auto;
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin-right: 12px;
  }
`;

export const Name = styled.span`
  margin: 0 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: none;
  }
`;
