import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MATCH_STATUS } from '~/enums/match';
import { ButtonsContainer, ModalWrapper } from '~/pages/shared/styles';
import { Button, Modal } from '~/ui/index';

import RegisterResultModal from '../RegisterResultModal/index';

const RegisterResultButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = useSelector(state => state.matchScreen);
  const { match, hostResult, guestResult } = data;

  if (match?.matchStatus !== MATCH_STATUS.PENDING_RESULT || !!hostResult || !!guestResult) return null;

  return (
    <ButtonsContainer>
      <Button isNewStyle onClick={() => setIsModalOpen(true)}>
        Register Result
      </Button>
      {isModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <RegisterResultModal onClose={() => setIsModalOpen(false)} />
        </Modal>
      )}
    </ButtonsContainer>
  );
};

export default RegisterResultButtons;
