import { useEffect, useState } from 'react';

export const useGeolocation = (options = { onError: () => {} }) => {
  const [location, setLocation] = useState(undefined);

  const { onError } = options;

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(loc => {
        if (loc) setLocation(loc.coords);
      }, onError);
    }
  }, []);

  return location;
};
