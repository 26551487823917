import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  *:not(:first-child) {
    max-width: 550px;
  }

  h1 {
    max-width: 747px;
  }
`;

export const EditArticle = styled(Button)`
  width: 219px;
  float: right;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin-bottom: 24px;
    float: none;
  }
`;

export const Title = styled.h1`
  display: inline-block;
  margin-top: 0;
  color: ${colors.white};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: block;
    margin-bottom: 0;
  }
`;

export const Img = styled.img`
  width: 100%;
  max-width: 550px;
  max-height: 310px;
  object-fit: cover;
`;

export const Content = styled.div`
  margin: 24px 0 32px;
  color: ${colors.whiteOpacity._75};

  h2 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
  }
`;

export const InfoContainer = styled.div`
  margin-bottom: 32px;
  padding: ${({ withIcon }) => (withIcon ? '6px 16px 16px' : '16px')};
  background: ${colors.highlight3};
  box-sizing: border-box;
`;

export const InfoParagraph = styled.p`
  margin: 0;
  color: ${colors.white};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;

  &:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.invert};
  }

  span {
    margin-left: 16px;
    color: ${colors.whiteOpacity._75};
    text-transform: none;

    .icon {
      margin-left: 0;
      margin-right: 10px;
      position: relative;
      bottom: -6px;
    }
  }
`;
