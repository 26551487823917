import capitalize from 'lodash/capitalize';

import { GENDER } from '~/enums';
import {
  endOfDay,
  fromUnixTime,
  getSkillLevelNumber,
  getUnixTime,
  isDateBeforeCurrentDateTime,
  parseISO,
} from '~/utils';

export const getCompetitionStatus = competition => {
  let status;
  let timeline = [];
  const competitionType = capitalize(competition.competitionType);

  const isCompetitionEnded = isDateBeforeCurrentDateTime(competition.endTime);
  const isCompetitionStarted = isDateBeforeCurrentDateTime(competition.startTime);
  const isRegistrationEnded = isDateBeforeCurrentDateTime(competition.registerEndTime);
  const isRegistrationStarted = isDateBeforeCurrentDateTime(competition.registerStartTime);
  const competitionStartDate = String(fromUnixTime(competition.startTime)).slice(0, 10);
  const competitionEndDate = String(fromUnixTime(competition.endTime)).slice(0, 10);
  const registrationStartDate = String(fromUnixTime(competition.registerStartTime)).slice(0, 10);
  const registrationEndDate = String(fromUnixTime(competition.registerEndTime)).slice(0, 10);

  if (isCompetitionEnded) {
    status = `${competitionType} ended`;
    timeline = [`Started on ${competitionStartDate}`, `Ended on ${competitionEndDate}`];
  } else if (isCompetitionStarted) {
    status = `${competitionType} started`;
    timeline = [`Started on ${competitionStartDate}`, `Ends on ${competitionEndDate}`];
  } else if (isRegistrationEnded) {
    status = 'Registration ended';
    timeline = [`${competitionType} starts on ${competitionStartDate}`];
  } else if (isRegistrationStarted) {
    status = 'Registration started';
    timeline = [
      `${competitionType} starts on ${competitionStartDate}`,
      `Registration from ${registrationStartDate} - ${registrationEndDate}`,
    ];
  } else if (!isRegistrationStarted) {
    status = 'Registration not started';
    timeline = [
      `${competitionType} starts on ${competitionStartDate}`,
      `Registration from ${registrationStartDate} - ${registrationEndDate}`,
    ];
  } else {
    status = 'Unknown status';
  }

  return { status, timeline };
};

export const filterCompetitionByDate = ({ from, to, data }) => {
  const fromTimeStamp = getUnixTime(parseISO(from));
  const toTimeStamp = getUnixTime(endOfDay(parseISO(to)));

  return data.filter(competition => {
    if (fromTimeStamp && toTimeStamp) {
      return competition.startTime >= fromTimeStamp && competition.endTime <= toTimeStamp;
    } else if (fromTimeStamp) {
      return competition.startTime >= fromTimeStamp;
    } else if (toTimeStamp) {
      return competition.startTime <= toTimeStamp;
    } else {
      return competition;
    }
  });
};

export const filterCompetitionBySearchValue = ({ searchValue, data }) => {
  return data.filter(({ name }) => {
    return name.toLowerCase().includes(searchValue.toLowerCase().trim());
  });
};

export const filterCompetitionByGender = ({ gender, data }) => {
  return data.filter(competition => {
    if (gender === GENDER.BOTH) {
      return competition;
    } else {
      return competition.gender !== null && competition.gender.toLowerCase() === gender.toLowerCase();
    }
  });
};

export const filterCompetitionByAgeRange = ({ ageRange, data }) => {
  return data.filter(({ ageFrom, ageTo }) => ageFrom >= ageRange.min && ageTo <= ageRange.max);
};

export const filterCompetitionBySkillLevelRange = ({ skillLevelRange, data }) => {
  return data.filter(({ skillLevelFrom, skillLevelTo }) => {
    const levelFrom = getSkillLevelNumber(skillLevelFrom.prettyName);
    const levelTo = getSkillLevelNumber(skillLevelTo.prettyName);

    return levelFrom >= skillLevelRange.min && levelTo <= skillLevelRange.max;
  });
};

export const getNearestPowerOf2 = n => {
  return 1 << (31 - Math.clz32(n));
};

export const getNumberOfByePlayers = ({ numberOfPlayers, nearestPowerOfPlayers }) => {
  if (numberOfPlayers > nearestPowerOfPlayers) {
    const difference = numberOfPlayers - nearestPowerOfPlayers;

    return nearestPowerOfPlayers - difference;
  }

  return null;
};
