import styled from 'styled-components';

import { Grid, theme } from '~/ui';

const { colors, mediaQueries, zIndex } = theme;

export const CompetitionInfo = styled.span`
  width: 360px;
  padding-bottom: 10px;
  position: absolute;
  left: 235px;
  top: -16px;
  background: ${colors.highlight3};
  z-index: ${zIndex.level1};

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;
    left: -16px;
  }
`;

export const StyledColumn = styled(Grid.Column)`
  margin: 15px 0 0;

  &:nth-child(1) {
    flex-basis: 10%;

    .icon {
      align-self: center;
    }
  }

  &:nth-child(2) {
    flex-basis: 80%;
  }
`;

export const StyledUl = styled.ul`
  margin: 0;
  padding: 0 16px 0 0;

  li {
    border-bottom: 1px solid ${colors.invert};
    padding: 5px 0;
    color: ${colors.white};
    font-size: 12px;
    line-height: 16px;
    list-style: none;
    letter-spacing: 0.92px;

    &:last-child {
      border: none;
    }

    span {
      margin-right: 16px;
      color: ${colors.white};
      font-weight: 600;
      text-transform: uppercase;
    }

    a {
      color: ${colors.tmGreen};
    }
  }
`;
