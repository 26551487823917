import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { WidgetItem } from '~/components/shared/styles';
import { DATE_FORMATS, MATCH_STATUS } from '~/enums';
import { useMatchStatusColor } from '~/hooks';
import { Icon } from '~/ui';
import { checkIfShouldShowMatchStatusLabel, formatDateFromUnixTime, matchStatusLabelMapper } from '~/utils';

import {
  MatchInfo,
  MatchInfoContainer,
  MatchOpponentAvatar,
  MatchOpponentAvatarContainer,
  MatchOpponentName,
  MatchStatus,
  MatchTimeAndPlace,
  MatchWinLossBadge,
} from '../styles';

const MatchItem = ({ match, opponent }) => {
  const history = useHistory();

  const { accountId } = useSelector(state => state.auth);

  const matchStatusColor = useMatchStatusColor(match.matchStatus);

  const { matchStatus, matchTime, hostId, winningUserId, competitionId } = match;
  const { firstName, lastName, avatarUrl } = opponent;

  return (
    <WidgetItem onClick={() => history.push(`/match/${match.id}`)}>
      <MatchInfoContainer>
        {competitionId && <Icon color='tmGreen' icon='trophy2' />}
        <MatchOpponentAvatarContainer>
          <MatchOpponentAvatar alt={firstName} src={avatarUrl} />
          {matchStatus === MATCH_STATUS.CONFIRMED && (
            <MatchWinLossBadge isWin={winningUserId === accountId}>
              {winningUserId === accountId ? 'WIN' : 'LOSS'}
            </MatchWinLossBadge>
          )}
        </MatchOpponentAvatarContainer>
        <MatchInfo>
          <MatchOpponentName>{`${firstName} ${lastName}`}</MatchOpponentName>
          <div>
            {checkIfShouldShowMatchStatusLabel(matchStatus) && (
              <>
                <MatchStatus color={matchStatusColor}>
                  {matchStatusLabelMapper(matchStatus, accountId, hostId)}
                </MatchStatus>
                <MatchTimeAndPlace> • </MatchTimeAndPlace>
              </>
            )}
            <MatchTimeAndPlace>{`${formatDateFromUnixTime(matchTime, DATE_FORMATS.LONG_WITH_TIME)} • ${get(
              match,
              ['court', 'name'],
              ''
            )}`}</MatchTimeAndPlace>
          </div>
        </MatchInfo>
      </MatchInfoContainer>
      <Icon
        color='ash3'
        icon='rightArrow2'
        size='s'
        style={{
          minWidth: '16px',
          minHeight: '16px',
        }}
      />
    </WidgetItem>
  );
};

export default MatchItem;
