import * as amplitude from '@amplitude/analytics-browser';
import jwt from 'jsonwebtoken';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { InitialRoutes } from '~/routes';
import { TokensService } from '~/services';
import { userIsProfileCompleted, userLogin } from '~/store/auth/slice';
import { getUserProfile } from '~/store/user/utils';
import { theme } from '~/ui';

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
  defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
});

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    userLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userLoggedIn = async () => {
    const currentUrlLocation = location.pathname;
    const tokens = await TokensService.getTokens();
    const profileCompleted = await JSON.parse(localStorage.getItem('profileCompleted'));

    if (tokens) {
      dispatch(userLogin(tokens));
    }

    if (tokens && profileCompleted) {
      const user = jwt.decode(tokens.accessToken);
      const { accountId } = user.authenticatedUser;

      dispatch(userIsProfileCompleted(profileCompleted));
      dispatch(getUserProfile(accountId));

      // stay on the current page after reload
      history.push(currentUrlLocation);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <InitialRoutes />
      <Toaster />
    </ThemeProvider>
  );
}

export default withRouter(App);
