import capitalize from 'lodash/capitalize';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Avatar, WidgetHeader, WidgetItem, WidgetWrapper } from '~/components/shared/index';
import { getCommunityScreenMembers } from '~/store/communities/utils';
import { updateFormField } from '~/store/matches/newMatchForm.slice';
import { Button } from '~/ui/index';

import ListItemsStatusFeedback from '../../shared/ListItemStatusFeedback/index';
import { Role, UserInfo, UserName } from './styles';

const Members = () => {
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { communityScreenMembers, communityScreenMembersLoading, communityScreenMembersErrorMessage } = useSelector(
    state => state.communities
  );
  const { accountId: currentAccountId } = useSelector(state => state.auth);

  useEffect(() => {
    if (communityId) {
      dispatch(getCommunityScreenMembers(communityId));
    }
  }, [communityId, dispatch]);

  const onScheduleMatch = opponent => {
    dispatch(updateFormField({ opponent }));
    history.push('/match/new-match', { isDirectOnly: true });
  };

  return (
    <WidgetWrapper>
      <WidgetHeader title='Members' />
      {communityScreenMembers.length > 0 ? (
        communityScreenMembers.map(member => {
          const { avatarUrl, firstName, lastName, accountId, role } = member;

          if (accountId === currentAccountId) {
            return null;
          }

          return (
            <WidgetItem key={accountId}>
              <UserInfo>
                <Avatar alt={firstName} size='l' src={avatarUrl} />
                <UserName>
                  {firstName} {lastName} <br />
                  <Role>{capitalize(role)}</Role>
                </UserName>
              </UserInfo>
              <Button
                color='tmGreen'
                isNewStyle
                onClick={() => onScheduleMatch({ fullName: `${firstName} ${lastName}`, id: accountId })}
              >
                Schedule match
              </Button>
            </WidgetItem>
          );
        })
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='There are no members in this community'
          errorMessage={communityScreenMembersErrorMessage}
          isError={!!communityScreenMembersErrorMessage}
          isLoading={communityScreenMembersLoading}
        />
      )}
    </WidgetWrapper>
  );
};

export default Members;
