import { capitalize } from 'lodash';
import React from 'react';

import { DATE_FORMATS } from '~/enums/dates';
import { formatDateFromUnixTime, getSkillLevelNumber } from '~/utils';

import { Container, Key, Value, ValueContainer } from './styles';

const SingleCompetitionInfo = ({ competition }) => {
  const gender = competition.gender === null ? 'Both' : capitalize(competition.gender);

  const skillLevelFrom = getSkillLevelNumber(competition.skillLevelFrom.prettyName).toFixed(1);

  const skillLevelTo = getSkillLevelNumber(competition.skillLevelTo.prettyName).toFixed(1);

  return (
    <>
      <Container>
        <ValueContainer>
          <Key>Admin email</Key>
          <Value>{competition.adminEmail}</Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Registration dates</Key>
          <Value>
            {formatDateFromUnixTime(competition.registerStartTime, DATE_FORMATS.MONTH_WITH_DAY_NAME_AND_TIME)} •{' '}
            {formatDateFromUnixTime(competition.registerEndTime, DATE_FORMATS.MONTH_WITH_DAY_NAME_AND_TIME)}
          </Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Playing dates</Key>
          <Value>
            {formatDateFromUnixTime(competition.startTime, DATE_FORMATS.MONTH_WITH_DAY_NAME_AND_TIME)} •{' '}
            {formatDateFromUnixTime(competition.endTime, DATE_FORMATS.MONTH_WITH_DAY_NAME_AND_TIME)}
          </Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Court address</Key>
          <Value>{competition.court.formattedAddress}</Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Participant gender</Key>
          <Value>{gender}</Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Skill level</Key>
          <Value>
            {skillLevelFrom} • {skillLevelTo}
          </Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Age Span</Key>
          <Value>
            {competition.ageFrom} • {competition.ageTo}
          </Value>
        </ValueContainer>
        <ValueContainer>
          <Key>Players</Key>
          <Value>{competition.numberOfRegisteredPlayers} players</Value>
        </ValueContainer>
      </Container>
      {competition.additionalDescription && (
        <Container>
          <ValueContainer>
            <Key>Description</Key>
            <Value newLine>{competition.additionalDescription}</Value>
          </ValueContainer>
        </Container>
      )}
    </>
  );
};

export default SingleCompetitionInfo;
