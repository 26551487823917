import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  max-width: 1040px;
  position: relative;
  top: -30px;
`;

export const MembersContainer = styled.div`
  min-height: ${({ error }) => (error ? 'auto' : '200px')};
  position: relative;
  background: ${colors.highlight2};

  div {
    background: none;
  }
`;

export const MembersHead = styled.p`
  display: flex;
  margin: 0;
  padding: 8px 16px;
  color: ${colors.whiteOpacity._75};
  background: ${colors.highlight3};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  letter-spacing: 0.92px;
  line-height: 16px;

  .icon {
    margin-right: 12px;
  }
`;

export const MembersNumber = styled.p`
  border-bottom: 1px solid ${colors.invert};
  padding-bottom: 16px;
  color: ${colors.white};
  font-weight: bold;
`;

export const MembersContent = styled.div`
  padding: 0 16px 16px;

  > div {
    margin-bottom: 16px;
  }
`;

export const MembersLink = styled(Link)`
  padding-bottom: 48px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const CellFirstInfo = styled.p`
  margin: 0;
  color: ${colors.white};
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  line-height: 24px;
`;

export const CellSecondInfo = styled.div`
  margin-top: 16px;
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const CellBlock = styled.div`
  display: block;
  flex: 1 1 auto;
  align-self: flex-start;

  p {
    padding: 0;

    &:first-of-type {
      margin-bottom: 8px;
    }
  }
`;

export const ManageButton = styled(Button)`
  border-color: ${colors.background};
  background: ${colors.highlight3};
`;
