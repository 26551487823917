import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Role = styled.span`
  margin-left: auto;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  text-transform: lowercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const MobileLabel = styled.span`
  margin-right: 10px;
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;

  @media screen and (min-width: ${mediaQueries.mobile}px) {
    display: none;
  }
`;
