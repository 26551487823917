import React from 'react';

import { Container, Number, Text } from './styles';

const MatchesInfo = ({ number, text }) => {
  return (
    <Container>
      <Number>{number}</Number>
      <Text>{text}</Text>
    </Container>
  );
};

export default MatchesInfo;
