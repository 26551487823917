import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const AboutItem = styled.div`
  padding: 12px 16px;
  background: ${colors.highlight3};
  display: flex;
  gap: 16px;

  & .icon {
    flex-shrink: 0;
  }
`;

export const AboutItemTitle = styled.h4`
  font-weight: 700;
  font-size: 16px;
  color: ${colors.white};
  line-height: 1.5;
  margin: 0;
`;

export const AboutItemDescription = styled.p`
  font-weight: 400;
  font-size: 13px;
  color: ${colors.ash2};
  line-height: 1.85;
  margin: 0;
  word-break: break-word;
`;

export const CommunityUserStatusWrapper = styled.div`
  margin: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const CommunityMembersInfo = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.ash2};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const InviteTextTitle = styled.h4`
  font-weight: 600;
  font-size: 21px;
  line-height: 2;
  text-align: center;
  color: ${colors.white};
  margin: 0;
`;
