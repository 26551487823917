import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { acceptMatchCancelation, rejectMatchCancelation } from '~/api/matches';
import { ErrorMessage } from '~/components/shared/styles';
import { MATCH_STATUS } from '~/enums/match';
import { ButtonsContainer } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button } from '~/ui/index';

import { StatusContainer, StatusMessage, StatusTitle } from '../styles';

const MatchPendingCancelationActions = () => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [errorMessage, setErrorMesssage] = useState('');

  const dispatch = useDispatch();
  const { data } = useSelector(state => state.matchScreen);
  const { accountId } = useSelector(state => state.auth);

  const { matchId } = useParams();

  const { match, host, guest } = data;

  if (
    isEmpty(match) ||
    ![MATCH_STATUS.PENDING_CANCEL_APPROVAL_FROM_GUEST, MATCH_STATUS.PENDING_CANCEL_APPROVAL_FROM_HOST].includes(
      match.matchStatus
    )
  ) {
    return null;
  }

  const opponent = match.matchStatus === MATCH_STATUS.PENDING_CANCEL_APPROVAL_FROM_HOST ? guest : host;

  if (opponent.accountId === accountId) {
    return null;
  }

  const onAccept = () => {
    setIsAccepting(true);
    acceptMatchCancelation(matchId)
      .then(() => {
        setIsAccepting(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMesssage('Something went wrong. Please try again.');
        setIsAccepting(false);
      });
  };

  const onReject = () => {
    setIsRejecting(true);
    rejectMatchCancelation(matchId)
      .then(() => {
        setIsRejecting(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMesssage('Something went wrong. Please try again.');
        setIsRejecting(false);
      });
  };

  return (
    <StatusContainer>
      <StatusTitle>{opponent.firstName} has requested a cancelation</StatusTitle>
      <StatusMessage>
        Because it is less than 12 hours until the play time, you can accept or reject the request. If you reject it,
        you will be given an automatic win.
      </StatusMessage>
      <ButtonsContainer>
        <Button disabled={isAccepting || isRejecting} isNewStyle onClick={onAccept}>
          {isAccepting ? 'Accepting...' : 'Accept'}
        </Button>
        <Button color='danger' disabled={isAccepting || isRejecting} isNewStyle onClick={onReject}>
          {isRejecting ? 'Rejecting...' : 'Reject'}
        </Button>
      </ButtonsContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StatusContainer>
  );
};

export default MatchPendingCancelationActions;
