import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIsMatchParticipant, useMatchInviteStatusColor, useMatchStatusColor } from '~/hooks';
import { Icon } from '~/ui';
import { matchStatusLabelMapper } from '~/utils/matches';

import {
  ButtonsContainer,
  HeaderContainer,
  MatchStatus,
  MatchStatusContainer,
  PageTitle,
  PageTitleContainer,
} from './styles';

const Header = ({ title, children, icon = 'close2', showStatus = true, iconColor = 'tmGreen' }) => {
  const { data } = useSelector(state => state.matchScreen);
  const { accountId } = useSelector(state => state.auth);
  const history = useHistory();

  const isParticipant = useIsMatchParticipant();

  const matchStatus = get(data, ['match', 'matchStatus'], null);
  const matchInviteStatus = get(data, ['matchInvite', 'matchInviteStatus'], null);
  const hostId = get(data, ['match', 'hostId'], null);

  const matchStatusColor = useMatchStatusColor(matchStatus);
  const matchInviteStatusColor = useMatchInviteStatusColor(matchInviteStatus);

  return (
    <HeaderContainer>
      <PageTitleContainer>
        <span onClick={history.goBack}>
          <Icon color={iconColor} cursor='pointer' icon={icon} size='s' />
        </span>
        {title && <PageTitle>{title}</PageTitle>}
      </PageTitleContainer>
      {isParticipant && (
        <MatchStatusContainer>
          {matchStatus && showStatus && (
            <>
              <p>Match</p>
              {showStatus && (
                <MatchStatus color={matchStatusColor}>
                  {matchStatusLabelMapper(matchStatus, accountId, hostId)}
                </MatchStatus>
              )}
            </>
          )}
          {!matchStatus && matchInviteStatus && showStatus && (
            <>
              <p>Match Invite</p>
              <MatchStatus color={matchInviteStatusColor}>
                {startCase(matchInviteStatus.toLowerCase().replace(/_/g, ' '))}
              </MatchStatus>
            </>
          )}
        </MatchStatusContainer>
      )}
      <ButtonsContainer>{children && children}</ButtonsContainer>
    </HeaderContainer>
  );
};

export default Header;
