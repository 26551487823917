import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const CompetitionItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompetitionItemTitleContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const CompetitionItemTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  margin: 0;
`;

export const CompetitionItemInfo = styled.span`
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin-left: 34px;
  color: ${colors.ash2};
`;

export const CompetitionWinnerInfo = styled.span`
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin-left: 34px;
  color: ${({ isWinner }) => (isWinner ? colors.orange : colors.white)};
`;
