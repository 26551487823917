import React from 'react';

import { Icon } from '~/ui';

import RoundMatch from './RoundMatch';
import { RoundName } from './styles';

const Round = ({ round }) => {
  const { roundMatches, roundNumber } = round;

  return (
    <>
      <RoundName>
        <Icon icon='trophy' />
        Round {roundNumber}
      </RoundName>
      {roundMatches.map(match => (
        <RoundMatch key={match.id} match={match} placeholderName={roundNumber === 1 ? 'BYE' : 'TBD'} />
      ))}
    </>
  );
};

export default Round;
