import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MATCH_LOST } from '~/enums';
import { Button, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const FullName = styled.p`
  margin-top: 0;
  color: ${colors.white};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`;

export const OptionsButton = styled(Button)`
  display: block;
  margin-bottom: 16px;
  padding: 12px 16px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const Img = styled.img`
  display: block;
  width: 150px;
  border-radius: 150px;
  margin: 0 auto;
  object-fit: cover;
`;

export const EditProfile = styled(Link)`
  display: flex;
  margin-top: 24px;
  color: ${colors.tmGreen};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const BlackBlock = styled.div`
  display: flex;
  border-radius: 4px;
  margin: 24px 0;
  padding: 20px 16px;
  background: ${colors.highlight3};
  align-items: center;

  > span {
    &:first-of-type {
      margin-right: 16px;
    }
  }
`;

export const UserDetails = styled.div`
  margin: 24px 0;
  padding: 0 16px;
  background: ${colors.highlight3};
`;

export const Section = styled.div`
  margin-bottom: 40px;

  > p:first-child {
    margin-bottom: 16px;
    color: ${colors.white};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.92px;
    line-height: 16px;
  }
`;

export const WinLoseCircleSymbol = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 76px;
  background: ${({ symbol }) => (symbol === MATCH_LOST ? colors.danger : colors.tmGreen)};
  color: ${colors.white};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: 32px;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin-right: 30px;
  }
`;

export const AboutMeText = styled.p`
  margin: 0;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

export const Notice = styled.p`
  padding: 12px 16px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.92px;
  line-height: 16px;
`;
