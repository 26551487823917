import styled from 'styled-components';

import { theme } from '~/ui/index';

const { colors } = theme;

export const HeaderContainer = styled.nav`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  background-color: ${colors.highlight3};
`;

export const BackIcon = styled.div`
  margin-right: 12px;
`;

export const PageTitle = styled.h1`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.6;
  color: ${colors.ash};
  margin: 0;
`;
