import React from 'react';

import { useMatchOpponent } from '~/hooks/match';
import { PcrBallRating } from '~/ui';

import { Container, Description, Title } from './styles';

const RateOpponent = ({ value, onChange }) => {
  const opponent = useMatchOpponent();
  return (
    <Container>
      <div>
        <Title>Rate your opponent</Title>
        <Description>Rate your match against {opponent.firstName} with 1 to 5 tennis balls.</Description>
      </div>
      <PcrBallRating iconSize='xl' maxRating={5} value={value} onChange={onChange} />
    </Container>
  );
};

export default RateOpponent;
