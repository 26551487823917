import styled, { css } from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  border-top: 1px solid ${colors.invert};
  padding: 12px 0;

  &:first-of-type {
    border: none;
  }
`;

const sharedStyle = css`
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const Name = styled.span`
  ${sharedStyle};
  color: ${colors.white};
  font-weight: bold;
  text-transform: uppercase;
`;

export const Value = styled.span`
  ${sharedStyle};
  margin-left: 16px;
  color: ${colors.whiteOpacity._75};
`;
