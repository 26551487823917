import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from '~/ui';

import { getCompetitionsColumns, getCompetitionsData } from './utils';

const CompetitionsTable = ({ type, data, error, isLoading }) => {
  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);

  return (
    <Table
      columns={getCompetitionsColumns({ communityId, type })}
      data={getCompetitionsData({ data })}
      error={error}
      isLoading={isLoading}
      itemsName={type}
    />
  );
};

export default CompetitionsTable;
