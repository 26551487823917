import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Dot = styled.span`
  flex-shrink: 0;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background: ${({ color }) => colors[color]};
`;
