import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { acceptMatchResult, challengeMatchResult } from '~/api/matches';
import ResultInputs from '~/components/Match/ResultInputs/index';
import ModalContent from '~/components/shared/ModalContent/index';
import { ErrorMessage } from '~/components/shared/styles';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button, showToast } from '~/ui';

import PCRDescriptionCheckboxes from '../PCRDescriptionCheckboxes/index';
import RateOpponent from '../RateOpponent/index';
import { Divider } from '../styles';

const ConfirmOrChallengeResultModals = ({ onClose }) => {
  const [isChallengeResults, setIsChallengeResults] = useState(false);
  const [isRateConfirmed, setIsRateConfirmed] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [rating, setRating] = useState(0);

  const [counterScores, setCounterScores] = useState([]);
  const [pcrDescriptionList, setPcrDescriptionList] = useState([]);
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  const { matchId } = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector(state => state.matchScreen);

  const { host, guest, result } = data;

  const scores = useMemo(() => {
    if (!result) return null;

    const { matchResultSetScoreList } = result;

    return matchResultSetScoreList.map(item => ({ hostScore: item.gamesWonByHost, guestScore: item.gamesWonByGuest }));
  }, [result]);

  if (!scores) return null;

  const onCheckboxSelect = (checked, value) => {
    if (checked) {
      setPcrDescriptionList([...pcrDescriptionList, value]);
    } else {
      setPcrDescriptionList(pcrDescriptionList.filter(el => el !== value));
    }
  };

  const onConfirmResults = () => {
    setIsLoading(true);
    setErrorMessage('');

    acceptMatchResult(matchId, { playerCredibilityRating: +rating })
      .then(() => {
        setIsLoading(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsLoading(false);
      });
  };

  const onChallengeResults = () => {
    setIsLoading(true);
    setErrorMessage('');

    const setResults = counterScores.map((val, i) => ({ setNo: i + 1, ...val }));

    const data = {
      playerCredibilityRating: +rating,
      pcrDescriptionList,
      setResults,
    };

    challengeMatchResult(matchId, data)
      .then(() => {
        showToast({
          title: 'Results submitted!',
          message:
            'Your match scores and feedback have been submitted. We’ll notify you once your opponent submits their scores.',
        });

        setIsLoading(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsLoading(false);
      });
  };

  if (!isChallengeResults && isRateConfirmed) {
    return (
      <ModalContent title='Confirm or Challenge Result' onClose={onClose}>
        <InfoMessage>
          Your opponent has already submitted the results from your match. You can either confirm or challenge it by
          submitting your own results.
        </InfoMessage>
        <ResultInputs disabled guest={guest} host={host} numberOfSets={scores.length} scores={scores} />
        <ButtonsContainer>
          <Button color='danger' disabled={isLoading} isNewStyle onClick={() => setIsChallengeResults(true)}>
            Challenge Results
          </Button>
          <Button color='white' disabled={isLoading} isNewStyle onClick={onConfirmResults}>
            {isLoading ? 'Confirming...' : 'Confirm Results'}
          </Button>
        </ButtonsContainer>
      </ModalContent>
    );
  }

  if (isChallengeResults && isRateConfirmed) {
    return (
      <ModalContent title='Challenge Results' onClose={onClose}>
        <InfoMessage>
          You are challenging the results provided by your opponent. The scores below were submitted by your opponent.
          Once you enter your own scores, we will let your opponent challenge or confirm them.
        </InfoMessage>
        <ResultInputs disabled guest={guest} host={host} numberOfSets={scores.length} scores={scores} />
        <ResultInputs
          guest={guest}
          host={host}
          scores={counterScores}
          setIsInputError={setIsInvalidInput}
          setScores={setCounterScores}
        />
        <Divider />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ButtonsContainer>
          <Button
            disabled={isLoading || isInvalidInput || counterScores.length <= 0}
            isNewStyle
            onClick={onChallengeResults}
          >
            {isLoading ? 'Submitting...' : 'Submit Results'}
          </Button>
        </ButtonsContainer>
      </ModalContent>
    );
  }

  return (
    <ModalContent title='Rate your opponent' onClose={onClose}>
      <InfoMessage>Before you can confirm or challenge results, please rate your opponent.</InfoMessage>
      <Divider />
      <RateOpponent value={rating} onChange={setRating} />
      {rating <= 2 && rating > 0 && <PCRDescriptionCheckboxes onCheckboxSelect={onCheckboxSelect} />}
      <ButtonsContainer>
        <Button disabled={!rating} isNewStyle onClick={() => setIsRateConfirmed(true)}>
          Submit
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ConfirmOrChallengeResultModals;
