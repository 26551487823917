import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Text = styled.p`
  display: inline-block;
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.92px;

  &:first-child {
    display: block;
    margin-top: 0px;
  }

  &:nth-of-type(2) {
    min-width: 150px;
    margin-right: 24px;
  }
`;

export const Container = styled.div`
  flex: 1 1 auto;
  padding: 0;
  align-self: flex-start;
`;
