import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MATCH_STATUS } from '~/enums/match';
import { ModalWrapper } from '~/pages/shared/styles';
import { Button, Modal } from '~/ui';
import { isDateBeforeCurrentDateTime } from '~/utils/dates';

import CancelMatchModal from '../CancelMatchModal';
import ProposeNewTimeModal from '../ProposeNewTimeModal/index';

const ScheduledMatchButtons = () => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isProposeNewTimeModalOpen, setIsProposeNewTimeModalOpen] = useState(false);

  const { data } = useSelector(state => state.matchScreen);

  const { match } = data;

  if (match?.matchStatus !== MATCH_STATUS.SCHEDULED) return null;

  return (
    <>
      {!isDateBeforeCurrentDateTime(match?.matchTime) && (
        <Button color='ash' isNewStyle onClick={() => setIsProposeNewTimeModalOpen(true)}>
          Propose New Time
        </Button>
      )}
      <Button color='danger' isNewStyle onClick={() => setIsCancelModalOpen(true)}>
        Cancel Match
      </Button>
      {isCancelModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <CancelMatchModal onClose={() => setIsCancelModalOpen(false)} />
        </Modal>
      )}
      {isProposeNewTimeModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <ProposeNewTimeModal onClose={() => setIsProposeNewTimeModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default ScheduledMatchButtons;
