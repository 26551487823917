import { getUnixTime } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { searchOpenMatches } from '~/api/matches';
import { ErrorMessage, FlexColumnWrapper } from '~/components/shared/index';
import { useGeolocation } from '~/hooks/geolocation';
import { ContentContainer } from '~/pages/shared/styles';
import MyChallenges from '~/pages/User/shared/MyChallenges/index';
import { Button, Spinner } from '~/ui';
import { getUnderscoredSkillLevel } from '~/utils/skills';

import Header from '../shared/Header';
import FiltersModal from './FiltersModal';

const FindMatch = () => {
  const [isFilterMenuOpen, setIsFiltersMenuOpen] = useState(false);
  const [filters, setFilters] = useState({
    skillLevelRange: { min: 1.0, max: 7.0 },
    opponentAgeRange: { min: 16, max: 72 },
    communityId: null,
    gender: null,
    timeFrom: null,
    timeTo: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [matches, setMatches] = useState([]);

  const [locationError, setLocationError] = useState('');
  const location = useGeolocation({
    onError() {
      setLocationError('Please allow location access in order to find a match.');
    },
  });

  useEffect(() => {
    setIsLoading(true);

    if (location) {
      const filterData = {
        location: {
          lat: location.latitude,
          lon: location.longitude,
        },
        skillFrom: getUnderscoredSkillLevel(parseFloat(filters.skillLevelRange.min)),
        skillTo: getUnderscoredSkillLevel(parseFloat(filters.skillLevelRange.max)),
        ageFrom: filters.opponentAgeRange.min,
        ageTo: filters.opponentAgeRange.max,
        communityId: filters.communityId,
        gender: filters.gender,
        timeFrom: filters.timeFrom ? getUnixTime(new Date(filters.timeFrom)) : null,
        timeTo: filters.timeTo ? getUnixTime(new Date(filters.timeTo)) : null,
      };

      searchOpenMatches(filterData)
        .then(res => {
          setIsLoading(false);
          setErrorMessage('');
          setMatches(
            res.data.body.records.map(({ matchInvite, community, userProfileCardView }) => ({
              community,
              match: matchInvite,
              opponent: userProfileCardView,
            }))
          );
        })
        .catch(() => {
          setIsLoading(false);
          setErrorMessage('Something went wrong. Please try again.');
        });
    }
  }, [filters, location]);

  return (
    <>
      <Header showStatus={false} title='Find a Match' />
      <ContentContainer>
        {!location && !locationError && <Spinner />}
        {locationError && <ErrorMessage>{locationError}</ErrorMessage>}
        {!locationError && location && (
          <FlexColumnWrapper>
            <Button fullWidth isNewStyle onClick={() => setIsFiltersMenuOpen(true)}>
              Open Filters
            </Button>
            <FiltersModal
              filters={filters}
              isVisible={isFilterMenuOpen}
              setFilters={setFilters}
              onClose={() => setIsFiltersMenuOpen(false)}
            />
            <MyChallenges
              emptyListMessage='There are no challenges that match your search criteria.'
              errorMessage={locationError || errorMessage}
              isLoading={isLoading}
              myChallenges={matches}
            />
          </FlexColumnWrapper>
        )}
      </ContentContainer>
    </>
  );
};

export default FindMatch;
