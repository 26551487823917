import React from 'react';
import TimePicker from 'react-time-picker';

import { withLabel } from '~/ui/hocs';

import { TimePickerContainer } from './styles';

function CustomTimePicker({ disabled = false, value, onChange, format = 'h:m a' }) {
  return (
    <TimePickerContainer>
      <TimePicker
        clearIcon={null}
        disableClock={true}
        disabled={disabled}
        format={format}
        hourPlaceholder='hh'
        minutePlaceholder='mm'
        value={value}
        onChange={onChange}
      />
    </TimePickerContainer>
  );
}

export default withLabel(CustomTimePicker);
