import styled from 'styled-components';

const avatarSizeMap = {
  xs: '16px',
  s: '24px',
  m: '32px',
  l: '48px',
  xl: '64px',
  xxl: '128px',
};

export const StyledAvatar = styled.img`
  width: ${({ size }) => avatarSizeMap[size]};
  height: ${({ size }) => avatarSizeMap[size]};
  min-width: ${({ size }) => avatarSizeMap[size]};
  object-fit: cover;
  border-radius: 50%;
`;
