import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const NoticeContainer = styled.div`
  max-width: 640px;
  margin: 40px 0;
`;

export const NoticeTitle = styled.h2`
  color: ${colors.white};
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

export const NoticeText = styled.p`
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

export const RoundName = styled.p`
  display: flex;
  margin: 0;
  padding: 12px 16px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  letter-spacing: 0.92px;
  line-height: 16px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};

  .icon {
    margin-right: 16px;
  }
`;

export const Match = styled.div`
  border: 1px solid ${colors.invert};
  margin-top: 24px;
  padding: 16px;
  background: ${colors.highlight2};
`;

export const Player = styled.span`
  display: flex;
  padding: 16px 0 0;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.invert};
    padding: 0 0 16px;
  }
`;

export const AvatarPlaceholder = styled.span`
  border-radius: 4px;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background: ${colors.highlight3};
  object-fit: cover;
`;

export const HideOnMobile = styled.div`
  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: none;
  }
`;

export const DeleteButton = styled(Button)`
  border: 1px solid ${colors.danger};
  margin: 0 0 40px;
  color: ${colors.danger};
`;
