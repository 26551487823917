import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';

import { Icon } from '~/ui';
import { formatDate } from '~/utils';

import { Container, Label, NewStyleContainer, NewStyleDatePicker, StyledDatePicker } from './styles';

const DatePicker = ({
  label,
  icon,
  iconColor = 'ash2',
  leftIconSize = 's',
  isClearable,
  minDate,
  maxDate,
  onChange,
  placeholder = 'dd/mm/yyyy',
  dateFormat = 'dd/MM/yyyy',
  returnValueDateFormat = 'yyyy-MM-dd',
  required,
  value,
  isNewStyle = false,
  disabled = false,
}) => {
  const formatValue = value && Date.parse(value);

  if (isNewStyle)
    return (
      <div>
        {label && <Label>{label}</Label>}
        <NewStyleContainer leftIconSize={leftIconSize}>
          <Icon color={iconColor} icon={icon} size={leftIconSize} />
          <NewStyleDatePicker
            dateFormat={dateFormat}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            placeholderText={placeholder}
            required={required}
            selected={formatValue}
            onChange={date => {
              const returnValue = date ? formatDate(date, returnValueDateFormat) : null;

              onChange(returnValue);
            }}
          />
          <Icon color={iconColor} icon='chevron2Down' size='s' />
        </NewStyleContainer>
      </div>
    );

  return (
    <Container label={label}>
      {label && <Label>{label}</Label>}
      {icon && <Icon color={iconColor} cursor='pointer' icon='calendar' />}
      <StyledDatePicker
        dateFormat={dateFormat}
        icon={icon}
        isClearable={isClearable && formatValue}
        maxDate={maxDate}
        minDate={minDate}
        placeholderText={placeholder}
        required={required}
        selected={formatValue}
        onChange={date => {
          const returnValue = date ? formatDate(date, returnValueDateFormat) : null;

          onChange(returnValue);
        }}
      />
    </Container>
  );
};

export default DatePicker;
