import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MATCH_RESULT_STATUS } from '~/enums/match';
import { ModalWrapper } from '~/pages/shared/styles';
import { Button, Modal } from '~/ui/index';

import ConfirmOrChallengeResultModals from '../ConfirmOrChallengeResultModals/index';
import ConfirmOrRejectResultsModal from '../ConfirmOrRejectResultsModal/index';
import { StatusContainer, StatusMessage, StatusTitle } from '../styles';

const RegisterResultStatus = () => {
  const { data } = useSelector(state => state.matchScreen);
  const { accountId } = useSelector(state => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { guest, host, result, match } = data;

  if (!match || !/result/gi.test(match?.matchStatus) || !result) {
    return null;
  }

  const { matchResultContract, matchResultSetScoreList } = result;

  if (!matchResultContract || !matchResultSetScoreList) {
    return null;
  }

  const { status } = matchResultContract;

  if (
    (status === MATCH_RESULT_STATUS.GUEST_ENTERED_RESULT && accountId === host.accountId) ||
    (status === MATCH_RESULT_STATUS.HOST_ENTERED_RESULT && accountId === guest.accountId)
  ) {
    return (
      <StatusContainer>
        <StatusTitle>Your opponent has registered the results.</StatusTitle>
        <StatusMessage>Tap the button below to confirm or challenge the results.</StatusMessage>
        <Button isNewStyle onClick={() => setIsModalOpen(true)}>
          Confirm or Challenge Results
        </Button>
        {isModalOpen && (
          <Modal ContentWrapper={ModalWrapper}>
            <ConfirmOrChallengeResultModals onClose={() => setIsModalOpen(false)} />
          </Modal>
        )}
      </StatusContainer>
    );
  }

  if (
    (status === MATCH_RESULT_STATUS.GUEST_RESULT_IS_CHALLENGED && accountId === guest.accountId) ||
    (status === MATCH_RESULT_STATUS.HOST_RESULT_IS_CHALLENGED && accountId === host.accountId)
  ) {
    return (
      <StatusContainer>
        <StatusTitle>Your opponent has challenged your results.</StatusTitle>
        <StatusMessage>Tap the button below to confirm or reject the results.</StatusMessage>
        <Button isNewStyle onClick={() => setIsModalOpen(true)}>
          Confirm or Reject Results
        </Button>
        {isModalOpen && (
          <Modal ContentWrapper={ModalWrapper}>
            <ConfirmOrRejectResultsModal onClose={() => setIsModalOpen(false)} />
          </Modal>
        )}
      </StatusContainer>
    );
  }

  if (
    ((status === MATCH_RESULT_STATUS.GUEST_ENTERED_RESULT ||
      status === MATCH_RESULT_STATUS.HOST_RESULT_IS_CHALLENGED) &&
      accountId === guest.accountId) ||
    ((status === MATCH_RESULT_STATUS.HOST_ENTERED_RESULT ||
      status === MATCH_RESULT_STATUS.GUEST_RESULT_IS_CHALLENGED) &&
      accountId === host.accountId)
  ) {
    return (
      <StatusContainer>
        <StatusTitle>You have registered the results.</StatusTitle>
        <StatusMessage>Sit tight until your opponent confirms or challenges the results.</StatusMessage>
      </StatusContainer>
    );
  }

  return null;
};

export default RegisterResultStatus;
