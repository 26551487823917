import React from 'react';

import NotificationDot from '../Dot';
import { Matches } from './styles';

const NotificationMatches = ({ dotColor, text }) => {
  return (
    <Matches>
      <NotificationDot color={dotColor} />
      <span>{text}</span>
    </Matches>
  );
};

export default NotificationMatches;
