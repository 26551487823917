import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Filters from '~/components/Admin/Filters';
import NewsFilterModal from '~/components/Admin/NewsFilterModal';
import NewsTable from '~/components/Admin/NewsTable';
import SearchAndFilter from '~/components/shared/SearchAndFilter';
import { getCommunityLeagues } from '~/store/leagues/utils';
import { selectedNewsReset } from '~/store/news/slice';
import { getCommunityNews } from '~/store/news/utils';
import { getCommunityTournaments } from '~/store/tournaments/utils';
import { Button, Grid, Icon, PageHeader, PageTableName } from '~/ui';
import { findArrayDataByPropertyAndValue, getUnixTime, parseISO } from '~/utils';

const defaultFilterValues = {
  dateFrom: '',
  dateTo: '',
  selectedCompetitionId: 0,
};

const NewsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { newsList, newsError } = useSelector(state => state.news);
  const { leaguesList } = useSelector(state => state.leagues);
  const { tournamentsList } = useSelector(state => state.tournaments);
  const [loadingNews, setLoadingNews] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [competitionsList, setCompetitionsList] = useState();
  const [filters, setFilters] = useState(defaultFilterValues);

  useEffect(() => {
    const loadNews = async () => {
      setLoadingNews(true);
      if (communityId) {
        await dispatch(getCommunityNews(communityId));
      }
      setLoadingNews(false);
    };

    const loadLeagues = async () => {
      if (communityId) await dispatch(getCommunityLeagues(communityId));
    };

    const loadTournaments = async () => {
      if (communityId) await dispatch(getCommunityTournaments(communityId));
    };

    loadNews();
    loadLeagues();
    loadTournaments();
    dispatch(selectedNewsReset());
    history.push(`/admin/community/${communityId}/news`);
  }, [dispatch, communityId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mergedCompetitionsList = [...leaguesList, ...tournamentsList];
    setCompetitionsList(mergedCompetitionsList);
  }, [leaguesList, tournamentsList]);

  const handleApplyFilters = ({ dateFrom, dateTo, selectedCompetitionId }) => {
    setFilters({
      dateFrom,
      dateTo,
      selectedCompetitionId,
    });
    setShowModal(false);
  };

  const handleClearFilters = () => {
    setFilters(defaultFilterValues);
    setShowModal(false);
  };

  const filterRecords = records => {
    let filteredData = records;

    const fromTimeStamp = filters.dateFrom ? getUnixTime(parseISO(filters.dateFrom)) : getUnixTime(1);
    const toTimeStamp = filters.dateTo ? getUnixTime(parseISO(filters.dateTo)) : getUnixTime(new Date());

    filteredData = filteredData.records.filter(news =>
      news.title.toLowerCase().includes(searchValue.toLowerCase().trim())
    );

    if (fromTimeStamp || toTimeStamp) {
      filteredData = filteredData.filter(news => news.timestamp >= fromTimeStamp && news.timestamp <= toTimeStamp);
    }

    if (filters.selectedCompetitionId) {
      filteredData = filteredData.filter(news => news.competitionId === filters.selectedCompetitionId);
    }

    return filteredData;
  };

  const filteredRecords = filterRecords(newsList);
  const isFilterActive = !isEqual(defaultFilterValues, filters);

  const selectedCompetition =
    filters.selectedCompetitionId &&
    findArrayDataByPropertyAndValue(competitionsList, 'id', filters.selectedCompetitionId);

  return (
    <>
      <PageHeader
        buttonIcon='newsPlus'
        buttonText='Write an article'
        link={`/admin/community/${communityId}/news/new-article`}
        title='News'
      />
      <Grid.Container>
        <Grid.Row>
          <Grid.Column columns={2}>
            <PageTableName name='Latest news' />
          </Grid.Column>
          <Grid.Column columns={2}>
            <SearchAndFilter
              filterActive={isFilterActive}
              filterDisabled={!newsList.records.length}
              filterOnClick={() => setShowModal(true)}
              searchOnChange={e => setSearchValue(e.target.value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
      <Filters
        default={defaultFilterValues}
        filters={{ selectedCompetition, ...filters }}
        onClick={value =>
          setFilters({
            dateFrom: value.dateFrom,
            dateTo: value.dateTo,
            selectedCompetitionId: value.selectedCompetitionId,
          })
        }
      />
      <NewsTable data={filteredRecords} error={newsError} isLoading={loadingNews} />
      {!filterRecords.length && isFilterActive && (
        <Button onClick={handleClearFilters}>
          <Icon color='tmGreen' cursor='pointer' icon='trash' />
          Clear Filters
        </Button>
      )}
      {showModal && (
        <NewsFilterModal
          competitionsList={competitionsList}
          filters={filters}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default NewsPage;
