import styled from 'styled-components';

import { Button, Input, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  display: flex;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: block;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin-left: 0;
  }
`;

export const Search = styled(Input)`
  width: 288px;
  margin-right: 16px;
  background: ${colors.highlight3};

  ::placeholder {
    color: ${colors.whiteOpacity._75};
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 90%;
  }
`;

export const FilterButton = styled(Button)`
  width: 100%;
  height: 40px;
  background: ${colors.highlight3};
  color: ${({ active }) => (active ? colors.tmGreen : colors.whiteOpacity._75)};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;

  &:disabled {
    background: ${colors.highlight3} !important;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 60%;
    padding: 12px 16px;
  }
`;
