import { useEffect, useRef } from 'react';

const useIgnoreOnFirstRender = (callback, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useIgnoreOnFirstRender;
