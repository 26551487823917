import React from 'react';

import { ROLE } from '~/enums';
import { Button, ErrorMessage, Icon, Modal } from '~/ui';

import { FullName, Img } from '../MemberProfile/styles';
import { ButtonDanger, ButtonsContainer, CancelButton, CloseModalButton, Message, ModalContainer } from './styles';

const MemberProfileModal = ({
  image,
  name,
  newRole,
  optionsModalError,
  removeMember,
  communityName,
  handlePromoteMember,
  handleRemoveMember,
  handleCancelButton,
}) => {
  const GetConfirmMessage = () => {
    let message;

    if (newRole === ROLE.OPERATOR || newRole === ROLE.ADMIN) {
      message = (
        <Message>
          Are you sure you want to promote {name} to community {newRole.toLowerCase()}?
          <br />
          An administrator can revoke his status back to a member.
        </Message>
      );
    } else if (removeMember) {
      message = (
        <Message>
          Are you sure you want to remove {name} from {communityName}?
          <br />
          The user can apply for membership again, but needs to be approved by administrator or operator.
        </Message>
      );
    } else {
      message = null;
    }

    return message;
  };

  return (
    <ModalContainer>
      <Modal>
        <CloseModalButton onClick={handleCancelButton}>
          <Icon color='tmGreen' cursor='pointer' icon={newRole || removeMember ? 'close' : 'arrowLeft'} />
          {newRole || removeMember ? 'Cancel' : 'Back'}
        </CloseModalButton>
        <FullName>{name}</FullName>
        <Img alt='Member image' src={image} />
        <GetConfirmMessage />

        {optionsModalError && <ErrorMessage red>{optionsModalError}</ErrorMessage>}

        <ButtonsContainer bigMarginTop={!newRole && !removeMember}>
          {(!newRole || newRole === ROLE.OPERATOR) && !removeMember && (
            <Button onClick={() => handlePromoteMember(ROLE.OPERATOR)}>
              <Icon color='tmGreen' cursor='pointer' icon='userSettings' />
              Promote to operator
            </Button>
          )}
          {(!newRole || newRole === ROLE.ADMIN) && !removeMember && (
            <Button onClick={() => handlePromoteMember(ROLE.ADMIN)}>
              <Icon color='tmGreen' cursor='pointer' icon='multipleUsers' />
              Promote to admin
            </Button>
          )}

          {!newRole && (
            <ButtonDanger onClick={handleRemoveMember}>
              <Icon color='danger' cursor='pointer' icon='userX' />
              Remove user from community
            </ButtonDanger>
          )}

          {(newRole || removeMember) && <CancelButton onClick={handleCancelButton}>Cancel</CancelButton>}
        </ButtonsContainer>
      </Modal>
    </ModalContainer>
  );
};

export default MemberProfileModal;
