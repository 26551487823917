import { getTournamentTree } from '~/api';

import {
  setIsLoadingTournamentTreeData,
  setTournamentTreeData,
  setTournamentTreeErrorMessage,
} from './tournamentTree.slice';

export const getTournamentTreeData = tournamentId => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingTournamentTreeData());

    const response = await getTournamentTree(tournamentId);
    const data = response.data.body;

    dispatch(setTournamentTreeData({ ...data }));
  } catch (e) {
    dispatch(setTournamentTreeErrorMessage('Something went wrong with getting tournament tree. Please try again.'));
  }
};
