import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { MATCH_STATUS } from '~/enums/match';
import { ModalWrapper } from '~/pages/shared/styles';
import { Button, Modal } from '~/ui';

import ReviewNewMatchTimeModal from '../ReviewNewMatchTimeModal/index';

const PendingNewTimeProposalButtons = () => {
  const [isReviewTimeModalOpen, setIsReviewTimeModalOpen] = useState(false);
  const { accountId } = useSelector(state => state.auth);
  const { data } = useSelector(state => state.matchScreen);

  const { host, guest, match } = data;

  if (
    (MATCH_STATUS.PENDING_NEW_TIME_APPROVAL_FROM_GUEST === match?.matchStatus && accountId === guest.accountId) ||
    (MATCH_STATUS.PENDING_NEW_TIME_APPROVAL_FROM_HOST === match?.matchStatus && accountId === host.accountId)
  ) {
    return (
      <>
        <Button color='ash' isNewStyle onClick={() => setIsReviewTimeModalOpen(true)}>
          Review New Time
        </Button>
        {isReviewTimeModalOpen && (
          <Modal ContentWrapper={ModalWrapper}>
            <ReviewNewMatchTimeModal startTime={match.matchTime} onClose={() => setIsReviewTimeModalOpen(false)} />
          </Modal>
        )}
      </>
    );
  }

  return null;
};

export default PendingNewTimeProposalButtons;
