import React from 'react';

import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';
import { DATE_FORMATS } from '~/enums';
import { Icon } from '~/ui';
import { formatDateFromUnixTime } from '~/utils/dates';

import ListItemsStatusFeedback from '../ListItemStatusFeedback';
import { Image, ItemContent, Message, Time, Title } from './styles';

const NewsList = ({ news = [], emptyListMessage = 'There are no news.' }) => {
  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgBook' iconColor='orange' title='News' />
      {news.length > 0 ? (
        news.map(newsItem => (
          <WidgetItem key={newsItem.id}>
            <ItemContent>
              <Image alt={newsItem.title} src={newsItem.imageUrl} />
              <div>
                <Title>{newsItem.title}</Title>
                <Message>{newsItem.message}</Message>
                <Time>{formatDateFromUnixTime(newsItem.timestamp, DATE_FORMATS.WITH_MONTH_NAME)}</Time>
              </div>
            </ItemContent>
            <Icon
              color='ash3'
              icon='rightArrow2'
              size='s'
              style={{
                minWidth: '16px',
                minHeight: '16px',
              }}
            />
          </WidgetItem>
        ))
      ) : (
        <ListItemsStatusFeedback emptyListMessage={emptyListMessage} />
      )}
    </WidgetWrapper>
  );
};

export default NewsList;
