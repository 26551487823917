import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getRecentMatches } from '~/api/matches';
import { BackButton } from '~/components/LoginForm/styles';
import { FlexColumnWrapper } from '~/components/shared';

import MyRecentMatches from '../../shared/MyRecentMatches';

const AllRecentMatches = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState([]);

  const { accountId } = useSelector(state => state.auth);

  const history = useHistory();

  useEffect(() => {
    if (accountId) {
      setIsLoading(true);
      getRecentMatches(accountId, { pageSize: 1000 })
        .then(response => {
          setErrorMessage('');
          setIsLoading(false);
          setData(response.data.body.records);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorMessage('Something went wrong, please try again.');
        });
    }
  }, [dispatch, accountId]);

  return (
    <FlexColumnWrapper>
      <BackButton text='Matches central' onClick={history.goBack} />
      <MyRecentMatches errorMessage={errorMessage} isLoading={isLoading} recentMatches={data} />
    </FlexColumnWrapper>
  );
};

export default AllRecentMatches;
