import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: ${colors.highlight3};
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const MobileMenuButton = styled.button`
  cursor: pointer;
  display: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: flex;
  }
`;
