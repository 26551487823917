import React from 'react';

import { Table } from '~/ui';

import { getMembersColumns, getMembersData } from './utils';

const MembersTable = ({ data, error, isLoading }) => {
  return (
    <Table
      columns={getMembersColumns()}
      data={getMembersData({ data })}
      error={error}
      isLoading={isLoading}
      itemsName='members'
    />
  );
};

export default MembersTable;
