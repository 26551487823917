export const MATCH_LOST = 'L';
export const MATCH_WON = 'W';

export const MATCH_TYPE = {
  COMPETITION: 'COMPETITION',
  DIRECT: 'DIRECT',
  OPEN: 'OPEN',
};

export const MATCH_STATUS = {
  CANCELED: 'CANCELED',
  CONFIRMED: 'CONFIRMED',
  GUEST_CHALLENGED_RESULT: 'GUEST_CHALLENGED_RESULT',
  GUEST_ENTERED_RESULT: 'GUEST_ENTERED_RESULT',
  GUEST_RECHALLENGED_RESULT: 'GUEST_RECHALLENGED_RESULT',
  HOST_CHALLENGED_RESULT: 'HOST_CHALLENGED_RESULT',
  HOST_ENTERED_RESULT: 'HOST_ENTERED_RESULT',
  HOST_RECHALLENGED_RESULT: 'HOST_RECHALLENGED_RESULT',
  PENDING_CANCEL_APPROVAL_FROM_GUEST: 'PENDING_CANCEL_APPROVAL_FROM_GUEST',
  PENDING_CANCEL_APPROVAL_FROM_HOST: 'PENDING_CANCEL_APPROVAL_FROM_HOST',
  PENDING_NEW_TIME_APPROVAL_FROM_GUEST: 'PENDING_NEW_TIME_APPROVAL_FROM_GUEST',
  PENDING_NEW_TIME_APPROVAL_FROM_HOST: 'PENDING_NEW_TIME_APPROVAL_FROM_HOST',
  PENDING_RESULT: 'PENDING_RESULT',
  SCHEDULED: 'SCHEDULED',
  UNRESOLVED: 'UNRESOLVED',
};

export const MATCH_INVITE_STATUS = {
  PENDING_OPPONENT: 'PENDING_OPPONENT',
  REJECTED: 'REJECTED',
  PENDING_CHALLENGER: 'PENDING_CHALLENGER',
  AGREED: 'AGREED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
};

export const MATCH_RESULT_STATUS = {
  PENDING_RESULT: 'PENDING_RESULT',
  HOST_ENTERED_RESULT: 'HOST_ENTERED_RESULT',
  HOST_RESULT_IS_CHALLENGED: 'HOST_RESULT_IS_CHALLENGED',
  HOST_ENTERED_RESULT_AFTER_CHALLENGE: 'HOST_ENTERED_RESULT_AFTER_CHALLENGE',
  GUEST_ENTERED_RESULT: 'GUEST_ENTERED_RESULT',
  GUEST_RESULT_IS_CHALLENGED: 'GUEST_RESULT_IS_CHALLENGED',
  GUEST_ENTERED_RESULT_AFTER_CHALLENGE: 'GUEST_ENTERED_RESULT_AFTER_CHALLENGE',
  REGISTERED_RESULT: 'REGISTERED_RESULT',
  UNRESOLVED_RESULT: 'UNRESOLVED_RESULT',
};
