import React, { useState } from 'react';

import ForgotPassword from './ForgotPassword';
import Login from './Login';

const LoginForm = () => {
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);

  return (
    <>
      {showForgotPasswordForm ? (
        <ForgotPassword setShowForgotPasswordForm={setShowForgotPasswordForm} />
      ) : (
        <Login setShowForgotPasswordForm={setShowForgotPasswordForm} />
      )}
    </>
  );
};

export default LoginForm;
