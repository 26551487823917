import styled from 'styled-components';

import theme from '../../theme';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  display: flex;
  gap: 12px;
  padding: 8px 12px;
  background-color: ${colors.highlight3};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  min-width: 360px;
  max-width: 360px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    max-width: 310px;
    min-width: 310px;
  }

  & .icon {
    min-width: 16px;
  }
`;

export const Title = styled.h5`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
  margin: 0;
`;

export const Message = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${colors.whiteOpacity._5};
`;
