import React from 'react';

import { Column, Grid, Row } from './styles';

const Container = ({ children, columns, maxWidth }) => {
  return (
    <Grid columns={columns} maxWidth={maxWidth}>
      {children}
    </Grid>
  );
};

export default {
  Container,
  Row,
  Column,
};
