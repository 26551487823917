import styled from 'styled-components';

import { theme } from '~/ui';

const { mediaQueries } = theme;

export const Matches = styled.div`
  display: inline-flex;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    vertical-align: middle;

    &:not(:first-child) {
      margin-right: 28px;
    }
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin-bottom: 8px;
  }
`;
