import React from 'react';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';

import ListItemsStatusFeedback from '../ListItemStatusFeedback';
import MatchItem from '../MatchItem/MatchItem';

const MyRecentMatches = ({ recentMatches = [], isLoading, errorMessage, title = 'Recent Matches', children }) => {
  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgFlag' iconColor='orange' title={title} />
      {recentMatches.length > 0 ? (
        recentMatches.map(({ match, opponent }) => <MatchItem key={match.id} match={match} opponent={opponent} />)
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='You do not have any recent matches.'
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isLoading}
        />
      )}
      {children}
    </WidgetWrapper>
  );
};

export default MyRecentMatches;
