import React from 'react';

import { COMPETITION_ELIGIBILITY_STATUS } from '~/enums/competition';

import { StatusContainer, StatusMessage, StatusTitle } from '../styles';

const TournamentIneligibleStatus = ({ eligibilityStatus }) => {
  if (eligibilityStatus !== COMPETITION_ELIGIBILITY_STATUS.INELIGIBLE) return null;

  return (
    <StatusContainer>
      <StatusTitle>You are ineligible to join this tournament.</StatusTitle>
      <StatusMessage>
        Based on the eligibility criteria listed above, you are unable to join this tournament.
      </StatusMessage>
    </StatusContainer>
  );
};

export default TournamentIneligibleStatus;
