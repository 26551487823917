import React from 'react';
import { useLocation } from 'react-router-dom';

import CommunityHeaderImage from '~/components/Admin/CommunityHeaderImage';

import { Container, InfoContainer, Notice, Title } from './styles';

const NotFoundPage = () => {
  const { pathname } = useLocation();

  return (
    <Container isAdmin={pathname.includes('admin')}>
      <CommunityHeaderImage />
      <InfoContainer>
        <Title>We couldn’t find that page.</Title>
        <Notice>
          Please make sure you have typed in the correct URL. If you think this is a mistake, please reach out to our{' '}
          <a href='mailto:support@tennismateapp.com'>support team</a>.
        </Notice>
      </InfoContainer>
    </Container>
  );
};

export default NotFoundPage;
