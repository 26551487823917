import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login } from '~/store/auth/utils';
import { Button, ErrorMessage, Input } from '~/ui';

import ForgotPasswordMessages from '../ForgotPasswordMessages';
import { ForgotPasswordButton, FormFooter } from '../styles';

const Login = ({ setShowForgotPasswordForm }) => {
  const { error } = useSelector(state => state.auth);
  const state = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleLoginSubmit = async e => {
    e.preventDefault();

    if (email === '' || password === '') return;

    const data = { email, password };
    dispatch(login(data));
  };

  return (
    <>
      {state.forgotPassword && <ForgotPasswordMessages />}
      <form onSubmit={handleLoginSubmit}>
        <Input
          error={error ? true : false}
          icon='mail'
          iconColor={error ? 'danger' : 'tmGreen'}
          label='Email address'
          type='email'
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          error={error ? true : false}
          icon='lock'
          iconColor={error ? 'danger' : 'tmGreen'}
          label='Password'
          rightIcon={isPasswordVisible ? 'eyeOff' : 'eye'}
          rightIconColor='ash'
          type={isPasswordVisible ? 'text' : 'password'}
          onChange={e => setPassword(e.target.value)}
          onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
        />
        <FormFooter>
          <div>{error && <ErrorMessage>{error.message}</ErrorMessage>}</div>
          <Button type='submit'>Log In</Button>
          <ForgotPasswordButton onClick={() => setShowForgotPasswordForm(true)}>Forgot Password?</ForgotPasswordButton>
        </FormFooter>
      </form>
    </>
  );
};

export default Login;
