import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  max-width: 495px;
  margin: 24px 0;
  padding: 0 16px;
  background: ${colors.highlight3};
`;

export const ValueContainer = styled.div`
  border-top: 1px solid ${colors.invert};
  padding: 12px 0;

  &:first-of-type {
    border: none;
  }
`;

export const Key = styled.span`
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const Value = styled.span`
  display: ${({ newLine }) => (newLine ? 'block' : 'inline')};
  margin: ${({ newLine }) => (newLine ? '12px 0 0' : '0 0 0 16px')};
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.92px;
  line-height: 16px;
  word-wrap: break-word;
`;
