import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const CommunityDropdownMenuItem = styled.div`
  border-bottom: 1px solid ${colors.invert};
  padding: 12px 16px;
  display: flex;
  flex-direction: column;

  &:hover span:first-child {
    color: ${colors.tmGreen};
  }

  &:last-child {
    border-bottom: none;
  }

  & span:first-child {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: ${colors.white};
  }

  & span:last-child {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: ${colors.ash2};
  }
`;
