import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  max-width: ${({ communityCreated }) => (communityCreated ? '570px' : '357px')};
  position: relative;
  top: ${({ communityCreated }) => (communityCreated ? '-30px' : '-80px')};

  > div {
    margin-top: 20px;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    max-width: 100%;
    top: 32px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 32px;
  color: ${colors.white};
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
`;

export const CreatedCommunityMessageTitle = styled.h3`
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;

export const CreatedCommunityMessage = styled.p`
  margin: 20px 0;
  color: ${colors.white};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`;

export const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
  outline: none;

  &:first-of-type {
    margin-right: 16px;
  }

  .icon {
    display: inline-block;
    margin-right: 12px;
    vertical-align: sub;
  }
`;
