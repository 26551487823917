import React, { forwardRef } from 'react';

import Icon from '../Icon';
import { Container, InputContainer, Label, NewStyleContainer, NewStyleInputContainer, Optional } from './styles';

const Input = forwardRef(
  (
    {
      icon,
      iconColor,
      leftIconSize = 's',
      rightIcon,
      rightIconSize = 's',
      rightIconColor,
      label,
      onChange,
      optional,
      placeholder,
      onRightIconClick,
      isNewStyle = false,
      ...props
    },
    ref
  ) => {
    if (isNewStyle)
      return (
        <NewStyleContainer leftIconSize={leftIconSize}>
          <Icon color={iconColor} icon={icon} size={leftIconSize} />
          <NewStyleInputContainer placeholder={placeholder} ref={ref} onChange={onChange} {...props} />
          <Icon color={iconColor} icon='chevron2Down' size='s' />
        </NewStyleContainer>
      );

    return (
      <Container label={label}>
        {label && (
          <Label>
            {label}
            {optional && <Optional>optional</Optional>}
          </Label>
        )}
        {icon && <Icon color={iconColor} icon={icon} />}
        <InputContainer
          icon={icon}
          placeholder={placeholder}
          ref={ref}
          rightIcon={rightIcon}
          onChange={onChange}
          {...props}
        />
        {rightIcon && (
          <Icon
            color={rightIconColor}
            cursor='pointer'
            icon={rightIcon}
            size={rightIconSize}
            onClick={onRightIconClick}
          />
        )}
      </Container>
    );
  }
);

export default Input;
