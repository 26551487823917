import { getUserProfileRequest, setupUserProfileRequest } from '~/api';
import { history } from '~/index';
import { renameUserProperties } from '~/utils';

import { userIsProfileCompleted, userLoginError } from '../auth/slice';
import { logout } from '../auth/utils';
import { isLoading } from '../general/slice';
import { userProfile, userProfileError } from './slice';

export const setupUserProfile = (accountId, data) => async dispatch => {
  try {
    dispatch(isLoading(true));
    await setupUserProfileRequest(accountId, data);
    localStorage.setItem('profileCompleted', true);
    dispatch(userIsProfileCompleted(true));

    dispatch(getUserProfile(accountId));
    dispatch(isLoading(false));
  } catch (error) {
    const payload = {
      message: 'Something went wrong. Please try again.',
    };

    dispatch(userProfileError(payload));
  }
};

export const getUserProfile = accountId => async dispatch => {
  try {
    dispatch(isLoading(true));
    const response = await getUserProfileRequest(accountId);
    const userProfileData = response.data.body;

    const renamedProperties = await renameUserProperties(userProfileData);

    dispatch(userProfile(renamedProperties));
    dispatch(isLoading(false));
  } catch (error) {
    const payload = {
      message: "Can't retrieve profile data. Please try again.",
    };

    dispatch(userProfileError());
    dispatch(logout());
    // set `isAuthenticated` to false if frontend can't get the profile data from the backend
    dispatch(userLoginError(payload));
    history.push('/login');
  }
};
