import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { leaveCommunity } from '~/api/communities';
import { ErrorMessage, ModalContent } from '~/components/shared/index';
import { ANALYTICS_EVENTS } from '~/enums';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMyCommunities } from '~/store/communities/utils';
import { Button } from '~/ui';
import { trackAnalyticsEvent } from '~/utils';

const LeaveCommunityModal = ({ onClose, communityName }) => {
  const [isLeaving, setIsLeaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const { accountId } = useSelector(state => state.auth);
  const { communityId } = useParams();

  const onLeaveClick = () => {
    setIsLeaving(true);

    leaveCommunity(communityId, accountId)
      .then(() => {
        setIsLeaving(false);
        dispatch(getMyCommunities());
        trackAnalyticsEvent(ANALYTICS_EVENTS.COMMUNITY_LEFT);
        history.push('/home');
      })
      .catch(() => {
        setIsLeaving(false);
        setErrorMessage('Something went wrong. Please try again.');
      });
  };

  return (
    <ModalContent title='Leave community' onClose={onClose}>
      <InfoMessage>
        Are you sure you want to leave {communityName}? If you leave you won't be able to participate in leagues and
        tournaments organized by this community.
      </InfoMessage>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button color='danger' disabled={isLeaving} isNewStyle onClick={onLeaveClick}>
          {isLeaving ? 'Leaving...' : ' Yes, leave'}
        </Button>
        <Button color='white' disabled={isLeaving} isNewStyle onClick={onClose}>
          No
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default LeaveCommunityModal;
