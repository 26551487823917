import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { requestCommunityJoin } from '~/api';
import { getCommunityScreen } from '~/store/communities/utils';
import { Button, ErrorMessage } from '~/ui';

const RequestCommunityJoinButton = ({ communityId, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const requestJoin = useCallback(async () => {
    try {
      setIsLoading(true);
      await requestCommunityJoin(communityId);
      setIsLoading(false);
      dispatch(getCommunityScreen(communityId));
    } catch (e) {
      setIsLoading(false);
      setErrorMessage('There was an error with requesting to join this community. Please try again.');
    }
  }, [communityId, dispatch]);

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  return (
    <Button disabled={isLoading} isNewStyle onClick={requestJoin}>
      {isLoading ? 'Sending...' : children}
    </Button>
  );
};

export default RequestCommunityJoinButton;
