import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TopNavigation from '~/components/shared/TopNavigation/index';
import { UserRoutes } from '~/routes';
import { getMyCommunities } from '~/store/communities/utils';
import { Spinner } from '~/ui/index';

import { ContentContainer } from '../shared/styles';

const UserPage = ({ match }) => {
  const dispatch = useDispatch();
  const parentRoute = match.path;

  const { allUserCommunitiesLoading } = useSelector(state => state.communities);

  useEffect(() => {
    dispatch(getMyCommunities());
  }, [dispatch]);

  if (allUserCommunitiesLoading) {
    return <Spinner />;
  }

  return (
    <>
      <TopNavigation />
      <ContentContainer>
        <UserRoutes parentRoute={parentRoute} />
      </ContentContainer>
    </>
  );
};

export default UserPage;
