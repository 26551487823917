import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FlexColumnWrapper } from '~/components/shared/styles';
import { getHomeScreen } from '~/store/home/utils';
import { Button, Icon } from '~/ui';

import Header from '../shared/Header';
import MyChallenges from '../shared/MyChallenges';
import MyRecentMatches from '../shared/MyRecentMatches/index';
import MyUpcomingMatches from '../shared/MyUpcomingMatches/index';
import MyCompetitions from './MyCompetitions';

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, isLoading, errorMessage } = useSelector(state => state.homeScreen);

  useEffect(() => {
    dispatch(getHomeScreen());
  }, [dispatch]);

  const { myChallenges, recentMatches, upcomingMatches } = data;

  return (
    <FlexColumnWrapper>
      <Header icon='home2' title='Home'>
        <Button isNewStyle onClick={() => history.push('/match/find-match')}>
          <Icon color='tmGreen' icon='search2' size='s' />
          Find a Match
        </Button>
        <Button isNewStyle onClick={() => history.push('/match/new-match')}>
          <Icon color='tmGreen' icon='plus2' size='s' />
          New Match
        </Button>
      </Header>
      <MyCompetitions />
      <MyChallenges errorMessage={errorMessage} isLoading={isLoading} myChallenges={myChallenges} />
      <MyUpcomingMatches errorMessage={errorMessage} isLoading={isLoading} upcomingMatches={upcomingMatches} />
      <MyRecentMatches
        errorMessage={errorMessage}
        isLoading={isLoading}
        recentMatches={recentMatches}
        title='Incomplete Matches'
      />
    </FlexColumnWrapper>
  );
};

export default Home;
