import { useEffect } from 'react';

const useKeyPress = (key, callback) => {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === key) callback();
    }

    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [key, callback]);
};

export const useEnter = callback => useKeyPress('Enter', callback);
