import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    isLoadingInitialState: () => {
      return initialState;
    },
  },
});

export const { isLoading, isLoadingInitialState } = generalSlice.actions;

export default generalSlice.reducer;
