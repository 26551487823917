import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button';
import Icon from '../Icon';
import PageTitle from '../PageTitle';
import { PageHeaderContainer } from './styles';

const PageHeader = ({ buttonIcon, buttonOnClick, buttonText, link, title }) => {
  const ButtonElement = () => {
    if (link) {
      return (
        <Link to={link}>
          <Button onClick={buttonOnClick}>
            <Icon color='tmGreen' cursor='pointer' icon={buttonIcon} />
            {buttonText}
          </Button>
        </Link>
      );
    } else {
      return (
        <Button>
          <Icon color='tmGreen' cursor='pointer' icon={buttonIcon} />
          {buttonText}
        </Button>
      );
    }
  };

  return (
    <PageHeaderContainer>
      <PageTitle title={title} />
      {buttonText && <ButtonElement />}
    </PageHeaderContainer>
  );
};

export default PageHeader;
