import { TennisMateApi } from '~/services';

export const getNewsByIdRequest = newsId => {
  return TennisMateApi.get(`/v1/news/${newsId}`);
};

export const updateNewsByIdRequest = async (newsId, data) => {
  return await TennisMateApi.put(`/admin/v1/news/${newsId}/update`, data);
};

export const deleteNewsByIdRequest = async newsId => {
  return await TennisMateApi.delete(`/admin/v1/news/${newsId}/delete`);
};

export const deleteNewsIdImageRequest = async newsId => {
  return await TennisMateApi.delete(`/admin/v1/news/${newsId}/delete-image`);
};

export const getCommunityNewsRequest = (communityId, pageSize) => {
  return TennisMateApi.get(`/v1/news/communities/${communityId}?pageSize=${pageSize}`);
};

export const createCommunityNewsRequest = async news => {
  return await TennisMateApi.post('/admin/v1/community/create-news', news);
};
