import 'react-input-range/lib/css/index.css';

import React from 'react';
import InputRange from 'react-input-range';

import { Container, Label, NewStyleLabel } from './styles';

const CustomInputRange = ({ label, minValue, maxValue, onChange, step, valueLabel, value, isNewStyle = false }) => {
  if (isNewStyle) {
    return (
      <Container>
        {label && <NewStyleLabel>{label}</NewStyleLabel>}
        <InputRange
          formatLabel={valueLabel}
          maxValue={maxValue}
          minValue={minValue}
          step={step}
          value={value}
          onChange={onChange}
        />
      </Container>
    );
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <InputRange
        formatLabel={valueLabel}
        maxValue={maxValue}
        minValue={minValue}
        step={step}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

export default CustomInputRange;
