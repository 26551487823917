import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { acceptMatchResult, rejectMatchResult } from '~/api/matches';
import ResultInputs from '~/components/Match/ResultInputs/index';
import ModalContent from '~/components/shared/ModalContent/index';
import { ErrorMessage } from '~/components/shared/styles';
import { ANALYTICS_EVENTS } from '~/enums';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button } from '~/ui/index';
import { trackAnalyticsEvent } from '~/utils';

const ConfirmOrRejectResultsModal = ({ onClose }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { matchId } = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector(state => state.matchScreen);
  const { accountId } = useSelector(state => state.auth);

  const { host, guest, hostResult, guestResult } = data;

  const hostScores = useMemo(() => {
    if (!hostResult) return null;
    const { matchResultSetScoreList } = hostResult;

    return matchResultSetScoreList.map(item => ({ hostScore: item.gamesWonByHost, guestScore: item.gamesWonByGuest }));
  }, [hostResult]);

  const guestScores = useMemo(() => {
    if (!guestResult) return null;
    const { matchResultSetScoreList } = guestResult;

    return matchResultSetScoreList.map(item => ({ hostScore: item.gamesWonByHost, guestScore: item.gamesWonByGuest }));
  }, [guestResult]);

  if (!hostScores || !guestScores) return null;

  const onConfirmResults = () => {
    setIsConfirming(true);
    setErrorMessage('');

    acceptMatchResult(matchId)
      .then(() => {
        setIsConfirming(false);
        trackAnalyticsEvent(ANALYTICS_EVENTS.DIRECT_MATCH_COMPLETED);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsConfirming(false);
      });
  };

  const onRejectResults = () => {
    setIsRejecting(true);
    setErrorMessage('');

    rejectMatchResult(matchId, {})
      .then(() => {
        setIsRejecting(false);
        trackAnalyticsEvent(ANALYTICS_EVENTS.MATCH_UNRESOLVED);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsRejecting(false);
      });
  };

  return (
    <ModalContent title='Confirm or Reject Result' onClose={onClose}>
      <InfoMessage>
        Your opponent has challenged the results you have previously submitted. You can either confirm the results or
        reject them. Rejecting them will result in this match being cancelled.
      </InfoMessage>
      <ResultInputs
        disabled
        guest={guest}
        host={host}
        label={accountId === host.accountId ? 'Your results' : 'Opponent Results'}
        numberOfSets={hostScores.length}
        scores={hostScores}
      />
      <ResultInputs
        disabled
        guest={guest}
        host={host}
        label={accountId === guest.accountId ? 'Your results' : 'Opponent Results'}
        numberOfSets={guestScores.length}
        scores={guestScores}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button color='danger' isNewStyle onClick={onRejectResults}>
          {isRejecting ? 'Rejecting...' : 'Reject Results'}
        </Button>
        <Button color='white' isNewStyle onClick={onConfirmResults}>
          {isConfirming ? 'Confirming...' : 'Confirm Results'}
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ConfirmOrRejectResultsModal;
