import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  width: ${({ uploadFileName }) => (uploadFileName ? 'min-content' : 'auto')};
`;

export const AddedFile = styled.div`
  display: flex;
  margin-top: 12px;
  color: ${colors.whiteOpacity._75};
  align-items: center;

  span:nth-of-type(2) {
    cursor: pointer;
  }
`;

export const FileName = styled.p`
  margin: 0 20px 0 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  word-break: break-all;
`;
