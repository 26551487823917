import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const ShowSelectedPlayersButton = styled(Button)`
  width: max-content;
  height: 40px;
  border: ${({ active }) => (active ? `1px solid ${colors.tmGreen}` : 'none')};
  margin-top: 12px;
  padding: 10px 16px;
  color: ${({ active }) => (active ? colors.tmGreen : colors.ash)};

  .icon {
    margin-left: 0;
  }
`;

export const AddPlayersButton = styled(Button)`
  display: flex;
  width: max-content;
  align-self: flex-end;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    align-self: flex-start;
  }
`;

export const SearchAndFilterContainer = styled.div`
  > div {
    input,
    > button {
      background: ${colors.highlight2};
    }

    > button {
      padding: 10px 16px;
    }
  }
`;
