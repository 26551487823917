import styled from 'styled-components';

import { Dropdown, theme } from '~/ui';

const { colors } = theme;

export const FilterRowTitle = styled.p`
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;

  &:nth-of-type(1) {
    margin: 32px 0 0;
  }

  &:nth-of-type(2) {
    margin: 12px 0;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  margin-bottom: 16px;
`;
