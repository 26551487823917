import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

const smallIconCss = css`
  left: 11px;
  top: 12px;
`;

const mediumIconCss = css`
  left: 10px;
  top: 8px;
`;

export const NewStyleContainer = styled.div`
  margin: 0;
  position: relative;
  box-shadow: 0px 2px 1px rgba(18, 22, 39, 0.02);

  & .icon:first-child {
    position absolute;
    ${({ leftIconSize }) => (leftIconSize === 's' ? smallIconCss : mediumIconCss)};
  }

  & .icon:last-child {
    position absolute;
    right: 14px;
    top: 12px;
  }
`;

export const NewStyleInputContainer = styled.input`
  border: 1px solid ${({ error }) => (error ? colors.danger : colors.ash3)};
  padding: 0 40px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  color: ${colors.white};
  background-color: ${colors.highlight3};
  font-size: 14px;
  line-height: 24px;
  outline: none;
  box-sizing: border-box;

  &[type='search'] {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &[type='date'] {
    -webkit-appearance: none;

    &::-webkit-calendar-picker-indicator {
      width: 45px;
      position: absolute;
      left: -30px;
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    background-color: ${colors.highlight3};
  }

  &::placeholder {
    color: ${colors.whiteOpacity._25};
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 12px 0;
  position: relative;

  .icon {
    position: absolute;
    left: 10px;
    top: ${({ label }) => (label ? '30px' : '6px')};
  }

  .icon:last-child {
    right: 10px;
    left: unset;
    top: 35px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: ${colors.whiteOpacity._75};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const InputContainer = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid ${({ error }) => (error ? colors.danger : colors.invert)};
  padding-left: ${({ icon }) => (icon ? '45px' : '16px')};
  padding-right: ${({ rightIcon }) => (rightIcon ? '45px' : '16px')};
  color: ${colors.whiteOpacity._75};
  background-color: ${colors.highlight2};
  font-size: 16px;
  line-height: 24px;
  outline: none;
  box-sizing: border-box;

  &[type='search'] {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &[type='date'] {
    -webkit-appearance: none;

    &::-webkit-calendar-picker-indicator {
      width: 45px;
      position: absolute;
      left: -30px;
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    background-color: ${colors.highlight3};
  }
`;

export const Optional = styled.span`
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  text-transform: lowercase;
  float: right;
  line-height: 16px;
  letter-spacing: 0.92px;
`;
