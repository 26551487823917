import React from 'react';
import { Switch } from 'react-router-dom';

import NotFoundPage from '~/pages/NotFound';
import TournamentTree from '~/pages/Tournament/TournamentTree';
import { ProtectedRoute, withSpinner } from '~/ui';

const TournametTreeRoutes = ({ parentRoute }) => {
  return (
    <Switch>
      <ProtectedRoute component={TournamentTree} exact path={`${parentRoute}/:tournamentId`} />
      <ProtectedRoute component={NotFoundPage} path={`${parentRoute}/*`} />
    </Switch>
  );
};

export default withSpinner(TournametTreeRoutes);
