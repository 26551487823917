import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const FormFooter = styled.div`
  width: 100%;

  > button,
  > p {
    display: inline-block;
    width: 50%;
  }
`;

export const ForgotPasswordButton = styled.p`
  display: inline-block;
  margin: 8px 0px;
  color: ${colors.tmGreen};
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
`;

export const BackButton = styled(ForgotPasswordButton)`
  margin-bottom: 15px;
  margin-left: 32px;

  .icon {
    display: inline-block;
    margin-right: 12px;
    vertical-align: bottom;
  }
`;

export const Notice = styled.p`
  margin: 0 32px 24px;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  line-height: 24px;
`;
