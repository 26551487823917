import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AsyncLocationDropdown from '~/components/shared/AsyncLocationDropdown';
import { COMPETITION_TYPE, GENDER } from '~/enums';
import { Button, DatePicker, Dropdown, ErrorMessage, Grid, Icon, Input, InputRange, Textarea, TimePicker } from '~/ui';
import {
  fromUnixTime,
  getSkillLevelNumber,
  getTimeString,
  getUnderscoredSkillLevel,
  getUnixTime,
  updateDateTime,
} from '~/utils';

import { Form, SectionTitle } from './styles';

const genders = [
  { gender: 'BOTH', prettyName: 'Both' },
  { gender: 'MALE', prettyName: 'Male' },
  { gender: 'FEMALE', prettyName: 'Female' },
];

const CompetitionForm = ({ competitionError, competitionType, formSubmit, partialEdit }) => {
  const { id: communityId, court: communityCourt } = useSelector(
    state => state.communities.selectedCommunity.communityInfo
  );

  const league = useSelector(state => state.leagues.selectedLeague);
  const tournament = useSelector(state => state.tournaments.selectedTournament);
  const competition = competitionType === COMPETITION_TYPE.LEAGUE ? league : tournament;

  const [adminEmail, setAdminEmail] = useState(competition.adminEmail);

  const [ageRange, setAgeRange] = useState({
    min: competition.ageFrom,
    max: competition.ageTo,
  });

  const [court, setCourt] = useState(competition.court.name ? competition.court : communityCourt);

  const [description, setDescription] = useState(competition.additionalDescription);

  const [gender, setGender] = useState(
    genders.filter(objectGender => {
      if (!competition.gender) {
        return objectGender.gender === GENDER.BOTH;
      } else {
        return objectGender.gender === competition.gender;
      }
    })
  );

  const [name, setName] = useState(competition.name);

  const [playingStartDate, setPlayingStartDate] = useState(fromUnixTime(competition.startTime));
  const [playingEndDate, setPlayingEndDate] = useState(fromUnixTime(competition.endTime));
  const [playingStartTime, setPlayingStartTime] = useState(() => {
    if (competition.startTime) {
      return getTimeString(new Date(fromUnixTime(competition.startTime)));
    }

    return '';
  });
  const [playingEndTime, setPlayingEndTime] = useState(() => {
    if (competition.endTime) {
      return getTimeString(fromUnixTime(competition.endTime));
    }

    return '';
  });

  const [registerStartDate, setRegisterStartDate] = useState(fromUnixTime(competition.registerStartTime));
  const [registerEndDate, setRegisterEndDate] = useState(fromUnixTime(competition.registerEndTime));
  const [registerStartTime, setRegisterStartTime] = useState(() => {
    if (competition.registerStartTime) {
      return getTimeString(new Date(fromUnixTime(competition.registerStartTime)));
    }

    return '';
  });
  const [registerEndTime, setRegisterEndTime] = useState(() => {
    if (competition.registerEndTime) {
      return getTimeString(new Date(fromUnixTime(competition.registerEndTime)));
    }

    return '';
  });

  const [skillLevelRange, setSkillLevelRange] = useState({
    min: getSkillLevelNumber(competition.skillLevelFrom.prettyName),
    max: getSkillLevelNumber(competition.skillLevelTo.prettyName),
  });

  const handleFormSubmit = e => {
    e.preventDefault();

    const skillLevelFrom = getUnderscoredSkillLevel(skillLevelRange.min);
    const skillLevelTo = getUnderscoredSkillLevel(skillLevelRange.max);

    const regStartTime = updateDateTime(registerStartDate, registerStartTime);
    const regEndTime = updateDateTime(registerEndDate, registerEndTime);
    const playStartTime = updateDateTime(playingStartDate, playingStartTime);
    const playEndTime = updateDateTime(playingEndDate, playingEndTime);

    const data = {
      id: competition ? competition.id : null,
      communityId,
      name,
      registerStartTime: getUnixTime(regStartTime),
      registerEndTime: getUnixTime(regEndTime),
      startTime: getUnixTime(playStartTime),
      endTime: getUnixTime(playEndTime),
      court,
      skillLevelFrom,
      skillLevelTo,
      ageFrom: ageRange.min,
      ageTo: ageRange.max,
      adminEmail,
      additionalDescription: description,
      gender: gender === null || gender.gender === GENDER.BOTH ? null : gender.gender,
    };

    formSubmit(data);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Input label={`${competitionType} name`} type='text' value={name} onChange={e => setName(e.target.value)} />
      <Input label='Admin email' type='email' value={adminEmail} onChange={e => setAdminEmail(e.target.value)} />
      {partialEdit ? (
        <DatePicker
          label={`${competitionType} end date`}
          minDate={new Date(playingStartDate)}
          value={playingEndDate}
          onChange={value => setPlayingEndDate(value)}
        />
      ) : (
        <Grid.Container>
          <SectionTitle>Registration from:</SectionTitle>
          <Grid.Row>
            <Grid.Column columns={2}>
              <DatePicker
                minDate={new Date()}
                required
                value={registerStartDate}
                onChange={value => setRegisterStartDate(value)}
              />
            </Grid.Column>
            <Grid.Column columns={2}>
              <TimePicker format='HH:mm' value={registerStartTime} onChange={v => setRegisterStartTime(v)} />
            </Grid.Column>
          </Grid.Row>
          <SectionTitle>Registration to:</SectionTitle>
          <Grid.Row>
            <Grid.Column columns={2}>
              <DatePicker
                minDate={new Date(registerStartDate)}
                required
                value={registerEndDate}
                onChange={value => setRegisterEndDate(value)}
              />
            </Grid.Column>
            <Grid.Column columns={2}>
              <TimePicker format='HH:mm' value={registerEndTime} onChange={v => setRegisterEndTime(v)} />
            </Grid.Column>
          </Grid.Row>
          <SectionTitle>Playing from:</SectionTitle>
          <Grid.Row>
            <Grid.Column columns={2}>
              <DatePicker
                minDate={new Date(new Date(registerEndDate))}
                required
                value={playingStartDate}
                onChange={value => setPlayingStartDate(value)}
              />
            </Grid.Column>
            <Grid.Column columns={2}>
              <TimePicker format='HH:mm' value={playingStartTime} onChange={v => setPlayingStartTime(v)} />
            </Grid.Column>
          </Grid.Row>
          <SectionTitle>Playing to:</SectionTitle>
          <Grid.Row>
            <Grid.Column columns={2}>
              <DatePicker
                minDate={new Date(playingStartDate)}
                required
                value={playingEndDate}
                onChange={value => setPlayingEndDate(value)}
              />
            </Grid.Column>
            <Grid.Column columns={2}>
              <TimePicker format='HH:mm' value={playingEndTime} onChange={v => setPlayingEndTime(v)} />
            </Grid.Column>
          </Grid.Row>
        </Grid.Container>
      )}
      {competitionType === COMPETITION_TYPE.LEAGUE && (
        <AsyncLocationDropdown
          label='Court address'
          placeholder='Search courts...'
          type='courts'
          value={court}
          onChange={court => setCourt(court)}
        />
      )}
      {!partialEdit && (
        <>
          <Dropdown
            getOptionLabel={gender => gender.prettyName}
            getOptionValue={gender => gender.gender}
            label='Participant gender'
            options={genders}
            placeholder='Select gender'
            value={gender}
            onChange={value => setGender(value)}
          />
          <InputRange
            label='Participant age'
            maxValue={72}
            minValue={16}
            value={ageRange}
            valueLabel={value => value}
            onChange={value => setAgeRange(value)}
          />
          <InputRange
            label='Skill Level'
            maxValue={7.0}
            minValue={1.0}
            step={0.5}
            value={skillLevelRange}
            valueLabel={value => parseFloat(value).toFixed(1)}
            onChange={value => setSkillLevelRange(value)}
          />
        </>
      )}
      <Textarea
        label='Rules'
        placeholder={`Describe your ${competitionType} in a couple of sentences. Write some rules, short info etc.`}
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      {competitionError && <ErrorMessage>{competitionError}</ErrorMessage>}
      <Button
        disabled={
          !name ||
          !adminEmail ||
          !registerStartDate ||
          !registerEndDate ||
          !registerStartTime ||
          !registerEndTime ||
          !playingStartDate ||
          !playingEndDate ||
          !playingStartTime ||
          !playingEndTime
        }
        fullWidth
      >
        {competition.id === 0 ? (
          `Create a ${competitionType}`
        ) : (
          <>
            <Icon color='tmGreen' cursor='pointer' icon='edit' />
            Edit {competitionType}
          </>
        )}
      </Button>
    </Form>
  );
};

export default CompetitionForm;
