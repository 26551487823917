import React from 'react';

import { WidgetItem } from '~/components/shared';
import { Icon } from '~/ui';

import { Text } from './styles';

const SeeAll = ({ text, onClick }) => {
  return (
    <WidgetItem onClick={onClick}>
      <Text>{text}</Text>
      <Icon color='ash3' icon='arrowLeft' />
    </WidgetItem>
  );
};

export default SeeAll;
