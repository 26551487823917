import styled from 'styled-components';

import { theme } from '~/ui';

const { mediaQueries } = theme;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 112px;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    padding-top: 0;
  }
`;
