import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from '~/ui';

import CommunityMenuItem from '../shared/CommunityMenuItem';
import { Backdrop, JoinCommunity, MenuItem, MobileLogo, SideMenu } from './styles';

const MobileMenu = ({ setIsOpen }) => {
  const [isCommunityDropdownOpen, setIsCommunityDropdownOpen] = useState(false);
  const { allUserCommunities } = useSelector(state => state.communities);
  const history = useHistory();

  const closeModal = () => {
    setIsOpen(false);
    setIsCommunityDropdownOpen(false);
  };

  const handleSelectCommunity = communityId => {
    history.push(`/home/community/${communityId}`);
    closeModal();
  };

  const handleJoinCommunity = () => {
    history.push('/home/join-community');
    closeModal();
  };

  return (
    <Backdrop onClick={closeModal}>
      <SideMenu onClick={e => e.stopPropagation()}>
        <MenuItem>
          <MobileLogo />
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeModal();
            history.push('/home');
          }}
        >
          <Icon icon='home2' />
          <span>Home</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeModal();
            history.push('/home/matches-central');
          }}
        >
          <Icon icon='match' />
          <span>Matches</span>
        </MenuItem>
        <MenuItem onClick={() => setIsCommunityDropdownOpen(!isCommunityDropdownOpen)}>
          <Icon icon='multipleUsers2' />
          <span>Communities</span>
          <Icon icon={isCommunityDropdownOpen ? 'chevron2Up' : 'chevron2Down'} size='s' />
        </MenuItem>
        {isCommunityDropdownOpen && (
          <>
            {allUserCommunities.map(({ communityInfo: { id, communityName, members } }) => (
              <CommunityMenuItem
                communityName={communityName}
                key={id}
                numberOfMembers={members.length}
                onClick={() => handleSelectCommunity(id)}
              />
            ))}
            <JoinCommunity onClick={handleJoinCommunity}>
              <Icon icon='search2' />
              <span className='green-text'>Join a Community</span>
            </JoinCommunity>
          </>
        )}
        {/* TODO: Commented out since this is not implemented yet */}
        {/* <MenuItem
          onClick={() => {
            closeModal();
            history.push('/mates');
          }}
        >
          <Icon icon='user2' />
          <span>Mates</span>
        </MenuItem> */}
      </SideMenu>
    </Backdrop>
  );
};

export default MobileMenu;
