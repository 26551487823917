import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const PageWrapper = styled.div`
  padding-top: 220px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Hero = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  height: 320px;
  width: 100%;
  z-index: -1;
  background: ${({ bgUrl }) => `linear-gradient(180deg, rgba(40, 40, 40, 0) 0%, #282828 100%), url(${bgUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ShowAllText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.tmGreen};

  & + .icon {
    transform: rotate(180deg);
  }
`;
