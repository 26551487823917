import React, { useState } from 'react';

import { searchUsers } from '~/api';
import { ModalContent } from '~/components/shared';
import ListItemsStatusFeedback from '~/pages/User/shared/ListItemStatusFeedback/index';
import { Search } from '~/ui';

import UserList from './UserList';

const PickOpponent = ({ onClose, onSelect }) => {
  const [users, setUsers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSearchUsers = async searchTerm => {
    setIsSearching(true);

    try {
      const response = await searchUsers({ search: searchTerm });
      const foundUsers = response.data.body.records;

      setUsers(foundUsers);
    } catch (e) {
      setErrorMessage('Something went wrong. Please try again');
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <ModalContent title='Pick Opponent' onClose={onClose}>
      <Search autoFocus isNewStyle onSearch={onSearchUsers} />
      {users.length > 0 ? (
        <UserList users={users} onSelect={onSelect} />
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='There are no users that match your search criteria.'
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isSearching}
          style={{ background: 'transparent' }}
        />
      )}
    </ModalContent>
  );
};

export default PickOpponent;
