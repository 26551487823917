import queryString from 'query-string';

import { TennisMateApi } from '~/services';
import { renameUserProperties } from '~/utils';

export const getUserProfileRequest = accountId => {
  return TennisMateApi.get(`/account/${accountId}/profile`);
};

export const getUserProfilesByIds = async (ids = []) => {
  return await Promise.all(
    ids.map(async accountId => {
      const response = await getUserProfileRequest(accountId);
      const userProfileData = response.data.body;
      const renamedProperties = await renameUserProperties(userProfileData);

      return renamedProperties;
    })
  );
};

export const getUserStatusRequest = accountId => {
  return TennisMateApi.get(`/users/${accountId}/status`);
};

export const setupUserProfileRequest = (accountId, data) => {
  return TennisMateApi.put(`/users/${accountId}/profile`, data);
};

export const uploadUserAvatarRequest = (accountId, data) => {
  return TennisMateApi.post(`/users/${accountId}/upload-avatar`, data);
};

export const searchUsers = params => {
  const query = queryString.stringify(params);

  return TennisMateApi.get(`/users?${query}`);
};
