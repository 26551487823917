import React from 'react';

import { CellWithIcon } from './styles';

const TableCellWithIcon = ({ hideOnMobile, children, style }) => {
  return (
    <CellWithIcon hideOnMobile={hideOnMobile} style={style}>
      {children}
    </CellWithIcon>
  );
};

export default TableCellWithIcon;
