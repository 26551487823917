import React from 'react';

import { MatchRoutes } from '~/routes/index';

const MatchPage = ({ match }) => {
  const parentRoute = match.path;

  return <MatchRoutes parentRoute={parentRoute} />;
};

export default MatchPage;
