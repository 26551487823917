import React from 'react';
import toast from 'react-hot-toast';

import { Icon } from '../index';
import { Container, Message, Title } from './styles';

const Toast = ({ title, message, icon = 'check2', onClose = () => {} }) => {
  return (
    <Container>
      <Icon color='tmGreen' icon={icon} />
      <div>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </div>
      <Icon color='ash' cursor='pointer' icon='close2' size='xs' onClick={onClose} />
    </Container>
  );
};

export const showToast = ({ title, message, icon = 'check2', options }) => {
  return toast(t => <Toast icon={icon} message={message} title={title} onClose={() => toast.dismiss(t.id)} />, {
    ...options,
    duration: 5000,
    position: 'bottom-right',
    style: {
      background: 'transparent',
      maxWidth: 'none',
      padding: '0',
      borderRadius: '0',
      display: 'block',
      boxShadow: 'none',
    },
  });
};

export default Toast;
