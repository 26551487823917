import React from 'react';
import { useSelector } from 'react-redux';

import Avatar from '~/components/shared/Avatar';
import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';
import ListItemsStatusFeedback from '~/pages/User/shared/ListItemStatusFeedback/index';

import { UserInfo, UserName } from '../styles';
import { Container, Message } from './styles';

const Messages = () => {
  const { data } = useSelector(state => state.matchScreen);

  const { host, guest, messages = [] } = data;

  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgMessage' iconColor='blue' title='Messages'></WidgetHeader>
      {messages.length > 0 ? (
        messages.map(({ authorId, message, id }) => {
          const isHostSender = authorId === host.accountId;
          const sender = isHostSender ? host : guest;

          return (
            <WidgetItem key={authorId + message}>
              <Container isReversed={!isHostSender}>
                <UserInfo isReversed={!isHostSender}>
                  <Avatar alt={sender.firstName} size='s' src={sender.avatarUrl} />
                  <UserName>
                    {sender.firstName} {sender.lastName}
                  </UserName>
                </UserInfo>
                <Message>{message}</Message>
              </Container>
            </WidgetItem>
          );
        })
      ) : (
        <ListItemsStatusFeedback emptyListMessage='There are no messages for this match.' />
      )}
    </WidgetWrapper>
  );
};

export default Messages;
