import React, { useEffect, useState } from 'react';

import { getUserProfileRequest } from '~/api';
import { Avatar, ErrorMessage, ModalContent } from '~/components/shared';
import { Spinner } from '~/ui';
import { getPcrBalls, getUserMatchesStatistics, renameUserProperties } from '~/utils';

import {
  InfoBlock,
  OpponentName,
  ProfileWrapper,
  RecentResultCircle,
  StatisticsContainer,
  StatisticsItem,
} from './styles';

const UserInfoModal = ({ userId, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [opponent, setOpponent] = useState(null);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserProfileRequest(userId)
        .then(res => {
          setIsLoading(false);
          const renamedUserProperties = [res.data.body].map(user => renameUserProperties(user));
          setOpponent(renamedUserProperties[0]);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorMessage('Something went wrong. Please try again.');
        });
    }
  }, [userId]);

  const { lostMatches, totalMatches, wonMatches, winRatio } = getUserMatchesStatistics(opponent);

  if (isLoading) {
    return <Spinner />;
  }

  if (!opponent || errorMessage) {
    return <ErrorMessage>{errorMessage || 'Opponent not found!'}</ErrorMessage>;
  }

  return (
    <ModalContent title='Opponent Info' onClose={onClose}>
      <ProfileWrapper>
        <Avatar alt={opponent.firstName} size='xl' src={opponent.avatarUrl} />
        <OpponentName>
          {opponent.firstName} {opponent.lastName}
        </OpponentName>
        <StatisticsContainer>
          <StatisticsItem>
            <span>{totalMatches}</span>
            <span>PLAYED</span>
          </StatisticsItem>
          <StatisticsItem>
            <span>{wonMatches}</span>
            <span>WON</span>
          </StatisticsItem>
          <StatisticsItem>
            <span>{lostMatches}</span>
            <span>LOST</span>
          </StatisticsItem>
          <StatisticsItem>
            <span>{winRatio} %</span>
            <span>WIN RATIO</span>
          </StatisticsItem>
        </StatisticsContainer>
        <InfoBlock>
          <span>Recent results</span>
          <div>
            {opponent.statsApi.winLose.map(el => (
              <RecentResultCircle isWin={el.symbol === 'W'} key={el.timestamp}>
                {el.symbol}
              </RecentResultCircle>
            ))}
          </div>
        </InfoBlock>
        <InfoBlock>
          <span>PCR</span>
          <div>
            {getPcrBalls(opponent.statsApi.playerCredRating)} {opponent.statsApi.noOfPcrReviews}{' '}
            {opponent.statsApi.noOfPcrReviews === 1 ? 'review' : 'reviews'}
          </div>
        </InfoBlock>
        <InfoBlock>
          <span>SKILL LEVEL</span>
          <span>{opponent.tennisLevel.prettyName.replace('Level ', '')}</span>
        </InfoBlock>
        <InfoBlock>
          <span>PLAYING STYLE</span>
          <span>{opponent.playingStyle.prettyName}</span>
        </InfoBlock>
        <InfoBlock>
          <span>PLAYING HAND</span>
          <span>{opponent.tennisLevel.prettyName.replace('Level ', '')}</span>
        </InfoBlock>
        <InfoBlock>
          <span>ABOUT</span>
          <span>{opponent.aboutMe}</span>
        </InfoBlock>
      </ProfileWrapper>
    </ModalContent>
  );
};

export default UserInfoModal;
