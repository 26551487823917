import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const CellBlock = styled.div`
  display: block;
  flex: 1 1 auto;
  align-self: flex-start;

  p {
    padding: 0;
  }
`;

export const CellFirstInfo = styled.p`
  margin-top: 0;
  color: ${colors.white} !important;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  line-height: 24px;
`;

export const CellSecondInfo = styled.div`
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;
