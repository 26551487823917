import { createSlice } from '@reduxjs/toolkit';

import { MEMBER_ERROR_MESSAGE } from '~/enums';

const initialNewsList = {
  pagination: {
    pageNumber: 0,
    pageSize: 0,
  },
  records: [],
  totalRecords: 0,
  totalPages: 0,
};

const initialSelectedNews = {
  competitionId: null,
  id: null,
  imageUrl: '',
  message: '',
  newsType: '',
  subtitle: '',
  timestamp: null,
  title: '',
};

const initialState = {
  newsList: initialNewsList,
  newsError: '',
  selectedNews: initialSelectedNews,
  selectedNewsError: '',
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    newsList: (state, action) => {
      state.newsList = action.payload;
      state.newsError = '';
    },
    newsError: (state, action) => {
      state.newsList = initialNewsList;
      state.newsError = action.payload;
    },
    selectedNews: (state, action) => {
      state.selectedNews = action.payload;
      state.selectedNewsError = '';
    },
    selectedNewsError: (state, { payload }) => {
      if (payload) {
        if (payload.errors) {
          payload.errors.map(({ code }) => {
            if (code === MEMBER_ERROR_MESSAGE.USER_NOT_IN_COMPETITION)
              state.selectedNewsError = 'This user is not in the competition related to this news.';

            return null;
          });
        } else {
          state.selectedNewsError = 'Something went wrong with loading the news. Please try again.';
        }
      } else {
        state.selectedNewsError = 'Something went wrong with loading the news. Please try again.';
      }

      state.selectedNews = initialSelectedNews;
    },
    selectedNewsReset: state => {
      state.selectedNews = initialSelectedNews;
      state.selectedNewsError = '';
    },
    newsInitialState: () => {
      return initialState;
    },
  },
});

export const { newsList, newsError, selectedNews, selectedNewsError, selectedNewsReset, newsInitialState } =
  newsSlice.actions;

export default newsSlice.reducer;
