import React from 'react';

import { getDateWithYearFromTimestamp, isDateInThePast } from '~/utils';

import { Cell, Time } from './styles';

const TableCompetitionTimelineCell = ({ time = [] }) => {
  let firstTimeText, secondTimeText;

  if (isDateInThePast(time[0])) {
    firstTimeText = 'Started on';
  }

  if (isDateInThePast(time[1])) {
    secondTimeText = 'Ended on';
  } else {
    secondTimeText = 'Ends on';
  }

  return (
    <Cell>
      {time[0] && (
        <Time>
          {firstTimeText} {getDateWithYearFromTimestamp({ timestamp: time[0], withYear: false })}
        </Time>
      )}

      {time[1] && (
        <Time>
          {secondTimeText} {getDateWithYearFromTimestamp({ timestamp: time[1], withYear: false })}
        </Time>
      )}
    </Cell>
  );
};

export default TableCompetitionTimelineCell;
