import React, { useState } from 'react';

import { COMPETITION_ELIGIBILITY_STATUS } from '~/enums/competition';
import { ModalWrapper } from '~/pages/shared/styles';
import { Button, Modal } from '~/ui/index';

import { StatusContainer, StatusMessage, StatusTitle } from '../styles';
import LeaveTournamentModal from './LeaveTournamentModal';

const TournamentUserRegisteredStatus = ({
  tournamentName,
  eligibilityStatus,
  tournamentStartDate,
  registerEndTime,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (
    eligibilityStatus !== COMPETITION_ELIGIBILITY_STATUS.JOINED ||
    eligibilityStatus === COMPETITION_ELIGIBILITY_STATUS.INELIGIBLE ||
    tournamentStartDate < new Date().getTime() / 1000 ||
    registerEndTime < new Date().getTime() / 1000
  ) {
    return null;
  }

  return (
    <StatusContainer>
      <StatusTitle>You are signed up!</StatusTitle>
      <StatusMessage>
        {tournamentName} has not started yet. If you get accepted into the tournament, you will be seeded into a bracket
        and automatically notified.
      </StatusMessage>
      <Button color='danger' isNewStyle onClick={() => setIsModalOpen(true)}>
        Leave Tournament
      </Button>
      {isModalOpen && (
        <Modal ContentWrapper={ModalWrapper}>
          <LeaveTournamentModal onClose={() => setIsModalOpen(false)} />
        </Modal>
      )}
    </StatusContainer>
  );
};

export default TournamentUserRegisteredStatus;
