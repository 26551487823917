import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon, useOutsideClick } from '~/ui';

import CommunityMenuItem from '../shared/CommunityMenuItem';
import { CommunityMenuContainer, JoinCommunity, MenuList } from './styles';

const CommunityMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { allUserCommunities } = useSelector(state => state.communities);
  const history = useHistory();

  const ref = useRef();
  useOutsideClick(ref, () => setIsMenuOpen(false));

  const handleSelectCommunity = communityId => {
    setIsMenuOpen(false);
    history.push(`/home/community/${communityId}`);
  };

  const handleJoinCommunity = e => {
    e.stopPropagation();
    setIsMenuOpen(false);
    history.push('/home/join-community');
  };

  return (
    <CommunityMenuContainer ref={ref} onClick={() => setIsMenuOpen(true)}>
      <Icon icon='multipleUsers2' />
      <span>Communities</span>
      <Icon cursor='pointer' icon={isMenuOpen ? 'chevron2Up' : 'chevron2Down'} size='s' />
      {isMenuOpen && (
        <MenuList>
          {allUserCommunities.map(({ communityInfo: { id, communityName, members } }) => (
            <CommunityMenuItem
              communityName={communityName}
              key={id}
              numberOfMembers={members.length}
              onClick={e => {
                e.stopPropagation();
                handleSelectCommunity(id);
              }}
            />
          ))}
          <JoinCommunity onClick={handleJoinCommunity}>
            <Icon color='tmGreen' icon='search2' />
            <span>Join community</span>
          </JoinCommunity>
        </MenuList>
      )}
    </CommunityMenuContainer>
  );
};

export default CommunityMenu;
