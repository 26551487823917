import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackButton, FlexColumnWrapper } from '~/components/shared';
import { getMatchesCentralData } from '~/store/matches/matchesCentral.utils';

import MyChallenges from '../../shared/MyChallenges';

const AllChallenges = () => {
  const dispatch = useDispatch();
  const { data, isLoading, errorMessage } = useSelector(state => state.matchesCentral);

  const history = useHistory();

  useEffect(() => {
    dispatch(getMatchesCentralData());
  }, [dispatch]);

  const { challenges } = data;

  return (
    <FlexColumnWrapper>
      <BackButton text='Matches central' onClick={history.goBack} />
      <MyChallenges errorMessage={errorMessage} isLoading={isLoading} myChallenges={challenges} />
    </FlexColumnWrapper>
  );
};

export default AllChallenges;
