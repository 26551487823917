import React from 'react';

import { TournamentTreeRoutes } from '~/routes';

const TournamentTreePage = ({ match }) => {
  const parentRoute = match.path;

  return <TournamentTreeRoutes parentRoute={parentRoute} />;
};

export default TournamentTreePage;
