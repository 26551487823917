import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';

import FilterModal from '~/components/shared/FilterModal';
import { Dropdown, Icon, InputRange } from '~/ui';
import { findArrayDataByPropertyAndValue } from '~/utils';

import { Container, NoticeBox, NoticeText } from './styles';

const genders = [
  { gender: 'BOTH', prettyName: 'Both' },
  { gender: 'MALE', prettyName: 'Male' },
  { gender: 'FEMALE', prettyName: 'Female' },
];

const MembersFilterModal = ({
  defaultFilterValues,
  filters: initialFilters,
  handleApplyFilters,
  handleClearFilters,
  notice,
  onHide,
  title,
}) => {
  const [gender, setGender] = useState(initialFilters.gender);
  const [pcrRange, setPcrRange] = useState(initialFilters.pcrRange);
  const [ageRange, setAgeRange] = useState(initialFilters.ageRange);
  const [skillLevelRange, setSkillLevelRange] = useState(initialFilters.skillLevelRange);

  const selectedGender = findArrayDataByPropertyAndValue(genders, 'prettyName', gender);

  return (
    <FilterModal
      apply={() =>
        handleApplyFilters({
          gender,
          pcrRange,
          ageRange,
          skillLevelRange,
        })
      }
      back={onHide}
      cancel={onHide}
      clear={() => handleClearFilters()}
      showCancelButton={
        gender === defaultFilterValues.gender &&
        isEqual(pcrRange, defaultFilterValues.pcrRange) &&
        isEqual(ageRange, defaultFilterValues.ageRange) &&
        isEqual(skillLevelRange, defaultFilterValues.skillLevelRange)
      }
      title={title}
    >
      <Container>
        <Dropdown
          defaultValue={selectedGender}
          getOptionLabel={gender => gender.prettyName}
          getOptionValue={gender => gender.gender}
          label='Gender'
          options={genders}
          placeholder='Select gender'
          value={selectedGender}
          onChange={value => setGender(value.gender)}
        />
        <InputRange
          label='Skill Level'
          maxValue={7.0}
          minValue={1.0}
          step={0.5}
          value={skillLevelRange}
          valueLabel={value => parseFloat(value).toFixed(1)}
          onChange={value => setSkillLevelRange(value)}
        />
        <InputRange
          label='Age'
          maxValue={72}
          minValue={16}
          value={ageRange}
          valueLabel={value => value}
          onChange={value => setAgeRange(value)}
        />
        <InputRange
          label='PCR'
          maxValue={5}
          minValue={1}
          value={pcrRange}
          valueLabel={value => value}
          onChange={value => setPcrRange(value)}
        />
        {notice && (
          <NoticeBox>
            <Icon color='ash2' icon='info' />
            <NoticeText>{notice}</NoticeText>
          </NoticeBox>
        )}
      </Container>
    </FilterModal>
  );
};

export default MembersFilterModal;
