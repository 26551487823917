import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import NewsArticleContent from '~/components/Admin/NewsArticleContent';
import { getNewsById } from '~/store/news/utils';
import { Spinner } from '~/ui';

const NewsArticlePage = () => {
  const dispatch = useDispatch();
  const { newsId } = useParams();
  const [loadingNewsArticle, setLoadingNewsArticle] = useState(false);

  useEffect(() => {
    const loadNews = async () => {
      setLoadingNewsArticle(true);
      await dispatch(getNewsById(newsId));
      setLoadingNewsArticle(false);
    };

    loadNews();
  }, [dispatch, newsId]);

  return loadingNewsArticle ? <Spinner /> : <NewsArticleContent />;
};

export default NewsArticlePage;
