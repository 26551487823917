import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const ModalContainer = styled.div`
  > div {
    > div {
      max-width: 392px !important;
      box-sizing: border-box;
    }
  }
`;

export const HeadCancelButton = styled(Button)`
  border: none;
  margin: 0 -5px;
  padding: 0;
  background: none;

  &:hover,
  &:active {
    background: none;
  }

  .icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
  }
`;

export const Title = styled.h2`
  margin: 12px 0 24px;
  color: ${colors.white};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`;

export const Text = styled.div`
  margin: 0 0 40px;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

export const ConfirmButton = styled(Button)`
  display: block;
  width: 100%;
  margin-bottom: 16px;
  border-color: ${({ confirmButtonColor }) => colors[confirmButtonColor]};
  color: ${({ confirmButtonColor }) => colors[confirmButtonColor]};
`;

export const CancelButton = styled(Button)`
  display: block;
  width: 100%;
  border: none;
  font-weight: normal;
`;
