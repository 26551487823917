import React from 'react';

import { CheckboxContainer, NewStyleCheckbox, NewStyleCheckboxContainer, NewStyleLabel } from './styles';

const Checkbox = ({ onChange, isNewStyle = false, label, ...props }) => {
  if (isNewStyle) {
    return (
      <NewStyleCheckboxContainer>
        <NewStyleCheckbox type='checkbox' onChange={onChange} {...props} id={props.id} />
        <NewStyleLabel htmlFor={props.id}>{label}</NewStyleLabel>
      </NewStyleCheckboxContainer>
    );
  }

  return <CheckboxContainer type='checkbox' onChange={onChange} {...props} />;
};

export default Checkbox;
