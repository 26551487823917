import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const ChallengeInviteInfo = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 2;
  color: ${({ isUserChallenged }) => (isUserChallenged ? colors.pending : colors.ash)};
`;

export const OpenMatchAvatar = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.invert};
  border-radius: 50%;
`;
