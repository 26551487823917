import { Editor, RichUtils } from 'draft-js';
import React from 'react';

import { Icon } from '~/ui';

import { EditorOption, TextEditorBox, TextEditorContainer } from './styles';

const TextEditor = ({ editorState, setEditorState }) => {
  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const formatTextStyle = e => {
    // onMouseDown and e.preventDefault because editor losses focus if you use onClick
    e.preventDefault();

    const formatStyle = e.currentTarget.id;
    const nextState = RichUtils.toggleInlineStyle(editorState, formatStyle.toUpperCase());
    setEditorState(nextState);
  };

  const formatTextType = e => {
    // onMouseDown and e.preventDefault because editor losses focus if you use onClick
    e.preventDefault();

    const formatType = e.currentTarget.id;
    const nextState = RichUtils.toggleBlockType(editorState, formatType);
    setEditorState(nextState);
  };

  const activeTextType = RichUtils.getCurrentBlockType(editorState);
  const activeTextStyle = editorState.getCurrentInlineStyle();

  return (
    <TextEditorContainer>
      <TextEditorBox>
        <EditorOption id='header-two' onClick={formatTextType}>
          <Icon color={activeTextType === 'header-two' ? 'tmGreen' : 'ash'} cursor='pointer' icon='typeH2' />
        </EditorOption>
        <EditorOption id='header-three' onClick={formatTextType}>
          <Icon color={activeTextType === 'header-three' ? 'tmGreen' : 'ash'} cursor='pointer' icon='typeH3' />
        </EditorOption>
        <EditorOption id='bold' onClick={formatTextStyle}>
          <Icon color={activeTextStyle.has('BOLD') ? 'tmGreen' : 'ash'} cursor='pointer' icon='typeBold' />
        </EditorOption>
        <EditorOption id='italic' onClick={formatTextStyle}>
          <Icon color={activeTextStyle.has('ITALIC') ? 'tmGreen' : 'ash'} cursor='pointer' icon='typeItalic' />
        </EditorOption>
        <EditorOption id='underline' onClick={formatTextStyle}>
          <Icon color={activeTextStyle.has('UNDERLINE') ? 'tmGreen' : 'ash'} cursor='pointer' icon='typeUnderline' />
        </EditorOption>
        <EditorOption id='unordered-list-item' onClick={formatTextType}>
          <Icon
            color={activeTextType === 'unordered-list-item' ? 'tmGreen' : 'ash'}
            cursor='pointer'
            icon='typeListUl'
          />
        </EditorOption>
        <Editor editorState={editorState} handleKeyCommand={handleKeyCommand} onChange={setEditorState} />
      </TextEditorBox>
    </TextEditorContainer>
  );
};

export default TextEditor;
