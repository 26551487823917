import React from 'react';

import { Icon } from '~/ui/index';

import { Container, Separator, Tab, TabLabel } from './styles';

const Tabs = ({ tabs = [], selectedTab }) => {
  return (
    <Container>
      {tabs.map(tab => {
        const { icon, label, value, onSelect, hidden } = tab;

        const isSelected = selectedTab === value;

        if (hidden) return null;

        return (
          <React.Fragment key={value}>
            <Tab onClick={onSelect}>
              <Icon color={isSelected ? 'tmGreen' : 'ash3'} cursor='pointer' icon={icon} />
              <TabLabel isSelected={isSelected}>{label}</TabLabel>
            </Tab>
            <Separator />
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default Tabs;
