import React from 'react';

import MatchInviteItem from '../../shared/MatchInviteItem/index';

const PendingMatchItem = ({ match, matchInvite, communityName, opponent }) => {
  if (!match && !!matchInvite) {
    return <MatchInviteItem community={communityName} match={matchInvite} opponent={opponent} />;
  }

  return null;
};

export default PendingMatchItem;
