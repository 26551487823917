import { getDateFromString, getDifferenceInYears, getPcrBalls, getSkillLevelNumber } from '~/utils';

export const columns = [
  {
    Header: 'NAME',
    accessor: 'name',
    width: 250,
    iconLeft: 'multipleUsers',
  },
  {
    Header: 'SKILL LEVEL',
    accessor: 'skillLevel',
  },
  {
    Header: 'Age',
    accessor: 'age',
  },
  {
    Header: 'Gender',
    accessor: 'gender',
  },
  {
    Header: 'PCR',
    accessor: 'pcr',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];

export const getPlayersData = data => {
  return data.map(member => {
    const { accountId, avatarUrl, birthday, firstName, gender, lastName, statsApi, tennisLevel } = member;

    return {
      accountId,
      age: getDifferenceInYears(getDateFromString(birthday, 'yyyy-MM-dd'), new Date()),
      avatarUrl,
      gender: gender.prettyName,
      name: `${firstName} ${lastName}`,
      pcr: getPcrBalls(statsApi.playerCredRating),
      skillLevel: getSkillLevelNumber(tennisLevel.prettyName).toFixed(1),
    };
  });
};

export const getPreviousRound = ({ tournamentTree, selectedRound }) => {
  let previous = [];
  const lastRound = tournamentTree.rounds[tournamentTree.rounds.length - 1];
  const lastRoundIndex = tournamentTree.rounds.indexOf(lastRound);
  const selectedRoundIndex = tournamentTree.rounds.findIndex(round => round.roundNumber === selectedRound.roundNumber);

  if (selectedRound.roundNumber === 1) {
    previous = [];
  } else {
    previous = [tournamentTree.rounds[selectedRoundIndex - 1]];

    if (selectedRoundIndex === lastRoundIndex && tournamentTree.rounds.length > 2) {
      previous = [tournamentTree.rounds[selectedRoundIndex - 2], ...previous];
    }
  }

  return previous;
};

export const getNextRound = ({ tournamentTree, selectedRound }) => {
  let next = [];
  const selectedRoundIndex = tournamentTree.rounds.findIndex(round => round.roundNumber === selectedRound.roundNumber);
  const firstNextRound = tournamentTree.rounds[selectedRoundIndex + 1];

  if (selectedRound.roundNumber === 1 && tournamentTree.rounds.length > 2) {
    const secondNextRound = tournamentTree.rounds[selectedRoundIndex + 2];

    next = [firstNextRound, secondNextRound];
  } else {
    next = firstNextRound ? [firstNextRound] : [];
  }

  return next;
};
