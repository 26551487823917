import React from 'react';
import { Link } from 'react-router-dom';

import logo from '~/assets/images/tennismate-logo.png';
import { Button } from '~/ui';

import { Container, Logo } from './styles';

const HomePage = () => {
  return (
    <Container>
      <Logo alt='logo' src={logo} />
      <Link to='/login'>
        <Button>Login</Button>
      </Link>
    </Container>
  );
};

export default HomePage;
