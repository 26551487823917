import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const ModalContainer = styled.div`
  > div {
    > div {
      height: ${({ increaseHeight }) => (increaseHeight ? '673px' : '617px')};
      position: relative;
      box-sizing: border-box;
    }
  }
`;

export const Title = styled.h3`
  margin: 0 0 16px 0;
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;

export const NoGroupsContainer = styled.div`
  display: flex;
  margin-top: 32px;
  padding: 12px 16px;
  background: ${colors.highlight1};
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;

  .icon {
    margin-right: 12px;
  }
`;

export const GroupsContainer = styled.div`
  max-height: 250px;
  margin-top: 24px;
  overflow-y: auto;
`;

export const GroupName = styled.span`
  display: flex;
  padding: 12px 16px;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  justify-content: space-between;
  line-height: 24px;
  word-break: break-word;

  &:nth-child(odd) {
    background: ${colors.highlight2};
  }

  &:nth-child(even) {
    background: ${colors.highlight1};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.invert};
  }
`;

export const CreateGroupsButton = styled(Button)`
  margin: 0;
  position: absolute;
  bottom: 32px;
`;
