import React from 'react';

import BackButton from '~/components/shared/BackButton';
import { Button, Grid, Icon, Modal } from '~/ui';

import { CancelButton, ClearButton, Title } from './styles';

const FilterModal = ({ apply, back, cancel, children, clear, showCancelButton, title }) => {
  return (
    <Modal>
      <BackButton onClick={back} />
      <Title>{title}</Title>
      {children}
      <Grid.Container>
        <Grid.Row>
          {apply && (
            <Grid.Column columns={2}>
              <Button onClick={apply}>
                <Icon color='tmGreen' cursor='pointer' icon='filter' />
                Apply Filter
              </Button>
            </Grid.Column>
          )}
          {clear && !showCancelButton ? (
            <Grid.Column columns={2}>
              <ClearButton type='reset' onClick={clear}>
                Clear Filters
              </ClearButton>
            </Grid.Column>
          ) : (
            <Grid.Column columns={2}>
              <CancelButton type='reset' onClick={cancel}>
                Cancel
              </CancelButton>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid.Container>
    </Modal>
  );
};

export default FilterModal;
