import styled from 'styled-components';

import theme from '../../theme';

const { mediaQueries } = theme;

export const Grid = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : null)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% / ${({ columns }) => (columns ? columns : 1)});
  flex: 1;
  margin: 0 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: ${mediaQueries.mobile}px) and (max-width: 1270px) {
    flex-basis: calc(50% - 32px);
    margin: 0;

    &:nth-child(odd) {
      margin-right: 32px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    flex-basis: 100%;
    margin: 0;
  }
`;
