import styled from 'styled-components';

import theme from '../../theme';

const { colors, zIndex } = theme;

export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(21, 21, 21, 0.9);
  overflow-y: scroll;
  z-index: ${zIndex.level5};
`;

export const Content = styled.div`
  width: 430px;
  height: auto;
  margin: auto;
  padding: 32px;
  background: ${colors.background};
`;
