import React from 'react';

import { Icon } from '~/ui';

import { Container, Label, NewStyleContainer, NewStyleTextarea, TextareaContainer } from './styles';

const Textarea = ({
  label,
  icon,
  iconColor = 'ash2',
  leftIconSize = 's',
  onChange,
  placeholder,
  value,
  isNewStyle = false,
  ...props
}) => {
  if (isNewStyle) {
    return (
      <NewStyleContainer leftIconSize={leftIconSize}>
        {icon && <Icon color={iconColor} icon={icon} size={leftIconSize} />}
        <NewStyleTextarea
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
          maxLength={512}
          withIcon={!!icon}
        />
      </NewStyleContainer>
    );
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <TextareaContainer maxLength={512} placeholder={placeholder} value={value} onChange={onChange} />
    </Container>
  );
};

export default Textarea;
