import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors, zIndex } = theme;

const smallIconCss = css`
  left: 11px;
  top: 12px;
`;

const mediumIconCss = css`
  left: 10px;
  top: 8px;
`;

export const Container = styled.div`
  box-shadow: 0px 2px 1px rgba(18, 22, 39, 0.02);
  width: 100%;
  position: relative;

  & .icon {
    position: absolute;
  }

  & .icon:first-child {
    ${({ leftIconSize }) => (leftIconSize === 's' ? smallIconCss : mediumIconCss)};
  }

  & .icon:last-child {
    right: 14px;
    top: 12px;
  }
`;

export const InputContainer = styled.input`
  border: 1px solid
    ${({ error, isDropdownOpen }) => (error ? colors.danger : isDropdownOpen ? colors.tmGreen : colors.ash3)};
  border-radius: 4px;
  width: 100%;
  height: 40px;
  padding: ${({ hasLeftIcon }) => (hasLeftIcon ? '0 40px' : '0 40px 0 12px')};
  color: ${colors.white};
  background-color: ${colors.highlight3};
  font-size: 14px;
  line-height: 24px;
  outline: none;
  box-sizing: border-box;

  &:active,
  &:focus {
    background-color: ${colors.highlight3};
    border-color: ${colors.tmGreen};
  }

  &::placeholder {
    color: ${colors.whiteOpacity._25};
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 10px;
  background-color: ${colors.highlight2};
  border-radius: 4px;
  z-index: ${zIndex.level5};
`;

export const TimeOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  height: 120px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 14px;
`;

export const TimeOptions = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const TimeOption = styled.span`
  padding: 0px 24px;
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.ash2)};
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? colors.tmGreen : colors.highlight3)};
  }

  background-color: ${({ isSelected }) => (isSelected ? colors.tmGreen : 'transparent')};

  opacity: ${({ isDisabled }) => (isDisabled ? 0.2 : 1)};

  &:disabled {
    background-color: ${colors.highlight3};
    opacity: 0.5;
  }
`;

export const ConfirmTimeButton = styled.span`
  padding: 6px 12px;
  color: ${colors.white};
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${colors.tmGreen};
`;

export const ClearTimeButton = styled.span`
  padding: 6px 12px;
  color: ${colors.ash2};
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
`;
