import React, { useState } from 'react';

import { searchCourtsRequest } from '~/api';
import { ModalContent } from '~/components/shared';
import ListItemsStatusFeedback from '~/pages/User/shared/ListItemStatusFeedback/index';
import { Search } from '~/ui';

import CourtList from './CourtList';

const PickCourt = ({ onClose, onSelect }) => {
  const [courts, setCourts] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSearchCourts = async searchTerm => {
    try {
      setIsSearching(true);
      const response = await searchCourtsRequest(searchTerm);
      const foundCourts = response.data.body;

      setCourts(foundCourts);
    } catch (e) {
      setErrorMessage('Something went wrong. Please try again');
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <ModalContent title='Pick Location' onClose={onClose}>
      <Search autoFocus isNewStyle onSearch={onSearchCourts} />
      {courts.length > 0 ? (
        <CourtList courts={courts} onSelect={onSelect} />
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='Search for your favorite courts and communities...'
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isSearching}
          style={{ background: 'transparent' }}
        />
      )}
    </ModalContent>
  );
};

export default PickCourt;
