import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  display: grid;
  gap: 8px;
  grid-template-areas:
    'info labels'
    'host hostScores'
    'guest guestScores';
  align-items: center;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    justify-content: center;
    align-content: center;
    gap: 16px;
    grid-template-areas:
      'info'
      'host'
      'hostScores'
      'labels'
      'guestScores'
      'guest';
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-self: end;
`;

export const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: end;
  justify-self: end;
  grid-area: labels;
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  width: 40px;
  color: ${colors.whiteOpacity._5};
`;

export const Input = styled.input`
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: none;
  text-align: center;
  background: ${colors.highlight3};
  border-radius: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${({ isWin, isError }) => (isError ? colors.danger : isWin ? colors.tmGreen : colors.white)};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &:focus {
    border: 1px solid ${colors.white};
  }

  &:disabled {
    border: 0;
    background-color: transparent;
  }
`;

export const PlayerInfo = styled.div`
  display: flex;
  flex-direction: ${({ isReversed }) => (isReversed ? 'row-reverse' : 'row')};
  gap: 8px;
`;

export const PlayerName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
`;
