export const COMPETITION_TYPE = {
  LEAGUE: 'league',
  TOURNAMENT: 'tournament',
};

export const COMPETITION_ELIGIBILITY_STATUS = {
  ELIGIBLE: 'ELIGIBLE',
  INELIGIBLE: 'INELIGIBLE',
  JOINED: 'JOINED',
  NOT_JOINED: 'NOT_JOINED',
};
