import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { COMMUNITY_USER_STATUS, ROLE } from '~/enums';
import { getCommunityScreen } from '~/store/communities/utils';
import { ErrorMessage, Spinner } from '~/ui';

import Header from '../shared/Header';
import About from './About';
import { useTabs } from './context/TabContext';
import Leagues from './Leagues';
import LeaveCommunityButton from './LeaveCommunityButton';
import Members from './Members';
import Overview from './Overview';
import { Hero, PageWrapper } from './styles';
import Tabs from './Tabs';
import Tournaments from './Tournaments';

const componentsMap = {
  overview: Overview,
  leagues: Leagues,
  tournaments: Tournaments,
  members: Members,
};

const PageContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { communityId } = useParams();
  const { communityScreen, communityScreenLoading, communityScreenError } = useSelector(state => state.communities);
  const { selectedTab, setSelectedTab } = useTabs();
  const [tabs, setTabs] = useState([]);

  const { community, userRole } = communityScreen;

  useEffect(() => {
    dispatch(getCommunityScreen(communityId));
  }, [communityId, dispatch]);

  useEffect(() => {
    if (locationState?.tab && COMMUNITY_USER_STATUS.JOINED === communityScreen.communityStatus) {
      setSelectedTab(locationState.tab);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let tabsToRender = [];
    if (!isEmpty(communityScreen)) {
      tabsToRender = [
        {
          value: 'overview',
          label: 'Overview',
          isDisabled: communityScreen.communityStatus !== COMMUNITY_USER_STATUS.JOINED,
          onSelect: () => setSelectedTab('overview'),
        },
        {
          value: 'leagues',
          label: 'Leagues',
          icon: 'badge',
          isDisabled: communityScreen.communityStatus !== COMMUNITY_USER_STATUS.JOINED,
          onSelect: () => setSelectedTab('leagues'),
        },
        {
          value: 'tournaments',
          label: 'Tournaments',
          icon: 'trophy2',
          isDisabled: communityScreen.communityStatus !== COMMUNITY_USER_STATUS.JOINED,
          onSelect: () => setSelectedTab('tournaments'),
        },
        {
          value: 'members',
          label: 'Members',
          isDisabled: communityScreen.communityStatus !== COMMUNITY_USER_STATUS.JOINED,
          onSelect: () => setSelectedTab('members'),
        },
        {
          value: 'about',
          label: 'About',
          isDisabled: false,
          onSelect: () => setSelectedTab('about'),
        },
      ];

      if (communityScreen.userRole === ROLE.ADMIN || communityScreen.userRole === ROLE.OPERATOR) {
        tabsToRender.push({
          value: 'admin',
          label: 'Admin',
          isDisabled: false,
          onSelect: () => history.push('/admin'),
        });
      }

      setTabs(tabsToRender);
      if (COMMUNITY_USER_STATUS.JOINED === communityScreen.communityStatus) {
        setSelectedTab(locationState?.tab || 'overview');
      } else {
        setSelectedTab('about');
      }
    }
  }, [communityScreen, history, setSelectedTab, locationState]);

  const TabContent = componentsMap[selectedTab] || About;

  if (communityScreenLoading) {
    return <Spinner />;
  }

  if (communityScreenError) {
    return (
      <PageWrapper>
        <ErrorMessage>{communityScreenError}</ErrorMessage>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Hero bgUrl={community.imageUrl} />
      <Header icon='multipleUsers2' title={community.communityName}>
        {COMMUNITY_USER_STATUS.JOINED === communityScreen.communityStatus && (
          <LeaveCommunityButton communityName={community.communtyName} userRole={userRole} />
        )}
      </Header>
      <Tabs selectedTab={selectedTab} tabs={tabs} />
      <TabContent />
    </PageWrapper>
  );
};

export default PageContent;
