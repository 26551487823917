import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import TextWithIcon from '~/components/shared/TextWithIcon';
import { COMPETITION_TYPE } from '~/enums/competition';
import { DATE_FORMATS } from '~/enums/dates';
import { theme } from '~/ui';
import { formatDateFromUnixTime } from '~/utils';

const { colors } = theme;

const MatchInfo = () => {
  const history = useHistory();

  const { data } = useSelector(state => state.matchScreen);
  const { match, matchInvite, communityAndCompetitionCard } = data;

  const matchData = match || matchInvite;

  const { court, matchTime, playTime } = matchData;

  return (
    <WidgetWrapper>
      {communityAndCompetitionCard?.communityName && (
        <WidgetItem onClick={() => history.push(`/home/community/${communityAndCompetitionCard?.communityId}`)}>
          <TextWithIcon
            icon='web'
            iconColor='tmGreen'
            text={communityAndCompetitionCard?.communityName}
            textStyle={{ color: colors.tmGreen }}
          />
        </WidgetItem>
      )}
      {communityAndCompetitionCard?.competitionName && (
        <WidgetItem
          onClick={() =>
            history.push(
              `/home/${communityAndCompetitionCard?.competitionType.toLowerCase()}/${
                communityAndCompetitionCard?.competitionId
              }`
            )
          }
        >
          <TextWithIcon
            icon={
              communityAndCompetitionCard?.competitionType.toLowerCase() === COMPETITION_TYPE.LEAGUE
                ? 'badge'
                : 'trophy2'
            }
            iconColor='tmGreen'
            text={communityAndCompetitionCard?.competitionName}
            textStyle={{ color: colors.tmGreen }}
          />
        </WidgetItem>
      )}
      <WidgetItem>
        <TextWithIcon
          icon='calendar'
          iconColor='tmGreen'
          text={formatDateFromUnixTime(matchTime || playTime, DATE_FORMATS.WITH_DAY_AND_MONTH_NAME)}
        />
      </WidgetItem>
      <WidgetItem>
        <TextWithIcon icon='location' iconColor='tmGreen' text={court.name} />
      </WidgetItem>
    </WidgetWrapper>
  );
};

export default MatchInfo;
