import React from 'react';

import { COMPETITION_ELIGIBILITY_STATUS } from '~/enums/competition';
import { DATE_FORMATS } from '~/enums/dates';
import { formatDateFromUnixTime } from '~/utils/dates';

import { StatusContainer, StatusMessage, StatusTitle } from '../styles';

const TournamnetRegistrationFinishedStatus = ({
  tournamentName,
  tournamentStartDate,
  registerEndTime,
  eligibilityStatus,
}) => {
  if (
    registerEndTime < new Date().getTime() / 1000 &&
    eligibilityStatus !== COMPETITION_ELIGIBILITY_STATUS.INELIGIBLE
  ) {
    return (
      <StatusContainer>
        <StatusTitle>
          {tournamentName} will start on {formatDateFromUnixTime(tournamentStartDate, DATE_FORMATS.MONTH_DAY_WITH_TIME)}
          !
        </StatusTitle>
        <StatusMessage>
          Registrations are closed.{' '}
          {eligibilityStatus === COMPETITION_ELIGIBILITY_STATUS.JOINED &&
            'If you get accepted into the tournament, you will be seeded into a bracket and automatically notified.'}
        </StatusMessage>
      </StatusContainer>
    );
  }

  return null;
};

export default TournamnetRegistrationFinishedStatus;
