import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/slice';
import communitiesReducer from './communities/slice';
import generalReducer from './general/slice';
import homeReducer from './home/slice';
import leaguesReducer from './leagues/slice';
import matchesCentralReducer from './matches/matchesCentral.slice';
import matchFormReducer from './matches/matchForm.slice';
import matchScreenReducer from './matches/matchScreen.slice';
import directMatchFormReducer from './matches/newMatchForm.slice';
import newsReducer from './news/slice';
import tournamentsReducer from './tournaments/slice';
import tournamentScreenReducer from './tournaments/tournamentScreen.slice';
import tournamentTreeReducer from './tournaments/tournamentTree.slice';
import userReducer from './user/slice';

export default configureStore({
  reducer: {
    auth: authReducer,
    communities: communitiesReducer,
    general: generalReducer,
    leagues: leaguesReducer,
    matchForm: matchFormReducer,
    news: newsReducer,
    tournaments: tournamentsReducer,
    user: userReducer,
    newMatchForm: directMatchFormReducer,
    matchScreen: matchScreenReducer,
    homeScreen: homeReducer,
    tournamentScreen: tournamentScreenReducer,
    tournamentTree: tournamentTreeReducer,
    matchesCentral: matchesCentralReducer,
  },
});
