import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  errorMessage: '',
  data: {},
};

export const matchScreen = createSlice({
  name: 'matchScreen',
  initialState,
  reducers: {
    setMatchData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setIsLoadingMatch: state => {
      state.isLoading = true;
    },
    setMatchErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.data = {};
      state.isLoading = false;
    },
  },
});

export default matchScreen.reducer;

export const { setMatchData, setIsLoadingMatch, setMatchErrorMessage } = matchScreen.actions;
