import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const ContentContainer = styled.div`
  padding: 32px 24px;
  max-width: 720px;
  margin: 0 auto;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    padding: 16px;
  }
`;

export const InfoMessage = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${colors.white};
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
