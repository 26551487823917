import queryString from 'query-string';

import { getUserProfilesByIds } from '~/api';
import { TennisMateApi } from '~/services';

export const getCommunityByIdRequest = communityId => {
  return TennisMateApi.get(`/communities/${communityId}`);
};

export const getMyCommunitiesRequest = () => {
  return TennisMateApi.get('/communities/my-communities');
};

export const searchCourtsRequest = searchParam => {
  return TennisMateApi.get(`/courts?query=${searchParam}`);
};

export const createCommunity = async data => {
  return await TennisMateApi.post('/communities', data);
};

export const updateCommunity = async ({ communityId, data }) => {
  return await TennisMateApi.put(`/communities/${communityId}`, { ...data });
};

export const uploadCommunityImageRequest = (communityId, image) => {
  return TennisMateApi.put(`/communities/${communityId}/upload-image`, {
    dataUri: image,
  });
};

export const getCommunityMembersRequest = communityId => {
  return TennisMateApi.get(`/communities/${communityId}/members`);
};

export const getCommunityJoinRequests = async communityId => {
  const response = await TennisMateApi.get(`/communities/${communityId}/join-requests`);
  const userIds = response.data.body.map(user => user.accountId);

  return await getUserProfilesByIds(userIds);
};

export const getCommunityInviteRequests = async communityId => {
  const response = await TennisMateApi.get(`/communities/${communityId}/invite-requests`);
  const userIds = response.data.body.map(user => user.accountId);

  return await getUserProfilesByIds(userIds);
};

export const acceptUserJoinCommunity = async (accountId, communityId) => {
  return await TennisMateApi.put(`/communities/${communityId}/user/${accountId}/accept`);
};

export const rejectUserJoinCommunity = async (accountId, communityId) => {
  return await TennisMateApi.put(`/communities/${communityId}/user/${accountId}/reject`);
};

export const updateMemberRoleRequest = (communityId, accountId, newRole) => {
  return TennisMateApi.put(`/communities/${communityId}/user/${accountId}/new-role/${newRole}`);
};

export const removeMemberFromCommunityRequest = (communityId, accountId) => {
  return TennisMateApi.delete(`/communities/${communityId}/user/${accountId}`);
};

export const searchCommunitiesRequest = async searchParams => {
  const query = queryString.stringify(searchParams);

  return TennisMateApi.get(`/communities/search?${query}`);
};

export const getCommunityScreenRequest = async communityId => {
  return TennisMateApi.get(`/mobile/v1/community/${communityId}`);
};

export const requestCommunityJoin = async communityId => {
  return TennisMateApi.post(`/communities/${communityId}/join`);
};

export const acceptCommunityInvite = async communityId => {
  return TennisMateApi.put(`/communities/${communityId}/accept`);
};

export const rejectCommunityInvite = async communityId => {
  return TennisMateApi.put(`/communities/${communityId}/reject`);
};

export const getCommunityRolesRequest = async (accountId, params) => {
  const query = queryString.stringify(params, { skipEmptyString: true });

  return TennisMateApi.get(`/communities/members/user/${accountId}?${query}`);
};

export const leaveCommunity = async (communityId, accountId) => {
  return TennisMateApi.delete(`/communities/${communityId}/user/${accountId}`);
};
