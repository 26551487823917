import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ContentContainer } from '~/pages/shared/styles';

import Header from '../shared/Header';
import DirectMatchForm from './DirectMatchForm';
import OpenMatchForm from './OpenMatchForm';
import { InfoMessage } from './styles';
import Tabs from './Tabs';

const NewMatch = () => {
  const [selectedTab, setSelectedTab] = useState('direct');

  const { state } = useLocation();

  const tabs = useRef([
    {
      value: 'direct',
      label: 'Direct',
      icon: 'multipleUsers2',
      onSelect: () => setSelectedTab('direct'),
      hidden: false,
    },
    {
      value: 'open',
      label: 'Open',
      icon: 'web',
      onSelect: () => setSelectedTab('open'),
      hidden: state?.isDirectOnly,
    },
  ]);

  return (
    <>
      <Header showStatus={false} title='New Match' />
      <ContentContainer>
        <Tabs selectedTab={selectedTab} tabs={tabs.current} />
        <InfoMessage>
          {selectedTab === 'direct'
            ? 'Direct challenges are sent to a specific opponent.'
            : 'Open challenges are visible to the TennisMate community and can be accepted by anyone.'}
        </InfoMessage>
        {selectedTab === 'open' ? <OpenMatchForm /> : <DirectMatchForm />}
      </ContentContainer>
    </>
  );
};

export default NewMatch;
