import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const SpinnerContainer = styled.div`
  height: 100px;
  position: relative;
`;

export const TableContainer = styled.div`
  margin: 20px 0;
  color: ${colors.whiteOpacity._75};
  overflow-x: scroll;

  a {
    color: ${colors.whiteOpacity._75};
  }
`;

export const TableHeaderRow = styled.div`
  display: flex;
`;

export const TableHeaderCell = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  background-color: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;

  & > span:not(:last-child) {
    margin-right: 16px;
  }

  & > span:not(.icon) {
    flex-grow: 1;
  }

  &:not(:last-child) {
    border-right: 1px solid ${colors.highlight1};
  }
`;

export const TableRow = styled.div`
  display: flex;
  cursor: ${({ onClick }) => !!onClick && 'pointer'};
  width: 100%;
  min-width: fit-content !important;

  &:nth-of-type(
      ${({ colorLineUnderRowNumber }) => (colorLineUnderRowNumber !== null ? colorLineUnderRowNumber : null)}
    ) {
    border-bottom: 1px solid ${colors.tmGreen} !important;
  }

  &:not(:last-child) {
    border-bottom: ${({ rowBorderBottom }) => (rowBorderBottom ? `1px solid ${colors.invert}` : 'none')};
  }

  &:nth-child(even) {
    background: ${({ isSelected }) => (isSelected ? colors.highlight3 : colors.highlight2)};
  }

  &:nth-child(odd) {
    background: ${({ isSelected }) => (isSelected ? colors.highlight3 : colors.highlight1)};
  }

  &:hover {
    background-color: ${({ onClick }) => !!onClick && colors.highlightHover};
  }
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;

  &:not(:last-child) {
    border-right: 1px solid ${colors.highlight1};
  }
`;

export const TableEmptyState = styled.div`
  padding: 16px;
`;

export const PaginationContainer = styled.div`
  padding-top: 24px;
`;
