import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

const smallSize = css`
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : '90px')};
  min-height: 32px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 16px;
`;

const normalSize = css`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  min-height: 48px;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`;

const getButtonSize = props => {
  return props.small ? smallSize : normalSize;
};

const disabledButton = css`
  cursor: not-allowed !important;
  opacity: 0.5;

  &:hover {
    background: ${colors.highlight2} !important;
  }
`;

export const ButtonContainer = styled.button`
  ${getButtonSize};
  ${({ disabled }) => (disabled ? disabledButton : null)};
  margin: 10px 0px;
  border: 1px solid ${colors.tmGreen};
  background: ${({ noBackground }) => (noBackground ? 'transparent' : colors.highlight2)};
  color: ${colors.tmGreen};
  font-style: normal;
  text-align: center;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background: ${colors.highlight3};
  }

  .icon {
    display: inline-block;
    margin-right: 12px;
    position: relative;
    bottom: 2px;
    vertical-align: middle;
  }
`;

export const NewStyleButtonContainer = styled.button`
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ color }) => colors[color]};
  border-radius: 2px;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4;
  color: ${({ color }) => colors[color]};
  cursor: pointer;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  &:disabled {
    border-color: ${colors.ash3};
    color: ${colors.ash3};
    cursor: not-allowed;
  }
`;
