import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icon } from '~/ui';

import { BackIcon, HeaderContainer, PageTitle } from './styles';

const PageHeader = ({ title }) => {
  const history = useHistory();

  return (
    <HeaderContainer>
      <BackIcon onClick={() => history.goBack()}>
        <Icon color='tmGreen' cursor='pointer' icon='close2' size='s' />
      </BackIcon>
      <Icon cursor='pointer' icon='trophy2' />
      <PageTitle>{title}</PageTitle>
    </HeaderContainer>
  );
};

export default PageHeader;
