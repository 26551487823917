import { TennisMateApi } from '~/services';

export const loginRequest = data => {
  return TennisMateApi.post('/login', data);
};

export const signupRequest = data => {
  return TennisMateApi.post('/users', data);
};

export const forgotPasswordRequest = data => {
  return TennisMateApi.put('/users/password/forgot-password', data);
};
