import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const CloseModalButton = styled(Button)`
  border: none;
  margin: 0 -5px;
  padding: 0;
  background: none;

  &:hover,
  &:active {
    background: none;
  }

  .icon {
    display: inline-block;
    margin-right: 12px;
    vertical-align: bottom;
  }
`;

export const ModalContainer = styled.div`
  > div {
    > div {
      max-width: 392px !important;
      box-sizing: border-box;
    }
  }
`;

export const Img = styled.img`
  display: block;
  width: 150px;
  border-radius: 150px;
  margin: 0 auto;
  object-fit: cover;
`;

export const ButtonsContainer = styled.div`
  margin-top: ${({ bigMarginTop }) => (bigMarginTop ? '74px' : '16px')};

  button {
    width: 100%;
    padding: 12px 0;
  }
`;

export const ButtonDanger = styled(Button)`
  border-color: ${colors.danger};
  color: ${colors.danger};
`;

export const CancelButton = styled(Button)`
  display: block;
  width: 100%;
  border: none;
  font-weight: normal;
`;

export const Message = styled.p`
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;
