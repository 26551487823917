import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const HeaderImage = styled.div`
  display: flex;
  margin: -32px;
  height: 180px;
  background-image: linear-gradient(to right, rgba(35, 35, 35, 0.0001) 61.82%, ${colors.highlight1} 100%),
    linear-gradient(to bottom, rgba(35, 35, 35, 0.0001) 0%, ${colors.highlight1} 100%), url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;

  span {
    color: ${colors.whiteOpacity._75};
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    cursor: pointer;

    .icon {
      display: inline-block;
      margin-right: 12px;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin: -16px;
  }
`;
