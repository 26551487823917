import React from 'react';
import { useHistory } from 'react-router-dom';

import { WidgetItem } from '~/components/shared/styles';
import { Icon } from '~/ui';

import { Image, Info, InfoWrapper, Name, Wrapper } from './styles';

const CommunityListItem = ({ image, name, numberOfMembers, address, communityId }) => {
  const history = useHistory();

  return (
    <WidgetItem onClick={() => history.push(`/home/community/${communityId}`)}>
      <Wrapper>
        <Image alt={name} src={image} />
        <InfoWrapper>
          <Name>{name}</Name>
          <Info>
            {numberOfMembers} members • {address}
          </Info>
        </InfoWrapper>
      </Wrapper>
      <Icon
        color='ash3'
        icon='rightArrow2'
        size='s'
        style={{
          minWidth: '16px',
          minHeight: '16px',
        }}
      />
    </WidgetItem>
  );
};

export default CommunityListItem;
