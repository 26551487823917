import React from 'react';

import { Icon } from '~/ui';

import { Container } from './styles';

const PcrBall = ({ color }) => {
  return (
    <Container>
      <Icon color={color} icon='ball' />
    </Container>
  );
};

export default PcrBall;
