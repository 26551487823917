import React from 'react';

import { Container, RoundTitle } from './styles';

const RoundsHeader = ({ numberOfRounds }) => {
  return (
    <Container>
      {numberOfRounds >= 5 && <RoundTitle>Round of 32</RoundTitle>}
      {numberOfRounds >= 4 && <RoundTitle>Round of 16</RoundTitle>}
      {numberOfRounds >= 3 && <RoundTitle>Quarter-final</RoundTitle>}
      {numberOfRounds >= 2 && <RoundTitle>Semi-final</RoundTitle>}
      <RoundTitle>Final</RoundTitle>
    </Container>
  );
};

export default RoundsHeader;
