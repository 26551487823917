import styled from 'styled-components';

import theme from '../../theme';

const { colors, mediaQueries } = theme;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;
  }
`;

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-right: 36px;
  background-color: ${({ isSelected }) => (isSelected ? colors.highlight3 : colors.highlight2)};

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    flex-grow: 1;
  }
`;

export const TabItemLabel = styled.span`
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.whiteOpacity._5)};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;
