import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const AcceptButton = styled(Button)`
  margin: 0;
  margin-right: 10px;
`;

export const DeclineButton = styled(Button)`
  margin: 0;
  border-color: ${colors.danger};
  color: ${colors.white};
`;

export const ClickableAvatar = styled.div`
  cursor: pointer;

  & span {
    color: ${colors.tmGreen};
  }
`;
