import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    challenges: [],
    recentMatches: [],
    upcomingMatches: [],
  },
  isLoading: false,
  errorMessage: '',
};

export const matchesCentralSlice = createSlice({
  name: 'matchesCentral',
  initialState,
  reducers: {
    setMatchesCentralData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setIsLoadingMatchesCentralData: state => {
      state.isLoading = true;
    },
    setMatchesCentralErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
      state.data = {
        challenges: [],
        recentMatches: [],
        upcomingMatches: [],
      };
    },
  },
});

export default matchesCentralSlice.reducer;

export const { setMatchesCentralData, setIsLoadingMatchesCentralData, setMatchesCentralErrorMessage } =
  matchesCentralSlice.actions;
