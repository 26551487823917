import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackButton, FlexColumnWrapper } from '~/components/shared';
import { getMatchesCentralData } from '~/store/matches/matchesCentral.utils';

import MyUpcomingMatches from '../../shared/MyUpcomingMatches';

const AllUpcomingMatches = () => {
  const dispatch = useDispatch();
  const { data, isLoading, errorMessage } = useSelector(state => state.matchesCentral);

  const history = useHistory();

  useEffect(() => {
    dispatch(getMatchesCentralData());
  }, [dispatch]);

  const { upcomingMatches } = data;

  return (
    <FlexColumnWrapper>
      <BackButton text='Matches central' onClick={history.goBack} />
      <MyUpcomingMatches errorMessage={errorMessage} isLoading={isLoading} upcomingMatches={upcomingMatches} />
    </FlexColumnWrapper>
  );
};

export default AllUpcomingMatches;
