import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  createTournamentBracket,
  deleteTournamentBracket,
  getTournamentTree,
  removeUserFromCompetition,
  seedPlayersToCompetition,
} from '~/api';
import NotificationBadge from '~/components/Admin/Notification/Badge';
import SingleCompetitionInfo from '~/components/Admin/SingleCompetitionInfo';
import { Header, Name, Option, OptionsBox, OptionsButton, Status } from '~/pages/Admin/SingleLeague/styles';
import { selectedTournamentReset } from '~/store/tournaments/slice';
import { getSelectedTournamentMembers, getTournamentDetails } from '~/store/tournaments/utils';
import { Button, ErrorMessage, Grid, Icon, ScrollMenu, Spinner, useOutsideClick } from '~/ui';
import { getCompetitionStatus, getNearestPowerOf2, getNumberOfByePlayers, isDateBeforeCurrentDateTime } from '~/utils';

import DragAndDropPlayersTable from './DragAndDropPlayersTable';
import Notice from './Notice';
import Round from './Round';
import { DeleteButton, HideOnMobile, RoundName } from './styles';
import { columns, getNextRound, getPlayersData, getPreviousRound } from './utils.js';

const SingleTournamentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: urlCommunityId, tournamentId } = useParams();
  const selectedCommunityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { selectedTournament, selectedTournamentError } = useSelector(state => state.tournaments);

  const [loadingTournament, setLoadingTournament] = useState(false);
  const [showTournamentOptions, setShowTournamentOptions] = useState(false);
  const [tournamentTree, setTournamentTree] = useState({
    tournamentName: '',
    rounds: [],
  });
  const [tournamentTreeError, setTournamentTreeError] = useState('');
  const [selectedRound, setSelectedRound] = useState({
    roundMatches: [],
    roundNumber: 0,
  });
  const [reorderedPlayers, setReorderedPlayers] = useState([]);

  const tournamentStatus = getCompetitionStatus(selectedTournament);
  const isTournamentStarted = isDateBeforeCurrentDateTime(selectedTournament.startTime || null);
  const isTournamentRegistrationEnded = isDateBeforeCurrentDateTime(selectedTournament.registerEndTime || null);

  const optionsContainerRef = useRef();

  useOutsideClick(optionsContainerRef, () => setShowTournamentOptions(false));

  if (selectedCommunityId !== Number(urlCommunityId)) {
    dispatch(selectedTournamentReset());
    history.push(`/admin/community/${selectedCommunityId}/tournaments`);
  }

  useEffect(() => {
    loadTournament();
    loadTournamentTree();
  }, [dispatch, tournamentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadTournament = async () => {
    setLoadingTournament(true);
    await dispatch(getTournamentDetails(tournamentId));
    await dispatch(getSelectedTournamentMembers(tournamentId));
    setLoadingTournament(false);
  };

  const loadTournamentTree = () => {
    getTournamentTree(tournamentId)
      .then(res => {
        if (res.data.body.rounds.length) {
          setTournamentTree(res.data.body);
          setSelectedRound(res.data.body.rounds[0]);
        }
      })
      .catch(() => setTournamentTreeError("Can't load tournament brackets."));
  };

  const handleSeedBracket = () => {
    const playerIds = reorderedPlayers.map(member => member.accountId);

    setLoadingTournament(true);
    seedPlayersToCompetition(playerIds, tournamentId)
      .then(() => {
        createTournamentBracket(tournamentId)
          .then(() => loadTournamentTree())
          .catch(() => setTournamentTreeError('Something went wrong with creating tournament brackets. Try again.'));
      })
      .catch(() => {
        if (playerIds.length < 2) {
          setTournamentTreeError('At least 2 players needed to seed.');
        } else {
          setTournamentTreeError('Something went wrong with seeding. Try again.');
        }
      })
      .finally(() => setLoadingTournament(false));
  };

  const handleDeleteBracket = () => {
    setLoadingTournament(true);
    deleteTournamentBracket(tournamentId)
      .then(() => setTournamentTree({ ...tournamentTree, rounds: [] }))
      .catch(() => setTournamentTreeError('Something went wrong with deleting the bracket. Try again.'))
      .finally(() => setLoadingTournament(false));
  };

  const handleRemoveUserFromCompetition = user => {
    const { accountId, name } = user.original;

    setLoadingTournament(true);
    removeUserFromCompetition(accountId, tournamentId)
      .then(() => loadTournament())
      .catch(() => setTournamentTreeError(`Something went wrong with removing ${name} from the tournament. Try again.`))
      .finally(() => setLoadingTournament(false));
  };

  let previousRound, nextRound;
  if (selectedRound.roundNumber === 0) {
    previousRound = [];
    nextRound = [];
  } else {
    previousRound = getPreviousRound({ tournamentTree, selectedRound });
    nextRound = getNextRound({ tournamentTree, selectedRound });
  }

  const numberOfPlayers = selectedTournament?.members?.length;
  const nearestPowerOfPlayers = getNearestPowerOf2(numberOfPlayers);
  const numberOfByePlayers = getNumberOfByePlayers({ numberOfPlayers, nearestPowerOfPlayers });

  const RoundsMenu = () => {
    const rounds = tournamentTree.rounds.slice(0, tournamentTree.rounds.length);

    return rounds.map(({ roundNumber }) => {
      return (
        <RoundName key={roundNumber} pointer>
          Round {roundNumber}
        </RoundName>
      );
    });
  };

  return (
    <>
      {loadingTournament ? (
        <Spinner />
      ) : selectedTournamentError ? (
        <ErrorMessage>{selectedTournamentError}</ErrorMessage>
      ) : (
        <>
          <Header>
            <div>
              <Name>{selectedTournament.name}</Name>
              <Status>{tournamentStatus.status}</Status>
              {tournamentStatus.status === 'Registration ended' && tournamentTree.rounds.length === 0 && (
                <NotificationBadge dotColor='pending' text='Populate brackets' />
              )}
            </div>
            {!isTournamentStarted && (
              <Link to={`/admin/community/${selectedCommunityId}/tournament/${selectedTournament.id}/add-players`}>
                <Button disabled={tournamentTree.rounds.length > 0}>
                  <Icon color='tmGreen' cursor='pointer' icon='userPlus' />
                  Add players to Tournament
                </Button>
              </Link>
            )}
            {tournamentStatus.status !== 'Tournament ended' && (
              <div ref={optionsContainerRef} style={{ flex: 0 }}>
                <OptionsButton noBorder onClick={() => setShowTournamentOptions(true)}>
                  <Icon color={showTournamentOptions ? 'white' : 'ash'} cursor='pointer' icon='threeDots' />
                </OptionsButton>
                {showTournamentOptions && (
                  <OptionsBox>
                    <Link
                      to={`/admin/community/${selectedCommunityId}/tournament/${selectedTournament.id}/edit-tournament`}
                    >
                      <Option>Edit Tournament</Option>
                    </Link>
                    <Option onClick={() => setShowTournamentOptions(false)}>Cancel Tournament</Option>
                  </OptionsBox>
                )}
              </div>
            )}
          </Header>
          <SingleCompetitionInfo competition={selectedTournament} />
          {tournamentTreeError && <ErrorMessage red>{tournamentTreeError}</ErrorMessage>}
          {tournamentTree.rounds.length === 0 ? (
            <>
              <Notice
                firstText={
                  isTournamentRegistrationEnded
                    ? `Please select the seeding of participants for the tournament in the list below. 
                       In Round 1, the highest seeded player will play with the lowest seeded player, second highest with second lowest etc.`
                    : 'Please wait for tournament registrations to end before seeding the brackets.'
                }
                secondText={
                  isTournamentRegistrationEnded
                    ? `If the number of participants is different from 4, 8, 16, 32 etc. (“a power of 2” number), 
                the lowest seeded participants (below the green line) will start from Round 1. 
                All other participants (above the green line) will start from Round 2.`
                    : ''
                }
                title='Seed Tournament'
              />
              <DragAndDropPlayersTable
                colorLineUnderRowNumber={numberOfByePlayers}
                columns={columns}
                data={getPlayersData(selectedTournament.members)}
                error={selectedTournament.membersError}
                onChange={value => setReorderedPlayers(value)}
                onDelete={value => handleRemoveUserFromCompetition(value)}
              />
              {isTournamentRegistrationEnded && !isTournamentStarted && (
                <Button
                  disabled={tournamentTreeError || !selectedTournament.members.length}
                  onClick={handleSeedBracket}
                >
                  <Icon color='tmGreen' cursor='pointer' icon='brackets' />
                  Seed and Generate Brackets
                </Button>
              )}
            </>
          ) : (
            <>
              {!isTournamentStarted && !!tournamentTree.rounds.length && (
                <>
                  <Notice
                    firstText='The tournament tree below has been generated based on the seeding you chose. 
                      Until the tournament starts, you can delete this bracket and generate new brackets with a different seed.'
                    secondText='If you want to add or remove players from the tournament, 
                      please delete the existing bracket and create new one.'
                    title='Tournament Brackets'
                  />
                  <DeleteButton onClick={handleDeleteBracket}>
                    <Icon color='danger' cursor='pointer' icon='trash' />
                    Delete Tree
                  </DeleteButton>
                </>
              )}
              <ScrollMenu
                extraIcon='trophy'
                selected={selectedRound}
                values={RoundsMenu}
                onChange={roundNumber => {
                  const selected = tournamentTree.rounds.filter(round => round.roundNumber === Number(roundNumber))[0];
                  setSelectedRound(selected);
                }}
              />
              <Grid.Container>
                <Grid.Row>
                  {previousRound.length > 0 &&
                    previousRound.map(round => (
                      <Grid.Column columns={3} key={`Previous ${round.roundNumber}`}>
                        <HideOnMobile>
                          <Round round={round} />
                        </HideOnMobile>
                      </Grid.Column>
                    ))}
                  <Grid.Column columns={3}>
                    <Round round={selectedRound} />
                  </Grid.Column>
                  {nextRound.map(round => (
                    <Grid.Column columns={3} key={`Previous ${round.roundNumber}`}>
                      <HideOnMobile>
                        <Round round={round} />
                      </HideOnMobile>
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid.Container>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SingleTournamentPage;
