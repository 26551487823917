import debounce from 'lodash/debounce';
import React from 'react';

import { searchCourtsRequest, searchTown } from '~/api';
import { AsyncDropdown } from '~/ui';

let locationType;

function loadLocations(input, callback) {
  if (input.length >= 3) {
    if (locationType === 'towns') {
      searchTown(input).then(response => {
        const data = response.data.body.map(town => ({
          label: town.description,
          value: town,
        }));
        callback(data);
      });
    }

    if (locationType === 'courts') {
      searchCourtsRequest(input).then(response => {
        const data = response.data.body.map(court => ({
          label: court.formattedAddress,
          value: court,
        }));
        callback(data);
      });
    }
  } else {
    callback();
  }
}

function hometownDropdownMessage(input) {
  if (input.length < 3) {
    return 'Type in at least three characters';
  }
}

function AsyncLocationDropdown({ label = 'Town', placeholder = 'Search location...', type, value, onChange }) {
  locationType = type;

  return (
    <AsyncDropdown
      label={label}
      loadingMessage={({ inputValue }) => hometownDropdownMessage(inputValue)}
      loadOptions={debounce(loadLocations, 500)}
      noOptionsMessage={({ inputValue }) => hometownDropdownMessage(inputValue)}
      placeholder={placeholder}
      value={locationType === 'towns' ? value?.description : value?.formattedAddress}
      onChange={hometown => onChange(hometown.value)}
    />
  );
}

export default AsyncLocationDropdown;
