import axios from 'axios';
import jwt from 'jsonwebtoken';

import { isDateInThePast } from '~/utils';

import TokensService from '../tokens';

if (!process.env.REACT_APP_API_URL) {
  throw new Error('Missing env variable: REACT_APP_API_URL');
}

const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

instance.interceptors.request.use(config => {
  const accessToken = TokensService.tokensExists() ? TokensService.getAccessToken() : null;

  config.headers.Authorization = accessToken;

  return config;
});

const concurrentRequests = async requests => {
  return await Promise.all(requests)
    .then(responses => {
      return responses;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

instance.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = error;

    const refreshToken = TokensService.tokensExists() ? TokensService.getRefreshToken() : null;

    if (response && response.status === 401 && response.statusText === 'Unauthorized' && !refreshToken) {
      return Promise.reject({
        message: 'Your email address and password do not match. ',
      });
    } else if (response && response.status === 401 && refreshToken) {
      const decodedRefreshToken = jwt.decode(refreshToken);
      // backend returns time in milliseconds
      // calculating to get seconds
      const refreshTokenExpirationTime = parseInt(decodedRefreshToken.expirationTime / 1000);

      if (isDateInThePast(refreshTokenExpirationTime)) {
        TokensService.clearTokens();
      } else {
        const response = await instance.get(`/tokens?refreshToken=${refreshToken}`);

        const newTokens = response.data.body;
        TokensService.setTokens(newTokens);
      }

      return config;
    } else {
      return Promise.reject(error.response);
    }
  }
);

const TennisMateApi = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  concurrentRequests,
};

export default TennisMateApi;
