import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ANALYTICS_EVENTS } from '~/enums';
import { logout } from '~/store/auth/utils';
import { Icon, useOutsideClick } from '~/ui';
import { trackAnalyticsEvent } from '~/utils';

import { AccountMenuContainer, Avatar, Label, MenuItem, MenuList } from './styles';

const AccountMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const { firstName, lastName, avatarUrl } = useSelector(state => state.user);

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

  const ref = useRef();

  useOutsideClick(ref, () => setIsMenuOpen(false));

  const baseURL = process.env.REACT_APP_API_URL;

  return (
    <AccountMenuContainer ref={ref} onClick={() => setIsMenuOpen(true)}>
      <Avatar alt={`${firstName}'s avatar`} src={avatarUrl} />
      <Label>{fullName}</Label>
      <Icon cursor='pointer' icon={isMenuOpen ? 'chevron2Up' : 'chevron2Down'} size='s' />
      {isMenuOpen && (
        <MenuList>
          <MenuItem>
            <Link
              to='/home/my-profile'
              onClick={e => {
                e.stopPropagation();
                setIsMenuOpen(false);
              }}
            >
              My profile
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to='/home/my-profile/edit'
              onClick={e => {
                e.stopPropagation();
                setIsMenuOpen(false);
              }}
            >
              Edit profile
            </Link>
          </MenuItem>
          <MenuItem>
            <a href={`${baseURL}/legal/tm_app_help.html`}>FAQ</a>
          </MenuItem>
          <MenuItem>
            <a href={`${baseURL}/legal/tm_app_eula.html`}>Terms of Use</a>
          </MenuItem>
          <MenuItem>
            <a href={`${baseURL}/legal/tm_app_privacy_policy.html`}>Privacy Policy</a>
          </MenuItem>
          <MenuItem>
            <Link
              to='/'
              onClick={() => {
                trackAnalyticsEvent(ANALYTICS_EVENTS.USER_LOGGED_OUT);
                dispatch(logout());
              }}
            >
              Logout
            </Link>
          </MenuItem>
        </MenuList>
      )}
    </AccountMenuContainer>
  );
};

export default AccountMenu;
