import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  margin: 0 32px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin: 0 16px;
  }
`;

export const Title = styled.p`
  margin-top: 26px;
  color: ${colors.white};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const Notice = styled.p`
  color: ${colors.white};
  font-size: 16px;
  line-height: 24px;
`;
