import React from 'react';

import { Icon } from '~/ui';

import { Actions, FilterButton, Search } from './styles';

const SearchAndFilter = ({ searchValue, searchOnChange, filterOnClick, filterDisabled, filterActive = false }) => {
  return (
    <Actions>
      <Search icon='search' placeholder='Search' type='search' value={searchValue} onChange={searchOnChange} />
      <FilterButton active={filterActive} disabled={filterDisabled} noBorder onClick={filterOnClick}>
        <Icon color={filterActive ? 'tmGreen' : 'ash'} cursor='pointer' icon='filter' />
        Filter{filterActive && ': ON'}
      </FilterButton>
    </Actions>
  );
};

export default SearchAndFilter;
