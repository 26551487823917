import React from 'react';

import { Icon } from '~/ui';

import { Tab, TabsContainer } from './styles';

const Tabs = ({ tabs = [], selectedTab }) => {
  return (
    <TabsContainer>
      {tabs.map(tab => {
        const { value, label, icon, isDisabled, onSelect } = tab;
        const isSelected = selectedTab === value;

        return (
          <Tab
            isDisabled={isDisabled}
            isSelected={isSelected}
            key={value}
            onClick={() => {
              if (isDisabled) return;
              onSelect();
            }}
          >
            {icon && <Icon cursor='pointer' icon={icon} size='s' />}
            {label}
          </Tab>
        );
      })}
    </TabsContainer>
  );
};

export default Tabs;
