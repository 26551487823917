import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { createCommunityLeagueRequest, createCommunityTournamentRequest } from '~/api';
import CompetitionForm from '~/components/Admin/CompetitionForm';
import BackButton from '~/components/shared/BackButton';
import { ANALYTICS_EVENTS, COMPETITION_TYPE } from '~/enums';
import { PageTitle } from '~/ui';
import { trackAnalyticsEvent } from '~/utils/analytics';

const NewCompetitionPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { id: communityId } = useParams();

  const [competitionError, setCompetitionError] = useState('');

  const competitionType = location.pathname.split('/').pop().replace('new-', '');

  const handleCompetitionFormSubmit = async data => {
    try {
      let response;

      if (competitionType === COMPETITION_TYPE.LEAGUE) {
        response = await createCommunityLeagueRequest(data);
      } else {
        response = await createCommunityTournamentRequest(data);
        trackAnalyticsEvent(ANALYTICS_EVENTS.NEW_TOURNAMENT_CREATED);
      }

      const newCompetitionId = response.data.body;

      setCompetitionError('');
      history.push(`/admin/community/${communityId}/${competitionType}/${newCompetitionId}`);
    } catch (error) {
      if (error) {
        if (error.status === 400 || error.status === 500) {
          setCompetitionError('Something went wrong. Please try again.');
        }
      } else {
        setCompetitionError('Something went wrong. Check inserted informations.');
      }
    }
  };

  return (
    <>
      <BackButton onClick={() => history.goBack()} />
      <PageTitle title={`Create a ${competitionType}`} />
      <CompetitionForm
        competitionError={competitionError}
        competitionType={competitionType}
        formSubmit={handleCompetitionFormSubmit}
      />
    </>
  );
};

export default NewCompetitionPage;
