import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { cancelMatchInvite } from '~/api/matches';
import ModalContent from '~/components/shared/ModalContent/';
import { ErrorMessage } from '~/components/shared/styles';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button } from '~/ui';

const CancelOpenMatchModal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const { matchId } = useParams();

  const onMatchInviteCancel = () => {
    setIsLoading(true);
    setErrorMessage('');

    cancelMatchInvite(matchId)
      .then(() => {
        setIsLoading(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsLoading(false);
      });
  };

  return (
    <ModalContent title='Cancel Match' onClose={onClose}>
      <InfoMessage>Are you sure you want to cancel the challenge?</InfoMessage>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button color='white' isNewStyle onClick={onClose}>
          No, keep it.
        </Button>
        <Button color='danger' disabled={isLoading} isNewStyle onClick={onMatchInviteCancel}>
          {isLoading ? 'Canceling...' : 'Cancel'}
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default CancelOpenMatchModal;
