import React from 'react';

import UserWithAvatar from '~/components/shared/UserWithAvatar';
import { ROLE } from '~/enums';
import { getDateFromString, getDifferenceInYears, getPcrBalls, getSkillLevelNumber } from '~/utils';

import { MobileLabel, Role, StyledLink } from './styles';

export const getMembersData = ({ data = [] }) => {
  return data.map(member => {
    const { accountId, avatarUrl, birthday, gender, firstName, lastName, role, statsApi, tennisLevel } = member;

    return {
      accountId,
      age: getDifferenceInYears(getDateFromString(birthday, 'yyyy-MM-dd'), new Date()),
      avatarUrl,
      gender: gender.prettyName,
      name: `${firstName} ${lastName}`,
      role,
      statsApi,
      skillLevel: getSkillLevelNumber(tennisLevel.prettyName).toFixed(1),
    };
  });
};

export const getMembersColumns = () => {
  return [
    {
      label: 'Name',
      value: 'name',
      width: 250,
      sortBy: 'name',
      iconLeft: 'multipleUsers',
      renderCustomCell: member => {
        const { accountId, avatarUrl, name, role } = member;

        return (
          <StyledLink to={`/admin/profile/${accountId}`}>
            <UserWithAvatar avatarUrl={avatarUrl} userName={name} />
            {(role === ROLE.ADMIN || role === ROLE.OPERATOR) && <Role>{role}</Role>}
          </StyledLink>
        );
      },
    },
    {
      label: 'Pcr',
      value: 'pcr',
      renderCustomCell: member => {
        const { statsApi } = member;

        return getPcrBalls(statsApi.playerCredRating);
      },
    },
    {
      label: 'Skill Level',
      value: 'skillLevel',
      renderCustomCell: member => {
        const { skillLevel } = member;

        return (
          <>
            <MobileLabel>Skill level</MobileLabel>
            {skillLevel}
          </>
        );
      },
    },
    {
      label: 'Age',
      value: 'age',
      hideOnMobile: true,
    },
    {
      label: 'Gender',
      value: 'gender',
      hideOnMobile: true,
    },
  ];
};
