import styled from 'styled-components';

import { theme } from '~/ui';

import Logo from '../Logo';

const { colors, zIndex, mediaQueries } = theme;

export const Backdrop = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${zIndex.level4};

  @media screen and (max-width: ${mediaQueries.tablet}px) {
    display: block;
  }
`;

export const SideMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  flex-direction: column;
  width: 280px;
  height: 100vh;
  z-index: ${zIndex.level5};
  background-color: ${colors.highlight3};

  @media screen and (max-width: ${mediaQueries.tablet}px) {
    display: flex;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-bottom: 1px solid ${colors.invert};
  cursor: pointer;

  & span:not(.icon) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.white};
  }

  &:first-child span:not(.icon) {
    font-size: 18px;
  }

  &:first-child .icon rect {
    fill: none;
  }

  & .icon:last-child {
    margin-left: auto;
  }
`;

export const JoinCommunity = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 12px;
  border-bottom: 1px solid ${colors.invert};
  cursor: pointer;

  & span:last-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  & span {
    color: ${colors.tmGreen};
  }
`;

export const MobileLogo = styled(Logo)`
  > span:not(.icon) {
    @media screen and (max-width: ${mediaQueries.mobile}px) {
      display: inline-block;
    }
  }
`;
