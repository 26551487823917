import React from 'react';

import { CutomAsyncDropdown, Label } from './styles';

const AsyncDropdown = ({ label, loadingMessage, loadOptions, noOptionsMessage, onChange, placeholder, value }) => {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <CutomAsyncDropdown
        classNamePrefix={'CustomDropdown'}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        defaultInputValue={value}
        loadingMessage={loadingMessage}
        loadOptions={loadOptions}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default AsyncDropdown;
