import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Nav = styled.nav`
  display: flex;
  height: 64px;
  padding: 16px 32px;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  box-sizing: border-box;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    padding: 16px;
  }
`;

export const IconsContainer = styled.div`
  flex: 1 1 auto;

  > span:first-child {
    display: none;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    > .icon:first-child {
      display: inline-block;
      margin-right: 24px;
    }
  }
`;
