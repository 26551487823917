import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SideNavigation, TopNavigation } from '~/components/Admin/Navigation';
import { AdminRoutes } from '~/routes';
import { getMyCommunities } from '~/store/communities/utils';
import { Spinner } from '~/ui';

import { Container, Content } from './styles';

const AdminPage = ({ match }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { adminCommunities } = useSelector(state => state.communities);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [loadingCommunities, setLoadingCommunities] = useState(false);
  const parentRoute = match.path;

  useEffect(() => setShowMobileMenu(false), [location]);

  useEffect(() => {
    const loadCommunities = async () => {
      setLoadingCommunities(true);
      await dispatch(getMyCommunities());
      setLoadingCommunities(false);
    };

    loadCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const communitiesExist = !!adminCommunities.length;

  return (
    <>
      {loadingCommunities ? (
        <Spinner />
      ) : (
        <>
          <TopNavigation setShowMobileMenu={setShowMobileMenu} showMobileMenu={showMobileMenu} />
          <Container>
            {communitiesExist && <SideNavigation showMobileMenu={showMobileMenu} />}
            <Content fullWidth={!communitiesExist}>
              <AdminRoutes parentRoute={parentRoute} />
            </Content>
          </Container>
        </>
      )}
    </>
  );
};

export default AdminPage;
