import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  skillLevelRange: { min: 1.0, max: 7.0 },
  opponentAgeRange: { min: 16, max: 72 },
  opponentGender: null,
  communityId: null,
  competitionId: null,
  groupId: null,
  opponent: {},
  date: '',
  time: '',
  court: {},
  message: null,
  competitionName: '',
};

export const directMatchFormSlice = createSlice({
  name: 'newMatchForm',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    updateFormField: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export default directMatchFormSlice.reducer;

export const { resetForm, updateFormField } = directMatchFormSlice.actions;
