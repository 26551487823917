export const DATE_FORMATS = {
  DEFAULT: 'dd/MM/yyyy',
  WITH_MONTH_NAME: 'MMMM dd, yyyy',
  DEFAULT_WITH_TIME: "EEE MMM d 'at' H:mm",
  LONG_WITH_TIME: "MMMM do, yyyy 'at' H:mm",
  SHORT_WITH_TIME: 'dd/MM/yyyy H:mm',
  WITH_DOT_TIME: "EEE MMM d yyyy '•' H:mm",
  WITH_DAY_AND_MONTH_NAME: "EEEE, LLLL do 'at' H:mm",
  MONTH_DAY_WITH_TIME: "MMM do 'at' H:mm",
  SHORT_MONTH_WITH_DAY: 'MMM dd',
  LONG_MONTH_WITH_DAY: 'MMMM dd',
  MONTH_WITH_DAY_NAME_AND_TIME: "EEE MMM do 'at' H:mm",
};
