import queryString from 'query-string';

import { getUserProfileRequest } from '~/api';
import { TennisMateApi } from '~/services';

export const getCommunityLeaguesRequest = communityId => {
  return TennisMateApi.get(`/leagues/community/${communityId}`);
};

export const createCommunityLeagueRequest = data => {
  return TennisMateApi.post('/leagues', data);
};

export const getLeagueByIdRequest = leagueId => {
  return TennisMateApi.get(`/leagues/${leagueId}`);
};

export const getLeagueDetailsRequest = leagueId => {
  return TennisMateApi.get(`/league-screen/${leagueId}`);
};

export const editLeagueRequest = data => {
  return TennisMateApi.put('/leagues', data);
};

export const getLeagueMembersRequest = leagueId => {
  return TennisMateApi.get(`/leagues/${leagueId}/users`);
};

export const createLeagueGroup = (leagueId, data) => {
  return TennisMateApi.post(`/leagues/${leagueId}/groups`, data);
};

export const createLeagueGroups = async (leagueId, groups) => {
  const groupsCalls = groups.map(group =>
    createLeagueGroup(leagueId, {
      name: group,
      leagueGroupStatus: 'ACTIVE',
    })
  );

  await TennisMateApi.concurrentRequests(groupsCalls);
  const leagueInfo = await getLeagueDetailsRequest(leagueId);
  const leagueMembers = await getLeagueMembersRequest(leagueId);

  const leagueMembersProfiles = await Promise.all(
    leagueMembers.data.body.map(async user => {
      const response = await getUserProfileRequest(user.accountId);
      const userProfileData = response.data.body;

      return {
        leagueStatus: user.status,
        leagueGroupId: user.groupId,
        ...userProfileData,
      };
    })
  );

  return {
    leagueInfo: leagueInfo.data.body,
    leagueMembersProfiles,
  };
};

export const addUserToLeagueGroup = (accountId, groupId) => {
  return TennisMateApi.post(`/leagues/groups/${groupId}/users/${accountId}`);
};

export const addUsersToLeagueGroup = async (users, groupId) => {
  const usersCalls = users.map(user => addUserToLeagueGroup(user, groupId));

  return await TennisMateApi.concurrentRequests(usersCalls);
};

export const deleteUserFromLeagueGroup = async (accountId, groupId) => {
  return await TennisMateApi.delete(`leagues/groups/${groupId}/users/${accountId}`);
};

export const changeUserLeagueGroup = async (accountId, currentGroupId, newGroupId) => {
  await deleteUserFromLeagueGroup(accountId, currentGroupId);
  return await addUserToLeagueGroup(accountId, newGroupId);
};

export const deleteUsersFromLeagueGroup = async (users, groupId) => {
  const usersCalls = users.map(user => deleteUserFromLeagueGroup(user, groupId));
  return await TennisMateApi.concurrentRequests(usersCalls);
};

export const deleteGroupById = async groupId => {
  return await TennisMateApi.delete(`/leagues/groups/${groupId}`);
};

export const getCommunityLeaguesForUserRequest = async (communityId, accountId) => {
  return await TennisMateApi.get(`/mobile/v1/community/${communityId}/user/${accountId}/leagues`);
};

export const getCommunityFinishedLeaguesRequest = async (communityId, params = { pageSize: 10, pageNumber: 1 }) => {
  const query = queryString.stringify(params);
  return await TennisMateApi.get(`/leagues/communities/${communityId}/finished-leagues-screen?${query}`);
};
