import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const CourtName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  color: ${colors.white};
  display: block;
`;

export const CourtAddress = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.6;
  color: ${colors.ash2};
  display: block;
`;
