export const ROLE = {
  ADMIN: 'ADMIN',
  OPERATOR: 'OPERATOR',
  MEMBER: 'MEMBER',
};

export const GENDER = {
  BOTH: 'BOTH',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const MEMBER_ERROR_MESSAGE = {
  UNABLE_TO_REMOVE_ADMIN: 'UNABLE_TO_REMOVE_ADMIN',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  USER_NOT_IN_COMPETITION: 'USER_NOT_IN_COMPETITION',
  USER_ALREADY_PARTICIPATING_IN_COMPETITION: 'USER_ALREADY_PARTICIPATING_IN_COMPETITION',
};
