import get from 'lodash/get';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UserInfoModal, WidgetHeader, WidgetWrapper } from '~/components/shared';
import { ModalWrapper } from '~/pages/Match/NewMatch/styles';
import { Modal } from '~/ui/index';

import FinalMatchItem from './FinalMatchItem';
import MatchScheduledItem from './MatchScheduledItem';
import NoOpponentItem from './NoOpponentItem';
import PendingMatchItem from './PendingMatchItem';
import PreviousMatches from './PreviousMatches';
import ScheduleMatchItem from './ScheduleMatchItem';
import UserOutOfTournamentItem from './UserOutOfTournamentItem';

const MyMatches = () => {
  const { data } = useSelector(state => state.tournamentScreen);
  const { tournamentId } = useParams();
  const [showOpponentModal, setShowOpponentModal] = useState(false);

  const communityId = get(data, ['community', 'communityInfo', 'id'], null);
  const communityName = get(data, ['community', 'communityInfo', 'communityName'], '');
  const court = get(data, ['tournamentResponse', 'court'], {});
  const nextOpponent = get(data, ['nextOpponent'], null);
  const opponent = get(data, ['nextOpponent', 'opponent'], {});
  const winners = get(data, ['tournamentWinners'], []);
  const tournamentConcluded = get(data, ['tournamentResponse', 'concluded'], false);

  const { match, matchInviteContract } = data;

  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgFlag' iconColor='orange' title='My Matches' />
      <ScheduleMatchItem
        communityId={+communityId}
        competitionId={+tournamentId}
        court={court}
        match={match}
        matchInvite={matchInviteContract}
        nextOpponent={nextOpponent}
        onNextOpponentClick={() => setShowOpponentModal(true)}
      />
      <PendingMatchItem
        communityName={communityName}
        match={match}
        matchInvite={matchInviteContract}
        opponent={opponent}
      />
      <MatchScheduledItem match={match} nextOpponent={nextOpponent} />
      <NoOpponentItem nextOpponent={nextOpponent} />
      <UserOutOfTournamentItem nextOpponent={nextOpponent} winners={winners} />
      <FinalMatchItem tournamentConcluded={tournamentConcluded} winners={winners} />
      <PreviousMatches />
      {showOpponentModal && (
        <Modal ContentWrapper={ModalWrapper}>
          <UserInfoModal opponentId={opponent.accountId} onClose={() => setShowOpponentModal(false)} />
        </Modal>
      )}
    </WidgetWrapper>
  );
};

export default MyMatches;
