import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Info = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
  flex: 1.5;
`;

export const Time = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.ash2};
  flex: 1;
  text-align: right;
`;
