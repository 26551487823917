import React, { useCallback } from 'react';

import { COMMUNITY_USER_STATUS } from '~/enums';
import { Button, Icon } from '~/ui';

import RequestCommunityJoinButton from './RequestCommunityJoinButton';
import { ButtonsContainer, CommunityMembersInfo, CommunityUserStatusWrapper, InviteTextTitle } from './styles';

const CommunityUserStatus = ({ communityId, communityStatus, communityName, numberOfMembers }) => {
  const renderButtons = useCallback(() => {
    switch (communityStatus) {
      case COMMUNITY_USER_STATUS.NOT_JOINED:
        return <RequestCommunityJoinButton communityId={communityId}>Join {communityName}</RequestCommunityJoinButton>;
      case COMMUNITY_USER_STATUS.NOT_JOINED_PRIVATE:
        return (
          <Button disabled isNewStyle>
            <Icon color='ash3' icon='lock2' size='s' />
            Join {communityName}
          </Button>
        );
      case COMMUNITY_USER_STATUS.PENDING_USER:
        return (
          <>
            <Button isNewStyle>Accept Invite</Button>
            <Button color='danger' isNewStyle>
              Decline
            </Button>
          </>
        );
      default:
        return null;
    }
  }, [communityStatus, communityId, communityName]);

  const inviteTextTitle =
    COMMUNITY_USER_STATUS.PENDING_USER === communityStatus
      ? `${communityName} has invited you to join their community.`
      : `Please wait for someone from ${communityName} to review your request.`;

  if (communityStatus === COMMUNITY_USER_STATUS.JOINED) return null;

  return (
    <CommunityUserStatusWrapper>
      {[COMMUNITY_USER_STATUS.PENDING_COMMUNITY, COMMUNITY_USER_STATUS.PENDING_USER].includes(communityStatus) && (
        <InviteTextTitle>{inviteTextTitle}</InviteTextTitle>
      )}
      <ButtonsContainer>{renderButtons()}</ButtonsContainer>
      {[
        COMMUNITY_USER_STATUS.NOT_JOINED,
        COMMUNITY_USER_STATUS.NOT_JOINED_PRIVATE,
        COMMUNITY_USER_STATUS.PENDING_USER,
      ].includes(communityStatus) && (
        <CommunityMembersInfo>
          Join {numberOfMembers} other people at {communityName}
        </CommunityMembersInfo>
      )}
    </CommunityUserStatusWrapper>
  );
};

export default CommunityUserStatus;
