import capitalize from 'lodash/capitalize';

import { GENDER } from '~/enums/member';
import { getDateFromString, getDifferenceInYears } from '~/utils';

export const filterMembersByName = ({ name, data }) => {
  return data.filter(({ firstName, lastName }) => {
    const fullName = `${firstName} ${lastName}`;

    return fullName.toLowerCase().includes(name.toLowerCase().trim());
  });
};

export const filterMembersByGender = ({ gender, data }) => {
  return data.filter(user => {
    if (gender.toLowerCase() === 'both') {
      return user;
    } else {
      return user.gender.prettyName.toLowerCase() === gender.toLowerCase();
    }
  });
};

export const filterMembersByPcrRange = ({ pcrRange, data }) => {
  return data.filter(({ statsApi }) => {
    return statsApi.playerCredRating >= pcrRange.min && statsApi.playerCredRating <= pcrRange.max;
  });
};

export const filterMembersByAgeRange = ({ ageRange, data }) => {
  return data.filter(({ birthday }) => {
    const userAge = getDifferenceInYears(getDateFromString(birthday, 'yyyy-MM-dd'), new Date());

    return userAge >= ageRange.min && userAge <= ageRange.max;
  });
};

export const filterMembersBySkillLevelRange = ({ skillLevelRange, data }) => {
  return data.filter(({ tennisLevel }) => {
    let userTennisLevel = tennisLevel.prettyName.replace('Level ', '');
    userTennisLevel = parseFloat(userTennisLevel);

    return userTennisLevel >= skillLevelRange.min && userTennisLevel <= skillLevelRange.max;
  });
};

export const genderOptions = [
  { value: GENDER.MALE, label: capitalize(GENDER.MALE) },
  { value: GENDER.FEMALE, label: capitalize(GENDER.FEMALE) },
  { value: null, label: capitalize(GENDER.BOTH) },
];
