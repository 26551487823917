import styled from 'styled-components';

import { theme } from '~/ui';

const { mediaQueries } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  margin-bottom: 50px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 290px;
  }
`;
