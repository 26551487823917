import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import InitialAppFormContainer from '~/components/InitialAppFormContainer';
import LoginForm from '~/components/LoginForm';
import SignupFrom from '~/components/SignupForm';
import { userResetError } from '~/store/auth/slice';

import { FormTypeContainer, FormTypeSelect } from './styles';

const LoginAndSignupPage = () => {
  const [selectedFormType, setSelectedFormType] = useState('login');
  const dispatch = useDispatch();

  const handleSelectedFormType = e => setSelectedFormType(e.target.id);

  useEffect(() => {
    dispatch(userResetError());
  }, [dispatch, selectedFormType]);

  return (
    <InitialAppFormContainer>
      <FormTypeContainer>
        <FormTypeSelect
          active={selectedFormType === 'signup' ? true : false}
          id='signup'
          onClick={handleSelectedFormType}
        >
          Sign Up
        </FormTypeSelect>
        <FormTypeSelect
          active={selectedFormType === 'login' ? true : false}
          id='login'
          onClick={handleSelectedFormType}
        >
          Log In
        </FormTypeSelect>
      </FormTypeContainer>
      {selectedFormType === 'login' ? <LoginForm /> : <SignupFrom />}
    </InitialAppFormContainer>
  );
};

export default LoginAndSignupPage;
