export const renameUserProperties = user => {
  const renamedProperties = {
    ...user.userProfileContract,
    apiMate: user.apiMate,
    mateInvite: user.mateInvite,
    mateProfileInviteStatus: user.mateProfileInviteStatus,
    statsApi: user.userStatsApi,
  };

  return renamedProperties;
};

export const getUserMatchesStatistics = user => {
  let totalMatches,
    wonMatches,
    lostMatches,
    winRatio = 0;

  if (!user?.statsApi) return {};

  if ((user.statsApi.matchesPlayed && user.statsApi.matchesWon) !== undefined) {
    totalMatches = user.statsApi.matchesPlayed;
    wonMatches = user.statsApi.matchesWon;
    lostMatches = totalMatches - wonMatches;

    if (totalMatches && wonMatches) {
      winRatio = Number(((wonMatches / totalMatches) * 100).toFixed(1));
    }
  }

  return {
    totalMatches,
    wonMatches,
    lostMatches,
    winRatio,
  };
};
