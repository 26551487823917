import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries, zIndex } = theme;

export const MenuContainer = styled.ul`
  width: calc(360px - 2 * 32px);
  padding: 0 32px;
  position: absolute;
  right: 0;
  top: 48px;
  background-color: ${colors.highlight3};
  text-align: right;
  list-style: none;
  z-index: ${zIndex.level4};

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: calc(209px - 2 * 32px);
  }
`;

export const MenuItem = styled.li`
  border-bottom: 1px solid ${colors.invert};
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  &:first-child {
    padding-top: 24px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 24px;
  }

  a:not(.icon) {
    color: ${colors.whiteOpacity._75};

    > .icon {
      display: inline-block;
      margin-left: 12px;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    padding: 12px 8px;
  }
`;
