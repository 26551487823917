import React from 'react';
import { useSelector } from 'react-redux';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';

import CompetitionItem from '../../shared/CompetitionItem';
import ListItemsStatusFeedback from '../../shared/ListItemStatusFeedback';

const MyCompetitions = () => {
  const { data, errorMessage, isLoading } = useSelector(state => state.homeScreen);

  const { myCompetitions } = data;

  return (
    <WidgetWrapper>
      <WidgetHeader icon='filledBgTrophy' iconColor='tmGreen' title='My Competitions' />
      {myCompetitions.length > 0 ? (
        myCompetitions.map(competition => {
          const { competitionType, competitionTitle, communityTitle, competitionId } = competition;

          return (
            <CompetitionItem
              id={competitionId}
              info={communityTitle}
              key={competitionId + competitionTitle}
              name={competitionTitle}
              type={competitionType}
            />
          );
        })
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='You are not participating in any competitions.'
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isLoading}
        />
      )}
    </WidgetWrapper>
  );
};

export default MyCompetitions;
