import { getTournamentScreenRequest } from '~/api';

import {
  setIsLoadingTournamentScreen,
  setTournamentScreenData,
  setTournamentScreenErrorMessage,
} from './tournamentScreen.slice';

export const getTournamentScreen = tournamentId => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingTournamentScreen());

    const response = await getTournamentScreenRequest(tournamentId);
    const data = response.data.body;

    const { communityId } = data.tournamentResponse;

    const community = getState().communities.allUserCommunities.find(c => c.communityInfo.id === communityId);

    dispatch(setTournamentScreenData({ ...data, community }));
  } catch (e) {
    dispatch(
      setTournamentScreenErrorMessage('Something went wrong with getting tournament details. Please try again.')
    );
  }
};
