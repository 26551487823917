import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const InfoFlexContianer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UserName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.tmGreen};
`;

export const ShowPreviousText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.tmGreen};

  + .icon {
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
    transition: transform 0.25s ease;
  }
`;

export const WaitingForOpponentStatusIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.ash3};
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  font-style: italic;
  color: ${colors.ash};
  border-radius: 50%;
  flex-shrink: 0;
`;

export const WaitingForOpponentMessage = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: ${colors.ash};
  margin: 0;

  & strong {
    color: ${colors.tmGreen};
  }
`;

export const PreviousMatchesContainer = styled.div`
  height: fit-content;
  max-height: ${({ isExpanded }) => (isExpanded ? '10000px' : '0px')};
  transition: max-height 0.25s ease;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const FinalMatchText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: ${colors.ash};
  margin: 0;

  & strong {
    color: ${colors.white};
  }
`;
