import styled, { css } from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 12px 0;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: ${colors.whiteOpacity._75};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const TextareaContainer = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 110px;
  padding: 16px;
  border: none;
  border-bottom: 1px solid ${colors.invert};
  background: ${colors.highlight2};
  color: ${colors.whiteOpacity._75};
  box-sizing: border-box;
  outline: none;
`;

const smallIconCss = css`
  left: 11px;
  top: 12px;
`;

const mediumIconCss = css`
  left: 10px;
  top: 8px;
`;

export const NewStyleContainer = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0px 2px 1px rgba(18, 22, 39, 0.02);

  & .icon {
    position: absolute;
    ${({ leftIconSize }) => (leftIconSize === 's' ? smallIconCss : mediumIconCss)};
  }
`;

export const NewStyleTextarea = styled.textarea`
  border: 1px solid ${({ error }) => (error ? colors.danger : colors.ash3)};
  padding: ${({ withIcon }) => (withIcon ? '8px 12px 8px 40px' : '8px 12px 8px 8px')};
  border-radius: 4px;
  width: 100%;
  color: ${colors.white};
  background-color: ${colors.highlight3};
  font-size: 14px;
  line-height: 24px;
  outline: none;
  box-sizing: border-box;
  resize: vertical;

  &::placeholder {
    color: ${colors.whiteOpacity._25};
  }
`;
