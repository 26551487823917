import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isReversed }) => (isReversed ? 'flex-end' : 'flex-start')};
`;

export const Message = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.ash};
  margin: 0 32px;
`;
