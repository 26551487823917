import {
  getCommunityTournamentsForUserRequest,
  getCommunityTournamentsRequest,
  getTournamentDetailsRequest,
  getTournamentMembersRequest,
  getUserProfileRequest,
} from '~/api';
import { renameCommunityTournamentsScreenProperties } from '~/utils/tournaments';

import {
  communityTournamentsScreen,
  communityTournamentsScreenError,
  communityTournamentsScreenLoading,
  selectedTournament,
  selectedTournamentError,
  selectedTournamentMembers,
  selectedTournamentMembersError,
  tournamentsError,
  tournamentsList,
} from './slice';

export const getCommunityTournaments = communityId => async dispatch => {
  try {
    const response = await getCommunityTournamentsRequest(communityId);
    const tournamentsListData = response.data.body;

    dispatch(tournamentsList(tournamentsListData));
  } catch (error) {
    dispatch(tournamentsError("Can't load community tournaments."));
  }
};

export const getTournamentDetails = tournamentId => async dispatch => {
  try {
    const response = await getTournamentDetailsRequest(tournamentId);
    const tournamentData = response.data.body;

    dispatch(selectedTournament(tournamentData));
  } catch (error) {
    dispatch(selectedTournamentError("Can't load tournament data."));
  }
};

export const getSelectedTournamentMembers = tournamentId => async dispatch => {
  try {
    const response = await getTournamentMembersRequest(tournamentId);
    const tournamentMembers = response.data.body;

    const tournamentMembersProfiles = await Promise.all(
      tournamentMembers.map(async user => {
        const response = await getUserProfileRequest(user.accountId);
        const userProfileData = response.data.body;

        return userProfileData;
      })
    );

    dispatch(selectedTournamentMembers(tournamentMembersProfiles));
  } catch (error) {
    dispatch(selectedTournamentMembersError("Can't load tournament members."));
  }
};

export const getCommunityTournamentsScreen = (communityId, accountId) => async dispatch => {
  try {
    dispatch(communityTournamentsScreenLoading());

    const response = await getCommunityTournamentsForUserRequest(communityId, accountId);
    const data = renameCommunityTournamentsScreenProperties(response.data.body);

    dispatch(communityTournamentsScreen(data));
  } catch (error) {
    dispatch(
      communityTournamentsScreenError('Something went wrong with loading community tournaments. Please try again.')
    );
  }
};
