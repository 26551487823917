import { getMatchDetailsScreenRequest } from '~/api';

import { setIsLoadingMatch, setMatchData, setMatchErrorMessage } from './matchScreen.slice';

export const getMatchDetailsScreen = matchId => async dispatch => {
  try {
    dispatch(setIsLoadingMatch());
    const response = await getMatchDetailsScreenRequest(matchId);
    const matchScreenData = response.data.body;

    dispatch(setMatchData(matchScreenData));
  } catch (error) {
    dispatch(setMatchErrorMessage('Something went wrong with getting match details. Please try again.'));
  }
};
