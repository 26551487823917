import { sleep } from '~/utils';

const members = [
  {
    accountId: 422,
    firstName: 'Boris',
    lastName: 'Mijac',
    gender: {
      gender: 'MALE',
      prettyName: 'Male',
    },
    hometown: {
      placeId: 'ChIJOcwCyZLWZUcRisL7KJYkRTo',
      description: 'Zagreb, Hrvatska',
      city: 'Zagreb',
      state: null,
      country: 'Hrvatska',
      location: {
        lat: 45.81501,
        lon: 15.981919,
      },
    },
    birthday: '1991-03-20',
    tennisLevel: {
      skillLevel: 'LEVEL_1_0',
      prettyName: 'Level 1.0',
      description: 'This player is just starting to play tennis.',
    },
    avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/user-default-image.png',
    playingStyle: {
      playingStyle: 'CHOP_SLICER',
      prettyName: 'Chop Slicer',
      description:
        'Mastered the chop slice shot so well, rarely bother hitting a flat or topspin shot. Can put the chopped slice deep, short, angled and lobbed.',
    },
    playingHand: {
      playingHand: 'LEFT',
      prettyName: 'Left',
    },
    aboutMe: null,
    phone: {
      countryCode: '+1',
      phoneNumber: '222222',
    },
    preferredMatchTime: 0,
    favoritePlayer: null,
    favoriteATPTournament: null,
    favoriteRacket: null,
    favoriteBalls: null,
    favoriteApparel: null,
    apiMate: null,
    mateInvite: null,
    mateProfileInviteStatus: 'NOT_INVITED',
    statsApi: {
      accountId: 422,
      xp: 100,
      playerCredRating: 5,
      noOfPcrReviews: 0,
      winLose: [],
      matchesPlayed: 0,
      matchesWon: 0,
    },
    role: 'OPERATOR',
  },
  {
    accountId: 423,
    firstName: 'Luka',
    lastName: 'Zrnic',
    gender: {
      gender: 'MALE',
      prettyName: 'Male',
    },
    hometown: {
      placeId: 'ChIJOcwCyZLWZUcRisL7KJYkRTo',
      description: 'Zagreb, Hrvatska',
      city: 'Zagreb',
      state: null,
      country: 'Hrvatska',
      location: {
        lat: 45.81501,
        lon: 15.981919,
      },
    },
    birthday: '1995-08-12',
    tennisLevel: {
      skillLevel: 'LEVEL_4_0',
      prettyName: 'Level 4.0',
      description:
        'This player has dependable strokes, including directional intent, on both forehand and backhand sides on moderate shots, plus the ability to use lobs, overheads, approach shots and volleys with some success. This player occasionally forces errors when serving and teamwork in doubles is evident.',
    },
    avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/423?timestamp=1606316736',
    playingStyle: {
      playingStyle: 'RETRIEVER',
      prettyName: 'Retriever',
      description: "Retrieving any shot as a “human backboard”. Hitting consistently and relying on opponent's misses.",
    },
    playingHand: {
      playingHand: 'RIGHT',
      prettyName: 'Right',
    },
    aboutMe:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    phone: {
      countryCode: '+385',
      phoneNumber: '919708053',
    },
    preferredMatchTime: 0,
    favoritePlayer: null,
    favoriteATPTournament: null,
    favoriteRacket: null,
    favoriteBalls: null,
    favoriteApparel: null,
    apiMate: null,
    mateInvite: null,
    mateProfileInviteStatus: 'NOT_INVITED',
    statsApi: {
      accountId: 423,
      xp: 0,
      playerCredRating: 5,
      noOfPcrReviews: 0,
      winLose: [],
      matchesPlayed: 0,
      matchesWon: 0,
    },
    role: 'ADMIN',
  },
  {
    accountId: 434,
    firstName: 'Norma',
    lastName: 'Smith',
    gender: {
      gender: 'FEMALE',
      prettyName: 'Female',
    },
    hometown: {
      placeId: 'ChIJIz2AXDxTUkYRuGeU5t1-3QQ',
      description: 'Copenhagen, Denmark',
      city: 'Copenhagen',
      state: null,
      country: 'Denmark',
      location: {
        lat: 55.676098,
        lon: 12.568337,
      },
    },
    birthday: '1985-12-01',
    tennisLevel: {
      skillLevel: 'LEVEL_2_5',
      prettyName: 'Level 2.5',
      description:
        'This player is learning to judge where the ball is going although court coverage is weak. This player can sustain a slow rally with other players of same ability.',
    },
    avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/434?timestamp=1603449595',
    playingStyle: {
      playingStyle: 'BASELINER',
      prettyName: 'Baseliner',
      description: 'Playing near the baseline and using varied ground strokes. Preferring long exchanges.',
    },
    playingHand: {
      playingHand: 'RIGHT',
      prettyName: 'Right',
    },
    aboutMe: null,
    phone: {
      countryCode: '+385',
      phoneNumber: '910000000',
    },
    preferredMatchTime: 0,
    favoritePlayer: null,
    favoriteATPTournament: null,
    favoriteRacket: null,
    favoriteBalls: null,
    favoriteApparel: null,
    apiMate: null,
    mateInvite: null,
    mateProfileInviteStatus: 'NOT_INVITED',
    statsApi: {
      accountId: 434,
      xp: 100,
      playerCredRating: 5,
      noOfPcrReviews: 0,
      winLose: [],
      matchesPlayed: 0,
      matchesWon: 0,
    },
    role: 'OPERATOR',
  },
  {
    accountId: 435,
    firstName: 'Mathew',
    lastName: 'Thompson',
    gender: {
      gender: 'MALE',
      prettyName: 'Male',
    },
    hometown: {
      placeId: 'ChIJOcwCyZLWZUcRisL7KJYkRTo',
      description: 'Zagreb, Hrvatska',
      city: 'Zagreb',
      state: null,
      country: 'Hrvatska',
      location: {
        lat: 45.81501,
        lon: 15.981919,
      },
    },
    birthday: '1970-09-01',
    tennisLevel: {
      skillLevel: 'LEVEL_6_0',
      prettyName: 'Level 6.0',
      description:
        'These players will generally not need NRTP rankings. Rankings or past rankings will speak for themselves. The 6.0 player typically has had intensive training for national tournament competition at the junior level and collegiate levels and has obtained a sectional or national ranking.',
    },
    avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/435?timestamp=1603449769',
    playingStyle: {
      playingStyle: 'JUNK_BALLER',
      prettyName: 'Junk Baller',
      description: 'Varying height, depth, angles and spin constantly. Rarely making unforced errors.',
    },
    playingHand: {
      playingHand: 'BOTH',
      prettyName: 'Both',
    },
    aboutMe: null,
    phone: {
      countryCode: '+385',
      phoneNumber: '910000000',
    },
    preferredMatchTime: 0,
    favoritePlayer: null,
    favoriteATPTournament: null,
    favoriteRacket: null,
    favoriteBalls: null,
    favoriteApparel: null,
    apiMate: null,
    mateInvite: null,
    mateProfileInviteStatus: 'NOT_INVITED',
    statsApi: {
      accountId: 435,
      xp: 100,
      playerCredRating: 5,
      noOfPcrReviews: 0,
      winLose: [],
      matchesPlayed: 0,
      matchesWon: 0,
    },
    role: 'MEMBER',
  },
  {
    accountId: 466,
    firstName: 'Boki',
    lastName: 'Boki',
    gender: {
      gender: 'MALE',
      prettyName: 'Male',
    },
    hometown: {
      placeId: 'ChIJOcwCyZLWZUcRisL7KJYkRTo',
      description: 'Zagreb, Hrvatska',
      city: 'Zagreb',
      state: null,
      country: 'Hrvatska',
      location: {
        lat: 45.81501,
        lon: 15.981919,
      },
    },
    birthday: '1998-11-16',
    tennisLevel: {
      skillLevel: 'LEVEL_5_0',
      prettyName: 'Level 5.0',
      description:
        'This player has good shot anticipation and frequently has an outstanding shot or attribute around which a game may be structured. This player can regularly hit winners or force errors off of short balls, can put away volleys, can successfully execute lobs, drop shots, half volleys and overhead smashes, and has good depth and spin on most second serves.',
    },
    avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/user-default-image.png',
    playingStyle: {
      playingStyle: 'UNIVERSAL',
      prettyName: 'Universal',
      description:
        'Being able to use any of the playing styles. Adapting own style according to what is needed to beat the opponent.',
    },
    playingHand: {
      playingHand: 'RIGHT',
      prettyName: 'Right',
    },
    aboutMe: null,
    phone: {
      countryCode: '+1',
      phoneNumber: '12345678',
    },
    preferredMatchTime: 0,
    favoritePlayer: null,
    favoriteATPTournament: null,
    favoriteRacket: null,
    favoriteBalls: null,
    favoriteApparel: null,
    apiMate: null,
    mateInvite: null,
    mateProfileInviteStatus: 'NOT_INVITED',
    statsApi: {
      accountId: 466,
      xp: 100,
      playerCredRating: 5,
      noOfPcrReviews: 0,
      winLose: [],
      matchesPlayed: 0,
      matchesWon: 0,
    },
    role: 'ADMIN',
  },
];

const mockMatches = [
  {
    id: 1,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 2,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610539200,
    location: 'Tenis Centar Tica',
  },
  {
    id: 3,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 4,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 5,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 6,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 7,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 8,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 9,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 10,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 11,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 12,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 13,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 14,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 15,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 16,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 17,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 18,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 19,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 422), members.find(member => member.accountId === 423)],
    competition: null,
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Maksimir',
  },
  {
    id: 20,
    type: 'DIRECT',
    players: [members.find(member => member.accountId === 434), members.find(member => member.accountId === 435)],
    competition: null,
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Aaaaa',
  },
  {
    id: 22,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 3,
      name: 'Test Liga',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Brki',
  },
  {
    id: 21,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 2,
      name: 'Zagreb Open',
      competitionType: 'tournament',
    },
    status: 'SCHEDULED',
    startTime: 1610557439,
    location: 'Zrna',
  },
  {
    id: 23,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 24,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 25,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 26,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 27,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 28,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 29,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 30,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 31,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 32,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'HOST_CHALLENGED_RESULT',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 33,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 34,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 35,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 36,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 37,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 38,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 39,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 40,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 41,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 42,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 43,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 44,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
  {
    id: 45,
    type: 'COMPETITION',
    players: [members.find(member => member.accountId === 435), members.find(member => member.accountId === 466)],
    competition: {
      id: 1,
      name: 'Božićni Turnir',
      competitionType: 'league',
    },
    status: 'CONFIRMED',
    startTime: 1610557439,
    location: 'Tenis Centar Tica',
  },
];

const mockMatch = {
  notices: [],
  errors: [],
  body: {
    match: {
      id: 171,
      hostId: 604,
      guestId: 598,
      court: {
        placeId: 'ChIJxzNxXQ915kcRTo7x4MS_fqs',
        name: 'USO Athis Mons Tennis',
        imageUrl: 'https://s3.amazonaws.com/tennis-mate-dev/court_images/court-default-image.png;',
        location: {
          lat: 48.720993,
          lon: 2.4082377,
        },
        formattedAddress: '1 Rue Roland Garros, 94390 Athis-Mons, France',
      },
      matchStatus: 'CONFIRMED',
      matchTime: 1805310202,
      communityId: null,
      winningUserId: 598,
      participants: [604, 598],
    },
    matchInvite: {
      id: 171,
      matchInviteStatus: 'AGREED',
      challengerId: 604,
      type: 'OPEN',
      playTime: 1805310202,
      inviteTime: 1506338523,
      opponentId: 598,
      court: {
        placeId: 'ChIJxzNxXQ915kcRTo7x4MS_fqs',
        name: 'USO Athis Mons Tennis',
        imageUrl: 'https://s3.amazonaws.com/tennis-mate-dev/court_images/court-default-image.png;',
        location: {
          lat: 48.720993,
          lon: 2.4082377,
        },
        formattedAddress: '1 Rue Roland Garros, 94390 Athis-Mons, France',
      },
      communityId: null,
      skillLevelFrom: null,
      skillLevelTo: null,
      opponentAgeFrom: null,
      opponentAgeTo: null,
      participants: [604, 598],
    },
    host: {
      accountId: 604,
      email: 'miabojcic+2@gmail.com',
      firstName: 'Mia',
      lastName: 'Bojcic',
      birthday: '1988-12-02',
      avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/423?timestamp=1606316736',
    },
    guest: {
      accountId: 598,
      email: 'miabojcic+test5@gmail.com',
      firstName: 'Mate',
      lastName: 'Bojcic',
      birthday: '1988-12-02',
      avatarUrl: 'https://tennismate-prod.fra1.digitaloceanspaces.com/user_avatar/435?timestamp=1603449769',
    },
    fullMatchHistory: [
      {
        timestamp: 1506339491,
        description: 'Mia accepted Mates reported score',
      },
      {
        timestamp: 1506339110,
        description: 'Mate has reported the score',
      },
      {
        timestamp: 1506338554,
        description: 'Match is SCHEDULED between Mate and Mia',
      },
      {
        timestamp: 1506338554,
        description: "Mate accepted Mia's challenge",
      },
      {
        timestamp: 1506338523,
        description: 'Mia created an open challenge',
      },
    ],
    messages: [],
  },
};

const mockMatchResults = {
  notices: [],
  errors: [],
  body: {
    matchId: 11,
    setResults: [
      {
        setNo: 1,
        hostScore: 2,
        guestScore: 0,
      },
      {
        setNo: 2,
        hostScore: 2,
        guestScore: 6,
      },
      {
        setNo: 3,
        hostScore: 7,
        guestScore: 5,
      },
    ],
  },
};

export async function getMatchesMock() {
  await sleep();
  return mockMatches;
}

export async function getMatchResultsMock() {
  return await mockMatchResults.body;
}

export async function getMatchMock() {
  await sleep();
  const matchData = await mockMatch.body;

  const { match } = matchData;

  const response = Promise.all([getMatchResultsMock(match.id)]).then(([matchResults]) => {
    return {
      ...matchData,
      matchResults,
      host: members.find(member => member.accountId === 422),
      guest: members.find(member => member.accountId === 423),
    };
  });

  return response;
}
