import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
`;

export const Badge = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => colors[color]};
  border-radius: 50%;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white};
  text-transform: uppercase;
  margin: 0;
`;
