import React from 'react';
import { useSelector } from 'react-redux';

import { WidgetWrapper } from '~/components/shared/styles';
import WidgetHeader from '~/components/shared/WidgetHeader';
import { Button } from '~/ui';

import CompetitionItem from '../../shared/CompetitionItem/index';
import ListItemsStatusFeedback from '../../shared/ListItemStatusFeedback/index';
import { useTabs } from '../context/TabContext';

const getWinnerInfo = ({ accountId, winnerId, yourLastRound, numberOfRounds }) => {
  if (accountId === winnerId) return 'You were the winner!';

  if (!!yourLastRound && !!numberOfRounds && yourLastRound === numberOfRounds) return 'You were the runner up!';

  return undefined;
};

const TournamentsList = ({
  tournaments = [],
  widgetTitle = '',
  widgetIcon = '',
  widgetIconColor = '',
  emptyListMessage = '',
  errorMessage = '',
  isLoading = false,
}) => {
  const { selectedTab, setSelectedTab } = useTabs();
  const { accountId } = useSelector(state => state.auth);

  return (
    <WidgetWrapper>
      <WidgetHeader icon={widgetIcon} iconColor={widgetIconColor} title={widgetTitle}></WidgetHeader>
      {tournaments.length > 0 ? (
        tournaments.map(tournament => (
          <CompetitionItem
            id={tournament.id || tournament.tournamentId}
            info={`${tournament.numberOfRegisteredPlayers} participants`}
            key={tournament.id || tournament.tournamentId}
            name={tournament.name}
            type={tournament.competitionType || 'TOURNAMENT'}
            winnerInfo={getWinnerInfo({
              accountId,
              winnerId: tournament?.winnerId,
              yourLastRound: tournament?.yourLastRound,
              numberOfRounds: tournament?.noOfRounds,
            })}
          />
        ))
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage={emptyListMessage}
          errorMessage={errorMessage}
          isError={!!errorMessage}
          isLoading={isLoading}
        >
          {selectedTab === 'overview' && widgetTitle === 'My Tournaments' && (
            <Button isNewStyle onClick={() => setSelectedTab('tournaments')}>
              Discover Tournaments
            </Button>
          )}
        </ListItemsStatusFeedback>
      )}
    </WidgetWrapper>
  );
};

export default TournamentsList;
