import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const Message = styled.p`
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ color }) => (color ? colors[color] : colors.whiteOpacity._75)};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;
