import React from 'react';
import { Link } from 'react-router-dom';

import newsDefaultImg from '~/assets/images/news-default-image.png';
import { Icon } from '~/ui';

import { Img, InfoContainer, NewsCategory, StyledLink, Subtitle, Title } from './styles';

export const getNewsData = ({ data = [] }) => {
  return data.map(news => ({ title: news.title, ...news }));
};

export const getNewsColumns = ({ communityId }) => {
  return [
    {
      label: 'News',
      value: 'news',
      sortBy: 'title',
      iconLeft: 'news',
      renderCustomCell: news => {
        const { competitionId, id, imageUrl, newsType, subtitle, title } = news;

        return (
          <>
            <Img alt={title} src={imageUrl ? imageUrl : newsDefaultImg} />
            <InfoContainer>
              <Title>
                <Link to={`/admin/community/${communityId}/news/${id}`}>{title}</Link>
              </Title>
              <NewsCategory>
                {competitionId || newsType === 'COMPETITION' ? (
                  <>
                    <Icon icon='trophy' />
                    <span>Competition News</span>
                  </>
                ) : (
                  'Community News'
                )}
              </NewsCategory>
              <Subtitle>{subtitle}</Subtitle>
              <StyledLink to={`/admin/community/${communityId}/news/${id}`}>Read more</StyledLink>
            </InfoContainer>
          </>
        );
      },
    },
  ];
};
