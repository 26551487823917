import React from 'react';

import { MobileLabel } from '~/components/Admin/MembersTable/styles';
import UserWithAvatar from '~/components/shared/UserWithAvatar';
import { history } from '~/index';
import { getPcrBalls, getSkillLevelNumber } from '~/utils';

import { AcceptButton, ClickableAvatar, DeclineButton } from './styles';

export const getJoinRequestsData = ({ data = [] }) => {
  return data.map(member => {
    const { accountId, avatarUrl, firstName, lastName, statsApi, tennisLevel } = member;

    return {
      accountId,
      avatarUrl,
      name: `${firstName} ${lastName}`,
      statsApi,
      skillLevel: getSkillLevelNumber(tennisLevel.prettyName).toFixed(1),
    };
  });
};

export const getJoinRequestsColumns = ({ onAccept, onDecline }) => {
  return [
    {
      label: 'Join requests',
      value: 'name',
      width: 250,
      sortBy: 'name',
      iconLeft: 'userPlus',
      renderCustomCell: member => {
        const { avatarUrl, name, accountId } = member;

        return (
          <ClickableAvatar onClick={() => history.push(`/admin/profile/${accountId}`)}>
            <UserWithAvatar
              avatarUrl={avatarUrl}
              userName={name}
              onClick={() => history.push(`/admin/profile/${accountId}`)}
            />
          </ClickableAvatar>
        );
      },
    },
    {
      label: 'Pcr',
      renderCustomCell: member => {
        const { statsApi } = member;

        return getPcrBalls(statsApi.playerCredRating);
      },
    },
    {
      label: 'Skill Level',
      renderCustomCell: member => {
        const { skillLevel } = member;

        return (
          <>
            <MobileLabel>Skill level</MobileLabel>
            {skillLevel}
          </>
        );
      },
    },
    {
      label: '',
      value: 'accountId',
      renderCustomCell: member => {
        return (
          <>
            <AcceptButton small onClick={() => onAccept(member)}>
              Accept
            </AcceptButton>
            <DeclineButton small onClick={() => onDecline(member)}>
              Decline
            </DeclineButton>
          </>
        );
      },
    },
  ];
};
