import { getHomeScreenRequest, getMatchesCentralScreenRequest } from '~/api';

import { setHomeData, setHomeErrorMessage, setIsLoadingHome } from './slice';

export const getHomeScreen = () => async (dispatch, getState) => {
  const accountId = getState().auth.accountId;

  try {
    dispatch(setIsLoadingHome());
    const homeResponse = await getHomeScreenRequest(accountId);
    const matchesResponse = await getMatchesCentralScreenRequest(accountId);
    const homeData = homeResponse.data.body;
    const matchesData = matchesResponse.data.body;

    const data = {
      ...homeData,
      myChallenges: matchesData.challenges,
    };

    dispatch(setHomeData(data));
  } catch (error) {
    dispatch(setHomeErrorMessage('Something went wrong. Please try again.'));
  }
};
