import { fromUnixTime } from 'date-fns';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { proposeNewMatchTime } from '~/api/matches';
import ModalContent from '~/components/shared/ModalContent/';
import { ErrorMessage } from '~/components/shared/styles';
import { useMatchOpponent } from '~/hooks/match';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { getTournamentScreen } from '~/store/tournaments/tournamentScreen.utils';
import { Button, DatePicker, IntervalTimePicker, Spinner, Textarea } from '~/ui';
import { checkIfDateDaysAreEqual } from '~/utils';

import { InputsContainer } from '../styles';

const ProposeNewTimeModal = ({ onClose }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: matchData } = useSelector(state => state.matchScreen);

  const competitionId = get(matchData, ['communityAndCompetitionCard', 'competitionId'], null);

  const { data: tournamentData, isLoadingTournament } = useSelector(state => state.tournamentScreen);

  const tournamentEnd = get(tournamentData, ['tournamentResponse', 'endTime'], null);
  const tournamentEndDate = tournamentEnd && competitionId ? new Date(fromUnixTime(tournamentEnd)) : null;
  const tournamentEndHours = tournamentEndDate && competitionId ? tournamentEndDate.getHours() : null;
  const tournamentEndMinutes = tournamentEndDate && competitionId ? tournamentEndDate.getMinutes() : null;

  useEffect(() => {
    if (competitionId) {
      dispatch(getTournamentScreen(competitionId));
    }
  }, [competitionId]);

  const dispatch = useDispatch();

  const { matchId } = useParams();

  const opponent = useMatchOpponent();

  const onNewTimePropsal = () => {
    setIsLoading(true);
    setErrorMessage('');

    const data = {
      message: message.trim() || null,
      newTime: new Date(`${date} ${time}`).getTime() / 1000,
    };

    proposeNewMatchTime(matchId, data)
      .then(() => {
        setIsLoading(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsLoading(false);
      });
  };

  const checkMaxHoursLimit = () => {
    if (competitionId && tournamentEndDate) {
      if (checkIfDateDaysAreEqual(new Date(date), tournamentEndDate)) {
        return tournamentEndHours;
      }
    }

    return null;
  };

  const checkMaxMinutesLimit = () => {
    if (competitionId && tournamentEndDate) {
      if (checkIfDateDaysAreEqual(new Date(date), tournamentEndDate)) {
        return tournamentEndMinutes;
      }
    }

    return null;
  };

  return (
    <ModalContent title='Propose New Time' onClose={onClose}>
      <InfoMessage>
        Do you want to propose a new time for your match? If you want, you can write a short message to{' '}
        {opponent.firstName} on why you are proposing a new time.
      </InfoMessage>
      <InputsContainer>
        {isLoadingTournament ? (
          <Spinner />
        ) : (
          <>
            <DatePicker
              icon='calendar'
              isNewStyle
              leftIconSize='m'
              maxDate={tournamentEndDate}
              minDate={new Date()}
              placeholder='Pick a date'
              value={date}
              onChange={date => setDate(date)}
            />
            <IntervalTimePicker
              icon='clock'
              leftIconSize='m'
              maxHours={checkMaxHoursLimit()}
              maxMinutes={checkMaxMinutesLimit()}
              placeholder='Pick a time'
              value={time}
              onChange={time => setTime(time)}
            />
          </>
        )}
      </InputsContainer>
      <Textarea
        isNewStyle
        placeholder='Write a message...'
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button disabled={isLoading || !date || !time} isNewStyle onClick={onNewTimePropsal}>
          {isLoading ? 'Submitting...' : 'Submit Proposal'}
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ProposeNewTimeModal;
