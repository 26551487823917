import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const CancelButton = styled(Button)`
  border: none;
  background: ${colors.highlight1};
  color: ${colors.whiteOpacity._75};
  font-weight: normal;
`;

export const ClearButton = styled(Button)`
  border: 1px solid ${colors.danger};
  color: ${colors.danger};
`;

export const Title = styled.h3`
  margin: 8px 0 16px 0;
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;
