import React from 'react';
import SVG from 'react-inlinesvg';

import * as icons from '../../assets/icons';
import { IconContainer } from './styles';

const Icon = ({ cursor, color = 'ash', icon, onClick, style = {}, size = 'm', ...rest }) => {
  const iconSrc = icons[icon]; // eslint-disable-line import/namespace

  return (
    <IconContainer className='icon' color={color} cursor={cursor} size={size} style={style} onClick={onClick} {...rest}>
      <SVG src={iconSrc} />
    </IconContainer>
  );
};

export default Icon;
