import styled from 'styled-components';

import { theme } from '~/ui/index';

const { colors } = theme;

export const HeaderContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: ${colors.highlight3};
`;

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 38px;
  flex: 1;
`;

export const PageTitle = styled.h1`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.6;
  color: ${colors.ash};
  margin: 0;
`;

export const MatchStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  & p {
    font-weight: 600;
    font-size: 15px;
    line-height: 1.6;
    color: ${colors.white};
    margin: 0;
  }
`;

export const MatchStatus = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ color }) => colors[color]};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  flex: 1;
`;
