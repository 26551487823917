import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors } = theme;

export const FormContainer = styled.form`
  margin-top: 26px;

  > div {
    margin-top: 20px;
  }

  label,
  input {
    color: ${colors.white};
  }

  button {
    margin: 24px 0 32px;
  }

  div.react-datepicker {
    button {
      margin: 0;
    }
  }
`;

export const Img = styled.img`
  display: block;
  width: 150px;
  border-radius: 150px;
  margin: 0 auto;
  object-fit: cover;
`;

export const ChangeImgButton = styled(Button)`
  display: block;
  margin: 16px auto 24px !important;
  background: ${colors.highlight3};
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.92px;

  .icon {
    margin-right: 16px;
  }
`;

export const Notice = styled.p`
  margin-top: 24px;
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.92px;
`;

export const Logout = styled.button`
  width: 100%;
  border: none;
  margin-top: 0 !important;
  background: none;
  color: ${colors.tmGreen};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  cursor: pointer;
  outline: none;
`;

export const PhoneInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & .PhoneInputCountry {
    width: 65px;
    background: ${colors.highlight2};
    height: 40px;
    margin: 0;
    margin-top: 12px;
    padding-left: 12px;
    border-bottom: 1px solid ${colors.invert};
    box-sizing: border-box;
  }
`;
