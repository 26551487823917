import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  width: 640px;
  height: fit-content;
  box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  background-color: ${colors.background};

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 21px;
  height: 56px;
  background-color: ${colors.highlight2};
  box-sizing: border-box;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.6;
  color: ${colors.white};
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: ${colors.background};
`;
