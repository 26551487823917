import styled, { css } from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

const selectedTabStyle = css`
  background-color: ${colors.tmGreen};
  border-color: ${colors.tmGreen};
  color: ${colors.white};

  & .icon {
    color: ${colors.white};
  }
`;

const notSelectedTabStyle = css`
  background-color: transparent;
  color: ${colors.ash};
  border-color: ${colors.ash};

  & .icon {
    color: ${colors.ash};
  }
`;

const disabledTabStyle = css`
  backgorund-color: transparent;
  color: ${colors.ash3};
  border-color: ${colors.ash3};
  cursor: not-allowed;

  & .icon {
    color: ${colors.ash3};
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px;
`;

export const Tab = styled.div`
  cursor: pointer;
  border-radius: 2px;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;

  ${({ isSelected }) => (isSelected ? selectedTabStyle : notSelectedTabStyle)}
  ${({ isDisabled }) => (isDisabled ? disabledTabStyle : null)}
`;
