import React from 'react';

import MatchItem from '../../shared/MatchItem/MatchItem';

const MatchScheduledItem = ({ match, nextOpponent }) => {
  if (match && nextOpponent && nextOpponent.opponent) {
    return <MatchItem match={match} opponent={nextOpponent.opponent} />;
  }

  return null;
};

export default MatchScheduledItem;
