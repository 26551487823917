import React from 'react';

import { Icon } from '~/ui';

import { Container, Text } from './styles';

const TextWithIcon = ({ icon, iconColor, text, textColor = 'white', iconSize = 'm', textStyle = {} }) => {
  return (
    <Container>
      <Icon color={iconColor} icon={icon} size={iconSize} />
      <Text style={textStyle} textColor={textColor}>
        {text}
      </Text>
    </Container>
  );
};

export default TextWithIcon;
