import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const ErrorMessageContainer = styled.p`
  color: ${({ red }) => (red ? colors.danger : colors.whiteOpacity._75)};
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  line-height: 24px;
`;
