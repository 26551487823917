import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FlexColumnWrapper } from '~/components/shared/styles';
import { MATCH_STATUS } from '~/enums/match';
import { useIsMatchParticipant } from '~/hooks/match';
import { ContentContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { ErrorMessage, Spinner } from '~/ui';

import Header from '../shared/Header';
import History from './History';
import MatchInfo from './MatchInfo';
import MatchInviteActions from './MatchInviteActions';
import MatchInviteInfo from './MatchInviteInfo';
import MatchPendingCancelationActions from './MatchPendingCancelationActions';
import Messages from './Messages';
import Participants from './Participants';
import PendingNewTimeProposalButtons from './PendingNewTimeProposalButtons';
import PendingOpenMatchButtons from './PendingOpenMatchButtons';
import RegisterResultButtons from './RegisterResultButtons';
import RegisterResultStatus from './RegisterResultStatus';
import Results from './Results';
import ScheduledMatchButtons from './ScheduledMatchButtons';

const MatchDetails = () => {
  const dispatch = useDispatch();
  const { matchId } = useParams();

  const { isLoading, data, errorMessage } = useSelector(state => state.matchScreen);
  const { accountId } = useSelector(state => state.auth);

  const isParticipant = useIsMatchParticipant();

  useEffect(() => {
    if (matchId) {
      dispatch(getMatchDetailsScreen(matchId));
    }
  }, [matchId, dispatch]);

  if (isLoading) return <Spinner />;

  if (!isParticipant || isEmpty(data)) {
    return (
      <>
        <Header />
        <ContentContainer>
          <InfoMessage>Match not found.</InfoMessage>
        </ContentContainer>
      </>
    );
  }

  const { guest, host, guestResult, hostResult, result, match } = data;

  return (
    <>
      <Header>
        <ScheduledMatchButtons />
        <PendingNewTimeProposalButtons />
        <RegisterResultButtons />
        <PendingOpenMatchButtons />
      </Header>
      <ContentContainer>
        {errorMessage ? (
          <ErrorMessage red style={{ textAlign: 'center' }}>
            {errorMessage}
          </ErrorMessage>
        ) : (
          <FlexColumnWrapper>
            <Participants />
            <RegisterResultStatus />
            {match?.matchStatus === MATCH_STATUS.CONFIRMED ? (
              <Results guest={guest} host={host} results={result} />
            ) : (
              <>
                <Results
                  guest={guest}
                  host={host}
                  results={hostResult}
                  title={host?.accountId === accountId ? 'Your Results' : 'Opponent Results'}
                />
                <Results
                  guest={guest}
                  host={host}
                  results={guestResult}
                  title={guest?.accountId === accountId ? 'Your Results' : 'Opponent Results'}
                />
              </>
            )}
            <MatchInviteActions />
            <MatchPendingCancelationActions />
            <MatchInviteInfo />
            <MatchInfo />
            <Messages />
            <History />
          </FlexColumnWrapper>
        )}
      </ContentContainer>
    </>
  );
};

export default MatchDetails;
