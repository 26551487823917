import React from 'react';

import { Icon } from '~/ui';

import { LogoContainer } from './styles';

const Logo = ({ className }) => {
  return (
    <LogoContainer className={className} to='/home'>
      <Icon color='tmGreen' cursor='pointer' icon='tmLogo32' size='l' />
      <span>TennisMate</span>
    </LogoContainer>
  );
};

export default Logo;
