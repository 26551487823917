import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Form = styled.form`
  > div {
    max-width: 366px;
    margin-bottom: 24px;
  }

  label {
    color: ${colors.white};
  }

  > button {
    min-width: 219px;
  }
`;

export const TextEditorTitle = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.92px;
`;

export const BigContainer = styled.div`
  max-width: 550px !important;
`;

export const ButtonsContainer = styled.div`
  margin-top: 24px;

  button {
    width: 50%;
    max-width: 263px;

    &:nth-of-type(1) {
      float: left;
    }

    &:nth-of-type(2) {
      float: right;
    }

    @media screen and (max-width: ${mediaQueries.mobile}px) {
      display: block;
      width: 100%;
      max-width: 100%;
      margin: 24px 0 0;
      float: none !important;
    }
  }
`;

export const DeleteButton = styled(Button)`
  border-color: ${colors.danger};
  color: ${colors.danger};
`;
