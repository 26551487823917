import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { acceptNewMatchTime, rejectNewMatchTime } from '~/api/matches';
import ModalContent from '~/components/shared/ModalContent/';
import { ErrorMessage } from '~/components/shared/styles';
import { DATE_FORMATS } from '~/enums/dates';
import { useMatchOpponent } from '~/hooks';
import { ButtonsContainer, InfoMessage } from '~/pages/shared/styles';
import { getMatchDetailsScreen } from '~/store/matches/matchScreen.utils';
import { Button, Textarea } from '~/ui';
import { formatDateFromUnixTime } from '~/utils/dates';

const ReviewNewMatchTimeModal = ({ onClose, startTime }) => {
  const [message, setMessage] = useState('');
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const { matchId } = useParams();

  const opponent = useMatchOpponent();

  const onAccept = () => {
    setIsAccepting(true);
    setErrorMessage('');

    acceptNewMatchTime(matchId, message.trim())
      .then(() => {
        setIsAccepting(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsAccepting(false);
      });
  };

  const onReject = () => {
    setIsRejecting(true);
    setErrorMessage('');

    rejectNewMatchTime(matchId, message.trim())
      .then(() => {
        setIsRejecting(false);
        dispatch(getMatchDetailsScreen(matchId));
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
        setIsRejecting(false);
      });
  };

  return (
    <ModalContent title='New Match Time Approval' onClose={onClose}>
      <InfoMessage>{opponent.firstName} requested a new time for this match.</InfoMessage>
      <InfoMessage>New Time: {formatDateFromUnixTime(startTime, DATE_FORMATS.WITH_DAY_AND_MONTH_NAME)}</InfoMessage>
      <Textarea
        isNewStyle
        placeholder='Write a message...'
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonsContainer>
        <Button disabled={isAccepting || isRejecting} isNewStyle onClick={onAccept}>
          {isAccepting ? 'Accepting...' : 'Accept'}
        </Button>
        <Button color='danger' disabled={isRejecting || isAccepting} isNewStyle onClick={onReject}>
          {isRejecting ? 'Rejecting...' : 'Reject'}
        </Button>
      </ButtonsContainer>
    </ModalContent>
  );
};

export default ReviewNewMatchTimeModal;
