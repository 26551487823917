import styled from 'styled-components';

import { theme } from '~/ui';

const { mediaQueries } = theme;

export const CellWithIcon = styled.div`
  display: flex;

  .icon {
    align-self: flex-start;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    > * {
      display: ${({ hideOnMobile }) => (hideOnMobile ? 'none !important' : 'block')};
    }
  }
`;
