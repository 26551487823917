import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  width: 320px;
  max-width: 320px;
  min-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  background-color: ${colors.highlight3};
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const RightArrow = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${colors.highlight3};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 4px solid ${colors.background};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -18px;
`;

export const LeftArrow = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${colors.highlight3};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 4px solid ${colors.background};
  position: absolute;
  bottom: 12px;
  left: -12px;

  &:first-child {
    top: 12px;
    bottom: unset;
  }

  & .icon {
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PlayerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlayerNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 2;
  overflow: hidden;
`;

export const PlayerName = styled.div`
  font-weight: ${({ isWinner }) => (isWinner ? 600 : 400)};
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  color: ${({ isWinner }) => (isWinner ? colors.white : colors.ash)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const PlayerIndentificatorBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  background: ${colors.tmGreen};
  border-radius: 2px;
  color: ${colors.white};
`;

export const ScoresContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const Score = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  display: block;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
