import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '~/ui';

import { IconsContainer, Nav } from './styles';
import User from './User';

const TopNavigation = ({ showMobileMenu, setShowMobileMenu }) => {
  return (
    <Nav>
      <IconsContainer>
        <Icon
          icon={showMobileMenu ? 'menuClose' : 'menu'}
          size='l'
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <Link to='/home'>
          <Icon color='tmGreen' cursor='pointer' icon='tmLogo32' size='l' />
        </Link>
      </IconsContainer>
      <User />
    </Nav>
  );
};

export default TopNavigation;
