import {
  differenceInYears,
  endOfDay,
  format,
  formatDistance,
  fromUnixTime,
  getUnixTime,
  isAfter,
  isBefore,
  isPast,
  isWithinInterval,
  parse,
  parseISO,
  subDays,
} from 'date-fns';

import { DATE_FORMATS } from '~/enums';

export const isCurrentDateTimeBetweenDates = ({ start, end }) => {
  const startDate = fromUnixTime(start);
  const endDate = fromUnixTime(end);

  return isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });
};

export const isDateAfterCurrentDateTime = timestamp => {
  const date = fromUnixTime(timestamp);

  return isAfter(date, new Date());
};

export const isDateBeforeCurrentDateTime = timestamp => {
  const date = fromUnixTime(timestamp);

  return isBefore(date, new Date());
};

export const isDateInThePast = timestamp => {
  return isPast(fromUnixTime(timestamp));
};

export const getDateWithYearFromTimestamp = ({ timestamp, withYear = true }) => {
  const dateNumber = fromUnixTime(timestamp).getDate();
  const day = format(fromUnixTime(timestamp), 'EEE');
  const month = format(fromUnixTime(timestamp), 'MMM');
  const year = format(fromUnixTime(timestamp), 'yyyy');

  const dateString = `${day} ${month} ${dateNumber}`;

  return withYear ? `${dateString} ${year}` : dateString;
};

export function getDifferenceInYears(date1, date2) {
  return Math.abs(differenceInYears(date1, date2));
}

export function getDateFromString(dateString, format) {
  return parse(dateString, format, new Date());
}

export function formatDateFromUnixTime(unixTime, dateFormat = DATE_FORMATS.DEFAULT_WITH_TIME) {
  return format(fromUnixTime(unixTime), dateFormat);
}

export const formatDate = (date, dateFormat = DATE_FORMATS.DEFAULT) => {
  return format(new Date(date), dateFormat);
};

export const timeSince = date => formatDistance(date, new Date());

export { endOfDay, format, fromUnixTime, getUnixTime, isBefore, isPast, parseISO, subDays };

export const checkIfDateDaysAreEqual = (date1, date2) => {
  return date1.getDate() === date2.getDate();
};
