import React, { createContext, useContext, useState } from 'react';

const TabsContext = createContext({ selectedTab: '', setSelectedTab: () => {} });

export const TabsProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState('');

  return (
    <TabsContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const context = useContext(TabsContext);

  if (context === undefined) {
    throw new Error('useTabs must be used within a TabsProvider');
  }

  return context;
};
