import React from 'react';

import PcrBall from '~/components/shared/PcrBall';

const getPcrBalls = pcr => {
  const balls = [];

  for (let i = 0; i < 5; i++) {
    balls.push(<PcrBall color={i < pcr ? 'tmGreen' : 'ash2'} key={i} />);
  }

  return balls;
};

export default getPcrBalls;
