import { createSlice } from '@reduxjs/toolkit';

import { renameUserProperties } from '~/utils';

const initialTournamentState = {
  additionalDescription: '',
  adminEmail: '',
  associatedCompetitions: [],
  ageFrom: 16,
  ageTo: 72,
  bracketRounds: [],
  communityId: 0,
  competitionType: '',
  court: {
    placeId: '',
    name: '',
    imageUrl: '',
    location: {
      lat: 0,
      lon: 0,
    },
    formattedAddress: '',
  },
  concluded: true,
  endTime: 0,
  gender: { gender: 'BOTH', prettyName: 'Both' },
  id: 0,
  lastOpponent: null,
  matchInviteContract: null,
  match: null,
  members: [],
  membersError: '',
  name: '',
  news: [],
  nextOpponent: null,
  numberOfRegisteredPlayers: 0,
  registerEndTime: 0,
  registerStartTime: 0,
  skillLevelFrom: {
    skillLevel: 'LEVEL_1_0',
    prettyName: 'Level 1.0',
    description: '',
  },
  skillLevelTo: {
    skillLevel: 'LEVEL_7_0',
    prettyName: 'Level 7.0',
    description: '',
  },
  startTime: 0,
  tournamentEligibilityStatus: '',
  totalRoundNumber: null,
  tournamentWinners: [],
};

const initialState = {
  tournamentsList: [initialTournamentState],
  tournamentsError: '',
  selectedTournament: initialTournamentState,
  selectedTournamentError: '',
  communityTournamentsScreen: {},
  communityTournamentsScreenLoading: false,
  communityTournamentsScreenError: '',
};

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState,
  reducers: {
    tournamentsList: (state, action) => {
      state.tournamentsList = action.payload;
      state.tournamentsError = '';
    },
    tournamentsError: (state, action) => {
      state.tournamentsList = [];
      state.tournamentsError = action.payload;
    },
    selectedTournament: (state, action) => {
      const {
        associatedCompetitions,
        bracketRounds,
        lastOpponent,
        match,
        matchInviteContract,
        news,
        nextOpponent,
        totalRoundNumber,
        tournamentEligibilityStatus,
        tournamentResponse,
        tournamentWinners,
      } = action.payload;

      state.selectedTournament = {
        ...tournamentResponse,
        associatedCompetitions,
        bracketRounds,
        lastOpponent,
        match,
        matchInviteContract,
        members: [],
        membersError: '',
        news,
        nextOpponent,
        totalRoundNumber,
        tournamentEligibilityStatus,
        tournamentWinners,
      };
      state.selectedTournamentError = '';
    },
    selectedTournamentError: (state, action) => {
      state.selectedTournament = initialTournamentState;
      state.selectedTournamentError = action.payload;
    },
    selectedTournamentMembers: (state, action) => {
      const tournamentMembers = action.payload.map(user => {
        const renamedProperties = renameUserProperties(user);

        return renamedProperties;
      });

      state.selectedTournament.members = tournamentMembers;
      state.selectedTournament.membersError = '';
    },
    selectedTournamentMembersError: (state, action) => {
      state.selectedTournament.members = [];
      state.selectedTournament.membersError = action.payload;
    },
    selectedTournamentReset: state => {
      state.selectedTournament = initialTournamentState;
      state.selectedTournamentError = '';
    },
    tournamentsInitialState: () => {
      return initialState;
    },
    communityTournamentsScreen: (state, action) => {
      state.communityTournamentsScreen = action.payload;
      state.communityTournamentsScreenLoading = false;
      state.communityTournamentsScreenError = '';
    },
    communityTournamentsScreenLoading: state => {
      state.communityTournamentsScreenLoading = true;
    },
    communityTournamentsScreenError: (state, action) => {
      state.communityTournamentsScreen = {};
      state.communityTournamentsScreenLoading = false;
      state.communityTournamentsScreenError = action.payload;
    },
  },
});

export const {
  tournamentsList,
  tournamentsError,
  selectedTournament,
  selectedTournamentError,
  selectedTournamentMembers,
  selectedTournamentMembersError,
  selectedTournamentReset,
  tournamentsInitialState,
  communityTournamentsScreen,
  communityTournamentsScreenLoading,
  communityTournamentsScreenError,
} = tournamentsSlice.actions;

export default tournamentsSlice.reducer;
