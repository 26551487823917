import React from 'react';

import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import { Button } from '~/ui';

import { CourtAddress, CourtName } from './styles';

const CourtList = ({ courts = [], onSelect }) => {
  return (
    <WidgetWrapper>
      {courts.map(court => {
        const { placeId, formattedAddress, name } = court;
        return (
          <WidgetItem key={placeId}>
            <div>
              <CourtName>{name}</CourtName>
              <CourtAddress>{formattedAddress}</CourtAddress>
            </div>
            <Button isNewStyle type='button' onClick={() => onSelect(court)}>
              Pick
            </Button>
          </WidgetItem>
        );
      })}
    </WidgetWrapper>
  );
};

export default CourtList;
