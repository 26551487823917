import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { signup } from '~/store/auth/utils';
import { Button, Checkbox, ErrorMessage, Input } from '~/ui';
import { isEmailValid, isPasswordValid } from '~/utils';

import { Notice, TermsContainer } from './styles';

const SignupForm = () => {
  const { error } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showRepeatPasswordError, setShowRepeatPasswordError] = useState(false);
  const [agreeToTermsAndPrivacyPolicy, setAgreeToTermsAndPrivacyPolicy] = useState(false);
  const [showTermsAndPrivacyPolicyError, setShowTermsAndPrivacyPolicyError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSignupSubmit = e => {
    e.preventDefault();

    const validPassword = isPasswordValid(password);
    const validEmail = isEmailValid(email);

    if (!agreeToTermsAndPrivacyPolicy || !validPassword || !validEmail || password !== repeatPassword) {
      setShowPasswordError(!validPassword);

      setShowRepeatPasswordError(password !== repeatPassword);

      setShowTermsAndPrivacyPolicyError(!agreeToTermsAndPrivacyPolicy);

      return;
    } else {
      const data = { email, password };

      dispatch(signup(data));
      setShowPasswordError(false);
      setShowTermsAndPrivacyPolicyError(false);
    }
  };

  return (
    <form onSubmit={handleSignupSubmit}>
      {error && <ErrorMessage red>{error.message}</ErrorMessage>}
      <Input
        autocomplete='email'
        error={error}
        icon='mail'
        iconColor={error ? 'danger' : 'tmGreen'}
        label='Email address'
        required
        type='email'
        onChange={e => setEmail(e.target.value)}
      />
      <Input
        autocomplete='current-password'
        error={showPasswordError || showRepeatPasswordError}
        icon='lock'
        iconColor={showPasswordError || showRepeatPasswordError ? 'danger' : 'tmGreen'}
        label='Password'
        required
        rightIcon={isPasswordVisible ? 'eyeOff' : 'eye'}
        rightIconColor='ash'
        type={isPasswordVisible ? 'text' : 'password'}
        onChange={e => setPassword(e.target.value)}
        onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
      />
      <Input
        autocomplete='current-password'
        error={showRepeatPasswordError}
        icon='lock'
        iconColor={showRepeatPasswordError ? 'danger' : 'tmGreen'}
        label='Repeat password'
        required
        type={isPasswordVisible ? 'text' : 'password'}
        onChange={e => setRepeatPassword(e.target.value)}
      />
      <TermsContainer>
        <Checkbox
          checked={agreeToTermsAndPrivacyPolicy}
          onChange={() => {
            setAgreeToTermsAndPrivacyPolicy(!agreeToTermsAndPrivacyPolicy);
            setShowTermsAndPrivacyPolicyError(agreeToTermsAndPrivacyPolicy);
          }}
        />
        <span>
          By signing up, I agree to the TennisMate
          <Link to='/'> Terms of Use</Link> and <Link to='/'>Privacy Policy</Link>.
        </span>
      </TermsContainer>
      {showTermsAndPrivacyPolicyError && (
        <ErrorMessage red>Please accept Terms of Use and Privacy Policy.</ErrorMessage>
      )}
      {showPasswordError && (
        <Notice error={showPasswordError}>
          Password must be between 6 and 16 characters with at least one uppercase, one lowercase and one digit or
          special character.
        </Notice>
      )}
      {showRepeatPasswordError && <Notice error={showRepeatPasswordError}>Passwords must match.</Notice>}
      <Button fullWidth type='submit'>
        Sign Up
      </Button>
    </form>
  );
};

export default SignupForm;
