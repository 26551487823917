const colors = {
  tmGreen: '#37AB21',
  pending: '#FFB200',
  danger: '#EE3030',
  blue: '#2F83FF',
  orange: '#E88A05',
  background: '#282828',
  highlight1: '#232323',
  highlight2: '#202020',
  highlight3: '#151515',
  highlightHover: '#1A1A1A',
  ash: '#C4C4C4',
  ash2: '#8A8A8A',
  ash3: '#5A5A5A',
  invert: '#444444',
  white: '#ffffff',
  whiteOpacity: {
    _75: 'rgba(255, 255, 255, 0.75)',
    _5: 'rgba(255, 255, 255, 0.5)',
    _25: 'rgba(255, 255, 255, 0.25)',
  },
  blackOpacity: {
    _75: 'rgba(0, 0, 0, 0.75)',
    _5: 'rgba(0, 0, 0, 0.5)',
    _25: 'rgba(0, 0, 0, 0.25)',
  },
};

export default colors;
