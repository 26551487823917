import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ANALYTICS_EVENTS } from '~/enums';
import { logout } from '~/store/auth/utils';
import { Icon } from '~/ui';
import { trackAnalyticsEvent } from '~/utils';

import { MenuContainer, MenuItem } from './styles';

const Menu = () => {
  const dispatch = useDispatch();

  return (
    <MenuContainer>
      <MenuItem>
        <Link to='/admin/my-profile'>
          View Profile
          <Icon cursor='pointer' icon='user' />
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to='/admin/my-profile/edit'>
          Edit Profile
          <Icon cursor='pointer' icon='settings' />
        </Link>
      </MenuItem>
      <MenuItem>
        <Link
          to='/'
          onClick={() => {
            trackAnalyticsEvent(ANALYTICS_EVENTS.USER_LOGGED_OUT);
            dispatch(logout());
          }}
        >
          Logout
          <Icon cursor='pointer' icon='logout' />
        </Link>
      </MenuItem>
    </MenuContainer>
  );
};

export default Menu;
