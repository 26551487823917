import React from 'react';
import { useSelector } from 'react-redux';

import { Avatar, WidgetItem } from '~/components/shared/index';

import { FinalMatchText, InfoFlexContianer } from './styles';

const FinalMatchItem = ({ winners, tournamentConcluded }) => {
  const { accountId } = useSelector(state => state.auth);

  if (winners.length === 0 || !tournamentConcluded) {
    return null;
  }

  const [player1, player2] = winners;

  if (player1.userProfileCardView.accountId !== accountId && player2.userProfileCardView.accountId !== accountId) {
    return null;
  }

  if (player1.place === 1 && player1.userProfileCardView.accountId === accountId) {
    return (
      <WidgetItem>
        <InfoFlexContianer>
          <Avatar src={player2.userProfileCardView.avatarUrl} />
          <FinalMatchText>
            You defeated{' '}
            <strong>
              {player2.userProfileCardView.firstName} {player2.userProfileCardView.lastName}
            </strong>{' '}
            in the <strong>Finals</strong>.
          </FinalMatchText>
        </InfoFlexContianer>
      </WidgetItem>
    );
  }

  return (
    <WidgetItem>
      <InfoFlexContianer>
        <Avatar src={player1.userProfileCardView.avatarUrl} />
        <FinalMatchText>
          You were defeated by{' '}
          <strong>
            {player1.userProfileCardView.firstName} {player1.userProfileCardView.lastName}
          </strong>{' '}
          in the <strong>Finals</strong>.
        </FinalMatchText>
      </InfoFlexContianer>
    </WidgetItem>
  );
};

export default FinalMatchItem;
