import React, { useEffect } from 'react';

import { Content, ModalContainer } from './styles';

const Modal = ({ children, ContentWrapper = Content }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);

  return (
    <ModalContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </ModalContainer>
  );
};

export default Modal;
