import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries, zIndex } = theme;

export const AccountMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 4px;
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${colors.ash};
`;

export const MenuList = styled.ul`
  width: 280px;
  position: absolute;
  right: 0;
  top: 64px;
  background-color: ${colors.highlight3};
  text-align: left;
  list-style: none;
  z-index: ${zIndex.level4};
  padding: 0;
  margin: 0;
`;

export const MenuItem = styled.li`
  border-bottom: 1px solid ${colors.invert};
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  &:hover a {
    color: ${colors.tmGreen};
  }

  & a {
    color: ${colors.white};
  }

  &:last-child {
    border-bottom: none;
  }

  &:last-child a {
    color: ${colors.danger};
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    padding: 12px 8px;
  }
`;
