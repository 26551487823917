import React from 'react';

import { UserContainer } from './styles';

function UserWithAvatar({ avatarUrl, style, userName }) {
  return (
    <UserContainer>
      {avatarUrl && <img alt={`${userName}'s avatar`} className='avatar' src={avatarUrl} />}
      <span style={style}>{userName}</span>
    </UserContainer>
  );
}

export default UserWithAvatar;
