import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { getUserProfileRequest } from '~/api';
import MemberProfile from '~/components/Admin/MemberProfile';
import { Spinner, useIgnoreOnFirstRender } from '~/ui';
import { renameUserProperties } from '~/utils';

const MemberProfilePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { accountId } = useParams();

  const { accountId: currentUserId } = useSelector(state => state.user);
  const selectedCommunityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const [loadingUserProfile, setLoadingUserProfile] = useState(false);
  const [userError, setUserError] = useState('');
  const [user, setUser] = useState({
    accountId: null,
    firstName: '',
    lastName: '',
    avatarUrl: '',
    tennisLevel: {
      prettyName: '',
    },
    playingStyle: {},
    playingHand: {},
    favoriteRacket: '',
    favoriteApparel: null,
    statsApi: {
      winLose: [],
    },
  });

  const isMyProfilePage = location.pathname.includes('my-profile');

  useEffect(() => {
    const loadSelectedUserProfile = async () => {
      setLoadingUserProfile(true);
      try {
        const response = await getUserProfileRequest(isMyProfilePage ? currentUserId : accountId);
        const renamedUserProperties = [response.data.body].map(user => renameUserProperties(user));

        setUser(renamedUserProperties[0]);
      } catch (error) {
        setUserError("Can't load user profile data. Try again.");
      }
      setLoadingUserProfile(false);
    };

    loadSelectedUserProfile();
  }, [dispatch, accountId, currentUserId, isMyProfilePage]);

  useIgnoreOnFirstRender(() => {
    history.push(`/admin/community/${selectedCommunityId}/members`);
  }, [selectedCommunityId]);

  return loadingUserProfile ? (
    <Spinner />
  ) : (
    <MemberProfile isMyProfilePage={isMyProfilePage} user={user} userError={userError} />
  );
};

export default MemberProfilePage;
