import styled from 'styled-components';

import theme from '../../theme';

const { colors, zIndex } = theme;

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 12px 16px 12px 48px;
  height: 48px;
  border-radius: 4px;
  background-color: ${colors.invert};
  color: ${colors.ash2};
  border: 0;
  max-width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  outline: none;

  &:active,
  &:focus {
    background-color: ${colors.highlight3};
  }
`;

export const SearchInputNewStyle = styled(SearchInput)`
  border: 1px solid ${colors.ash3};
  background-color: ${colors.highlight3};

  &:active,
  &:focus {
    border-color: ${colors.tmGreen};
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
  z-index: ${zIndex.level2};
`;
