import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';

import { TokensService } from '~/services';

const tokens = TokensService.tokensExists() ? TokensService.getTokens() : {};

const initialState = {
  isAuthenticated: TokensService.tokensExists() ? true : false,
  tokens,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogin: (state, action) => {
      if (action.payload && action.payload.accessToken) {
        const authData = jwt.decode(action.payload.accessToken);
        const { authenticatedUser, expirationTime } = authData;

        TokensService.setTokensExpirationTime(expirationTime);

        state.isAuthenticated = true;
        state.accountId = authenticatedUser.accountId;
        state.accountType = authenticatedUser.accountType;
        state.email = authenticatedUser.email;
        state.forgotPassword = null;
        state.tokensExpirationTime = expirationTime;
        state.tokens = action.payload;
        state.error = null;
      }
    },
    userIsProfileCompleted: (state, action) => {
      state.isProfileCompleted = action.payload;
    },
    userLoginError: (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    userSignupError: (state, action) => {
      state.error = action.payload;
    },
    userResetError: state => {
      return {
        ...state,
        error: '',
      };
    },
    userLogout: () => {
      return {
        isAuthenticated: false,
        tokens: {},
      };
    },
    userForgotPassword: (state, action) => {
      state.forgotPassword = action.payload;
    },
    userForgotPasswordError: (state, action) => {
      state.forgotPassword = action.payload;
    },
  },
});

export const {
  userLogin,
  userIsProfileCompleted,
  userLoginError,
  userSignupError,
  userResetError,
  userLogout,
  userForgotPassword,
  userForgotPasswordError,
} = authSlice.actions;

export default authSlice.reducer;
