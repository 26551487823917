import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar, WidgetItem } from '~/components/shared/index';
import { updateFormField } from '~/store/matches/newMatchForm.slice';
import { Button } from '~/ui/index';

import { InfoFlexContianer, UserName, WaitingForOpponentMessage, WaitingForOpponentStatusIcon } from './styles';

const ScheduleMatchItem = ({
  court,
  communityId,
  competitionId,
  nextOpponent,
  match,
  matchInvite,
  onNextOpponentClick,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountId } = useSelector(state => state.auth);

  const { data } = useSelector(state => state.tournamentScreen);

  const competitionName = get(data, ['tournamentResponse', 'name'], '');

  if (!nextOpponent || !nextOpponent?.opponent || !!match || !!matchInvite) return null;

  const { opponent, bracketRoundMatchContract } = nextOpponent;

  const opponentFullName = `${opponent?.firstName} ${opponent?.lastName}`;

  const onSchedule = () => {
    dispatch(
      updateFormField({
        opponent: {
          id: opponent.accountId,
          fullName: opponentFullName,
        },
      })
    );
    dispatch(updateFormField({ court }));
    dispatch(updateFormField({ communityId }));
    dispatch(updateFormField({ competitionId }));
    dispatch(updateFormField({ competitionName }));
    history.push('/match/new-match', {
      isDirectOnly: true,
    });
  };

  if (bracketRoundMatchContract.hostPlayerId === accountId) {
    return (
      <WidgetItem>
        <InfoFlexContianer onClick={onNextOpponentClick}>
          <Avatar alt={opponentFullName} size='m' src={opponent.avatarUrl} />
          <UserName>{opponentFullName}</UserName>
        </InfoFlexContianer>
        <Button isNewStyle onClick={onSchedule}>
          Schedule
        </Button>
      </WidgetItem>
    );
  }

  return (
    <WidgetItem>
      <InfoFlexContianer>
        <WaitingForOpponentStatusIcon>WFO</WaitingForOpponentStatusIcon>
        <WaitingForOpponentMessage>
          Please wait for <strong onClick={onNextOpponentClick}>{opponentFullName}</strong>, the match host, to schedule
          the match. Thank you for your patience!
        </WaitingForOpponentMessage>
      </InfoFlexContianer>
    </WidgetItem>
  );
};

export default ScheduleMatchItem;
