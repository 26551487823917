import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const MenuContainer = styled.ul`
  width: calc(274px - 2 * 32px);
  padding: 24px 32px;
  position: absolute;
  top: 16px;
  background: ${colors.highlight1};
  list-style: none;
`;

export const MenuItem = styled.li`
  padding-bottom: 16px;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  &:first-of-type {
    padding-top: 16px;
    border-top: 1px solid ${colors.invert};
  }

  &:last-of-type {
    margin-bottom: 18px;
    border-bottom: 1px solid ${colors.invert};
  }

  &:hover,
  &:focus {
    color: ${colors.white};
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.tmGreen};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.92px;
`;

export const Title = styled.p`
  margin-top: 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;
