import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 12px;
`;

export const OpponentName = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${colors.highlight3};
  width: 100%;
  border-radius: 12px;
  padding: 12px;

  & > span:first-child {
    font-weight: 700;
    font-size: 14px;
    color: ${colors.ash};
  }

  & > span:last-child {
    font-weight: 700;
    font-size: 16px;
    color: ${colors.white};
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 12px;
    color: white;
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const StatisticsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  & span:first-child {
    font-weight: 700;
    font-size: 17px;
    color: ${colors.white};
  }

  & span:last-child {
    font-weight: 500;
    font-size: 14px;
    color: ${colors.ash};
  }
`;

export const RecentResultCircle = styled.span`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.white};
  background-color: ${({ isWin }) => (isWin ? colors.tmGreen : colors.danger)};
  margin: 0;
`;
