import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 40px;
  gap: 64px;
  background-color: ${colors.invert};
  width: 100%;
  min-width: fit-content;
  box-sizing: border-box;
`;

export const RoundTitle = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  color: ${colors.ash};
  width: 320px;
  min-width: 320px;
`;
