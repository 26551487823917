import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  gap: 2px;
`;

export const WidgetItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: ${colors.highlight3};
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 1.6;
  color: ${colors.danger};
  text-align: center;
  margin: 0;
`;
