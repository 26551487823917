import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const WidgetHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background-color: ${colors.highlight3};

  & h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: ${colors.white};
    margin: 0;
  }
`;
