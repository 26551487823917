import {
  getCommunityByIdRequest,
  getCommunityMembersRequest,
  getCommunityRolesRequest,
  getCommunityScreenRequest,
  getMyCommunitiesRequest,
  getUserProfileRequest,
  searchCommunitiesRequest,
} from '~/api';
import { COMMUNITY_USER_STATUS, ROLE } from '~/enums';
import { history } from '~/index';
import { renameCommunityProperties, renameUserProperties } from '~/utils';

import {
  adminCommunities,
  allUserCommunities,
  allUserCommunitiesLoading,
  communitiesError,
  communityScreen,
  communityScreenError,
  communityScreenLoading,
  communityScreenMembers,
  communityScreenMembersErrorMessage,
  communityScreenMembersLoading,
  memberCommunities,
  searchedCommunities,
  searchedCommunitiesError,
  searchedCommunitiesLoading,
  selectedCommunity,
  selectedCommunityError,
  selectedCommunityMembers,
  selectedCommunityMembersError,
} from './slice';

export const getSelectedCommunityData = communityId => async dispatch => {
  try {
    const response = await getCommunityByIdRequest(communityId);
    const communityData = response.data.body;

    dispatch(selectedCommunity(communityData));
  } catch (error) {
    dispatch(selectedCommunityError('Something went wrong with selecting the community. Please try again.'));
  }
};

export const getMyCommunities = () => async dispatch => {
  try {
    dispatch(allUserCommunitiesLoading());
    const response = await getMyCommunitiesRequest();
    const myCommunitiesList = response.data.body;

    const renamedProperties = await renameCommunityProperties(myCommunitiesList);

    const communitiesWithMembersInfo = await Promise.all(
      renamedProperties.map(async community => {
        const membersResponse = await getCommunityMembersRequest(community.communityInfo.id);

        return {
          ...community,
          communityInfo: { ...community.communityInfo, members: membersResponse.data.body },
        };
      })
    );

    const adminCommunitiesData = communitiesWithMembersInfo.filter(
      ({ myStatus }) => myStatus.role === ROLE.ADMIN || myStatus.role === ROLE.OPERATOR
    );

    const memberCommunitiesData = communitiesWithMembersInfo.filter(({ myStatus }) => myStatus.role === ROLE.MEMBER);

    const communityIdFromUrl = Number(history.location.pathname.split('/')[3]);
    const communityDataFromUrl = adminCommunitiesData.find(
      ({ communityInfo }) => communityInfo.id === communityIdFromUrl
    );

    dispatch(adminCommunities(adminCommunitiesData));
    dispatch(memberCommunities(memberCommunitiesData));
    dispatch(allUserCommunities(communitiesWithMembersInfo));
    dispatch(selectedCommunity(communityDataFromUrl ?? adminCommunitiesData[0]));
  } catch (error) {
    dispatch(communitiesError("Can't get community list. Please try again."));
  }
};

export const getCommunityMembers = communityId => async dispatch => {
  try {
    const response = await getCommunityMembersRequest(communityId);
    const communityMembers = response.data.body;

    const communityMembersProfiles = await Promise.all(
      communityMembers.map(async user => {
        const response = await getUserProfileRequest(user.accountId);
        const userProfileData = response.data.body;
        const renamedProperties = await renameUserProperties(userProfileData);

        return {
          ...renamedProperties,
          role: user.role,
        };
      })
    );

    dispatch(selectedCommunityMembers(communityMembersProfiles));
  } catch (error) {
    dispatch(selectedCommunityMembersError("Can't load community members."));
  }
};

export const searchCommunities = searchParams => async dispatch => {
  try {
    dispatch(searchedCommunitiesLoading());

    const response = await searchCommunitiesRequest(searchParams);
    const communities = response.data.body.records;

    const searchedCommunitiesWithMembers = await Promise.all(
      communities.map(async community => {
        const membersResponse = await getCommunityMembersRequest(community.id);

        return {
          ...community,
          members: membersResponse.data.body,
        };
      })
    );

    dispatch(searchedCommunities(searchedCommunitiesWithMembers));
  } catch (error) {
    dispatch(searchedCommunitiesError('There was an error with loading communities. Please try again later.'));
  }
};

export const getCommunityScreen = communityId => async (dispatch, getState) => {
  try {
    dispatch(communityScreenLoading());

    const response = await getCommunityScreenRequest(communityId);
    const communityData = response.data.body;

    if (communityData.communityStatus === COMMUNITY_USER_STATUS.JOINED) {
      const accountId = getState().auth.accountId;
      const rolesRespone = await getCommunityRolesRequest(accountId, { roles: ['ADMIN', 'OPERATOR'] });
      const communitiesWithRoles = rolesRespone.data.body;

      const community = communitiesWithRoles.find(com => com.communityId === communityData.community.id);

      communityData.userRole = community?.role;
    }

    dispatch(communityScreen(communityData));
  } catch (error) {
    dispatch(communityScreenError('There was an error with loading community details. Please try again.'));
  }
};

export const getCommunityScreenMembers = communityId => async dispatch => {
  try {
    dispatch(communityScreenMembersLoading());
    const response = await getCommunityMembersRequest(communityId);
    const communityMembers = response.data.body;

    const communityMembersProfiles = await Promise.all(
      communityMembers.map(async user => {
        const response = await getUserProfileRequest(user.accountId);
        const userProfileData = response.data.body;
        const renamedProperties = await renameUserProperties(userProfileData);

        return {
          ...renamedProperties,
          role: user.role,
        };
      })
    );

    dispatch(communityScreenMembers(communityMembersProfiles));
  } catch (error) {
    dispatch(
      communityScreenMembersErrorMessage('There was an error with loading community members. Please try again.')
    );
  }
};
