import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const FormTypeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormTypeSelect = styled.p`
  display: flex;
  width: 100%;
  height: 56px;
  margin-top: 0;
  background-color: ${({ active }) => (active ? colors.highlight3 : colors.highlight2)};
  color: ${({ active }) => (active ? colors.white : colors.whiteOpacity._75)};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;
