import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createLeagueGroups } from '~/api';
import BackButton from '~/components/shared/BackButton';
import { selectedLeague, selectedLeagueMembers } from '~/store/leagues/slice';
import { Button, ErrorMessage, Icon, Input, Modal, useEnter } from '~/ui';

import { CreateGroupsButton, GroupName, GroupsContainer, ModalContainer, NoGroupsContainer, Title } from './styles';

const LeagueCreateGroupsModal = ({ league, onHide }) => {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState('');
  const [newGroupError, setNewGroupError] = useState('');

  const addNewGroup = () => {
    if (!newGroup) {
      return;
    } else if (groups.includes(newGroup)) {
      setNewGroupError(`${newGroup} already exists.`);
    } else {
      setGroups([...groups, newGroup]);
      setNewGroup('');
      setNewGroupError('');
    }
  };

  useEnter(addNewGroup);

  const removeGroup = removeGroupName => {
    const newGroups = groups.filter(group => group !== removeGroupName);
    setGroups(newGroups);
    setNewGroupError('');
  };

  const groupsFull = [...groups, ...league.groupsAndMembers].length >= 16;

  const handleCreateGroupsSubmit = async () => {
    try {
      const newLeagueData = await createLeagueGroups(league.id, groups);
      const { leagueInfo, leagueMembersProfiles } = newLeagueData;

      dispatch(selectedLeague(leagueInfo));
      dispatch(selectedLeagueMembers(leagueMembersProfiles));
      onHide();
    } catch (error) {
      setNewGroupError('Something went wrong with creating new group. Try again.');
    }
  };

  return (
    <ModalContainer increaseHeight={newGroupError || groupsFull}>
      <Modal>
        <BackButton onClick={onHide} />
        <Title>Create groups</Title>
        <Input
          placeholder='Group name'
          type='text'
          value={newGroup}
          onChange={e => setNewGroup(e.target.value.trimStart())}
        />
        {newGroupError && <ErrorMessage>{newGroupError}</ErrorMessage>}
        {groupsFull && <ErrorMessage>You have reached a limit on the number of groups for this league.</ErrorMessage>}
        <Button disabled={groupsFull || !newGroup} small onClick={addNewGroup}>
          Add group
        </Button>
        {!groups.length ? (
          <NoGroupsContainer>
            <Icon color='ash2' icon='info' />
            <span>As you add groups, they will appear in the list below.</span>
          </NoGroupsContainer>
        ) : (
          <GroupsContainer>
            {groups.map(group => (
              <GroupName key={group}>
                {group}
                <Icon cursor='pointer' icon='close' onClick={() => removeGroup(group)} />
              </GroupName>
            ))}
          </GroupsContainer>
        )}
        <CreateGroupsButton disabled={!groups.length} onClick={handleCreateGroupsSubmit}>
          <Icon color='tmGreen' icon='multipleUsers' />
          Create Groups
        </CreateGroupsButton>
      </Modal>
    </ModalContainer>
  );
};

export default LeagueCreateGroupsModal;
