import React from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../../components/Spinner';

const withSpinner =
  WrappedComponent =>
  ({ ...otherProps }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isLoading } = useSelector(state => state.general);

    return isLoading ? <Spinner /> : <WrappedComponent {...otherProps} />;
  };

export default withSpinner;
