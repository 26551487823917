import React from 'react';

import { Container, Label } from './styles';

function withLabel(WrappedComponent) {
  function WithLabel({ label, ...rest }) {
    return (
      <Container>
        {label && <Label>{label}</Label>}
        <WrappedComponent {...rest} />
      </Container>
    );
  }

  return WithLabel;
}

export default withLabel;
