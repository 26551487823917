import debounce from 'lodash/debounce';
import React from 'react';

import { Icon } from '~/ui';

import { SearchContainer, SearchIcon, SearchInput, SearchInputNewStyle } from './styles';

const Search = ({ onSearch, isNewStyle = false, onSearchDelay = 500, placeholder = 'Search...', ...rest }) => {
  const debouncedOnSearch = debounce(onSearch, onSearchDelay);

  if (isNewStyle)
    return (
      <SearchContainer>
        <SearchIcon>
          <Icon color='ash' icon='search2' size='s' />
        </SearchIcon>
        <SearchInputNewStyle
          placeholder={placeholder}
          onChange={e => debouncedOnSearch(e.target.value.trimStart())}
          {...rest}
        />
      </SearchContainer>
    );

  return (
    <SearchContainer>
      <SearchIcon>
        <Icon color='ash' icon='search2' />
      </SearchIcon>
      <SearchInput placeholder={placeholder} onChange={e => debouncedOnSearch(e.target.value.trimStart())} {...rest} />
    </SearchContainer>
  );
};

export default Search;
