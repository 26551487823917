import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton, ErrorMessage, FlexColumnWrapper, TextWithIcon } from '~/components/shared';
import { COMPETITION_ELIGIBILITY_STATUS } from '~/enums/competition';
import { DATE_FORMATS } from '~/enums/dates';
import { GENDER } from '~/enums/member';
import { getTournamentScreen } from '~/store/tournaments/tournamentScreen.utils';
import { Button, Icon, Spinner } from '~/ui';
import { formatDateFromUnixTime } from '~/utils/dates';

import NewsList from '../shared/NewsList/index';
import MyMatches from './MyMatches/index';
import { AboutTournamentContainer, InfoContainer, InfoText, InfoTitle } from './styles';
import TournamentEndedStatus from './TournamentEndedStatus/index';
import TournamentIneligibleStatus from './TournamentIneligibleStatus';
import TournamentOpenForRegistrationStatus from './TournamentOpenForRegistrationStatus';
import TournamnetRegistrationFinishedStatus from './TournamentRegistrationsFinishedStatus';
import TournamentUserRegisteredStatus from './TournamentUserRegisteredStatus';
import TournamentWinners from './TournamentWinners/index';

const TournamentDetails = () => {
  const dispatch = useDispatch();
  const { tournamentId } = useParams();
  const history = useHistory();

  const { data, isLoading, errorMessage } = useSelector(state => state.tournamentScreen);

  useEffect(() => {
    if (tournamentId) {
      dispatch(getTournamentScreen(tournamentId));
    }
  }, [tournamentId, dispatch]);

  if (isLoading || isEmpty(data)) {
    return <Spinner />;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const communityName = get(data, ['community', 'communityInfo', 'communityName'], '');
  const communityId = get(data, ['community', 'communityInfo', 'id'], '');
  const tournamentName = get(data, ['tournamentResponse', 'name'], '');
  const tournamentConcluded = get(data, ['tournamentResponse', 'concluded'], false);
  const tournamentDescription = get(data, ['tournamentResponse', 'additionalDescription'], '');
  const tournamentStartDate = get(data, ['tournamentResponse', 'startTime'], 0);
  const tournamentEndDate = get(data, ['tournamentResponse', 'endTime'], 0);
  const registerEndTime = get(data, ['tournamentResponse', 'registerEndTime'], '');
  const numberOfPlayers = get(data, ['tournamentResponse', 'numberOfRegisteredPlayers'], 0);
  const skillLevelFrom = get(data, ['tournamentResponse', 'skillLevelFrom', 'prettyName'], '').split(' ')[1];
  const skillLevelTo = get(data, ['tournamentResponse', 'skillLevelTo', 'prettyName'], '').split(' ')[1];
  const ageFrom = get(data, ['tournamentResponse', 'ageFrom'], 0);
  const ageTo = get(data, ['tournamentResponse', 'ageTo'], 0);
  const gender = get(data, ['tournamentResponse', 'gender'], null);
  const { tournamentEligibilityStatus } = data;

  let genderIcon = 'unisex2';
  let genderText = 'Men and Women';

  if (gender) {
    genderIcon = gender === GENDER.MALE ? 'male' : 'female';
    genderText = gender === GENDER.MALE ? 'Male' : 'Female';
  }

  return (
    <FlexColumnWrapper>
      <AboutTournamentContainer>
        <BackButton
          text={communityName}
          onClick={() => history.push(`/home/community/${communityId}`, { tab: 'tournaments' })}
        />
        <TournamentEndedStatus tournamentConcluded={tournamentConcluded} />
        <TextWithIcon
          icon='trophy2'
          iconColor='tmGreen'
          iconSize='l'
          text={tournamentName}
          textStyle={{ fontSize: '27px', lineHeight: '40px' }}
        />
        <InfoContainer>
          <TextWithIcon
            icon='calendar'
            iconColor='tmGreen'
            text={`${formatDateFromUnixTime(
              tournamentStartDate,
              DATE_FORMATS.MONTH_DAY_WITH_TIME
            )} - ${formatDateFromUnixTime(tournamentEndDate, DATE_FORMATS.MONTH_DAY_WITH_TIME)}`}
          />
          <TextWithIcon icon='user2' iconColor='tmGreen' text={`${numberOfPlayers} players`} />
          <TextWithIcon icon='badge' iconColor='tmGreen' text={`Lvl: ${skillLevelFrom} - ${skillLevelTo}`} />
          <TextWithIcon icon='balloon' iconColor='tmGreen' text={`Age: ${ageFrom} - ${ageTo}`} />
          <TextWithIcon icon={genderIcon} iconColor='tmGreen' text={genderText} />
        </InfoContainer>
        <InfoTitle>Tournament info/rules</InfoTitle>
        <InfoText>{tournamentDescription}</InfoText>
      </AboutTournamentContainer>
      {!isEmpty(data.bracketRounds) && COMPETITION_ELIGIBILITY_STATUS.JOINED === tournamentEligibilityStatus && (
        <>
          <Button fullWidth isNewStyle onClick={() => history.push(`/tournament-tree/${tournamentId}`)}>
            <Icon color='tmGreen' cursor='pointer' icon='brackets2' size='s' />
            Open Tournament Tree
          </Button>
          <TournamentWinners winners={data.tournamentWinners || []} />
          <MyMatches />
        </>
      )}
      {tournamentStartDate > new Date().getTime() / 1000 && (
        <>
          <TournamentIneligibleStatus eligibilityStatus={tournamentEligibilityStatus} />
          <TournamentOpenForRegistrationStatus
            communityName={communityName}
            eligibilityStatus={tournamentEligibilityStatus}
            registerEndTime={registerEndTime}
            tournamentName={tournamentName}
          />
          <TournamentUserRegisteredStatus
            eligibilityStatus={tournamentEligibilityStatus}
            registerEndTime={registerEndTime}
            tournamentName={tournamentName}
            tournamentStartDate={tournamentStartDate}
          />
          <TournamnetRegistrationFinishedStatus
            eligibilityStatus={tournamentEligibilityStatus}
            registerEndTime={registerEndTime}
            tournamentName={tournamentName}
            tournamentStartDate={tournamentStartDate}
          />
        </>
      )}
      <NewsList emptyListMessage={`There are no news at ${tournamentName} tournament.`} news={data.news} />
    </FlexColumnWrapper>
  );
};

export default TournamentDetails;
