import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const PaginationWrapper = styled.div`
  margin: 0 auto;

  .container {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
  }

  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    cursor: pointer;
  }

  .pageLink {
    width: 24px;
    color: ${colors.whiteOpacity._75};
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    outline: none;

    &.pageActiveLink {
      color: ${colors.white};
      font-weight: bold;
    }
  }

  .break {
    margin: 0 8px;
    cursor: pointer;
  }

  .breakLink {
    color: ${colors.whiteOpacity._75};
    outline: none;
  }
`;
