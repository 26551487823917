import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedCommunity } from '~/store/communities/slice';

import { MenuContainer, MenuItem, StyledLink, Title } from './styles';

const CommunityMenu = ({ setShowCommunityMenu }) => {
  const dispatch = useDispatch();
  const { adminCommunities, selectedCommunity: currentCommunity } = useSelector(state => state.communities);

  const handleSelectCommunity = community => {
    dispatch(selectedCommunity(community));
    setShowCommunityMenu(false);
  };

  const filteredCommunities = adminCommunities.filter(
    community => community.communityInfo.id !== currentCommunity.communityInfo.id
  );

  return (
    <MenuContainer>
      <Title>Community</Title>
      {filteredCommunities &&
        filteredCommunities.map(community => (
          <MenuItem key={community.communityInfo.id} onClick={() => handleSelectCommunity(community)}>
            {community.communityInfo.communityName}
          </MenuItem>
        ))}
      <StyledLink to='/admin/new-community' onClick={() => setShowCommunityMenu(false)}>
        + create a community
      </StyledLink>
    </MenuContainer>
  );
};

export default CommunityMenu;
