import React from 'react';
import { useHistory } from 'react-router-dom';

import { WidgetItem } from '~/components/shared/styles';
import { COMPETITION_TYPE } from '~/enums/competition';
import { Icon } from '~/ui';

import {
  CompetitionItemInfo,
  CompetitionItemInfoContainer,
  CompetitionItemTitle,
  CompetitionItemTitleContainer,
  CompetitionWinnerInfo,
} from './styles';

const CompetitionItem = ({ type, name, id, info, winnerInfo }) => {
  const history = useHistory();

  return (
    <WidgetItem onClick={() => history.push(`/home/${type.toLowerCase()}/${id}`)}>
      <CompetitionItemInfoContainer>
        <CompetitionItemTitleContainer>
          <Icon
            color='tmGreen'
            icon={type.toLowerCase() === COMPETITION_TYPE.LEAGUE ? 'badge' : 'trophy2'}
            size='m'
            style={{
              minWidth: '24px',
              minHeight: '24px',
            }}
          />
          <CompetitionItemTitle>{name}</CompetitionItemTitle>
        </CompetitionItemTitleContainer>
        <CompetitionItemInfo>{info}</CompetitionItemInfo>
        {winnerInfo && (
          <CompetitionWinnerInfo isWinner={/winner/gi.test(winnerInfo)}>{winnerInfo}</CompetitionWinnerInfo>
        )}
      </CompetitionItemInfoContainer>
      <Icon
        color='ash3'
        icon='rightArrow2'
        size='s'
        style={{
          minWidth: '16px',
          minHeight: '16px',
        }}
      />
    </WidgetItem>
  );
};

export default CompetitionItem;
