import React from 'react';
import { useSelector } from 'react-redux';

import { ErrorMessage } from '~/ui';

const ForgotPasswordMessages = () => {
  const { message } = useSelector(state => state.auth.forgotPassword);

  const style = {
    margin: '0px 32px 24px',
  };

  if (message.success) {
    return (
      <div style={style}>
        <ErrorMessage bold>{message.success}</ErrorMessage>
        <ErrorMessage>{message.notice}</ErrorMessage>
      </div>
    );
  }

  if (message.error) {
    return (
      <div style={style}>
        <ErrorMessage bold>{message.error}</ErrorMessage>
      </div>
    );
  }

  return;
};

export default ForgotPasswordMessages;
