import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { applyForCompetition } from '~/api/competition';
import { ErrorMessage } from '~/components/shared';
import { ANALYTICS_EVENTS } from '~/enums';
import { COMPETITION_ELIGIBILITY_STATUS } from '~/enums/competition';
import { DATE_FORMATS } from '~/enums/dates';
import { getTournamentScreen } from '~/store/tournaments/tournamentScreen.utils';
import { Button } from '~/ui/index';
import { trackAnalyticsEvent } from '~/utils';
import { formatDateFromUnixTime } from '~/utils/dates';

import { StatusContainer, StatusMessage } from '../styles';

const TournamentOpenForRegistrationStatus = ({ eligibilityStatus, registerEndTime, tournamentName, communityName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { tournamentId } = useParams();
  const dispatch = useDispatch();

  if (
    eligibilityStatus !== COMPETITION_ELIGIBILITY_STATUS.ELIGIBLE ||
    eligibilityStatus === COMPETITION_ELIGIBILITY_STATUS.INELIGIBLE ||
    new Date().getTime() > registerEndTime * 1000
  ) {
    return null;
  }

  const onSignMeUp = () => {
    applyForCompetition(tournamentId)
      .then(() => {
        setIsLoading(false);
        trackAnalyticsEvent(ANALYTICS_EVENTS.USER_REGISTERED_FOR_TOURNAMENT);
        dispatch(getTournamentScreen(tournamentId));
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage('Something went wrong. Please try again.');
      });
  };

  return (
    <StatusContainer>
      <Button isNewStyle onClick={onSignMeUp}>
        {isLoading ? 'Signing up...' : 'Sign me up!'}
      </Button>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <StatusMessage>
        Registrations for {tournamentName} at {communityName} are open until{' '}
        {formatDateFromUnixTime(registerEndTime, DATE_FORMATS.MONTH_WITH_DAY_NAME_AND_TIME)}.
      </StatusMessage>
    </StatusContainer>
  );
};

export default TournamentOpenForRegistrationStatus;
