import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  errorMessage: '',
};

export const tournamentTreeSlice = createSlice({
  name: 'tournamentTree',
  initialState,
  reducers: {
    setTournamentTreeData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setIsLoadingTournamentTreeData: state => {
      state.isLoading = true;
    },
    setTournamentTreeErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
      state.data = {};
      state.isLoading = false;
    },
  },
});

export const { setTournamentTreeData, setIsLoadingTournamentTreeData, setTournamentTreeErrorMessage } =
  tournamentTreeSlice.actions;

export default tournamentTreeSlice.reducer;
