import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { deleteNewsByIdRequest, deleteNewsIdImageRequest, updateNewsByIdRequest } from '~/api';
import NewsArticleForm from '~/components/Admin/NewsArticleForm';
import BackButton from '~/components/shared/BackButton';
import ConfirmModal from '~/components/shared/ConfirmModal';
import { getNewsById } from '~/store/news/utils';
import { ErrorMessage, PageTitle } from '~/ui';

const NewsEditArticlePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { newsId } = useParams();

  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { selectedNews, selectedNewsError } = useSelector(state => state.news);
  const [articleError, setArticleError] = useState('');
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  useEffect(() => {
    const loadNews = async () => {
      await dispatch(getNewsById(newsId));
    };

    if (!selectedNews.title) loadNews();
  }, [dispatch, newsId]); // eslint-disable-line

  const handleEditArticleSubmit = props => {
    const { title, subtitle, message, imageUri, visible } = props;

    const data = {
      title,
      subtitle,
      message,
      imageUri,
      visible,
    };

    updateNewsByIdRequest(selectedNews.id, data)
      .then(() => {
        setArticleError('');
        history.push(`/admin/community/${communityId}/news/${selectedNews.id}`);
      })
      .catch(() => setArticleError('Something went wrong. Try again.'));
  };

  const handleDeleteArticleSubmit = e => {
    e.preventDefault();

    deleteNewsByIdRequest(selectedNews.id)
      .then(() => {
        setArticleError('');
        history.push(`/admin/community/${communityId}/news`);
      })
      .catch(() => {
        setArticleError('Something went wrong. Try again.');
      })
      .finally(() => setOpenConfirmDeleteModal(false));
  };

  const deleteImage = () => {
    deleteNewsIdImageRequest(selectedNews.id)
      .then(() => setArticleError(''))
      .catch(() => setArticleError('Something went wrong with deleting the image. Try again.'));
  };

  return (
    <>
      <BackButton onClick={() => history.goBack()} />
      {selectedNewsError ? (
        <ErrorMessage>{selectedNewsError}</ErrorMessage>
      ) : (
        <>
          <PageTitle title='Edit article' />
          <NewsArticleForm
            articleError={articleError}
            deleteArticle={() => setOpenConfirmDeleteModal(true)}
            deleteArticleImage={deleteImage}
            formSubmit={handleEditArticleSubmit}
            isEdit
          />
          {openConfirmDeleteModal && (
            <ConfirmModal
              cancel={() => setOpenConfirmDeleteModal(false)}
              cancelButtonText='Cancel'
              confirm={handleDeleteArticleSubmit}
              confirmButtonColor='danger'
              confirmButtonIcon='trash'
              confirmButtonText='Delete article'
              text={`Are you sure you want to delete the article "${selectedNews.title}"?`}
              title='Delete article?'
            />
          )}
        </>
      )}
    </>
  );
};

export default NewsEditArticlePage;
