import React from 'react';

import { Icon } from '~/ui';

import { WidgetHeaderContainer } from './styles';

const WidgetHeader = ({ icon, title, iconColor = 'ash' }) => {
  return (
    <WidgetHeaderContainer>
      {icon && <Icon color={iconColor} icon={icon} />}
      <h2>{title}</h2>
    </WidgetHeaderContainer>
  );
};

export default WidgetHeader;
