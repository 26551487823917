import colors from './colors';
import mediaQueries from './mediaQueries';
import zIndex from './zIndex';

const theme = {
  colors,
  mediaQueries,
  zIndex,
};

export default theme;
