import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import manServe from '~/assets/images/man-serve.png';
import { Button, ErrorMessage, Icon } from '~/ui';

import { Container, HeaderImage, Title, WelcomeMessage } from './styles';

const WelcomePage = ({ match }) => {
  const { communitiesError } = useSelector(state => state.communities);

  return (
    <>
      <HeaderImage className='noDefaultPadding' communityImage={manServe} />
      {communitiesError ? (
        <ErrorMessage>{communitiesError}</ErrorMessage>
      ) : (
        <Container>
          <Title>Welcome to TennisMate!</Title>
          <WelcomeMessage>To start using the TennisMate admin panel, please create a new community.</WelcomeMessage>
          <Link to={`${match.path}/new-community`}>
            <Button>
              <Icon color='tmGreen' cursor='pointer' icon='ballPlus' />
              Create a community
            </Button>
          </Link>
        </Container>
      )}
    </>
  );
};

export default WelcomePage;
