import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const ItemContent = styled.div`
  display: flex;
  gap: 16px;
`;

export const Image = styled.img`
  width: 160px;
  min-width: 160px;
  height: 80px;
  object-fit: cover;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.white};
  margin: 0;
`;

export const Message = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.8;
  color: ${colors.ash2};
  margin: 0;
`;

export const Time = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: ${colors.ash3};
`;
