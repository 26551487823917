import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '~/ui';

const { mediaQueries, colors } = theme;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: ${mediaQueries.tablet}px) {
    display: none;
  }
`;

export const NavLinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  > span:not(.icon) {
    color: ${colors.white};
  }

  &.active span {
    color: ${colors.tmGreen};
  }

  &:hover span {
    color: ${colors.tmGreen};
  }
`;
