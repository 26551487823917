import { createSlice } from '@reduxjs/toolkit';

// Slice
const initialState = {
  player1: null,
  player2: null,
  date: null,
  time: null,
  venue: null,
};

export const matchFormSlice = createSlice({
  name: 'matchForm',
  initialState: {},
  reducers: {
    resetMatchForm: () => {
      return {};
    },
    setMatchForm: () => {
      return initialState;
    },
    updateMatchFormField: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export default matchFormSlice.reducer;

// Actions
export const { resetMatchForm, setMatchForm, updateMatchFormField } = matchFormSlice.actions;

// Selectors
export const matchForm = state => state.matchForm;
