import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Container = styled.div`
  position: relative;
  top: -50px;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    top: 40px;
  }
`;

export const HeaderLabel = styled.span`
  color: ${colors.whiteOpacity._75};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const Name = styled.h1`
  margin: 8px 0 40px;
  color: ${colors.white};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`;

export const TableName = styled.h3`
  color: ${colors.white};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;
