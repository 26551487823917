import React from 'react';
import { Switch } from 'react-router-dom';

import EditProfilePage from '~/pages/Admin/EditProfile';
import MemberProfilePage from '~/pages/Admin/MemberProfile';
import NotFoundPage from '~/pages/NotFound';
import CommunityDetails from '~/pages/User/CommunityDetails';
import FinishedTournaments from '~/pages/User/CommunityDetails/Tournaments/FinishedTournaments';
import Home from '~/pages/User/Home';
import JoinCommunity from '~/pages/User/JoinCommunity';
import AllChallenges from '~/pages/User/MatchesCentral/AllChallenges/index';
import AllRecentMatches from '~/pages/User/MatchesCentral/AllRecentMatches/index';
import AllUpcomingMatches from '~/pages/User/MatchesCentral/AllUpcomingMatches/index';
import MatchesCentral from '~/pages/User/MatchesCentral/index';
import TournamentDetails from '~/pages/User/TournamentDetails/index';
import { ProtectedRoute, withSpinner } from '~/ui';

const UserRoutes = ({ parentRoute }) => {
  return (
    <Switch>
      <ProtectedRoute component={JoinCommunity} exact path={`${parentRoute}/join-community`} />
      <ProtectedRoute component={CommunityDetails} exact path={`${parentRoute}/community/:communityId`} />
      <ProtectedRoute
        component={FinishedTournaments}
        exact
        path={`${parentRoute}/community/:communityId/finished-tournaments`}
      />
      <ProtectedRoute component={TournamentDetails} exact path={`${parentRoute}/tournament/:tournamentId`} />
      <ProtectedRoute component={MatchesCentral} exact path={`${parentRoute}/matches-central`} />
      <ProtectedRoute component={AllChallenges} exact path={`${parentRoute}/matches-central/challenges`} />
      <ProtectedRoute component={AllUpcomingMatches} exact path={`${parentRoute}/matches-central/upcoming`} />
      <ProtectedRoute component={AllRecentMatches} exact path={`${parentRoute}/matches-central/recent`} />
      <ProtectedRoute component={EditProfilePage} exact path={`${parentRoute}/my-profile/edit`} />
      <ProtectedRoute component={MemberProfilePage} exact path={`${parentRoute}/my-profile`} />
      <ProtectedRoute component={Home} exact path={`${parentRoute}`} />
      <ProtectedRoute component={NotFoundPage} path={`${parentRoute}/*`} />
    </Switch>
  );
};

export default withSpinner(UserRoutes);
