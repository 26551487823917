import styled from 'styled-components';

import theme from '../../theme';

const { colors } = theme;

export const TimePickerContainer = styled.div`
  .react-time-picker {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 1px solid ${colors.invert};
    background-color: ${colors.highlight2};

    &.react-time-picker--open {
      background-color: ${colors.highlight3};
    }
  }

  .react-time-picker__wrapper {
    border: none;
  }

  .react-time-picker__inputGroup {
    padding: 0 16px;
    color: ${colors.whiteOpacity._75};
  }

  .react-time-picker__inputGroup__input {
    color: ${colors.whiteOpacity._75};
    font-size: 16px;
    outline: none;

    &.react-time-picker__inputGroup__amPm {
      cursor: pointer;
    }
  }
`;
