import React, { useState } from 'react';

import { Icon } from '~/ui';

import UserMenu from './AccountMenu';
import Logo from './Logo';
import MobileMenu from './MobileMenu/index';
import Navigation from './Navigation';
import { LinksContainer, LogoContainer, MobileMenuButton, NavContainer } from './styles';

const TopNavigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <NavContainer>
      <LogoContainer>
        <MobileMenuButton onClick={() => setIsMobileMenuOpen(true)}>
          <Icon cursor='pointer' icon='menu2' />
        </MobileMenuButton>
        <Logo />
      </LogoContainer>
      <LinksContainer>
        <Navigation />
        <UserMenu />
      </LinksContainer>
      {isMobileMenuOpen && <MobileMenu setIsOpen={setIsMobileMenuOpen} />}
    </NavContainer>
  );
};

export default TopNavigation;
