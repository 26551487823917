import React from 'react';

import { NoticeContainer, NoticeText, NoticeTitle } from './styles';

const Notice = ({ firstText, secondText, title }) => {
  return (
    <NoticeContainer>
      <NoticeTitle>{title}</NoticeTitle>
      <NoticeText>{firstText}</NoticeText>
      {secondText && <NoticeText>{secondText}</NoticeText>}
    </NoticeContainer>
  );
};

export default Notice;
