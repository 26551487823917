import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { createCommunity, updateCommunity, uploadCommunityImageRequest } from '~/api';
import AsyncLocationDropdown from '~/components/shared/AsyncLocationDropdown';
import BackButton from '~/components/shared/BackButton';
import { ANALYTICS_EVENTS } from '~/enums';
import { getMyCommunities } from '~/store/communities/utils';
import { Button, ErrorMessage, Icon, Input, Spinner, Textarea } from '~/ui';
import { isUrlValidWithHttpPrefix, trackAnalyticsEvent } from '~/utils';

import CommunityHeaderImage from '../CommunityHeaderImage';
import { Container, CreatedCommunityMessage, CreatedCommunityMessageTitle, StyledButton, Title } from './styles';

const CommunityForm = ({ isEdit = false, onBack = () => {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCommunity = useSelector(state => state.communities.selectedCommunity.communityInfo);

  const [communityImage, setCommunityImage] = useState(isEdit ? selectedCommunity.imageUrl : null);
  const [communityName, setCommunityName] = useState(isEdit ? selectedCommunity.communityName : '');
  const [email, setEmail] = useState(isEdit ? selectedCommunity.email : '');
  const [court, setCourt] = useState(isEdit ? selectedCommunity.court : '');
  const [description, setDescription] = useState(isEdit ? selectedCommunity.info : '');
  const [website, setWebsite] = useState(isEdit ? selectedCommunity.url : '');
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState('');
  const [selectedImageTypeUpload, setSelectedImageTypeUpload] = useState('');
  const [isCreatedSuccessfully, setIsCreatedSuccessfully] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const hiddenCommunityImageFileInput = useRef(null);

  const handleSelectFileWindow = e => {
    e.preventDefault();
    const selectedUpload = e.target.id;
    setSelectedImageTypeUpload(selectedUpload);

    if (selectedUpload === 'communityImage') {
      hiddenCommunityImageFileInput.current.click();
    }
  };

  const handleFileUpload = async e => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      // get image and create image URI
      const reader = new FileReader();
      reader.onloadend = () => {
        if (selectedImageTypeUpload === 'communityImage') {
          setCommunityImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNewCommunitySubmit = async e => {
    e.preventDefault();

    const validUrl = isUrlValidWithHttpPrefix(website);

    if (!validUrl) {
      setWebsiteErrorMessage('Website address invalid. URL must contain http or https. E.g. https://www.example.com');
    } else {
      const data = {
        email,
        communityName: communityName.trim(),
        court: court,
        url: website,
        info: description,
        private: false,
      };

      if (data.communityName === '') {
        return setError("Community name can't be empty.");
      }

      if (isEdit) {
        const communityId = selectedCommunity.id;

        setIsLoading(true);
        updateCommunity({ communityId, data })
          .then(async () => {
            if (communityImage.includes('data:image')) {
              await uploadCommunityImageRequest(communityId, communityImage);
            }
          })
          .then(async () => {
            await dispatch(getMyCommunities());
            setIsLoading(false);
            onBack();
          })
          .catch(() => {
            setError('Something went wrong with updating the community. Try again.');
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        createCommunity(data)
          .then(async res => {
            const communityId = res.data.body.id;

            setIsCreatedSuccessfully(true);

            if (communityImage?.includes('data:image')) {
              await uploadCommunityImageRequest(communityId, communityImage);
            }
            trackAnalyticsEvent(ANALYTICS_EVENTS.COMMUNITY_CREATED);
            setIsLoading(false);
          })
          .then(async () => await dispatch(getMyCommunities()))
          .catch(e => {
            setIsCreatedSuccessfully(false);
            setIsLoading(false);
            setError('Something went wrong with creating the community. Try again.');
          });
      }

      setWebsiteErrorMessage('');
    }
  };

  return (
    <>
      {isCreatedSuccessfully ? (
        <>
          <CommunityHeaderImage image={communityImage} />
          <Container communityCreated>
            <Title>{communityName}</Title>
            <CreatedCommunityMessageTitle>Your community has been created!</CreatedCommunityMessageTitle>
            <CreatedCommunityMessage>
              You are now able to continue setting up your community by adding members, creating tournaments, leagues
              and more!
            </CreatedCommunityMessage>
            <Link to='/admin'>
              <Button>
                <Icon color='tmGreen' cursor='pointer' icon='ballPlus' />
                Continue to admin panel
              </Button>
            </Link>
          </Container>
        </>
      ) : (
        <>
          {isLoading ? (
            <Spinner />
          ) : (
            <form onSubmit={handleNewCommunitySubmit}>
              <CommunityHeaderImage image={communityImage}>
                {communityImage ? (
                  <>
                    <StyledButton id='communityImage' onClick={handleSelectFileWindow}>
                      <Icon cursor='pointer' icon='camera' />
                      Edit
                    </StyledButton>
                    <StyledButton type='reset' onClick={() => setCommunityImage()}>
                      <Icon cursor='pointer' icon='close' />
                      Remove
                    </StyledButton>
                  </>
                ) : (
                  <span id='communityImage' onClick={handleSelectFileWindow}>
                    <Icon cursor='pointer' icon='camera' />
                    Add your community photo
                  </span>
                )}
                <input
                  accept='image/*'
                  ref={hiddenCommunityImageFileInput}
                  style={{ display: 'none' }}
                  type='file'
                  onChange={handleFileUpload}
                />
              </CommunityHeaderImage>
              <Container>
                <BackButton
                  type='reset'
                  onClick={() => {
                    if (isEdit) {
                      onBack();
                    } else {
                      history.goBack();
                    }
                  }}
                />
                <Title>{isEdit ? 'Edit' : 'New'} Community</Title>
                <Input
                  label='Name'
                  type='text'
                  value={communityName}
                  onChange={e => setCommunityName(e.target.value)}
                />
                <Input label='Email address' type='email' value={email} onChange={e => setEmail(e.target.value)} />
                <AsyncLocationDropdown
                  label='Address'
                  placeholder='Your home court address'
                  type='courts'
                  value={court}
                  onChange={court => setCourt(court)}
                />
                <Textarea
                  label='Description'
                  placeholder='Describe your community in a couple of sentences. Your vision, rules, purpose, etc.'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
                <Input
                  error={websiteErrorMessage ? true : false}
                  label='Web site'
                  type='text'
                  value={website}
                  onChange={e => setWebsite(e.target.value)}
                />
                {websiteErrorMessage && <ErrorMessage>{websiteErrorMessage}</ErrorMessage>}
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <Button disabled={!communityName || !email || !court || !email || !description || !website} fullWidth>
                  <Icon color='tmGreen' cursor='pointer' icon={isEdit ? 'edit' : 'ballPlus'} />
                  {isEdit ? 'Save changes' : 'Create a community'}
                </Button>
              </Container>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default CommunityForm;
