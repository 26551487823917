import React from 'react';
import { Switch } from 'react-router-dom';

import FindMatch from '~/pages/Match/FindMatch/index';
import MatchDetails from '~/pages/Match/MatchDetails/index';
import NewMatch from '~/pages/Match/NewMatch';
import NotFoundPage from '~/pages/NotFound';
import { ProtectedRoute, withSpinner } from '~/ui';

const MatchRoutes = ({ parentRoute }) => {
  return (
    <Switch>
      <ProtectedRoute component={NewMatch} exact path={`${parentRoute}/new-match`} />
      <ProtectedRoute component={FindMatch} exact path={`${parentRoute}/find-match`} />
      <ProtectedRoute component={MatchDetails} exact path={`${parentRoute}/:matchId`} />
      <ProtectedRoute component={NotFoundPage} path={`${parentRoute}/*`} />
    </Switch>
  );
};

export default withSpinner(MatchRoutes);
