import { TennisMateApi } from '~/services';

export const addUserToCompetition = async (accountId, competitionId) => {
  return await TennisMateApi.post(`/competitions/${competitionId}/users/${accountId}`);
};

export const addUsersToCompetition = async (users, competitionId) => {
  const usersCalls = users.map(user => addUserToCompetition(user.accountId, competitionId));

  return await TennisMateApi.concurrentRequests(usersCalls);
};

export const removeUserFromCompetition = async (accountId, competitionId) => {
  return await TennisMateApi.delete(`/competitions/${competitionId}/users/${accountId}`);
};

export const seedPlayersToCompetition = async (players, competitionId) => {
  return await TennisMateApi.post(`/competitions/${competitionId}/seed`, { playerIds: players });
};

export const applyForCompetition = async competitionId => {
  return TennisMateApi.post(`/competitions/${competitionId}/apply`);
};

export const leaveCompetition = async (competitionId, userId) => {
  return TennisMateApi.delete(`/competitions/${competitionId}/users/${userId}`);
};

export const getPastMatches = async competitionId => {
  return TennisMateApi.get(`/tournament/${competitionId}/past-matches`);
};
