import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isProfileCompleted } = useSelector(state => state.auth);

  if (isAuthenticated && isProfileCompleted) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else if (isAuthenticated && !isProfileCompleted) {
    return <Redirect to='/complete-profile' />;
  } else {
    return <Redirect to='/login' />;
  }
};

export default ProtectedRoute;
