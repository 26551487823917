import styled from 'styled-components';

import { Button, theme } from '~/ui';

const { colors, mediaQueries, zIndex } = theme;

export const Header = styled.div`
  display: flex;
  position: relative;

  > div {
    flex: 1 1 auto;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: block;
  }
`;

export const Name = styled.h1`
  margin: 0 0 16px;
  color: ${colors.white};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`;

export const Status = styled.p`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  line-height: 16px;
`;

export const OptionsButton = styled(Button)`
  margin-left: 16px;
  padding: 12px 16px;
  background: ${colors.highlight2};
  color: ${colors.whiteOpacity._75};

  .icon {
    margin: 0;
  }

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    margin: 0;
  }
`;

export const OptionsBox = styled.div`
  width: 226px;
  height: 100px;
  border: 2px solid ${colors.background};
  padding: 12px 16px;
  position: absolute;
  right: ${({ small }) => (small ? 'unset' : 0)};
  left: ${({ small }) => (small ? 0 : 'unset')};
  top: ${({ small }) => (small ? '40px' : '55px')};
  background: ${colors.highlight3};
  box-sizing: border-box;
  z-index: ${zIndex.level1};

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    right: unset;
    top: unset;
  }
`;

export const Option = styled.p`
  margin: 0;
  padding: 12px 0px;
  color: ${colors.whiteOpacity._75};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  line-height: 24px;

  &:first-child {
    border-bottom: 1px solid ${colors.invert};
    padding-top: 0;
  }
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: block;
  }
`;

export const GroupsContainer = styled.div`
  display: flex;
`;

export const GroupList = styled.div`
  flex: 10%;
  max-width: 200px;
`;

export const GroupListItem = styled.div`
  padding: 12px 16px;
  position: relative;
  background: ${({ active }) => (active ? colors.highlight3 : colors.highlight2)};
  color: ${({ active }) => (active ? colors.white : colors.whiteOpacity._75)};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.highlight1};
  }

  > .icon {
    position: relative;
    bottom: 5px;
    float: right;
    vertical-align: middle;
  }
`;

export const GroupTableContainer = styled.div`
  flex: 80%;
  margin-left: 32px;

  > div {
    margin: 0;
  }
`;

export const SeedPlayersNoticeContainer = styled.div`
  max-width: 495px;
  > div {
    color: ${colors.whiteOpacity._75};
    line-height: 24px;
  }
`;
