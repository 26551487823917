import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGeolocation } from '~/hooks';
import { searchedCommunitiesError } from '~/store/communities/slice';
import { searchCommunities } from '~/store/communities/utils';

import ListItemsStatusFeedback from '../shared/ListItemStatusFeedback';
import CommunityListItem from './CommunityListItem';

const CommunityList = ({ searchTerm }) => {
  const dispatch = useDispatch();
  const {
    searchedCommunities: communities,
    searchedCommunitiesLoading: isLoadingCommunities,
    searchedCommunitiesError: communitiesError,
  } = useSelector(state => state.communities);

  const onLocationAccessError = useCallback(() => {
    dispatch(
      searchedCommunitiesError(
        'There was an error with loading recommended communties. Please allow location access and try again.'
      )
    );
  }, [dispatch]);

  const location = useGeolocation({
    onError: onLocationAccessError,
  });

  const getCommunities = useCallback(() => {
    let searchParams = null;
    if (!!searchTerm) {
      searchParams = { query: searchTerm };
    } else if (location) {
      searchParams = {
        location: `${Number.parseInt(location.latitude)},${Number.parseInt(location.longitude)}`,
      };
    }

    if (searchParams) {
      dispatch(searchCommunities(searchParams));
    }
  }, [dispatch, searchTerm, location, onLocationAccessError]);

  useEffect(() => {
    getCommunities();
  }, [location, searchTerm, getCommunities]);

  return (
    <>
      {communities.length ? (
        communities.map(community => {
          const { id, imageUrl, communityName, court, members } = community;

          return (
            <CommunityListItem
              address={court.formattedAddress}
              communityId={id}
              image={imageUrl}
              key={id}
              name={communityName}
              numberOfMembers={members.length}
            />
          );
        })
      ) : (
        <ListItemsStatusFeedback
          emptyListMessage='No communities found.'
          errorMessage={communitiesError}
          isError={!!communitiesError}
          isLoading={isLoadingCommunities}
        />
      )}
    </>
  );
};

export default CommunityList;
