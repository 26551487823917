import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createCommunityNewsRequest } from '~/api';
import NewsArticleForm from '~/components/Admin/NewsArticleForm';
import BackButton from '~/components/shared/BackButton';
import { getCommunityLeagues } from '~/store/leagues/utils';
import { getCommunityTournaments } from '~/store/tournaments/utils';
import { PageTitle } from '~/ui';

const NewsNewArticlePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const communityId = useSelector(state => state.communities.selectedCommunity.communityInfo.id);
  const { leaguesList } = useSelector(state => state.leagues);
  const { tournamentsList } = useSelector(state => state.tournaments);
  const [articleError, setArticleError] = useState('');
  const [competitionsList, setCompetitionsList] = useState([]);

  useEffect(() => {
    const loadLeagues = async () => {
      if (communityId) await dispatch(getCommunityLeagues(communityId));
    };

    const loadTournaments = async () => {
      if (communityId) await dispatch(getCommunityTournaments(communityId));
    };

    loadLeagues();
    loadTournaments();
  }, [dispatch, communityId]);

  useEffect(() => {
    const mergedCompetitionsList = [...leaguesList, ...tournamentsList];
    setCompetitionsList(mergedCompetitionsList);
  }, [leaguesList, tournamentsList]);

  const handleNewArticleSubmit = async props => {
    const { title, subtitle, message, imageUri, competitionId } = props;

    const data = {
      title,
      subtitle,
      message,
      imageUri,
      competitionId,
      communityId,
    };

    createCommunityNewsRequest(data)
      .then(() => {
        setArticleError('');
        history.push(`/admin/community/${communityId}/news`);
      })
      .catch(() => setArticleError('Something went wrong. Try again.'));
  };

  return (
    <>
      <BackButton onClick={() => history.goBack()} />
      <PageTitle title='Write a new article' />
      <NewsArticleForm
        articleError={articleError}
        competitionsList={competitionsList}
        formSubmit={handleNewArticleSubmit}
      />
    </>
  );
};

export default NewsNewArticlePage;
