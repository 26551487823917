import React from 'react';
import { useParams } from 'react-router-dom';

import { Table } from '~/ui';

import { getNewsColumns, getNewsData } from './utils';

const NewsTable = ({ data, error, isLoading }) => {
  const { id: communityId } = useParams();

  return (
    <Table
      columns={getNewsColumns({ communityId })}
      data={getNewsData({ data })}
      error={error}
      isLoading={isLoading}
      itemsName='community news'
      pageSize={5}
    />
  );
};

export default NewsTable;
