import React from 'react';

import { WidgetItem, WidgetWrapper } from '~/components/shared/styles';
import { Button } from '~/ui';
import { getDifferenceInYears } from '~/utils/dates';

import { UserAge, UserAvatar, UserContainer, UserName, UserPCR } from './styles';

const UserList = ({ users = [], onSelect }) => {
  return (
    <WidgetWrapper>
      {users.map(user => {
        const { userProfileCardView, pcr } = user;
        const { accountId, firstName, lastName, avatarUrl, birthday } = userProfileCardView;
        return (
          <WidgetItem key={accountId}>
            <UserContainer>
              <UserAvatar alt={firstName} src={avatarUrl} />
              <div>
                <UserName>
                  {firstName} {lastName}
                </UserName>
                <UserPCR>PCR: {pcr}</UserPCR>
                <UserAge>{getDifferenceInYears(new Date(), new Date(birthday))} years old</UserAge>
              </div>
            </UserContainer>
            <Button isNewStyle type='button' onClick={() => onSelect(user)}>
              Pick
            </Button>
          </WidgetItem>
        );
      })}
    </WidgetWrapper>
  );
};

export default UserList;
