import get from 'lodash/get';
import startCase from 'lodash/startCase';

import { MATCH_INVITE_STATUS, MATCH_STATUS } from '~/enums';

export function getMatchStatusColor(status) {
  if (MATCH_STATUS.CONFIRMED === status || MATCH_STATUS.SCHEDULED === status) {
    return 'tmGreen';
  } else if ([MATCH_STATUS.CANCELED, MATCH_STATUS.UNRESOLVED].includes(status)) {
    return 'danger';
  } else {
    return 'pending';
  }
}

export function getMatchInviteStatusColor(status) {
  if (MATCH_INVITE_STATUS.AGREED === status || MATCH_INVITE_STATUS.COMPLETED === status) {
    return 'tmGreen';
  } else if (MATCH_INVITE_STATUS.PENDING_CHALLENGER === status || MATCH_INVITE_STATUS.PENDING_OPPONENT === status) {
    return 'pending';
  }
  return 'danger';
}

export function filterMatchesBySearchValue({ searchValue, data }) {
  return data.filter(match => {
    const {
      location,
      players: [player1, player2],
    } = match;

    return (
      `${player1.firstName} ${player1.lastName}`.toLowerCase().includes(searchValue.toLowerCase()) ||
      `${player2.firstName} ${player2.lastName}`.toLowerCase().includes(searchValue.toLowerCase()) ||
      location.toLowerCase().includes(searchValue.toLowerCase())
    );
  });
}

export function filterMatchesByStatus({ status, data }) {
  return data.filter(match => {
    if (status.value === 'all matches') {
      return match;
    } else {
      return match.status.toLowerCase().replace(/_/g, ' ') === status.value;
    }
  });
}

export const filterMatchesByCompetition = ({ competition, data }) => {
  return data.filter(match => match.competition.id === competition.id);
};

export function filterMatchesByPlayers({ players, data }) {
  const playersId = players.map(player => player.accountId);

  // eslint-disable-next-line array-callback-return
  return data.filter(match => {
    const matchIncludesPlayer = match.players.map(player => playersId.includes(player.accountId));

    if (matchIncludesPlayer[0] || matchIncludesPlayer[1]) {
      return match;
    }
  });
}

export const checkMatchSetScores = (scores, i) => {
  const hostScore = +get(scores, [i, 'hostScore']);
  const guestScore = +get(scores, [i, 'guestScore']);

  if (Number.isNaN(hostScore) || Number.isNaN(guestScore)) {
    return false;
  }

  if (hostScore + guestScore <= 10 && (hostScore === 6 || guestScore === 6)) {
    return false;
  }

  if (hostScore + guestScore === 13 && (hostScore === 7 || guestScore === 7)) {
    return false;
  }

  if (hostScore + guestScore === 12 && (hostScore === 7 || guestScore === 7)) {
    return false;
  }

  return true;
};

/**
 * @param  {string} matchStatus
 */
export const checkIfShouldShowMatchStatusLabel = matchStatus => {
  return matchStatus !== MATCH_STATUS.SCHEDULED && matchStatus !== MATCH_STATUS.UNRESOLVED;
};

/**
 * @param  {string} matchStatus
 * @param {number} currentUserId
 * @param {number} hostId
 */
export const matchStatusLabelMapper = (matchStatus, currentUserId, hostId) => {
  const statusesMap = {
    [MATCH_STATUS.GUEST_CHALLENGED_RESULT]: 'Match result challenged',
    [MATCH_STATUS.HOST_CHALLENGED_RESULT]: 'Match result challenged',
    [MATCH_STATUS.GUEST_ENTERED_RESULT]: 'Result approval pending',
    [MATCH_STATUS.HOST_ENTERED_RESULT]: 'Result approval pending',
    [MATCH_STATUS.PENDING_NEW_TIME_APPROVAL_FROM_GUEST]:
      currentUserId === hostId ? 'Pending time change approval from opponent' : 'Time change requested',
    [MATCH_STATUS.PENDING_NEW_TIME_APPROVAL_FROM_HOST]:
      currentUserId === hostId ? 'Time change requested' : 'Pending time change approval from opponent',
    [MATCH_STATUS.GUEST_RECHALLENGED_RESULT]: 'Match result rechallenged',
    [MATCH_STATUS.HOST_RECHALLENGED_RESULT]: 'Match result rechallenged',
    [MATCH_STATUS.PENDING_CANCEL_APPROVAL_FROM_GUEST]:
      currentUserId === hostId ? 'Pending cancelation approval from opponent' : 'Match cancelation requested',
    [MATCH_STATUS.PENDING_CANCEL_APPROVAL_FROM_HOST]:
      currentUserId === hostId ? 'Match cancelation requested' : 'Pending cancelation approval from opponent',
    [MATCH_STATUS.CONFIRMED]: 'Completed',
  };

  return statusesMap[matchStatus] || startCase(matchStatus.toLowerCase());
};
