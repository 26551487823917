import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '~/ui';

const { colors, mediaQueries } = theme;

export const Img = styled.img`
  width: 275px;
  max-width: 25%;
  height: 155px;
  margin-right: 32px;
  object-fit: cover;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  width: 50%;

  @media screen and (max-width: ${mediaQueries.mobile}px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;

  a {
    color: ${colors.white};
  }
`;

export const NewsCategory = styled.p`
  display: flex;
  margin: 12px 0 18px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  letter-spacing: 0.92px;
  line-height: 16px;

  > .icon {
    margin-right: 12px;
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  padding: 0 0 8px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`;

export const StyledLink = styled(Link)`
  color: ${colors.tmGreen} !important;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.92px;
`;
