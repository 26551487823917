import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { addUsersToCompetition } from '~/api';
import CompetitionPlayersTable from '~/components/Admin/CompetitionPlayersTable';
import Filters from '~/components/Admin/Filters';
import MembersFilterModal from '~/components/Admin/MembersFilterModal';
import BackButton from '~/components/shared/BackButton';
import SearchAndFilter from '~/components/shared/SearchAndFilter';
import { ANALYTICS_EVENTS, COMPETITION_TYPE, GENDER, MEMBER_ERROR_MESSAGE } from '~/enums';
import { getCommunityMembers } from '~/store/communities/utils';
import { selectedLeagueReset } from '~/store/leagues/slice';
import { selectedTournamentReset } from '~/store/tournaments/slice';
import { Button, ErrorMessage, Grid, Icon, PageTitle } from '~/ui';
import {
  filterMembersByAgeRange,
  filterMembersByGender,
  filterMembersByName,
  filterMembersByPcrRange,
  filterMembersBySkillLevelRange,
  getSkillLevelNumber,
  trackAnalyticsEvent,
} from '~/utils';

import { AddPlayersButton, SearchAndFilterContainer, ShowSelectedPlayersButton } from './styles';

const defaultFilterValues = {
  gender: GENDER.BOTH,
  pcrRange: { min: 1, max: 5 },
  ageRange: { min: 16, max: 72 },
  skillLevelRange: { min: 1.0, max: 7.0 },
};

const AddCompetitionPlayersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id: urlCommunityId, leagueId, tournamentId } = useParams();
  const { communityInfo, members, membersError } = useSelector(state => state.communities.selectedCommunity);

  const competitionType = location.pathname.includes(COMPETITION_TYPE.LEAGUE)
    ? COMPETITION_TYPE.LEAGUE
    : COMPETITION_TYPE.TOURNAMENT;
  const competitionId = competitionType === COMPETITION_TYPE.LEAGUE ? leagueId : tournamentId;

  const league = useSelector(state => state.leagues.selectedLeague);
  const tournament = useSelector(state => state.tournaments.selectedTournament);
  const competition = competitionType === COMPETITION_TYPE.LEAGUE ? league : tournament;

  const {
    ageFrom: competitionAgeFrom,
    ageTo: competitionAgeTo,
    skillLevelFrom: competitionSkillLevelFrom,
    skillLevelTo: competitionSkillLevelTo,
  } = competition;

  const [loadingMembers, setLoadingMembers] = useState(false);
  const [addCompetitionPlayers, setAddCompetitionPlayers] = useState([]);
  const [playerErrors, setPlayersError] = useState('');
  const [showOnlySelectedPlayers, setShowOnlySelectedPlayers] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState(defaultFilterValues);

  if (competition.id === 0) {
    history.push(`/admin/community/${urlCommunityId}/${competitionType}/${competitionId}`);
  }

  if (communityInfo.id !== Number(urlCommunityId)) {
    if (competitionType === COMPETITION_TYPE.LEAGUE) {
      dispatch(selectedLeagueReset());
    } else {
      dispatch(selectedTournamentReset());
    }

    history.push(`/admin/community/${communityInfo.id}/${competitionType}s`);
    dispatch(getCommunityMembers(communityInfo.id));
  }

  useEffect(() => {
    const loadMembers = async () => {
      setLoadingMembers(true);
      await dispatch(getCommunityMembers(communityInfo.id));
      setLoadingMembers(false);
    };

    loadMembers();
  }, [dispatch, communityInfo.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddPlayerToCompetitionList = player => {
    if (addCompetitionPlayers.find(arrayPlayer => arrayPlayer.accountId === player.accountId)) {
      const updatedArray = addCompetitionPlayers.filter(
        competitionPlayer => competitionPlayer.accountId !== player.accountId
      );

      setAddCompetitionPlayers(updatedArray);
    } else {
      setAddCompetitionPlayers([...addCompetitionPlayers, player]);
    }
  };

  const handleAddPlayersToCompetitionSubmit = async () => {
    addUsersToCompetition(addCompetitionPlayers, competitionId)
      .then(() => {
        trackAnalyticsEvent(ANALYTICS_EVENTS.USER_REGISTERED_FOR_TOURNAMENT_BY_ADMIN);
        history.push(`/admin/community/${communityInfo.id}/${competitionType}/${competitionId}`);
      })
      .catch(error => {
        if (error.data && error.data.errors) {
          error.data.errors.map(({ code }) => {
            if (code === MEMBER_ERROR_MESSAGE.USER_ALREADY_PARTICIPATING_IN_COMPETITION) {
              setPlayersError('Note: Some of the selected players are already participating in the competition.');
            } else {
              setPlayersError('Something went wrong. Try again.');
            }

            return null;
          });
        } else {
          setPlayersError('Something went wrong. Try again.');
        }
      });
  };

  const handleClearAddedPlayersArray = () => setAddCompetitionPlayers([]);

  const handleApplyFilters = ({ ageRange, gender, pcrRange, skillLevelRange }) => {
    setFilters({
      gender,
      pcrRange,
      ageRange,
      skillLevelRange,
    });
    setShowModal(false);
  };

  const handleClearFilters = () => {
    setSearchValue('');
    setFilters(defaultFilterValues);
    setShowModal(false);
  };

  const filterMembers = data => {
    let filteredData = data;
    const { gender, pcrRange, ageRange, skillLevelRange } = filters;

    filteredData = filterMembersByName({
      name: searchValue,
      data: filteredData,
    });

    filteredData = filterMembersByGender({
      gender,
      data: filteredData,
    });

    filteredData = filterMembersByPcrRange({
      pcrRange,
      data: filteredData,
    });

    filteredData = filterMembersByAgeRange({
      ageRange,
      data: filteredData,
    });

    filteredData = filterMembersBySkillLevelRange({
      skillLevelRange,
      data: filteredData,
    });

    return filteredData;
  };

  const filteredMembers = filterMembers(
    members.filter(member => competition.members.every(player => player.accountId !== member.accountId))
  );

  const isFilterActive = !isEqual(defaultFilterValues, filters);

  return (
    <>
      <BackButton onClick={() => history.goBack()} />
      <PageTitle title={`Add players to ${capitalize(competitionType)}`} />
      <Grid.Container>
        <Grid.Row>
          <SearchAndFilterContainer>
            <SearchAndFilter
              filterActive={isFilterActive}
              filterDisabled={membersError}
              filterOnClick={() => setShowModal(true)}
              searchOnChange={e => setSearchValue(e.target.value)}
              searchValue={searchValue}
            />
          </SearchAndFilterContainer>
          <Grid.Column columns={3}>
            <ShowSelectedPlayersButton
              active={showOnlySelectedPlayers}
              noBorder
              small
              onClick={() => setShowOnlySelectedPlayers(!showOnlySelectedPlayers)}
            >
              <Icon color={showOnlySelectedPlayers ? 'tmGreen' : 'ash'} cursor='pointer' icon='reveal' />
              show only selected players
            </ShowSelectedPlayersButton>
          </Grid.Column>
          <Grid.Column columns={3}>
            <AddPlayersButton
              disabled={!addCompetitionPlayers.length}
              onClick={() => handleAddPlayersToCompetitionSubmit()}
            >
              <Icon color='tmGreen' cursor='pointer' icon='userPlus' />
              Add {addCompetitionPlayers.length > 0 ? addCompetitionPlayers.length : ''}{' '}
              {addCompetitionPlayers.length === 1 ? 'player' : 'players'}
            </AddPlayersButton>
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
      <Filters default={defaultFilterValues} filters={filters} onClick={value => setFilters(value)} />
      {playerErrors && <ErrorMessage>{playerErrors}</ErrorMessage>}
      <CompetitionPlayersTable
        competitionType={competitionType}
        data={showOnlySelectedPlayers ? addCompetitionPlayers : filteredMembers}
        error={membersError}
        isLoading={loadingMembers}
        selectedItems={addCompetitionPlayers}
        tableType='addPlayers'
        onAdd={handleAddPlayerToCompetitionList}
        onClear={handleClearAddedPlayersArray}
      />
      {!filteredMembers.length && isFilterActive && (
        <Button onClick={handleClearFilters}>
          <Icon color='tmGreen' cursor='pointer' icon='trash' />
          Clear Filters
        </Button>
      )}
      {showModal && (
        <MembersFilterModal
          defaultFilterValues={defaultFilterValues}
          filters={filters}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          notice={`${capitalize(
            competitionType
          )} is open for players aged ${competitionAgeFrom} to ${competitionAgeTo}. Skill level range is from ${getSkillLevelNumber(
            competitionSkillLevelFrom.prettyName
          )} to ${getSkillLevelNumber(competitionSkillLevelTo.prettyName)}.
          `}
          title='Filter Players'
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default AddCompetitionPlayersPage;
