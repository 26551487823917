export const ANALYTICS_EVENTS = {
  USER_LOGGED_IN: 'User logged in',
  USER_LOGGED_OUT: 'User logged out',
  NEW_ACCOUNT_CREATED: 'New account created',
  COMMUNITY_CREATED: 'Community created',
  NEW_DIRECT_MATCH_INVITE: 'New direct match invite',
  NEW_DIRECT_MATCH_INVITE_REJECTED: 'New direct match invite rejected',
  NEW_DIRECT_MATCH_SCHEDULED: 'New direct match scheduled',
  DIRECT_MATCH_COMPLETED: 'Direct match completed',
  MATCH_UNRESOLVED: 'Match unresolved',
  MATCH_RESOLUTION_PENDING: 'Match resolution pending',
  NEW_TOURNAMENT_CREATED: 'New tournament created',
  TOURNAMENT_MATCH_SCHEDULED: 'Tournament match scheduled',
  USER_REGISTERED_FOR_TOURNAMENT: 'User registered for tournament',
  USER_REGISTERED_FOR_TOURNAMENT_BY_ADMIN: 'User registered for tournament by admin',
  TOURNAMENT_COMPLETED: 'Tournament completed',
  OPEN_MATCH_CHALLENGE_CREATED: 'Open match challenge created',
  OPEN_MATCH_SCHEDULED: 'Open match scheduled',
  MATCH_CANCELED: 'Match canceled',
  COMMUNITY_LEFT: 'Community left',
  COMMUNITY_JOINED: 'Community joined',
};
