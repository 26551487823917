import styled from 'styled-components';

import { theme } from '~/ui';

const { colors } = theme;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Image = styled.img`
  width: 160px;
  min-width: 160px;
  height: 90px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  margin: 0;
`;

export const Info = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${colors.ash2};
`;
