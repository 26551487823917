import React, { useState } from 'react';

import { Icon } from '../index';
import { RatingContainer } from './styles';

const PcrBallRating = ({
  value,
  onChange,
  maxRating = 5,
  readonly = false,
  iconSize = 'm',
  fillColor = 'tmGreen',
  emptyColor = 'ash2',
}) => {
  const [currentHoveredIcon, setCurrentHoveredIcon] = useState(0);

  const onClick = i => {
    if (readonly) return;

    onChange(i);
  };

  return (
    <RatingContainer>
      {Array.from({ length: maxRating }).map((_, i) => (
        <Icon
          color={!readonly && (currentHoveredIcon >= i + 1 || value >= i + 1) ? fillColor : emptyColor}
          cursor='pointer'
          icon='ball'
          key={`ball-${i}`}
          size={iconSize}
          onClick={() => onClick(i + 1)}
          onMouseEnter={() => setCurrentHoveredIcon(i + 1)}
          onMouseLeave={() => setCurrentHoveredIcon(0)}
        />
      ))}
    </RatingContainer>
  );
};

export default PcrBallRating;
